import { forwardRef, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { useBreakpointQuery } from '../../utils';
import { JItem } from '../jformik/jformik.types';

export const FLabeledInput = forwardRef((jItem: JItem, ref) => {
  const smDown = useBreakpointQuery('sm-down');
  const [openTooltip, setOpenTooltip] = useState<boolean | undefined>(
    jItem?.tooltip?.open
  );
  return (
    <Field name={jItem.name}>
      {({
        field: { name, value, onChange, onBlur },
        form: { touched, errors, status, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        return (
          <FormControl
            fullWidth
            // size={smDown ? 'small' : 'medium'}
            variant="outlined"
            error={
              !!(getIn(errors, name) && (getIn(touched, name) || submitCount))
            }
          >
            {jItem.tooltip ? (
              <Tooltip {...{ ...jItem.tooltip, open: openTooltip }}>
                <>
                  <InputLabel htmlFor={`${name}`}>{jItem.label}</InputLabel>

                  <OutlinedInput
                    ref={ref}
                    onFocus={() => {
                      if (openTooltip) {
                        setOpenTooltip(false);
                      }
                    }}
                    id={name}
                    type={jItem.input_type || 'text'}
                    label={jItem.label}
                    inputProps={
                      jItem.inputProps
                        ? { ...jItem.inputProps, 'aria-label': 'weight' }
                        : { 'aria-label': 'weight' }
                    }
                    value={value}
                    onChange={onChange}
                    onKeyPress={(event: any) => {
                      if (event.charCode === 46 || event.charCode === 101) {
                        event.preventDefault();
                      }
                    }}
                    startAdornment={jItem?.jItemProps?.startAdornment}
                    {...jItem.jItemProps}
                  />
                </>
              </Tooltip>
            ) : (
              <>
                <InputLabel htmlFor={`${name}`}>{jItem.label}</InputLabel>

                <OutlinedInput
                  ref={ref}
                  id={name}
                  type={jItem.input_type || 'text'}
                  label={jItem.label}
                  inputProps={
                    jItem.inputProps
                      ? { ...jItem.inputProps, 'aria-label': 'weight' }
                      : { 'aria-label': 'weight' }
                  }
                  value={value}
                  onChange={onChange}
                  onKeyPress={(event: any) => {
                    if (event.charCode === 46 || event.charCode === 101) {
                      event.preventDefault();
                    }
                  }}
                  startAdornment={jItem?.jItemProps?.startAdornment}
                  {...jItem.jItemProps}
                />
              </>
            )}

            {(jItem.helpertext ||
              ((getIn(touched, name) || submitCount) &&
                getIn(errors, name))) && (
              <FormHelperText>
                {getIn(touched, name) || submitCount
                  ? getIn(errors, name) || jItem.helpertext
                  : jItem.helpertext}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
});
