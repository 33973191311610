import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import { Field, FieldProps, getIn } from "formik";
import { JItem } from "../jformik/jformik.types";
import { forwardRef } from "react";
import { useBreakpointQuery } from "../..//utils";
import { useJFormikContext } from "../jformik/jformik.context";
import moment from "moment";

export const FDateTimePicker = forwardRef((jItem: JItem, ref) => {
  const { label, name } = jItem;
  const smDown = useBreakpointQuery("sm-down");
  const { disabled = false, disableSubmitOnEnter = true } = useJFormikContext();
  return (
    <Field name={name} ref={ref}>
      {({
        field: { name, value, onChange, onBlur },
        form: { values, errors, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              reduceAnimations
              disabled={
                jItem.disabled === false ? false : disabled || jItem.disabled
              }
              // format="MM/dd/yyyy hh:mm a"
              label={label}
              value={
                value && moment(value).isValid() ? moment(value).local() : null
              }
              // minTime={new Date(0, 0, 0, 8)}
              onChange={(date) => {
                setFieldValue(
                  name,
                  date && moment(date).isValid() ? moment(date) : null
                );
              }}
              // onChange={newValue => {
              //   setFieldValue(name, newValue);
              // }}
              // mask="____/__/__ __:__ _M"
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    disabled={
                      jItem.disabled === false
                        ? false
                        : disabled || jItem.disabled
                    }
                    onKeyPress={(e) => {
                      disableSubmitOnEnter &&
                        e.key === "Enter" &&
                        e.preventDefault();
                    }}
                    variant={jItem.jItemProps?.variant || "outlined"}
                    fullWidth
                    // size={smDown ? 'small' : 'medium'}
                    error={
                      !!(
                        getIn(errors, name)
                        // (getIn(touched, name) || submitCount)
                      )
                    }
                    helperText={
                      getIn(errors, name)
                        ? getIn(errors, name)
                        : jItem?.jItemProps?.helperText
                        ? typeof jItem?.jItemProps?.helperText === "function"
                          ? jItem?.jItemProps?.helperText(values)
                          : jItem?.jItemProps?.helperText
                        : null
                    }
                    PopperProps={{
                      placement: "bottom-end",
                    }}
                  />
                ),
              }}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
});
