import { Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Field, FieldProps } from 'formik';
import { JItem } from '../jformik/jformik.types';

export interface LoadingButtonProps {
  label: string;
  loading: boolean;
}

export const FTypography = (jItem: JItem) => {
  return (
    <Field name={jItem.name}>
      {({ field: { value } }: FieldProps) => (
        <Tooltip
          title={jItem.jItemProps?.tooltip}
          placement={jItem.jItemProps?.tooltipPlacement}
        >
          <Typography
            id={`${jItem.name}-field`}
            {...jItem.jItemProps}
            sx={{
              width: '100%',
              overflow: 'hidden',
              ...(jItem.jItemProps?.sx || {}),
              color: theme =>
                jItem.disabled
                  ? theme.palette.text.disabled
                  : jItem.jItemProps?.sx?.color,
            }}
          >
            {jItem.label !== '' ? jItem.label : value}
            {jItem.jItemProps?.tooltip && (
              <InfoOutlined
                fontSize="small"
                sx={{
                  fontSize: 'inherit',
                  pl: 1,
                }}
              />
            )}
          </Typography>
        </Tooltip>
      )}
    </Field>
  );
};
