import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { Device, Call } from "@twilio/voice-sdk";
import { toast } from "react-toastify";
import { RootState } from "store/reducer";

interface TwilioVoiceState {
  deviceReady: boolean;
  callStatus: string;
  error: any;
  incomingCall: Call | null;
  outgoingCall: Call | null;
  phone: string | null;
}

const initialState: TwilioVoiceState = {
  deviceReady: false,
  callStatus: "disconnected",
  error: null,
  incomingCall: null,
  outgoingCall: null,
  phone: null,
};

// We keep the Twilio Device instance outside of the Redux state
// because it is not serializable.
let device: Device | null = null;

interface InitializeDeviceArgs {
  token: string;
  options?: Device.Options;
}

export const initializeDevice = createAsyncThunk(
  "twilioVoice/initializeDevice",
  async ({ token, options }: InitializeDeviceArgs, { dispatch, getState }) => {
    if (!token) {
      throw new Error("Twilio token is required");
    }
    const state = getState() as { twilioVoice: TwilioVoiceState };
    //Only register 1 new Device
    if (!state.twilioVoice.deviceReady) {
      if (device) {
        //destroy prior device
        await device.unregister();
        device.destroy();
        device = null;
      }
      device = new Device(token, options);

      device.on("registered", () => {
        dispatch(deviceRegistered());
      });

      device.on("error", (err) => {
        dispatch(deviceError(err.message));
      });

      device.on("incoming", (connection: Call) => {
        dispatch(incomingCallReceived(connection));
      });

      await device.register();
    }
  }
);

interface CallParams {
  [key: string]: string;
}

export const makeCall = createAsyncThunk<
  void, // Return type
  {
    params: Device.ConnectOptions | undefined;
    callback?: (update: any) => void;
  }, // Input args (including callbacks)
  { rejectValue: string }
>(
  "twilioVoice/makeCall",
  async (
    { params, callback = (update: any) => console.info(update) },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (!device) {
      throw new Error("Device is not ready");
    }
    const state = getState() as { twilioVoice: TwilioVoiceState };
    //Only 1 call at a time
    if (!state.twilioVoice.outgoingCall) {
      try {
        dispatch(callStatusChanged("connecting"));
        const connection: Call = await device.connect(params); // await resolves the promise

        dispatch(
          outgoingCallSet({
            call: connection,
            phone: params?.params?.To || null,
          })
        );
        dispatch(callStatusChanged("connecting"));
        callback({ callStatus: "Call Ongoing" });

        connection.on("accept", () => {
          dispatch(callStatusChanged("connected"));
          callback({ callStatus: "connected" });
        });

        connection.on("disconnect", () => {
          dispatch(callStatusChanged("disconnected"));
          dispatch(outgoingCallSet(null));
          callback({ callStatus: "disconnected" });
        });

        connection.on("error", (err: Error) => {
          dispatch(errorSet(err.message));
          dispatch(outgoingCallSet(null));
          dispatch(callStatusChanged("disconnected"));
          callback({ callStatus: "error" });
        });
      } catch (err: any) {
        dispatch(errorSet(err.message));
      }
    } else {
      toast.warning("A call is already taking place");
    }
  }
);

export const acceptCall = createAsyncThunk(
  "twilioVoice/acceptCall",
  async (_, { dispatch, getState }) => {
    const state = getState() as { twilioVoice: TwilioVoiceState };
    const incomingCall = state.twilioVoice.incomingCall;

    if (incomingCall) {
      incomingCall.accept();
      dispatch(callStatusChanged("connected"));

      incomingCall.on("disconnect", () => {
        dispatch(callStatusChanged("disconnected"));
        dispatch(incomingCallSet(null));
      });

      incomingCall.on("error", (err) => {
        dispatch(errorSet(err.message));
        dispatch(incomingCallSet(null));
        dispatch(callStatusChanged("disconnected"));
      });
    }
  }
);

export const rejectCall = createAsyncThunk(
  "twilioVoice/rejectCall",
  async (_, { dispatch, getState }) => {
    const state = getState() as { twilioVoice: TwilioVoiceState };
    const incomingCall = state.twilioVoice.incomingCall;

    if (incomingCall) {
      incomingCall.reject();
      dispatch(incomingCallSet(null));
      dispatch(callStatusChanged("disconnected"));
    }
  }
);

export const hangUp = createAsyncThunk(
  "twilioVoice/hangUp",
  async (_, { dispatch, getState }) => {
    const state = getState() as { twilioVoice: TwilioVoiceState };
    const outgoingCall = state.twilioVoice.outgoingCall;
    const incomingCall = state.twilioVoice.incomingCall;

    if (outgoingCall) {
      outgoingCall.disconnect();
      dispatch(outgoingCallSet(null));
      dispatch(callStatusChanged("disconnected"));
    } else if (incomingCall) {
      incomingCall.disconnect();
      dispatch(incomingCallSet(null));
      dispatch(callStatusChanged("disconnected"));
    } else if (device) {
      device.disconnectAll();
      dispatch(callStatusChanged("disconnected"));
    }
  }
);

export const destroyDevice = createAsyncThunk(
  "twilioVoice/destroyDevice",
  async () => {
    if (device) {
      await device.unregister();
      device.destroy();
      device = null;
    }
  }
);

export const twilioVoiceSlice = createSlice({
  name: "twilioVoice",
  initialState,
  reducers: {
    deviceRegistered(state) {
      state.deviceReady = true;
    },
    deviceError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
    incomingCallReceived(state, action: PayloadAction<Call>) {
      state.incomingCall = action.payload;
      state.callStatus = "ringing";
    },
    callStatusChanged(state, action: PayloadAction<string>) {
      state.callStatus = action.payload;
    },
    outgoingCallSet(
      state,
      action: PayloadAction<{ call: Call; phone: string | null } | null>
    ) {
      state.outgoingCall = action?.payload?.call || null;
      state.phone = action?.payload?.phone || null;
    },
    incomingCallSet(state, action: PayloadAction<Call | null>) {
      state.incomingCall = action.payload;
    },
    errorSet(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeDevice.rejected, (state, action) => {
      state.error = action.error.message;
      state.deviceReady = false;
    });
    builder.addCase(makeCall.rejected, (state, action) => {
      state.callStatus = "disconnected";
      state.deviceReady = false;
      state.error = action.error.message || "Call failed";
      // Optionally, you can use a toast or log the error
      toast.error(
        "Failed to make the call. Resetting phone system. Please try again. If the problem persists please reload the browser window"
      );
    });
  },
});

export const {
  deviceRegistered,
  deviceError,
  incomingCallReceived,
  callStatusChanged,
  outgoingCallSet,
  incomingCallSet,
  errorSet,
} = twilioVoiceSlice.actions;
