import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Result } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Result entity
const resultAdapter = createEntityAdapter<Result, EntityId>({
  selectId: (result: Result) => result.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface ResultsState extends EntityState<Result, EntityId> {
  // Add any additional state properties here if needed
}

const initialResultState: ResultsState = resultAdapter.getInitialState();

// Selectors for Results
export const {
  selectById: selectResultById,
  selectAll: selectAllResults,
  selectEntities: selectResultEntities,
  selectIds: selectResultIds,
  selectTotal: selectResultTotal,
} = resultAdapter.getSelectors((state: RootState) => state?.result);

export const resultSlice = createSlice({
  name: "result",
  initialState: initialResultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(result.endpoints.getResults.matchFulfilled),
      (state, action) => {
        resultAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        result.endpoints.createResult.matchFulfilled,
        result.endpoints.updateResult.matchFulfilled,
        result.endpoints.patchResult.matchFulfilled
      ),
      (state, action) => {
        resultAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      result.endpoints.deleteResult.matchFulfilled,
      (state, action) => {
        resultAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const result = baseApi
  .enhanceEndpoints({ addTagTypes: ["Results", "Result"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getResults: builder.query<Result[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "result",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Result" as const, id })),
                { type: "Results" as const },
              ]
            : [{ type: "Results" as const }],
      }),
      getResult: builder.query<Result, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `result/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Result", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createResult: builder.mutation<Result, Partial<Result>>({
        query: (request) => ({
          url: "result",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Results" }],
      }),
      patchResult: builder.mutation<Result, Result>({
        query: (request) => ({
          url: `result/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Result", id: arg.id },
        ],
      }),
      updateResult: builder.mutation<Result, Result>({
        query: (request) => ({
          url: `result/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Result", id: arg.id },
        ],
      }),
      deleteResult: builder.mutation<void, number>({
        query: (id) => ({
          url: `result/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Result", id: arg },
          { type: "Results" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetResultsQuery,
  useGetResultQuery,
  useCreateResultMutation,
  usePatchResultMutation,
  useUpdateResultMutation,
  useDeleteResultMutation,
  usePrefetch: useResultsPrefetch,
} = result;
