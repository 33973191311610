import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogout = () => {
    navigate("/", {});
    dispatch(logout());
  };
  return onLogout;
};
