import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Edge, Node } from "@xyflow/react";
import { useGetMobilizeEventsQuery } from "store/api/mobilize/mobilize.api";
import { Event, EventGroup } from "store/api/mobilize/mobilize_models";
import { useGetEventGroupsQuery } from "store/api/mobilize/mobilize_event_group.api";
import { useGetScriptsQuery } from "store/api/script.api";
import { Conversation, Person, Prospect, Script } from "store/api/models";

export type QuestionOption = {
  id: string;
  index: number;
  option: string;
  jumpid?: string;
  jumpindex?: number;
};

export type QuestionFieldData = {
  id: string;
  index: number;
  type: string;
  question: string;
  options?: QuestionOption[];
  [key: string]: any;
};

export type FormBuilderContextContent = {
  questions: Node<QuestionFieldData>[];
  setQuestions: React.Dispatch<React.SetStateAction<Node<QuestionFieldData>[]>>;
  addQuestion: (index?: number) => void;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  onQuestionDelete?: (deletedNodes: Node<QuestionFieldData>[]) => void;
  activeQuestion: Node<QuestionFieldData> | null;
  setActiveQuestion: React.Dispatch<
    React.SetStateAction<Node<QuestionFieldData> | null>
  >;
  handleClickQuestion: (id: string) => void;
  handleQuestionUpdate: (updatedNode: Node<QuestionFieldData>) => void;
  disabled: boolean;
  events: Event[];
  event_groups: EventGroup[];
  scripts: Script[];
  copyScript: (script: Script) => void;
  answers: { [key: string]: any };
  conversation: Conversation;
  prospect: Prospect;
  person: Person;
};
export const FormBuilderContext = createContext<FormBuilderContextContent>({
  questions: [
    {
      id: "1",
      type: "questionNode",
      position: { x: 250, y: 5 },
      data: {
        id: "1",
        index: 0,
        type: "shortAnswer",
        question: "",
        options: [],
      },
    },
  ],
  setQuestions: () => {},
  addQuestion: (index?: number) => {},
  edges: [],
  setEdges: () => {},
  onQuestionDelete: () => {},
  activeQuestion: null,
  setActiveQuestion: () => {},
  handleClickQuestion: (id: string) => {},
  handleQuestionUpdate: (updatedNode: Node<QuestionFieldData>) => {},
  disabled: true,
  events: [],
  event_groups: [],
  scripts: [],
  copyScript: (script: Script) => {},
  answers: {},
  conversation: {} as Conversation,
  prospect: {} as Prospect,
  person: {} as Person,
});

interface FormBuilderProviderProps {
  questions?: Node<QuestionFieldData>[];
  edges?: Edge[];
  disabled?: boolean;
  children: ReactNode;
  answers?: { [key: string]: any };
  conversation?: Conversation;
  prospect?: Prospect;
  person?: Person;
}

export const FormBuilderProvider = ({
  questions: orgQs,
  answers = {},
  edges: orgEs,
  disabled = true,
  children,
  conversation = {} as Conversation,
  prospect = {} as Prospect,
  person = {
    firstname: "John",
    lastname: "Doe",
    phone: "(123) 456-7890",
    email: "john@doe.com",
    address: "5501 Josh Birmingham Pkwy",
    city: "Charlotte",
    state: "NC",
    zip: "28208",
    addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
  } as Person,
}: FormBuilderProviderProps) => {
  const copyScript = (script: Script) => {
    setQuestions(script?.form?.questions || []);
    setEdges(script?.form?.edges || []);
  };
  const [questions, setQuestions] = useState<Node<QuestionFieldData>[]>(
    orgQs || [
      {
        id: "1",
        type: "questionNode",
        position: { x: 250, y: 5 },
        data: {
          index: 0,
          id: "1",
          type: "shortAnswer",
          question: "",
          options: [],
        },
      },
    ]
  );

  useEffect(() => {
    setQuestions(orgQs || []);
    setEdges(orgEs || []);
  }, [orgQs, orgEs]);
  const [activeQuestion, setActiveQuestion] =
    useState<Node<QuestionFieldData> | null>(null); // Track the active question
  const [edges, setEdges] = useState<Edge[]>(orgEs || []);
  const {
    data: events = [] as Event[],
    isLoading: eventsLoading,
    refetch,
  } = useGetMobilizeEventsQuery({});
  const { data: event_groups = [], isLoading: eventGroupsLoading } =
    useGetEventGroupsQuery({ params: { nested: "all" } });
  const eventsWithLocation = useMemo(
    () => events?.filter((e) => !!e.location?.location?.latitude),
    [events]
  );
  const { data: scripts = [] } = useGetScriptsQuery({});
  const addQuestion = useCallback(
    (index?: number) => {
      const newNode: Node<QuestionFieldData> = {
        id: `${questions.length + 1}`,
        type: "questionNode",
        selected: true,
        position: {
          x: activeQuestion?.position?.x || 250 + 10 * questions?.length,
          y: activeQuestion?.position?.y || 100 + 10 * questions?.length,
        },
        data: {
          index: index ? index + 1 : questions.length,
          id: `${Date.now()}`,
          type: "shortAnswer",
          question: "",
          options: [],
        } as QuestionFieldData,
      };
      if (index) {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index + 1, 0, newNode);
        //update indexes
        const indexedQuestions = updatedQuestions.map((i, index) => ({
          ...i,
          selected: false,
          data: { ...i.data, index },
        }));
        // return indexedQuestions;
        setQuestions(indexedQuestions);
      } else {
        setQuestions(
          questions
            .concat(newNode)
            //update indexes
            .map((i, index) => ({
              ...i,
              selected: false,
              data: { ...i.data, index },
            }))
        );
      }
      setActiveQuestion(newNode);
    },
    [setQuestions, questions, activeQuestion, setActiveQuestion, edges]
  );

  // Handler for deleting nodes
  const onQuestionDelete = useCallback(
    (deletedNodes: Node<QuestionFieldData>[]) => {
      // Update the nodes state to remove the deleted nodes
      setQuestions((nds) =>
        nds
          .filter((node) => !deletedNodes.find((dn) => dn.id === node.id))
          //update indexes
          .map((i, index) => ({ ...i, data: { ...i.data, index } }))
      );

      // Update the edges state to remove edges connected to deleted nodes
      setEdges((eds) =>
        eds.filter(
          (edge) =>
            !deletedNodes.find(
              (node) => node.id === edge.source || node.id === edge.target
            )
        )
      );

      // Perform any additional cleanup or state updates as needed
    },
    [setQuestions, setEdges]
  );

  const handleClickQuestion = useCallback(
    (id: string) => {
      const clickedQuestion = questions?.find(
        (q) => Number(q?.id) === Number(id)
      );
      if (clickedQuestion) {
        setActiveQuestion(clickedQuestion);
      }
    },
    [setActiveQuestion, questions]
  );

  const handleQuestionUpdate = useCallback(
    (updatedNode: Node<QuestionFieldData>) => {
      setQuestions((qs) => {
        const updatedQuestions = [...qs];
        updatedQuestions[updatedNode?.data?.index] = updatedNode;
        return updatedQuestions;
      });
    },
    [setQuestions]
  );

  return (
    <FormBuilderContext.Provider
      value={{
        questions,
        setQuestions,
        addQuestion,
        edges,
        setEdges,
        onQuestionDelete,
        activeQuestion,
        setActiveQuestion,
        handleClickQuestion,
        handleQuestionUpdate,
        disabled,
        events: eventsWithLocation,
        event_groups,
        scripts,
        copyScript,
        answers,
        conversation,
        prospect,
        person,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

export const useFormBuilderContext = () => useContext(FormBuilderContext);
