import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  TextField,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Script } from "store/api/models";
import { Close } from "@mui/icons-material";
import { ReactFlowProvider } from "@xyflow/react";
import { TabPanel } from "components/tab/TabPanel";
import { setDisabled } from "store/slice/formBuilder.slice";
import { FormBuilderRedux } from "forms/builder/FormBuilderRedux";
import { FlowFormBuilderRedux } from "forms/builder/FlowFormBuilderRedux";
import { BranchingFormRedux } from "forms/render/BranchingFormRedux";
import { ScriptDetailActionsRedux } from "./ScriptDetailActionsRedux";
import { ScriptTitleRedux } from "./ScriptTitleRedux";
import { selectScriptById } from "store/api/script.api";
import { RootState } from "store/reducer";
import { FormBuilderReduxWindow } from "forms/builder/FormBuilderReduxWindow";
import { FormBuilderReduxLazy } from "forms/builder/FormBuilderReduxLazy";

export const ScriptDetailsRedux = ({
  id,
  disabled,
  close,
  callback,
  action,
  tab,
  appendtab,
}: {
  id: number;
  disabled: boolean;
  close: Function;
  callback: Function;
  action?: any;
  tab?: any;
  appendtab?: any;
}) => {
  const [value, setValue] = useState(tab?.value || "1");
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // Update disabled state in Redux when prop changes
  useEffect(() => {
    dispatch(setDisabled(disabled));
  }, [disabled, dispatch]);

  return (
    <ReactFlowProvider key={id}>
      <Box
        sx={{
          height: "100%",
          maxHeight: "100%",
          position: "relative",
        }}
      >
        {action ? (
          action
        ) : (
          <ScriptDetailActionsRedux
            id={id}
            disabled={disabled}
            callback={callback}
            close={close}
            externalInit={!!tab}
          />
        )}
        <Box
          sx={{
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <IconButton
            onClick={() => close()}
            sx={{
              position: "absolute",
              top: 4,
              right: 8,
              zIndex: (theme) => theme.zIndex.fab,
            }}
          >
            <Close />
          </IconButton>
          {!tab && <ScriptTitleRedux />}

          <Paper
            sx={{
              flex: 9,
              p: 1,
              height: "100%",
              maxHeight: tab ? "100%" : "89%",
              overflowY: "scroll",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 1 }}>
              <Tabs value={value} onChange={handleChange} variant="fullWidth">
                {tab && <Tab label={tab.label} value={tab.value} />}
                <Tab label="SCRIPT QUESTIONS" value="1" />
                <Tab label="SCRIPT LOGIC" value="2" />
                <Tab label="SCRIPT PREVIEW" value="3" />
                {appendtab && (
                  <Tab label={appendtab.label} value={appendtab.value} />
                )}
              </Tabs>
            </Box>
            {tab && (
              <TabPanel value={value} index={tab.value}>
                {tab.content}
              </TabPanel>
            )}
            <TabPanel value={value} index={"1"}>
              <FormBuilderRedux />
            </TabPanel>
            <TabPanel value={value} index={"2"}>
              <FlowFormBuilderRedux />
            </TabPanel>
            <TabPanel value={value} index={"3"}>
              <BranchingFormRedux />
            </TabPanel>
            {appendtab && (
              <TabPanel value={value} index={appendtab.value}>
                {appendtab.content}
              </TabPanel>
            )}
          </Paper>
        </Box>
      </Box>
    </ReactFlowProvider>
  );
};
