import { useState } from "react";
import * as Yup from "yup";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  useGetRolesQuery,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  useCreateRoleMutation,
} from "store/api";
import { JFormik } from "forms";
import { sortString } from "utils";
import { toast } from "react-toastify";

export const RolesTab = () => {
  const [editing /*, setEditing*/] = useState<boolean>(false);
  const { data: roles = [], isLoading } = useGetRolesQuery({});
  const [createRole] = useCreateRoleMutation();
  const [editRole] = useUpdateRoleMutation();
  const [deleteRole] = useDeleteRoleMutation();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <JFormik
      initialValues={() => ({
        roles: [...(roles || [])].sort((a, b) => sortString(a.name, b.name)),
      })}
      handleSubmit={async (values, formik) => {}}
      loading={isLoading}
      validationSchema={
        editing
          ? Yup.object({
              name: Yup.string().required("Role name is required"),
              // type: Yup.string().nullable().required('Role type is required'),
            })
          : Yup.object({})
      }
      disabled={!editing}
      form={[
        {
          name: "roles",
          itemType: "table_array",
          label: "Roles",
          tableProps: {
            onCreate: async (values: any) => {
              try {
                const res = await createRole(values).unwrap();
                if (res.id > 0) {
                  toast.success("Role successfully created!");
                } else {
                  toast.warning(
                    "There was an issue creating the role, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error creating the role, please try again."
                );
              }
            },
            onEdit: async (values: any) => {
              try {
                const res = await editRole(values).unwrap();
                if (res.id > 0) {
                  toast.success("Role successfully edited!");
                } else {
                  toast.warning(
                    "There was an issue editing the role, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error editing the role, please try again."
                );
              }
            },
            onDelete: async (id: number) => {
              try {
                await deleteRole(id).unwrap();
                toast.success("Role successfully deleted!");
              } catch (error) {
                toast.error(
                  "There was an error deleting the role, please try again."
                );
              }
            },
            columns: [
              {
                name: "name",
                label: "Role *",
                itemType: "textfield",
                jItemProps: {
                  width: "70%",
                  touched: true,
                  sx: {
                    boxSizing: "border-box",
                    px: { xs: 0.5 },
                    minWidth: 180,
                  },
                },
                gridProps: { size: { xs: 12 } },
              },
            ],
          },
          device: downMd ? "mobile" : "desktop",
        },
      ]}
    />
  );
};
