import { Box } from "@mui/material";
import { BackdropLoading } from "components/BackdropLoading";
import { toast } from "react-toastify";
import { selectUser, user, volunteer } from "store";
import { toolbarHeight, VolunteerState } from "./agent.types";
import { AgentConversation } from "./AgentConversation";
import { AgentConversationHistory } from "./AgentConversationHistory";
import { AgentIdle } from "./AgentIdle";
import { AgentProspectInfo } from "./AgentProspectInfo";
import {
  useCreateConversationMutation,
  usePatchConversationMutation,
} from "store/api/conversation.api";
import { useDispatch, useSelector } from "react-redux";
import { Conversation, Task } from "store/api/models";
import { useEffect, useRef } from "react";
import { AgentDialogDisposition } from "./AgentDispositionDialog";
import { setConversation } from "store/slice/formBuilder.slice";
import { Form } from "forms/render/types";
import { useCreateProspectNoteMutation } from "store/api/prospect_note.api";

export const AgentTask = ({
  state,
  setState,
  variant = "volunteer",
}: {
  variant?: "task" | "volunteer";
  state: VolunteerState;
  setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const notesRef = useRef();
  const [createConversation] = useCreateConversationMutation();
  const [patchConversation] = usePatchConversationMutation();
  const outgoingCall = useSelector(
    (state: any) => state.twilioVoice?.outgoingCall
  );
  const [createNote] = useCreateProspectNoteMutation();
  useEffect(() => {
    setState((prev) => ({ ...prev, callOngoing: outgoingCall }));
  }, [outgoingCall]);
  return (
    <Box
      id="right-side-content"
      sx={{
        position: "relative",
        height: `calc(100vh - ${toolbarHeight}px)`,
        width: "100%",
        p: 1,
        bgcolor: (theme) => theme.palette.divider,
      }}
    >
      {state.isLoading && <BackdropLoading relative={true} />}
      <AgentDialogDisposition
        state={state}
        setState={setState}
        notesRef={notesRef}
        onDisposition={async ({
          disposition,
          result,
          note,
          flagged,
        }: {
          disposition: string;
          result?: number | undefined;
          note?: string;
          flagged?: boolean;
        }) => {
          if (outgoingCall) {
            toast.warning(
              "Please complete the call before submitting the form. Thank you!"
            );
            return;
          }
          try {
            // if (notesRef.current) {
            //   const note = (notesRef.current as any).getNote();
            //   const flagged = (notesRef.current as any).getFlagged();
            // pull note from dialog instead.
            if (note) {
              createNote({
                prospect: state.selectedConversation?.prospect,
                volunteer: user.volunteer,
                form: { note },
                note: note,
                flagged: flagged,
                result: result,
              });
            }
            // }
            setState((prev) => ({
              ...prev,
              isLoading: true,
            }));
            const res = await patchConversation({
              ...state.selectedConversation,
              disposition: disposition,
              result: result,
            } as Conversation).unwrap();
            if (
              res &&
              state.selectedConversation &&
              res?.id === state.selectedConversation?.id
            ) {
              if (state.fromCallHistory) {
                dispatch(setConversation(res));
                setState((prev) => ({
                  ...prev,
                  // selectedConversation: null,
                  isLoading: false,
                  disposition: false,
                }));
              } else {
                setState((prev) => ({
                  ...prev,
                  selectedConversation: null,
                  isLoading: false,
                  disposition: false,
                }));
              }
              toast.info("Disposition recorded.");
            } else {
              setState((prev) => ({
                ...prev,
                // selectedConversation: null,
                isLoading: false,
                disposition: false,
              }));
              toast.warning("Disposition error, please try again.");
            }
          } catch (error) {
            setState((prev) => ({
              ...prev,
              // selectedConversation: null,
              isLoading: false,
              disposition: false,
            }));
            toast.warning("Disposition error, please try again.");
          }
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          height: "100%",
          bgcolor: (theme) => theme.palette.divider,
        }}
      >
        {(state.completed ||
          (!state.isLoading &&
            state.selectedMenu !== -1 &&
            !state.selectedConversation)) && (
          <AgentIdle
            state={state}
            setState={setState}
            onClick={async () => {
              try {
                if (state.selectedSkill && user.volunteer) {
                  setState((prev) => ({
                    ...prev,
                    completed: false,
                    isLoading: true,
                  }));
                  const res = await createConversation({
                    skill: state.selectedSkill.id,
                    volunteer: user.volunteer,
                  }).unwrap();
                  if (res.id) {
                    setState((prev) => ({
                      ...prev,
                      selectedConversation: res,
                      isLoading: false,
                    }));
                    toast.success("You have been assign a task!");
                  } else {
                    setState((prev) => ({
                      ...prev,
                      completed: false,
                      isLoading: false,
                    }));
                    toast.success(
                      "No more prospects to be called at this time!"
                    );
                  }
                } else {
                  setState((prev) => ({
                    ...prev,
                    isLoading: false,
                  }));
                  toast.error(
                    "There was an error. Please reload the page and try again."
                  );
                }
              } catch (error) {
                const { status } = error as any;
                if (status === 404) {
                  toast.info("No more prospects to be called at this time!");
                } else {
                  toast.error(
                    "There was an error. Please reload the page and try again."
                  );
                }
                setState((prev) => ({
                  ...prev,
                  isLoading: false,
                }));
              }
            }}
          />
        )}
        {/* Task Table */}
        {state.selectedMenu === -1 && !state.selectedConversation && (
          <Box
            sx={{
              flexGrow: state.selectedConversation ? 0.25 : 1,
              maxWidth: state.selectedConversation ? 300 : undefined,
              transition: "flex-grow 0.3s",
              pr: state.selectedConversation ? 1 : 0,
            }}
          >
            <AgentConversationHistory
              onClick={(conversation: Conversation) =>
                setState((prev) => ({
                  ...prev,
                  selectedConversation: conversation,
                  fromCallHistory: true,
                }))
              }
            />
          </Box>
        )}

        {/* Task Details */}
        {state.selectedConversation && (
          <AgentProspectInfo
            ref={notesRef}
            state={state}
            setState={setState}
            variant={variant}
            onClose={() =>
              setState((prev) => ({
                ...prev,
                selectedConversation: null,
                fromCallHistory: undefined,
              }))
            }
            onDisposition={async () => {
              if (outgoingCall) {
                toast.warning(
                  "Please complete the call before submitting the form. Thank you!"
                );
                return;
              }

              setState((prev) => ({
                ...prev,
                // selectedConversation: null,
                disposition: true,
              }));
            }}
            onSkip={async () => {
              if (outgoingCall) {
                toast.warning(
                  "Please complete the call before submitting the form. Thank you!"
                );
                return;
              }
              if (state.fromCallHistory) {
                setState((prev) => ({
                  ...prev,
                  selectedConversation: null,
                  fromCallHistory: undefined,
                }));
              } else {
                if (notesRef.current) {
                  const note = (notesRef.current as any).getNote();
                  const flag = (notesRef.current as any).getFlagged();
                  if (note) {
                    createNote({
                      prospect: state.selectedConversation?.prospect,
                      volunteer: user.volunteer,
                      form: { note },
                      note: note,
                      flagged: flag,
                    });
                  }
                }
                if (state.selectedConversation && !state.fromCallHistory) {
                  setState((prev) => ({
                    ...prev,
                    isLoading: true,
                  }));
                  const res = await patchConversation({
                    ...(state.selectedConversation || {}),
                    disposition: "Skipped",
                  }).unwrap();
                  if (res.id) {
                    setState((prev) => ({
                      ...prev,
                      selectedConversation: null,
                      fromCallHistory: undefined,
                      isLoading: false,
                      completed: false,
                      state: "welcome",
                    }));
                    toast.info("Prospect skipped");
                  }
                }
                setState((prev) => ({
                  ...prev,
                  selectedConversation: null,
                  fromCallHistory: undefined,
                }));
              }
            }}
          />
        )}
        {state.selectedConversation?.form?.form &&
          state.selectedConversation?.form?.form?.questions && (
            <Box sx={{ flex: 0.65 }}>
              <AgentConversation
                state={state}
                setState={setState}
                save={async (answers: any) => {
                  if (state.selectedConversation && !state.fromCallHistory) {
                    patchConversation({
                      id: state.selectedConversation.id,
                      disposition: "Called",
                      form: {
                        ...(state.selectedConversation?.form || {}),
                        form: {
                          ...(state.selectedConversation?.form?.form || {}),
                          answers: { ...answers },
                        } as Form,
                      } as Task,
                    } as Conversation);
                  }
                }}
                submit={async (answers: any) => {
                  if (state.selectedConversation) {
                    if (!outgoingCall) {
                      setState((prev) => ({
                        ...prev,
                        // selectedConversation: null,
                        disposition: true,
                      }));
                    } else {
                      toast.warning(
                        "Please complete the call before submitting the form. Thank you!"
                      );
                    }

                    // const res = await patchConversation({
                    //   ...(state.selectedConversation || {}),
                    //   disposition: "Complete",
                    //   result: 14, //Canvassed
                    //   form: {
                    //     ...(state.selectedConversation?.form || {}),
                    //     form: {
                    //       ...(state.selectedConversation?.form?.form || {}),
                    //       answers: { ...answers },
                    //     } as Form,
                    //   } as Task,
                    // }).unwrap();
                    // if (res.id && !outgoingCall) {
                    //   if (state.fromCallHistory) {
                    //     setState((prev) => ({
                    //       ...prev,
                    //       isLoading: false,
                    //       selectedConversation: null,
                    //       fromCallHistory: undefined,
                    //     }));
                    //   } else {
                    //     setState((prev) => ({
                    //       ...prev,
                    //       selectedConversation: null,
                    //       selectedMenu: null,
                    //       isLoading: false,
                    //       completed: true,
                    //       state: "completed",
                    //     }));
                    //   }
                    //   toast.success("Form submitted successfuly!");
                    // }
                  }
                }}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};
