import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  AnimationOutlined,
  AccountCircleOutlined,
  Search as SearchIcon,
  Delete,
  Edit,
  Event as EventIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Event } from "store/api/models";
import { useDeleteEventMutation } from "store/api/event.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";

export const EventList = ({
  events,
  selectedEvent,
  handleEventClick,
  handleEventEditClick,
}: {
  events: Event[];
  selectedEvent?: Event;
  handleEventClick: (event: Event) => void;
  handleEventEditClick: (event: Event) => void;
}) => {
  const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [deleteEvent] = useDeleteEventMutation();

  const filteredEvents = [...(events || [])]
    ?.filter((e) => {
      return e.title?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.title, b.title));
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
        overflowY: "scroll",
      }}
    >
      <AlertDialog
        open={!!openAlert}
        title={`Delete event`}
        content={`Are you sure you want to delete this event?`}
        onClose={() => {
          setOpenAlert(undefined);
        }}
        onConfirm={async () => {
          if (openAlert && openAlert > 0) {
            try {
              await deleteEvent(openAlert);
              toast.success("Event successfully deleted");
            } catch (error) {
              toast.success("Unable to delete contact");
            }
          }
          setOpenAlert(undefined);
        }}
      />
      <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
        <Grid
          container
          alignItems={"bottom"}
          justifyContent={"space-between"}
          spacing={0}
        >
          <Grid>
            <Typography variant="h6" component={"span"}>
              EVENT LIST
            </Typography>
          </Grid>
          <Grid>
            <Grid
              container
              alignItems={"bottom"}
              justifyContent={"flex-end"}
              spacing={2}
            >
              <Grid sx={{ textAlign: "right" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Search Events"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                  onChange={(event) => setSearch((event?.target as any)?.value)}
                  sx={{ maxWidth: { xs: "none", sm: 340 } }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <List
        dense
        disablePadding
        sx={{
          maxHeight: "calc(100vh - 85px)",
          overflowY: "scroll",
        }}
      >
        {filteredEvents?.map((event: Event) => (
          <>
            <ListItem
              key={event.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor: (theme) =>
                  selectedEvent && selectedEvent?.id === event.id
                    ? theme.palette.divider
                    : undefined,
              }}
              dense
              secondaryAction={
                <>
                  <Tooltip title={"Edit"}>
                    <IconButton onClick={() => handleEventEditClick(event)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Delete"}>
                    {openAlert && openAlert === event.id ? (
                      <IconButton>
                        <CircularProgress size={22} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setOpenAlert(event.id)}>
                        <Delete />
                      </IconButton>
                    )}
                  </Tooltip>
                </>
              }
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="overline" sx={{ fontWeight: 700 }}>
                    {event.title}
                  </Typography>
                }
                // secondary={`${event.contact_r?.email}`}
                onClick={() => handleEventClick(event)}
                sx={{ cursor: "pointer", py: 0, my: 0 }}
              />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Box>
  );
};
