import { useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import {
  Tabs,
  Tab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Backdrop,
  useTheme,
  Box,
  TextField,
  backdropClasses,
  Breadcrumbs,
  Link,
  Tooltip,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  useMediaQuery,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Close,
  Save,
  Edit,
  ArrowDropDown,
  SlideshowOutlined,
  AddCircle,
  Add,
} from '@mui/icons-material';
import { ChipButtonMenuProps, ChipButtonMenu } from './ChipButtonMenu';
import { BackdropLoading } from '../components/BackdropLoading';
import { TabSelection, DrawerTabProps } from './TabSelection';

type Device = 'desktop' | 'mobile';

export interface TabDrawerProps {
  title: string;
  width?: object;
  maxWidth?: object | string | number;
  status?: ChipButtonMenuProps | string;
  open?: boolean;
  onHelp?: Function;
  onClose?: Function;
  tabs: { [key: string]: DrawerTabProps };
  setTabs?: (tabs: { [key: string]: DrawerTabProps }) => void;
  initialTab?: string;
  icons?: JSX.Element[];
  tabIcons?: JSX.Element[];
  setTitle?: Function;
  isLoading?: boolean;
  contentLoading?: boolean;
  save?: Function;
  transparentBackdrop?: boolean;
  breadcrumbs?: { label: string; onClick?: Function }[];
  device?: Device;
  tabSelection?: boolean;
}

export const TabDrawer = (props: TabDrawerProps) => {
  const { initialTab, tabs, ...rest } = props;
  const [activeTab, setActiveTab] = useState<string>(
    initialTab || Object.keys(tabs)?.[0]
  );

  useEffect(() => {
    if (!Object.keys(tabs).includes(activeTab) && initialTab) {
      setActiveTab(initialTab);
    }
  }, [activeTab, initialTab]);

  return (
    <ControlledTabDrawer
      {...props}
      onClose={() => {
        setActiveTab(initialTab || Object.keys(tabs)?.[0]);
        if (props.onClose) props.onClose();
      }}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
    />
  );
};

export interface ControlledTabDrawerProps {
  title: string;
  width?: object;
  maxWidth?: object | string | number;
  status?: ChipButtonMenuProps | string;
  open?: boolean;
  onHelp?: Function;
  onClose?: Function;
  tabs: { [key: string]: DrawerTabProps };
  setTabs?: (tabs: { [key: string]: DrawerTabProps }) => void;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  icons?: JSX.Element[];
  tabIcons?: JSX.Element[];
  setTitle?: Function;
  isEditing?: boolean;
  save?: Function;
  isLoading?: boolean;
  contentLoading?: boolean;
  transparentBackdrop?: boolean;
  breadcrumbs?: { label: string; onClick?: Function }[];
  device?: Device;
  tabSelection?: boolean;
}

export const ControlledTabDrawer = ({
  open = false,
  width,
  maxWidth,
  onHelp,
  onClose = () => {},
  title,
  setTitle,
  status,
  tabs,
  setTabs = () => {},
  activeTab,
  setActiveTab,
  save,
  icons,
  tabIcons,
  isLoading: defaultLoading = false,
  contentLoading = false,
  transparentBackdrop,
  breadcrumbs,
  device = 'desktop',
  tabSelection,
}: ControlledTabDrawerProps) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const closeDrawer = () => {
    if (save && isEditing) {
      save();
    }
    setEditing(false);
    // setActiveTab('info');
    onClose();
  };
  const [isEditing, setEditing] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(defaultLoading);

  useEffect(() => setLoading(defaultLoading), [defaultLoading]);

  const TabsComponent = device === 'desktop' ? TabsForDesktop : TabsForMobile;
  const tabsComponentProps = {
    activeTab,
    setActiveTab,
    tabs,
    setTabs,
    tabIcons,
    tabSelection,
  };

  const isBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={() => closeDrawer()}
      ModalProps={
        transparentBackdrop
          ? {
              // components: { Backdrop: TransparentBackdrop }, // <== replacement method.
              BackdropComponent: TransparentBackdrop, // <== will be depricated soon but replacement method not available yet.
            }
          : {}
      }
      PaperProps={{
        sx: {
          width: width
            ? width
            : {
                xs: '100%',
                md: '50%',
              },
          height: '100vh',
          maxWidth,
        },
      }}
      sx={{
        root: {
          zIndex: 1180,
          backgroundColor: 'transparent',
        },
      }}
    >
      {!contentLoading ? (
        <Grid
          container
          sx={{
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          <Grid item xs={12} style={{ flex: 1 }}>
            <Toolbar
              sx={{
                py: '10px',
                ...(device === 'mobile'
                  ? { borderBottom: 1, borderColor: 'divider' }
                  : {}),
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        float: 'right',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        ml: 1,
                      }}
                    >
                      {icons &&
                        icons.map((icon, index) => (
                          <Box id={`tab-drawer-icon-${index}`} key={index}>
                            {icon}
                          </Box>
                        ))}
                      {status && typeof status === 'string' && (
                        <Box>
                          <Chip label={status} />
                        </Box>
                      )}
                      {status && typeof status !== 'string' && (
                        <Box id={`tab-drawer-chipbuttonmenu`}>
                          <ChipButtonMenu {...status} />
                        </Box>
                      )}
                      {onHelp && (
                        <Box>
                          <Tooltip title={'Tutorial'}>
                            <IconButton onClick={() => onHelp()}>
                              <SlideshowOutlined />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box>
                        <Tooltip title={'Close'}>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => closeDrawer()}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>

                    {isBreadcrumbs && (
                      <Box sx={{ float: 'left', py: 1 }}>
                        <Breadcrumbs>
                          {breadcrumbs.map((breadcrumb, index) => (
                            <Link
                              key={index}
                              underline="hover"
                              color="inherit"
                              onClick={() =>
                                breadcrumb.onClick && breadcrumb.onClick()
                              }
                            >
                              {breadcrumb.label}
                            </Link>
                          ))}
                        </Breadcrumbs>
                      </Box>
                    )}

                    {isEditing && setTitle ? (
                      <Box
                        sx={{
                          clear: 'left',
                          display: 'flex',
                          alignItems: 'center',
                          pt: 1.5,
                          width: downSm ? '100%' : 'auto',
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={async () => {
                            setLoading(true);
                            if (save && isEditing) {
                              await save();
                            }
                            setEditing(false);
                            setLoading(false);
                          }}
                        >
                          <TextField
                            fullWidth={true}
                            InputProps={{
                              sx: {
                                fontSize: downSm ? 28 : 34,
                                fontWeight: 700,
                              },
                            }}
                            value={title}
                            onChange={event => setTitle(event.target.value)}
                          />
                        </ClickAwayListener>
                      </Box>
                    ) : (
                      <Box
                        sx={{ clear: 'left' }}
                        onClick={() => {
                          if (setTitle) {
                            setEditing(true);
                          }
                        }}
                      >
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            fontSize: downSm ? 28 : 34,
                            fontWeight: 700,
                            float: 'left',
                            ...(isBreadcrumbs ? {} : { lineHeight: '40px' }),
                          }}
                          color={'primary'}
                        >
                          {title}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TabsComponent {...tabsComponentProps} />
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid
            item
            xs={12}
            flexGrow={90}
            sx={{
              backgroundColor: tabs[activeTab]?.backgroundColor
                ? tabs[activeTab].backgroundColor
                : 'rgba(0, 0, 0, 0.05)',
              px: !!!tabs[activeTab]?.padding ? { xs: '16px', sm: '24px' } : 0,
              py: !!!tabs[activeTab]?.padding ? { xs: '16px', sm: '24px' } : 0,
              height: '100%',
              overflowX: 'hidden',
            }}
          >
            {tabs[activeTab]?.contents}
          </Grid>
        </Grid>
      ) : (
        <BackdropLoading />
      )}
    </Drawer>
  );
};

const TransparentBackdrop = styled(Backdrop)(({ theme }) => ({
  [`&.${backdropClasses.root}`]: {
    zIndex: theme.zIndex.drawer - 20,
    backgroundColor: 'transparent',
  },
}));

type TabsProps = Pick<
  ControlledTabDrawerProps,
  | 'activeTab'
  | 'setActiveTab'
  | 'tabs'
  | 'setTabs'
  | 'tabIcons'
  | 'tabSelection'
>;

const TabsForDesktop = ({
  activeTab,
  setActiveTab,
  tabs,
  setTabs = () => {},
  tabIcons,
  tabSelection,
}: TabsProps) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap={'nowrap'}
        sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}
      >
        <Grid item sx={{ maxWidth: '100%' }}>
          <Tabs
            variant="scrollable"
            scrollButtons={'auto'}
            value={activeTab}
            onChange={(event, value) => setActiveTab(value)}
          >
            {[...Object.values(tabs)]
              .filter(t => t.visible)
              .sort((a, b) => a?.sequence - b?.sequence)
              .map((tab, index) => (
                <Tab
                  id={`tab-drawer-tab-${index}`}
                  icon={tab.icon}
                  // iconPosition="start"
                  label={tab?.label}
                  key={index}
                  value={tab.key}
                  sx={{ minHeight: 'unset' }}
                />
              ))}
            {tabSelection && <TabSelection tabs={tabs} setTabs={setTabs} />}
          </Tabs>
        </Grid>

        {/* <Grid
          item
          sx={{
            marginRight: '1%',
            display: 'inline-flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <Button color="primary" startIcon={<AddCircle />}>
            ITEM OPTIONS
          </Button>
          {tabIcons &&
            tabIcons.map((icon, index) => (
              <Box sx={{ marginRight: '2px' }} key={`tab-icon-${index}`}>
                {icon}
              </Box>
            ))}
        </Grid> */}
      </Grid>
    </Grid>
  );
};

const TabsForMobile = ({
  activeTab,
  setActiveTab,
  tabs,
  tabIcons,
}: TabsProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (event: any, key: string) => {
    setActiveTab(key);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            ref={anchorRef}
            size="small"
            color="primary"
            label={tabs[activeTab].icon}
            onClick={handleToggle}
            onDelete={handleToggle}
            deleteIcon={<ArrowDropDown />}
            sx={{ width: 'fit-content' }}
          />
        </Grid>

        <Grid item>
          <Typography variant="caption" color="textSecondary">
            : Click to choose other settings.
          </Typography>
        </Grid>
      </Grid>

      <Popper
        sx={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement={'bottom-start'}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {[...Object.values(tabs)]
                    .sort((a, b) => a?.sequence - b?.sequence)
                    .map((tab, index) => (
                      <MenuItem
                        key={`mobile-tab-${index}`}
                        selected={tab.key === activeTab}
                        onClick={event => handleMenuItemClick(event, tab.key)}
                        sx={{ py: 1.5 }}
                      >
                        {tab.icon}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
