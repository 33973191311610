// formBuilderSlice.ts

import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { Node, Edge, NodeChange } from "@xyflow/react";
import { createSelector } from "reselect";
import { Conversation, Person, Prospect, Script } from "store/api/models";
import { RootState } from "store/reducer";
import { DropResult } from "react-beautiful-dnd";
import { FormatLineSpacing } from "@mui/icons-material";
import { buildQuestionMap } from "forms/render/utils";
import { WritableDraft } from "immer";
import { conversation } from "store/api/conversation.api";

export type QuestionOption = {
  id: string;
  index: number;
  option: string;
  jumpid?: string;
  jumpindex?: number;
};

export type QuestionFieldData = {
  id: string;
  index: number;
  type: string;
  question: string;
  options?: QuestionOption[];
  [key: string]: any;
};

interface FormBuilderState {
  title: string;
  titleError?: string;
  scriptId: number | null;
  script: Script;
  questionIds: { id: string; index: number }[];
  renderedQuestionIds: string[];
  questions: Node<QuestionFieldData>[];
  questionsData: QuestionFieldData[];
  questionsMap: { [id: string]: QuestionFieldData };
  edgesMap?: { [k: string]: Edge[] };
  nodes: Node[];
  edges: Edge[];
  activeRenderedQuestionId: string | null;
  activeQuestionId: string | null;
  answers: { [key: string]: any };
  conversation?: Conversation | null;
  prospect: Prospect;
  person: Person;
  user: Person;
  disabled: boolean;
  completed: boolean;
  history: string[];
}

const initialState: FormBuilderState = {
  title: "",
  scriptId: null,
  script: {} as Script,
  questionIds: [{ id: "1", index: 0 }],
  renderedQuestionIds: [],
  questions: [
    {
      id: "1",
      type: "questionNode",
      position: { x: 250, y: 5 },
      data: {
        id: "1",
        index: 0,
        type: "shortAnswer",
        question: "",
        options: [],
      },
    },
  ],
  questionsData: [
    {
      id: "1",
      index: 0,
      type: "shortAnswer",
      question: "",
      options: [],
    },
  ],
  questionsMap: {
    "1": {
      id: "1",
      index: 0,
      type: "shortAnswer",
      question: "",
      options: [],
    },
  },
  nodes: [
    {
      id: "1",
      type: "questionNode",
      position: { x: 250, y: 5 },
      data: {},
    },
  ],
  edges: [],
  activeRenderedQuestionId: "1",
  activeQuestionId: null,
  answers: {},
  conversation: {} as Conversation,
  prospect: {} as Prospect,
  person: {
    firstname: "John",
    lastname: "Doe",
    phone: "(123) 456-7890",
    email: "john@doe.com",
    address: "5501 Josh Birmingham Pkwy",
    city: "Charlotte",
    state: "NC",
    zip: "28208",
    addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
  } as Person,
  user: {
    firstname: "Mary",
    lastname: "Mae",
    phone: "(098) 765-4321",
    email: "mary@mae.com",
    address: "5501 Josh Birmingham Pkwy",
    city: "Charlotte",
    state: "NC",
    zip: "28208",
    addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
  } as Person,
  disabled: true,
  completed: false,
  history: [],
};

//Logic that will be shared between reducers
const handleNextCommon = (
  state: WritableDraft<FormBuilderState>,
  init: boolean
) => {
  let activeRenderedQuestionId: string | null;
  let activeRenderedQuestion: Node<QuestionFieldData> | null;
  let answers: {
    [key: string]: any;
  };
  let questions: WritableDraft<Node<QuestionFieldData>>[];
  let eM: WritableDraft<{
    [k: string]: Edge[];
  }>;
  activeRenderedQuestionId = state.activeRenderedQuestionId;
  if (init) {
    //it isn't a writeable draft if being called from the init state
    answers = state.answers;
    questions = state.questions;
    activeRenderedQuestion =
      questions.find((q) => q.id == activeRenderedQuestionId) || null;
    eM = state.edgesMap || {};
  } else {
    // Unwrap proxies
    answers = state.answers ? current(state.answers) || {} : {};
    questions = state.questions ? current(state.questions) || [] : [];
    activeRenderedQuestion =
      questions.find((q) => q.id == activeRenderedQuestionId) || null;
    eM = state.edgesMap ? current(state.edgesMap) || {} : {};
  }

  if (activeRenderedQuestionId) {
    const edgesMap = new Map<string, Edge[]>(Object.entries(eM));
    if (
      !activeRenderedQuestion ||
      !answers ||
      ((!answers[activeRenderedQuestionId] ||
        ("value" in answers[activeRenderedQuestionId] &&
          answers[activeRenderedQuestionId].value === "")) &&
        !["script", "event"].includes(activeRenderedQuestion.data.type))
    ) {
      return;
    }
    const answer = answers[activeRenderedQuestionId];

    // Determine the next question based on edges
    let nextQuestionId: string | null = null;
    if (edgesMap.size === 0) {
      //just a water fall move to next question automatically
      const nextIndex = activeRenderedQuestion.data?.index + 1;
      if (nextIndex >= questions.length) {
        nextQuestionId = "done";
        state.completed = true;
      } else {
        nextQuestionId = questions[nextIndex]?.id;
      }
    } else {
      // For options, use sourceHandle to find the correct edge
      if (activeRenderedQuestion.data.type === "multipleChoice" && answer) {
        const choice = answer?.value;
        const edgeKey = `${activeRenderedQuestionId}-option-${choice}-next`;
        const edges = edgesMap.get(edgeKey);
        if (edges && edges.length > 0) {
          nextQuestionId = edges[0].target;
        }
      } else {
        // Default edge from the question
        const edges = edgesMap.get(`${activeRenderedQuestionId}-next`);
        if (edges && edges.length > 0) {
          nextQuestionId = edges[0].target;
        }
      }
    }
    if (nextQuestionId) {
      //ADD HISTORY!
      state.history = state.history.concat(activeRenderedQuestionId);
      state.renderedQuestionIds =
        state.renderedQuestionIds.concat(nextQuestionId);
      state.activeRenderedQuestionId = nextQuestionId;
      activeRenderedQuestion =
        questions.find((q) => q.id == nextQuestionId) || null;
      if (
        init &&
        activeRenderedQuestion &&
        ["script", "event"].includes(activeRenderedQuestion.data.type)
      ) {
        handleNextCommon(state, init);
      }
    } else {
      // No more questions
      state.history = state.history.concat(activeRenderedQuestionId);
      state.renderedQuestionIds = state.renderedQuestionIds.concat("completed");
      state.activeRenderedQuestionId = "completed";
      state.completed = true;
    }
  }
};

export const formBuilderSlice = createSlice({
  name: "formBuilder",
  initialState,
  reducers: {
    setQuestions(state, action: PayloadAction<Node<QuestionFieldData>[]>) {
      state.questions = action.payload;
      state.questionIds = action.payload.map((i, x) => ({
        id: i.id,
        index: x,
      }));
      state.renderedQuestionIds = [action.payload?.[0]?.id];
      state.activeRenderedQuestionId = action.payload?.[0]?.id;
      state.activeQuestionId = action.payload?.[0]?.id;
      state.questionsData = action.payload.map((i) => i.data);
      let questionsMap: { [id: string]: QuestionFieldData } = {};
      action.payload.forEach((q) => {
        questionsMap[q.id] = q.data;
      });
      state.questionsMap = questionsMap;
      state.nodes = action.payload.map((n) => ({ ...n, data: {} }));
    },
    // setRenderedQuestionIds(state, action: PayloadAction<string[]>) {
    //   state.renderedQuestionIds = action.payload;
    // },
    // addHistory(state, action: PayloadAction<string>) {
    //   state.history = state.history.concat(action.payload);
    // },
    // backHistory(state) {
    //   state.history = state.history.slice(0, -1);
    // },
    // setHistory(state, action: PayloadAction<string[]>) {
    //   state.history = action.payload;
    // },
    addQuestion(state, action: PayloadAction<{ index?: number }>) {
      const { index } = action.payload;
      const questionLength = state.questions.length;
      const newId = questionLength + 1;
      const newIndex = index !== undefined ? index + 1 : questionLength;
      const activeQuestion =
        state.questions.find((q) => q.id == state.activeQuestionId) || null;
      const newNode: Node<QuestionFieldData> = {
        id: `${newId}`,
        type: "questionNode",
        selected: true,
        position: {
          x: activeQuestion?.position?.x || 250 + 10 * questionLength,
          y: activeQuestion?.position?.y || 100 + 10 * questionLength,
        },
        data: {
          index: newIndex,
          id: `${newId}`,
          type: "shortAnswer",
          question: "",
          options: [],
        },
      };

      if (index !== undefined) {
        const updatedQuestions = [...state.questions];
        updatedQuestions.splice(index + 1, 0, newNode);
        state.questions = updatedQuestions.map((i, idx) => ({
          ...i,
          selected: false,
          data: { ...i.data, index: idx },
        }));
        state.questionIds = updatedQuestions.map((i, x) => ({
          id: i.id,
          index: x,
        }));
        state.renderedQuestionIds = [updatedQuestions?.[0]?.id];
        state.activeRenderedQuestionId = updatedQuestions?.[0]?.id;
        state.questionsData = updatedQuestions.map((i) => i.data);
        let questionsMap: { [id: string]: QuestionFieldData } = {};
        updatedQuestions.forEach((q) => {
          questionsMap[q.id] = q.data;
        });
        state.questionsMap = questionsMap;
        state.nodes = updatedQuestions.map((n) => ({ ...n, data: {} }));
      } else {
        state.questions = state.questions.concat(newNode).map((i, idx) => ({
          ...i,
          selected: false,
          data: { ...i.data, index: idx },
        }));
        state.questionIds = state.questionIds.concat({
          id: `${newId}`,
          index: newIndex,
        });
        state.renderedQuestionIds = [state.questions?.[0]?.id];
        state.activeRenderedQuestionId = state.questions?.[0]?.id;
        state.questionsData = state.questionsData.concat(newNode.data);
        state.questionsMap[`${newId}`] = newNode.data;
        state.nodes = state.nodes.concat(newNode).map((i, idx) => ({
          ...i,
          selected: false,
          data: {},
        }));
      }
      state.activeQuestionId = `${newId}`;
    },
    setNodes(state, action: PayloadAction<Node[]>) {
      state.nodes = action.payload;
    },
    setEdges(state, action: PayloadAction<Edge[]>) {
      state.edges = action.payload;
      const { edgesMap } = buildQuestionMap({
        questions: [],
        edges: action.payload,
      });
      state.edgesMap = Object.fromEntries(edgesMap);
    },
    onQuestionDelete(state, action: PayloadAction<string[]>) {
      const deletedNodes = action.payload;
      const filteredQuestions = state.questions.filter(
        (node) => !deletedNodes.find((dn) => dn === node.id)
      );
      state.questions = filteredQuestions.map((i, idx) => ({
        ...i,
        data: { ...i.data, index: idx },
      }));
      state.questionIds = filteredQuestions.map((i, idx) => ({
        id: i.id,
        index: idx,
      }));
      state.renderedQuestionIds = [filteredQuestions?.[0]?.id];
      state.questionsData = filteredQuestions.map((i, idx) => i.data);
      let questionsMap: { [id: string]: QuestionFieldData } = {};
      filteredQuestions.forEach((q) => {
        questionsMap[q.id] = q.data;
      });
      state.questionsMap = questionsMap;
      state.nodes = state.nodes.filter(
        (node) => !deletedNodes.find((dn) => dn === node.id)
      );
      state.edges = state.edges.filter(
        (edge) =>
          !deletedNodes.find(
            (node) => node === edge.source || node === edge.target
          )
      );
    },
    // setActiveQuestionId(state, action: PayloadAction<string | null>) {
    //   state.activeQuestionId = action.payload ? action.payload : null;
    // },
    // setActiveRenderedQuestionId(state, action: PayloadAction<string | null>) {
    //   state.activeRenderedQuestionId = action.payload ? action.payload : null;
    // },
    handleClickQuestion(state, action: PayloadAction<string>) {
      const id = action.payload;
      const clickedQuestion = state.questions.find(
        (q) => Number(q.id) === Number(id)
      );
      if (clickedQuestion) {
        state.activeQuestionId = clickedQuestion.id;
      }
    },
    handleQuestionUpdate(
      state,
      action: PayloadAction<Node<QuestionFieldData>>
    ) {
      const updatedNode = action.payload;
      state.questions = state.questions.map((q) =>
        q.id === updatedNode.id ? updatedNode : q
      );
      // state.questionsData = state.questionsData.map((q) =>
      //   q.id === updatedNode.id ? updatedNode.data : q
      // );
      // state.questionsMap[action.payload.id] = action.payload.data;
    },
    handleNodesUpdate(
      state,
      action: PayloadAction<NodeChange<Node<QuestionFieldData>>[]>
    ) {
      const testUpdate = action.payload[0];
      if (
        testUpdate.type === "dimensions" ||
        (testUpdate.type === "position" &&
          (!testUpdate.position?.x || !testUpdate.position?.y))
      ) {
        return;
      }
      state.questions = state.questions.map((q) => {
        const updatedNode = action.payload.find((n: any) => n.id === q.id);
        return updatedNode &&
          updatedNode.type !== "dimensions" &&
          (updatedNode.type !== "position" ||
            (updatedNode.position?.x && updatedNode.position?.y))
          ? { ...q, ...updatedNode, type: q.type }
          : q;
      });
      // state.nodes = state.nodes.map((q) => {
      //   const updatedNode = action.payload.find((n) => n.id === q.id);
      //   return updatedNode ? updatedNode : q;
      // });
    },
    // Handle drag and drop functionality
    handleDragEnd(state, action: PayloadAction<DropResult>) {
      const result = action.payload;
      if (!result.destination) return;

      // Create a copy of the questions array
      const items = Array.from(state.questions);

      // Remove the dragged item from its original position
      const [reorderedItem] = items.splice(result.source.index, 1);

      // Insert the dragged item at its new position
      items.splice(result.destination?.index, 0, reorderedItem);

      // Update the index of each question
      state.questions = items.map((item, index) => ({
        ...item,
        data: { ...item.data, index },
      }));
      state.questionIds = items.map((i, idx) => ({ id: i.id, index: idx }));
      state.renderedQuestionIds = [items?.[0]?.id];
      state.activeRenderedQuestionId = items?.[0]?.id;
      state.questionsData = items.map((i) => i.data);
    },
    handleNext(state) {
      handleNextCommon(state, false);
    },
    handleBack(state) {
      if (state.history.length === 0) {
        state.activeRenderedQuestionId = state.questions?.[0]?.id;
        return; // No previous questions to go back to just restart
      }
      // Get the previous question from the state.history
      const previousQuestionId = state.history[state.history.length - 1];
      //Remove current answer
      if (state.activeRenderedQuestionId) {
        state.answers = {
          ...(state.answers || {}),
          [state.activeRenderedQuestionId]: undefined,
        };
      }
      // Remove the last entry from the state.history
      state.history = state.history.slice(0, -1);
      state.completed = false;
      state.renderedQuestionIds = state.renderedQuestionIds.slice(0, -1);
      state.activeRenderedQuestionId = previousQuestionId;
    },
    handleRestart(state) {
      state.renderedQuestionIds = [state.questions?.[0]?.id];
      state.activeRenderedQuestionId = state.questions?.[0]?.id;
      state.completed = false;
      state.history = [];
      state.answers = {};
    },
    copyScript(state, action: PayloadAction<Script>) {
      const script = action.payload;
      state.questions = [...(script?.form?.questions || [])];
      state.questionIds = (script?.form?.questions || []).map(
        (i: any, idx: number) => ({
          id: i.id,
          index: idx,
        })
      );
      state.renderedQuestionIds = [script?.form?.questions?.[0]?.id];
      state.activeRenderedQuestionId = script?.form?.questions?.[0]?.id;
      state.activeQuestionId = script?.form?.questions?.[0]?.id;
      state.edges = [...(script?.form?.edges || [])];
    },
    setDisabled(state, action: PayloadAction<boolean>) {
      state.disabled = action.payload;
    },
    setAnswers(state, action: PayloadAction<{ [key: string]: any }>) {
      state.answers = action.payload;
    },
    setAnswer(state, action: PayloadAction<{ [key: string]: any }>) {
      if (Object.keys(action.payload).length !== 1) {
        console.error("messed up answer");
      }
      const key = Object.keys(action.payload)[0];
      state.answers = {
        ...(state.answers || {}),
        [key]: {
          ...(state.answers?.[key] || {}),
          ...(action.payload[key] || {}),
        },
      };
    },
    setConversation(state, action: PayloadAction<Conversation>) {
      state.conversation = action.payload;
    },
    setProspect(state, action: PayloadAction<Prospect>) {
      state.prospect = action.payload;
    },
    setPerson(state, action: PayloadAction<Person>) {
      state.person = action.payload;
    },
    setCompleted(state, action: PayloadAction<boolean>) {
      state.completed = action.payload;
    },
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
      if (action.payload && action.payload !== "") {
        state.titleError = undefined;
      }
    },
    setTitleError(state, action: PayloadAction<string | undefined>) {
      state.titleError = action.payload;
    },
    setScriptId(state, action: PayloadAction<number | null>) {
      state.scriptId = action.payload;
    },
    setScript(state, action: PayloadAction<Script>) {
      state.script = action.payload;
    },
    setInitialData(
      state,
      action: PayloadAction<{
        title: string;
        scriptId: number | null;
        questions: Node<QuestionFieldData>[];
        edges: Edge[];
        answers: { [key: string]: any };
        disabled: boolean;
        person: Person;
        user: any;
        conversation?: Conversation | null;
      }>
    ) {
      state.conversation = action.payload.conversation;
      state.person = { ...action.payload.person };
      state.user = { ...action.payload.user };
      state.title = action.payload.title;
      state.scriptId = action.payload.scriptId;
      //ensure node id and data id are the same
      state.questions = action.payload.questions?.map((q) => ({
        ...q,
        data: { ...q.data, id: q.id },
      }));
      state.questionIds = action.payload.questions.map((i, idx) => ({
        id: i.id,
        index: idx,
      }));
      state.questionsData = action.payload.questions.map((q) => q.data);
      let questionsMap: { [id: string]: QuestionFieldData } = {};
      action.payload.questions.forEach((q) => {
        questionsMap[q.id] = q.data;
      });
      state.questionsMap = questionsMap;
      state.nodes = action.payload.questions.map((q) => ({ ...q, data: {} }));
      state.edges = action.payload.edges;
      state.answers = action.payload.answers || {};
      state.disabled = action.payload.disabled;
      const { edgesMap } = buildQuestionMap({
        questions: [],
        edges: action.payload.edges,
      });
      state.edgesMap = Object.fromEntries(edgesMap);
      if (
        action.payload.answers &&
        Object.keys(action.payload.answers).length > 0
      ) {
        state.renderedQuestionIds = [action.payload.questions[0]?.id];
        state.activeRenderedQuestionId = action.payload.questions?.[0]?.id;
        state.activeQuestionId = action.payload.questions?.[0]?.id;
        //handle when it starts with script/or event
        handleNextCommon(state, true);
        for (
          let index = 0;
          index < Object.keys(action.payload.answers).length;
          index++
        ) {
          handleNextCommon(state, true);
        }
      } else {
        state.renderedQuestionIds = [action.payload.questions[0]?.id];
        state.activeRenderedQuestionId = action.payload.questions?.[0]?.id;
        state.activeQuestionId = action.payload.questions?.[0]?.id;
        state.completed = false;
        state.history = [];
      }
    },
  },
});

export const {
  setQuestions,
  addQuestion,
  setEdges,
  setNodes,
  onQuestionDelete,
  // setActiveQuestionId,
  // setRenderedQuestionIds,
  // setNextRenderedQuestionIds,
  handleClickQuestion,
  handleQuestionUpdate,
  handleNodesUpdate,
  handleDragEnd,
  handleBack,
  handleNext,
  handleRestart,
  copyScript,
  // addHistory,
  // backHistory,
  // setHistory,
  setDisabled,
  setAnswers,
  setCompleted,
  setAnswer,
  setConversation,
  setProspect,
  setPerson,
  setTitle,
  setTitleError,
  setScriptId,
  setScript,
  setInitialData,
} = formBuilderSlice.actions;

// Selectors for better performance
export const selectFormBuilderState = (state: RootState) => state.formBuilder;

export const selectAnswers = createSelector(
  [selectFormBuilderState],
  (formBuilderState) => formBuilderState.answers as { [key: string]: any }
);

export const selectQuestions = createSelector(
  [selectFormBuilderState],
  (formBuilderState) => formBuilderState.questions as Node<QuestionFieldData>[]
);

export const selectHistory = createSelector(
  [selectFormBuilderState],
  (formBuilderState) => formBuilderState.history as string[]
);

export const selectQuestionIds = createSelector(
  [selectFormBuilderState],
  (formBuilderState) =>
    formBuilderState.questionIds as { id: string; index: number }[]
);

export const selectRenderedQuestionIds = createSelector(
  [selectFormBuilderState],
  (formBuilderState) => formBuilderState.renderedQuestionIds as string[]
);

export const selectQuestionById = (id: string) =>
  createSelector(
    [(state: RootState) => state.formBuilder.questions],
    (questions) => {
      return (
        questions?.find((q: Node<QuestionFieldData>) => q.id === id) ||
        ({} as Node<QuestionFieldData>)
      );
    }
  );

export const selectRenderedQuestionById = (id: string) =>
  createSelector(
    [
      (state: RootState) => state.formBuilder.questions,
      (state: RootState) => state.formBuilder.person,
      (state: RootState) => state.formBuilder.user,
    ],
    (questions, person, user) => {
      const questionById: Node<QuestionFieldData> =
        questions?.find((q: Node<QuestionFieldData>) => q.id === id) ||
        ({} as Node<QuestionFieldData>);
      let questionByIdString = JSON.stringify(questionById);

      // Replace placeholders with actual values
      for (const key of Object.keys(person)) {
        if ((person as any)[key] && (person as any)[key] !== "") {
          questionByIdString = questionByIdString.replaceAll(
            `{{${key}}}`,
            (person as any)[key]
          );
        }
      }
      if (user?.firstname) {
        questionByIdString = questionByIdString.replaceAll(
          `{{agent_firstname}}`,
          user.firstname
        );
      }
      if (user?.lastname) {
        questionByIdString = questionByIdString.replaceAll(
          `{{agent_lastname}}`,
          user.lastname
        );
      }
      const updatedQuestionById: Node<QuestionFieldData> =
        JSON.parse(questionByIdString);
      return updatedQuestionById;
    }
  );

// export const selectQuestionById = (id: string) =>
//   createSelector(
//     [(state: RootState) => state.formBuilder.questionsMap],
//     (questionsMap) => questionsMap[id]
//   );

export const selectQuestionByIndex = (index: number) =>
  createSelector(
    [selectQuestions],
    (questions) => questions?.[index] || ({} as Node<QuestionFieldData>)
  );

export const selectAnswerById = (id: string) =>
  createSelector(
    [selectFormBuilderState],
    (formBuilderState) => formBuilderState.answers?.[id]
  );

export const selectActiveRenderedQuestion = createSelector(
  [
    (state: RootState) => state.formBuilder.questions,
    (state: RootState) => state.formBuilder.person,
    (state: RootState) => state.formBuilder.user,
    (state: RootState) => state.formBuilder.activeRenderedQuestionId,
  ],
  (questions, person, user, activeRenderedQuestionId) => {
    const questionById: Node<QuestionFieldData> =
      questions?.find(
        (q: Node<QuestionFieldData>) => q.id === activeRenderedQuestionId
      ) || ({} as Node<QuestionFieldData>);
    let questionByIdString = JSON.stringify(questionById);

    // Replace placeholders with actual values
    for (const key of Object.keys(person)) {
      if ((person as any)[key] && (person as any)[key] !== "") {
        questionByIdString = questionByIdString.replaceAll(
          `{{${key}}}`,
          (person as any)[key]
        );
      }
    }
    if (user?.firstname) {
      questionByIdString = questionByIdString.replaceAll(
        `{{agent_firstname}}`,
        user.firstname
      );
    }
    if (user?.lastname) {
      questionByIdString = questionByIdString.replaceAll(
        `{{agent_lastname}}`,
        user.lastname
      );
    }
    const updatedQuestionById: Node<QuestionFieldData> =
      JSON.parse(questionByIdString);
    return updatedQuestionById;
  }
);

export const selectTitle = createSelector(
  [selectFormBuilderState],
  (formBuilderState) => formBuilderState.title as string
);

export const selectQuestionDataPropById = (id: string, prop: string) =>
  createSelector(
    [(state: RootState) => state.formBuilder.questions],
    (questions) => {
      return questions?.find((q: Node<QuestionFieldData>) => q.id === id)
        ?.data?.[prop] as any;
    }
  );
