import { Box, IconButton, Paper, Typography } from "@mui/material";
import { JFormik } from "forms";
import {
  useCreateLoginMutation,
  usePatchLoginMutation,
} from "store/api/login.api";
import { useLoginForm } from "forms/forms/useLoginForm";
import { toast } from "react-toastify";
import { Login } from "store/api/models";
import { Close } from "@mui/icons-material";

export const LoginDetails = ({
  login,
  disabled,
  close,
  callback,
}: {
  login: Login;
  disabled: boolean;
  close: Function;
  callback: Function;
}) => {
  const [createLogin] = useCreateLoginMutation();
  const [patchLogin] = usePatchLoginMutation();
  const form = useLoginForm();
  return (
    <Paper
      key={login.id}
      sx={{
        bgcolor: "white",
        position: "relative", // Allows positioning of the close button
        maxHeight: "100%",
        height: "100%",
      }}
      elevation={5}
    >
      <Box
        sx={{
          maxHeight: "100%",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={() => close()}
          sx={{ position: "absolute", top: 4, right: 8 }}
        >
          <Close />
        </IconButton>
        <Box sx={{ px: 1, mt: 1 }}>
          <Typography variant="h6">LOGIN DETAILS</Typography>
        </Box>
        <Box
          sx={{
            p: 1,
            mt: 3,
            maxHeight: "100%",
            overflowY: "scroll",
            flexGrow: 3,
          }}
        >
          <JFormik
            disabled={disabled}
            initialValues={{
              ...login,
              admin: login.admin ? "YES" : "NO",
            }}
            enableReinitialize={true}
            handleSubmit={async (values, formik) => {
              if (values.id) {
                try {
                  await patchLogin({
                    ...values,
                    admin: true,
                  }).unwrap();
                  close();
                  // toast.info("Login updated successfuly");
                } catch (error) {
                  toast.error("Unable to update login");
                }
              } else {
                try {
                  let res: any = {};
                  res = await createLogin({
                    ...values,
                    admin: true,
                  }).unwrap();
                  close();
                  callback && callback(res);
                  toast.info("Login created successfuly");
                } catch (error) {
                  toast.error("Unable to create login");
                }
              }
            }}
            validationSchema={form.validationSchema}
            form={form.form}
          />
        </Box>
      </Box>
    </Paper>
  );
};
