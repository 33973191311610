import { Device } from "@twilio/voice-sdk";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetVoiceTokenQuery } from "store";
import { initializeDevice, destroyDevice } from "store/slice/twilioVoice.slice";
import { AppDispatch } from "store/types/types";

export const useTwilioVoice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: token } = useGetVoiceTokenQuery({});
  const { deviceReady, callStatus, error, incomingCall, outgoingCall } =
    useSelector((state: any) => state.twilioVoice);
  useEffect(() => {
    const twilioToken = token?.token;
    // if (error) {
    //   toast.error(
    //     "Error making call, resetting phone system, please try again. If the problem persists please reset the browswer window"
    //   );
    // }
    if (twilioToken) {
      const deviceOptions: Device.Options = {
        allowIncomingWhileBusy: false,
        appName: "voluntize",
        appVersion: "1.0",
        closeProtection: true,
        // logLevel: "DEBUG",
      };
      dispatch(
        initializeDevice({ token: twilioToken, options: deviceOptions })
      );
    }
    return () => {
      dispatch(destroyDevice());
    };
  }, [dispatch, token, error]);
};
