import { Paper, IconButton, Typography, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  handleClickQuestion,
  addQuestion,
  onQuestionDelete,
  selectQuestionDataPropById,
} from "store/slice/formBuilder.slice";
import { QuestionRedux } from "./QuestionRedux";
import React, { useState } from "react";

interface QuestionProps {
  // node: Node<QuestionFieldData>;
  id: string;
  index: number;
}

const QuestionDnDReduxComponent: React.FC<QuestionProps> = ({ id, index }) => {
  const dispatch = useDispatch();
  const activeQuestionId = useSelector(
    (state: any) => state.formBuilder.activeQuestionId
  );
  const nodeTitle = useSelector(selectQuestionDataPropById(id, "nodeTitle"));
  const disabled = useSelector((state: any) => state.formBuilder.disabled);
  const isActive = activeQuestionId === id;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(nodeTitle);

  const handleTextClick = (): void => {
    setIsEditing(true);
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setText(event.target.value);
  };

  const handleBlur = (): void => {
    setIsEditing(false);
  };

  return (
    <Paper
      onClick={() => dispatch(handleClickQuestion(id))}
      elevation={isActive ? 5 : 3}
      sx={{ padding: 2, position: "relative", marginBottom: 2 }}
    >
      {/* DragIndicator Icon */}
      {!disabled && (
        <IconButton
          sx={{
            position: "absolute",
            top: -10,
            left: "50%",
            transform: "translateX(-50%) rotate(90deg)",
            cursor: "move",
          }}
        >
          <DragIndicatorIcon />
        </IconButton>
      )}

      {isEditing ? (
        <TextField
          value={text}
          onChange={handleTextChange}
          onBlur={handleBlur}
          variant="outlined"
          size="small"
          autoFocus
          className="nodrag"
        />
      ) : (
        <Typography
          variant="h6"
          gutterBottom
          onClick={() => {
            if (!disabled) {
              handleTextClick();
            }
          }}
          className="nodrag"
        >
          {`${text || "Question Node"} ${index === 0 ? "(START)" : ""}`}
        </Typography>
      )}
      <QuestionRedux id={id} nodeTitle={text} />

      {/* Add and Delete Icons in a floating Paper */}
      {isActive && !disabled && (
        <Paper
          sx={{
            position: "absolute",
            right: -50,
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            flexDirection: "column",
            padding: 1,
          }}
          elevation={5}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={() => dispatch(addQuestion({ index: index }))}
          >
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>

          <IconButton
            size="small"
            color="secondary"
            onClick={() => dispatch(onQuestionDelete([id]))}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Paper>
      )}
    </Paper>
  );
};

// Memoizing the component
export const QuestionDnDRedux = React.memo(
  QuestionDnDReduxComponent,
  (prevProps, nextProps) => {
    // Custom comparison function to only re-render if necessary
    return prevProps.id === nextProps.id && prevProps.index === nextProps.index;
  }
);
