import { ContentCopy } from "@mui/icons-material";
import {
  Grid2 as Grid,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { AlertDialog } from "dialogs";
import { Node, Edge } from "@xyflow/react";
import { useDispatch, useSelector } from "react-redux";
import { copyScript, setScript } from "store/slice/formBuilder.slice";
import { selectUser } from "store";
import { selectAllScripts, useGetScriptsQuery } from "store/api/script.api";

export const FormTemplateCopyRedux = () => {
  const dispatch = useDispatch();
  // const { data: scripts = [] } = useGetScriptsQuery({});
  const scripts = useSelector(selectAllScripts);
  const questionsLength: number = useSelector(
    (state: any) => state.formBuilder.questions.length
  );
  // Get the questions and disabled state from Redux
  const script = useSelector((state: any) => state.formBuilder.script);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  return (
    <Grid>
      <AlertDialog
        open={!!openAlert}
        title={`This will overwrite the current script`}
        content={`Are you sure you want to overwrite script with the template?`}
        onClose={() => {
          setOpenAlert(false);
        }}
        onConfirm={async () => {
          if (openAlert && script) {
            dispatch(copyScript(script));
          }
          setOpenAlert(false);
        }}
      />
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"flex-end"}
        sx={{ mb: 1 }}
      >
        <Grid>
          <Autocomplete
            size="small"
            value={script.title ? script : { title: "" }}
            options={scripts}
            onChange={(e, v) => {
              if (v) {
                dispatch(setScript(v));
              }
            }}
            sx={{ width: 300 }}
            getOptionLabel={(o) => o.title}
            renderInput={(params) => (
              <TextField {...params} label="Script template" />
            )}
          />
        </Grid>
        <Grid>
          <Tooltip title="Copy script template here">
            <IconButton
              disabled={!script}
              onClick={() => {
                if (questionsLength > 0) {
                  setOpenAlert(true);
                } else if (script) {
                  dispatch(copyScript(script));
                }
              }}
              sx={{
                ml: 2,
                bgcolor: (theme) => theme.palette.info.main,
              }}
            >
              <ContentCopy sx={{ color: "white" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
