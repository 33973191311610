import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { TaskType } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the TaskType entity
const taskTypeAdapter = createEntityAdapter<TaskType, EntityId>({
  selectId: (task_type: TaskType) => task_type.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface TaskTypesState extends EntityState<TaskType, EntityId> {
  // Add any additional state properties here if needed
}

const initialTaskTypeState: TaskTypesState = taskTypeAdapter.getInitialState();

// Selectors for TaskTypes
export const {
  selectById: selectTaskTypeById,
  selectAll: selectAllTaskTypes,
  selectEntities: selectTaskTypeEntities,
  selectIds: selectTaskTypeIds,
  selectTotal: selectTaskTypeTotal,
} = taskTypeAdapter.getSelectors((state: RootState) => state?.task_type);

export const taskTypeSlice = createSlice({
  name: "task_type",
  initialState: initialTaskTypeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(task_type.endpoints.getTaskTypes.matchFulfilled),
      (state, action) => {
        taskTypeAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        task_type.endpoints.createTaskType.matchFulfilled,
        task_type.endpoints.updateTaskType.matchFulfilled,
        task_type.endpoints.patchTaskType.matchFulfilled
      ),
      (state, action) => {
        taskTypeAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      task_type.endpoints.deleteTaskType.matchFulfilled,
      (state, action) => {
        taskTypeAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const task_type = baseApi
  .enhanceEndpoints({ addTagTypes: ["TaskTypes", "TaskType"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTaskTypes: builder.query<TaskType[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "task_type",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "TaskType" as const, id })),
                { type: "TaskTypes" as const },
              ]
            : [{ type: "TaskTypes" as const }],
      }),
      getTaskType: builder.query<TaskType, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `task_type/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "TaskType", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createTaskType: builder.mutation<TaskType, Partial<TaskType>>({
        query: (request) => ({
          url: "task_type",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "TaskTypes" }],
      }),
      patchTaskType: builder.mutation<TaskType, TaskType>({
        query: (request) => ({
          url: `task_type/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskType", id: arg.id },
        ],
      }),
      updateTaskType: builder.mutation<TaskType, TaskType>({
        query: (request) => ({
          url: `task_type/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskType", id: arg.id },
        ],
      }),
      deleteTaskType: builder.mutation<void, number>({
        query: (id) => ({
          url: `task_type/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskType", id: arg },
          { type: "TaskTypes" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetTaskTypesQuery,
  useGetTaskTypeQuery,
  useCreateTaskTypeMutation,
  usePatchTaskTypeMutation,
  useUpdateTaskTypeMutation,
  useDeleteTaskTypeMutation,
  usePrefetch: useTaskTypesPrefetch,
} = task_type;
