import React, { useCallback } from "react";
import {
  Paper,
  Stack,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Divider,
  Grid2 as Grid,
} from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Add } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import {
  addQuestion,
  selectQuestionIds,
  handleDragEnd,
} from "store/slice/formBuilder.slice";
import { QuestionDnDRedux } from "./QuestionDnDRedux";
import { FormTemplateCopyRedux } from "./FormTemplateCopyRedux";

export const FormBuilderRedux: React.FC = () => {
  const dispatch = useDispatch();

  // Get the questions and disabled state from Redux
  const questionIds = useSelector(selectQuestionIds);

  const disabled = useSelector((state: any) => state.formBuilder.disabled);

  // Handle drag and drop functionality
  const onHandleDragEnd = (result: DropResult) => {
    dispatch(handleDragEnd(result));
  };

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <Paper elevation={5} sx={{ m: 1 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            pt: 1,
            px: 2,
            mb: 1,
            bgcolor: (theme) => theme.palette.divider,
          }}
        >
          <Grid>
            <Typography variant="h6" sx={{ mb: 2 }}>
              SCRIPT FORM BUILDER{" "}
              {!disabled && (
                <Tooltip title="Add question">
                  <IconButton
                    onClick={() => dispatch(addQuestion({}))}
                    sx={{ ml: 2, bgcolor: (theme) => theme.palette.info.main }}
                  >
                    <Add sx={{ color: "white" }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          </Grid>
          {!disabled && (
            <Grid>
              <FormTemplateCopyRedux />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Divider sx={{ mb: 1 }} />
      <DragDropContext onDragEnd={onHandleDragEnd}>
        <Droppable droppableId="questions" isDropDisabled={disabled}>
          {(provided) => (
            <Stack
              spacing={2}
              sx={{ ml: 2, mr: 8 }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {questionIds.map((q: any, index: number) => (
                <Draggable
                  key={q.id}
                  draggableId={q.id}
                  index={index}
                  isDragDisabled={disabled}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <QuestionDnDRedux
                        // key={q.id}
                        id={q.id}
                        index={index}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
