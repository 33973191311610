import { createContext, useContext } from 'react';
export type JFormikContextContent = {
  back?: Function;
  disabled?: boolean;
  disableSubmitOnEnter?: boolean;
};
export const JFormikContext = createContext<JFormikContextContent>({
  back: () => {},
  disabled: false,
  disableSubmitOnEnter: true,
});
export const useJFormikContext = () => useContext(JFormikContext);
