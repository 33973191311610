import { Save } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Person, Script } from "store/api/models";
import {
  selectScriptById,
  useCreateScriptMutation,
  usePatchScriptMutation,
} from "store/api/script.api";
import { RootState } from "store/reducer";
import {
  setInitialData,
  setScriptId,
  setTitleError,
} from "store/slice/formBuilder.slice";

export const ScriptDetailActionsRedux = ({
  id,
  close,
  callback,
  disabled = false,
  externalInit = false,
}: {
  close: Function;
  callback: Function;
  id: number;
  disabled: boolean;
  externalInit?: boolean;
}) => {
  const dispatch = useDispatch();
  const [createScript] = useCreateScriptMutation();
  const [patchScript] = usePatchScriptMutation();
  // Get state from Redux store
  const title = useSelector((state: any) => state.formBuilder.title);
  const scriptId = useSelector((state: any) => state.formBuilder.scriptId);
  const questions = useSelector((state: any) => state.formBuilder.questions);
  const edges = useSelector((state: any) => state.formBuilder.edges);
  const reduxDisabled = useSelector((state: any) => state.formBuilder.disabled);
  const script: Script = useSelector(
    (state: RootState) => selectScriptById(state, id) || ({} as Script)
  );
  useEffect(() => {
    // Initialize Redux store with script data
    if (!externalInit) {
      dispatch(
        setInitialData({
          title: script?.title || "",
          scriptId: script?.id || null,
          questions: script?.form?.questions || [],
          edges: script?.form?.edges || [],
          answers: script?.form?.answers,
          disabled: disabled,
          person: {
            firstname: "John",
            lastname: "Doe",
            phone: "(123) 456-7890",
            email: "john@doe.com",
            address: "5501 Josh Birmingham Pkwy",
            city: "Charlotte",
            state: "NC",
            zip: "28208",
            addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
          } as Person,
          user: {
            firstname: "Mary",
            lastname: "Mae",
            phone: "(123) 456-7890",
            email: "mary@mae.com",
            address: "5501 Josh Birmingham Pkwy",
            city: "Charlotte",
            state: "NC",
            zip: "28208",
            addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
          } as Person,
        })
      );
    }
  }, []);
  if (reduxDisabled) {
    return <></>;
  } else
    return (
      <Fab
        color="primary"
        sx={{
          position: "absolute",
          right: 10,
          bottom: 10,
        }}
        onClick={async () => {
          if (!title || title === "") {
            dispatch(setTitleError("Please enter a title!"));
            return;
          }
          if (scriptId) {
            // Update existing script
            try {
              const res = await patchScript({
                id: scriptId,
                title: title,
                form: {
                  questions,
                  edges,
                },
              } as Script).unwrap();
              close();
              if (res.id) {
                callback && callback(res);
                toast.info("Script updated successfully");
              }
            } catch (error) {
              toast.error("Unable to update script");
            }
          } else {
            try {
              let res: any = {};
              res = await createScript({
                title: title,
                form: {
                  questions,
                  edges,
                },
              }).unwrap();
              close();
              if (res.id) {
                dispatch(setScriptId(res.id));
                callback && callback(res);
                toast.info("Script created successfully");
              }
            } catch (error) {
              toast.error("Unable to create script");
            }
          }
        }}
      >
        <Save />
      </Fab>
    );
};
