import { Backdrop, CircularProgress } from '@mui/material';

export const BackdropLoading = ({
  relative = false,
  absolute = false,
  sx = {},
}: {
  relative?: boolean;
  absolute?: boolean;
  sx?: object;
}) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgb(0,0,0,0.3)',
        zIndex: theme => theme.zIndex.modal + 1,
        ...(absolute
          ? { position: 'absolute', height: '100%', width: '100%' }
          : relative
          ? { position: 'relative', height: '100%', width: '100%' }
          : {}),
        ...sx,
      }}
      open={true}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};
