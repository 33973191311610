import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
  Chip,
  Grid2,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  AnimationOutlined,
  AccountCircleOutlined,
  Search as SearchIcon,
  Delete,
  Edit,
  EmojiPeople,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Login, Role, Skill, Volunteer } from "store/api/models";
import { useDeleteVolunteerMutation } from "store/api/volunteer.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";
import { VolunteerAvatar } from "../avatar/VolunteerAvatar";
import {
  selectContactEntities,
  selectRoleEntities,
  selectSkillEntities,
} from "store";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { EntityId } from "@reduxjs/toolkit";
import moment from "moment";

export const VolunteerList = ({
  volunteers,
  logins,
  selectedVolunteer = {} as Volunteer,
  handleVolunteerClick = () => {},
  handleVolunteerEditClick = () => {},
}: {
  volunteers: Volunteer[];
  logins: Login[];
  selectedVolunteer?: Volunteer;
  handleVolunteerClick?: (volunteer: Volunteer) => void;
  handleVolunteerEditClick?: (volunteer: Volunteer) => void;
}) => {
  const contacts = useSelector((state: RootState) =>
    selectContactEntities(state)
  );
  const roles = useSelector((state: RootState) => selectRoleEntities(state));
  const skills = useSelector((state: RootState) => selectSkillEntities(state));

  const [view, setView] = useState<"volunteer" | "skill" | "role">("volunteer");
  const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [deleteVolunteer] = useDeleteVolunteerMutation();

  const filteredVolunteers = [...(volunteers || [])]
    ?.filter((v) => {
      const contact_r = contacts[v.contact];
      const role_r = roles[v.role];
      const sklls = v.skills?.map((s) => skills[s]);
      if (view === "volunteer") {
        return (
          contact_r?.firstname?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.lastname?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          role_r?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
          sklls?.find((s) =>
            s?.name?.toLowerCase()?.includes(search.toLowerCase())
          )
        );
      } else if (view === "skill") {
        return (
          contact_r?.firstname?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.lastname?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          sklls?.find((s) =>
            s?.name?.toLowerCase()?.includes(search.toLowerCase())
          )
        );
      } else if (view === "role") {
        return (
          contact_r?.firstname?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.lastname?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          contact_r?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
          role_r?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
          sklls?.find((s) =>
            s?.name?.toLowerCase()?.includes(search.toLowerCase())
          )
        );
      }
    })
    ?.sort((a, b) =>
      sortString(
        view === "volunteer"
          ? `${contacts[a.contact]?.firstname} ${contacts[a.contact]?.lastname}`
          : view === "role"
          ? skills[a.skills?.[0] || 0]?.name || ""
          : roles[a.role]?.name,
        view === "volunteer"
          ? `${contacts[b.contact]?.firstname} ${contacts[b.contact]?.lastname}`
          : view === "skill" || ""
          ? skills[b.skills?.[0] || 0]?.name || ""
          : roles[b.role]?.name
      )
    );

  const filteredRoles = groupContactsByRole(filteredVolunteers, roles);
  const filteredSkills = groupContactsBySkill(filteredVolunteers, skills);
  const ListVolunteer = ({ volunteer }: { volunteer: Volunteer }) => {
    const login = logins?.find(
      (l) => l.email === contacts[volunteer.contact]?.email
    );
    return (
      <ListItem
        key={volunteer.id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: (theme) =>
            selectedVolunteer && selectedVolunteer?.id === volunteer.id
              ? theme.palette.divider
              : undefined,
          borderRadius: "4px",
          boxShadow: (theme) => theme.shadows[2],
          my: 1,
        }}
      >
        <Grid2
          container
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ListItemIcon sx={{ minWidth: "unset", mr: 2 }}>
              <EmojiPeople color="secondary" />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography variant="overline" sx={{ fontWeight: 700 }}>
                  {`${contacts[volunteer.contact]?.firstname} ${
                    contacts[volunteer.contact]?.lastname
                  }`}

                  <>
                    {login?.last_logged_in &&
                    moment(login.last_logged_in).isSameOrAfter(
                      moment().startOf("year")
                    ) ? (
                      <Chip
                        size="small"
                        label={`Logged in ${moment(login?.last_logged_in).diff(
                          moment.now(),
                          "days"
                        )} days ago`}
                        variant="outlined"
                        color="success"
                        sx={{ zoom: "75%", ml: 0.5 }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label={"Not yet logged in"}
                        variant="outlined"
                        color="warning"
                        sx={{ zoom: "75%", ml: 0.5 }}
                      />
                    )}
                  </>
                  <>
                    {volunteer?.skills?.map((s) => (
                      <Chip
                        size="small"
                        label={skills[s]?.name}
                        variant="outlined"
                        color="secondary"
                        sx={{ zoom: "75%", ml: 0.5 }}
                      />
                    ))}
                  </>
                </Typography>
              }
              onClick={() => handleVolunteerClick(volunteer)}
              sx={{ cursor: "pointer", py: 0, my: 0 }}
            />
          </Box>
          <Grid2 alignSelf={"center"}>
            <Box
              sx={{
                borderRadius: "4px",
                border: (theme) => `1px solid ${theme.palette.secondary.main}`,
              }}
            >
              <Tooltip title={"Edit"}>
                <IconButton onClick={() => handleVolunteerEditClick(volunteer)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"}>
                {openAlert && openAlert === volunteer.id ? (
                  <IconButton>
                    <CircularProgress size={22} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setOpenAlert(volunteer.id)}>
                    <Delete />
                  </IconButton>
                )}
              </Tooltip>
            </Box>
          </Grid2>
        </Grid2>
      </ListItem>
    );
  };
  return (
    <Box
      sx={{
        maxHeight: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          maxHeight: "100%",
          height: "100%",
        }}
      >
        <AlertDialog
          open={!!openAlert}
          title={`Delete volunteer`}
          content={`Are you sure you want to delete this volunteer?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteVolunteer(openAlert);
                toast.success("Volunteer successfully deleted");
              } catch (error) {
                toast.success("Unable to delete contact");
              }
            }
            setOpenAlert(undefined);
          }}
        />
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid>
              <Typography variant="h6" component={"span"} sx={{ px: 1 }}>
                {view === "role" ? (
                  <span>
                    VOLUNTEER LIST{" "}
                    <small>
                      <i>(Grouped by Role)</i>
                    </small>
                  </span>
                ) : view === "skill" ? (
                  <span>
                    VOLUNTEER LIST{" "}
                    <small>
                      <i>(Grouped by Skill)</i>
                    </small>
                  </span>
                ) : (
                  "VOLUNTEER LIST"
                )}
              </Typography>
            </Grid>
            <Grid>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid>
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(event: any, value: any) => setView(value)}
                  >
                    <ToggleButton value="volunteer">
                      <Tooltip title="List Volunteers">
                        <PersonOutlineOutlined />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="skill">
                      <Tooltip title="Group Volunteers by Skill">
                        <AnimationOutlined />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="role">
                      <Tooltip title="Group Volunteers by Role">
                        <AccountCircleOutlined />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid sx={{ textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Volunteers"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    sx={{ maxWidth: { xs: "none", sm: 340 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <List
          dense
          disablePadding
          sx={{
            maxHeight: "100%",
            height: "100%",
            overflowY: "scroll",
            px: 1,
          }}
        >
          {view === "volunteer" &&
            filteredVolunteers?.map((volunteer: Volunteer) => (
              <ListVolunteer volunteer={volunteer} />
            ))}
          {view === "skill" &&
            [...Object.keys(filteredSkills)].sort()?.map((skill, rindex) => (
              <>
                <ListItem
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[100],
                    fontWeight: "600",
                  }}
                >
                  <ListItemIcon>
                    <AnimationOutlined color="secondary" />
                  </ListItemIcon>
                  {skill}
                </ListItem>
                <Box sx={{ pl: 2 }}>
                  {filteredSkills[skill]?.volunteers?.map((v: Volunteer) => (
                    <ListVolunteer volunteer={v} />
                  ))}
                </Box>
              </>
            ))}
          {view === "role" &&
            [...Object.keys(filteredRoles)].sort()?.map((role, rindex) => (
              <>
                <ListItem
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[100],
                    fontWeight: "600",
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleOutlined color="secondary" />
                  </ListItemIcon>
                  {role}
                </ListItem>
                <Box sx={{ pl: 2 }}>
                  {filteredRoles[role]?.volunteers?.map((v: Volunteer) => (
                    <ListVolunteer volunteer={v} />
                  ))}
                </Box>
              </>
            ))}
        </List>
      </Box>
    </Box>
  );
};

// Helper function to group volunteers by role
export const groupContactsByRole = (
  volunteers: Volunteer[],
  roles: Record<EntityId, Role>
) => {
  const roleMap: {
    [key: string]: { volunteers: any[] };
  } = {};
  volunteers?.forEach((volunteer) => {
    if (!roleMap[roles[volunteer.role]?.name || " "]) {
      roleMap[roles[volunteer.role]?.name || " "] = {
        volunteers: [],
      };
    }
    roleMap[roles[volunteer.role]?.name || " "].volunteers.push(volunteer);
  });
  Object.keys(roleMap)?.forEach((role: string) => {
    roleMap[role].volunteers = [...(roleMap[role].volunteers || [])].sort(
      (a: any, b: any) =>
        sortString(
          `${a.contact_r?.firstname} ${a.contact_r?.lastname}`,
          `${b.contact_r?.firstname} ${b.contact_r?.lastname}`
        )
    );
  });

  return roleMap;
};

// Helper function to group volunteers by skill
export const groupContactsBySkill = (
  volunteers: Volunteer[],
  skills: Record<EntityId, Skill>
) => {
  const skillMap: {
    [key: string]: { volunteers: any[] };
  } = {};
  volunteers?.forEach((volunteer) => {
    volunteer?.skills?.forEach((skill) => {
      if (!skillMap[skills[skill]?.name || " "]) {
        skillMap[skills[skill]?.name || " "] = {
          volunteers: [],
        };
      }
      skillMap[skills[skill]?.name || " "].volunteers.push(volunteer);
    });
  });
  Object.keys(skillMap)?.forEach((role: string) => {
    skillMap[role].volunteers = [...(skillMap[role].volunteers || [])].sort(
      (a: any, b: any) =>
        sortString(
          `${a.contact_r?.firstname} ${a.contact_r?.lastname}`,
          `${b.contact_r?.firstname} ${b.contact_r?.lastname}`
        )
    );
  });

  return skillMap;
};
