import React from 'react';
import {
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Toolbar,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';

export interface DrawerTabProps {
  icon: JSX.Element;
  key: string; //Should be the same as the key of the tab in the tabs object
  sequence: number;
  label?: string;
  contents: JSX.Element;
  backgroundColor?: string;
  padding?: boolean;
  disabled?: boolean;
  visible: boolean;
}

export interface TabSelectionProps {
  tabs: { [key: string]: DrawerTabProps };
  setTabs: (tabs: { [key: string]: DrawerTabProps }) => void;
}

export const TabSelection = ({ tabs, setTabs }: TabSelectionProps) => {
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onTabSelection = (tab?: string) => {
    if (tab) {
      const newTabs = { ...tabs };
      newTabs[tab].visible = !newTabs[tab].visible;
      setTabs(newTabs);
    }
  };

  return (
    <>
      <Toolbar title={'Add item tabs options'}>
        <Button
          color="secondary"
          startIcon={<AddCircle />}
          onClick={handleClick}
        >
          TABS
        </Button>
      </Toolbar>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid
          container
          direction={'column'}
          sx={{
            px: 4,
            py: 2,
            position: 'relative',
          }}
        >
          {/* <small>* drag n drop to reorder tabs</small> */}
          {[...Object.values(tabs)]
            .sort((a, b) => a?.sequence - b?.sequence)
            .map((tab: DrawerTabProps, index: number) => (
              <Grid>
                <FormControlLabel
                  key={`project-tab-config-option-${index}`}
                  control={
                    <Checkbox
                      checked={tab.visible}
                      disabled={tab.disabled}
                      color="primary"
                      onChange={() => onTabSelection(tab.key)}
                    />
                  }
                  label={<span>{tab.label}</span>}
                />
              </Grid>
            ))}
        </Grid>
      </Popover>
    </>
  );
};
