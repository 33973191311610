import { useState } from "react";
import { Box, Fab, Paper } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useGetTasksQuery } from "store/api/task.api";
import { Conversation, ProspectList, Task } from "store/api/models";
import { TaskList } from "./TaskList";
import { BackdropLoading } from "../BackdropLoading";
import { useGetProspectListsQuery } from "store/api/prospect_list.api";
import { useGetTaskProspectListsQuery } from "store/api/task_prospect_list.api";
import { TaskDetailsRedux } from "./TaskDetailsRedux";
import { AgentTask } from "components/agent/AgentTask";
import { VolunteerState } from "components/agent/agent.types";

export const TasksIndex = () => {
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [state, setState] = useState<VolunteerState>({
    selectedConversation: null,
    selectedSkill: null,
    selectedMenu: null,
    isLoading: false,
    completed: false,
    welcome: true,
    disposition: false,
    callOngoing: false,
    fromCallHistory: true,
  });
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: tasks = [] as Task[],
    isLoading: tasksLoading,
    refetch,
  } = useGetTasksQuery({ params: { nested: "normalize" } });
  const {
    data: prospect_lists = [] as ProspectList[],
    isLoading: prospect_listsLoading,
  } = useGetProspectListsQuery({ params: { nested: "normalize" } });
  const {
    data: task_prospect_lists = [] as ProspectList[],
    isLoading: task_prospect_listsLoading,
  } = useGetTaskProspectListsQuery({ params: { nested: "normalize" } });
  const handleTaskClick = (task: Task) => {
    setEditing(false);
    setSelectedTask(task);
  };
  const handleTaskEditClick = (task: Task) => {
    setEditing(true);
    setSelectedTask(task);
  };
  const handleDuplicateTask = (task: Task) => {
    setEditing(true);
    setSelectedTask(task);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {state.selectedConversation && (
        <AgentTask state={state} setState={setState} variant={"task"} />
      )}
      {tasksLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedTask ? 0.25 : 1,
            width: selectedTask ? "25%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedTask ? 1 : 0,
            height: "100%",
            maxHeight: "100%",
            display: state.selectedConversation ? "none" : undefined,
          }}
        >
          <Paper sx={{ p: 1, height: "100%", maxHeight: "100%" }} elevation={5}>
            <TaskList
              tasks={tasks}
              selectedTask={selectedTask}
              handleDuplicateTask={handleDuplicateTask}
              close={() => setSelectedTask(undefined)}
              handleTaskClick={handleTaskClick}
              handleTaskEditClick={handleTaskEditClick}
            />
          </Paper>
        </Box>
      )}

      {!selectedTask && !state.selectedConversation && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            left: 10,
            bottom: 10,
          }}
          onClick={() => {
            setEditing(true);
            setSelectedTask({} as Task);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* Task Details */}
      {selectedTask && (
        <Box
          key={selectedTask.id}
          sx={{
            flexGrow: 0.75,
            maxWidth: "75%",
            width: "75%",
            bgcolor: "transparent",
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            height: "100%",
            maxHeight: "100%",
            display: state.selectedConversation ? "none" : undefined,
          }}
        >
          <TaskDetailsRedux
            disabled={!editing}
            task={selectedTask}
            close={() => {
              setSelectedTask(undefined);
              setEditing(false);
            }}
            callback={async () => {
              await refetch();
              setSelectedTask(undefined);
              setEditing(false);
            }}
            onConversationClick={(conversation: Conversation) =>
              setState((prev) => ({
                ...prev,
                selectedConversation: conversation,
              }))
            }
          />
        </Box>
      )}
    </Box>
  );
};
