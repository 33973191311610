import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  sx?: any;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx = {}, ...other } = props;

  return (
    <Box
      sx={{
        maxHeight: "100%",
        height: "100%",
        flex: 3,
        overflowY: "scroll",
        ...sx,
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "100%", height: "100%" }}>{children}</Box>
      )}
    </Box>
  );
};
