import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Script } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Script entity
const scriptAdapter = createEntityAdapter<Script, EntityId>({
  selectId: (script: Script) => script.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface ScriptsState extends EntityState<Script, EntityId> {
  // Add any additional state properties here if needed
}

const initialScriptState: ScriptsState = scriptAdapter.getInitialState();

// Selectors for Scripts
export const {
  selectById: selectScriptById,
  selectAll: selectAllScripts,
  selectEntities: selectScriptEntities,
  selectIds: selectScriptIds,
  selectTotal: selectScriptTotal,
} = scriptAdapter.getSelectors((state: RootState) => state?.script);

export const scriptSlice = createSlice({
  name: "script",
  initialState: initialScriptState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(script.endpoints.getScripts.matchFulfilled),
      (state, action) => {
        scriptAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        script.endpoints.createScript.matchFulfilled,
        script.endpoints.updateScript.matchFulfilled,
        script.endpoints.patchScript.matchFulfilled
      ),
      (state, action) => {
        scriptAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      script.endpoints.deleteScript.matchFulfilled,
      (state, action) => {
        scriptAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const script = baseApi
  .enhanceEndpoints({ addTagTypes: ["Scripts", "Script"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getScripts: builder.query<Script[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "script",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Script" as const, id })),
                { type: "Scripts" as const },
              ]
            : [{ type: "Scripts" as const }],
      }),
      getScript: builder.query<Script, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `script/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Script", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createScript: builder.mutation<Script, Partial<Script>>({
        query: (request) => ({
          url: "script",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Scripts" }],
      }),
      patchScript: builder.mutation<Script, Script>({
        query: (request) => ({
          url: `script/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Script", id: arg.id },
        ],
      }),
      updateScript: builder.mutation<Script, Script>({
        query: (request) => ({
          url: `script/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Script", id: arg.id },
        ],
      }),
      deleteScript: builder.mutation<void, number>({
        query: (id) => ({
          url: `script/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Script", id: arg },
          { type: "Scripts" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetScriptsQuery,
  useGetScriptQuery,
  useCreateScriptMutation,
  usePatchScriptMutation,
  useUpdateScriptMutation,
  useDeleteScriptMutation,
  usePrefetch: useScriptsPrefetch,
} = script;
