import { useNavigate } from "react-router-dom";
import { useForgotPwMutation } from "store/api/user.api";
import { ForgotPassword } from "./ForgotPassword";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [reset] = useForgotPwMutation();

  return <ForgotPassword reset={reset} navigate={navigate} />;
};
