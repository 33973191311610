import { useState } from "react";
import { Box, Fab, Paper } from "@mui/material";

import { TaskGroup, Task } from "store/api/models";
import { TaskDetails } from "../tasks/TaskDetails";
import { BackdropLoading } from "../BackdropLoading";
import { useGetTasksQuery } from "store/api/task.api";
import { TaskGroups } from "./TaskGroups";
import { Add } from "@mui/icons-material";
import { useGetTaskGroupsQuery } from "store/api/task_group.api";
import { TaskGroupDetails } from "./TaskGroupDetails";

export const TaskGroupsIndex = () => {
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<TaskGroup>();
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: task_groups = [] as TaskGroup[],
    isLoading: taskGroupsLoading,
    refetch,
  } = useGetTaskGroupsQuery({ params: { nested: "all" } });

  const { isLoading: tasksLoading } = useGetTasksQuery({});

  const handleTaskClick = (task: Task) => {
    setSelectedTask(task);
  };
  const handleTaskGroupClick = (task: TaskGroup) => {
    setSelectedTaskGroup(task);
  };
  const handleTaskGroupEditClick = (task: TaskGroup) => {
    setEditing(true);
    setSelectedTaskGroup(task);
  };
  const handleDuplicateTaskGroup = (event_group: TaskGroup) => {
    setEditing(true);
    setSelectedTaskGroup(event_group);
  };

  return (
    <Box
      id="top"
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {tasksLoading || taskGroupsLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedTaskGroup ? 0.25 : 1,
            width: selectedTaskGroup ? "25%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedTaskGroup ? 1 : 0,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Paper sx={{ p: 1, height: "100%", maxHeight: "100%" }} elevation={5}>
            <TaskGroups
              task_groups={task_groups}
              selectedTaskGroup={selectedTaskGroup}
              handleDuplicateTaskGroup={handleDuplicateTaskGroup}
              handleTaskGroupClick={handleTaskGroupClick}
              handleTaskGroupEditClick={handleTaskGroupEditClick}
              close={() => setSelectedTaskGroup(undefined)}
            />
          </Paper>
        </Box>
      )}
      {!selectedTaskGroup && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            left: 10,
            bottom: 10,
          }}
          onClick={() => {
            setEditing(true);
            setSelectedTaskGroup({} as TaskGroup);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* TaskGroup Details */}
      {selectedTaskGroup && (
        <Box
          key={selectedTaskGroup.id}
          sx={{
            flexGrow: 0.75,
            maxWidth: "75%",
            width: "75%",
            bgcolor: "transparent",
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          {selectedTask ? (
            <TaskDetails
              key={selectedTask.id}
              disabled={true}
              task={selectedTask}
              close={() => {
                setSelectedTask(undefined);
                setEditing(false);
              }}
              callback={async () => {
                await refetch();
                setSelectedTask(undefined);
                setEditing(false);
              }}
            />
          ) : (
            <TaskGroupDetails
              key={selectedTaskGroup.id}
              disabled={!editing}
              task_group={selectedTaskGroup}
              close={() => {
                setSelectedTaskGroup(undefined);
                setEditing(false);
              }}
              handleTaskClick={handleTaskClick}
              callback={async () => {
                await refetch();
                setSelectedTask(undefined);
                setEditing(false);
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
