import { forwardRef, useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { Field, FieldProps, getIn, useFormikContext } from 'formik';
import { useBreakpointQuery } from '../../utils/media.util';
import { JItem } from '../jformik/jformik.types';
import { useJFormikContext } from '../jformik/jformik.context';

export const FCustomTextField = forwardRef((jItem: JItem, ref) => {
  const smDown = useBreakpointQuery('sm-down');
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const { disabled: jDisabled = false, disableSubmitOnEnter = true } =
    useJFormikContext();
  const disabled =
    jItem.disabled === false ? false : jDisabled || jItem.disabled;

  const { values, setFieldValue } = useFormikContext();
  const [customStep, setCustomStep] = useState<string>(
    jItem?.jItemProps?.customTextField?.labelOnly
      ? 'label'
      : jItem?.jItemProps?.customTextField?.valueOnly
      ? 'value'
      : getIn(values, jItem.name)?.value &&
        getIn(values, jItem.name)?.value !== ''
      ? 'value'
      : jItem.jItemProps?.customTextField?.customStep || 'label'
  );

  const normalizeNumber = (value: string) => {
    if (!value) return value;
    return value.replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  useEffect(() => {
    if (jItem.defaultValue && !getIn(values, jItem.name)) {
      setFieldValue(jItem.name, jItem.defaultValue);
    }
  }, [jItem.name, jItem.defaultValue]);

  return (
    <Field name={jItem.name}>
      {({
        field: { name, value, onChange, onBlur },
        form: { touched, values, errors, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        return (
          <TextField
            onMouseEnter={() => setHover(true && !disabled)}
            onMouseLeave={() => setHover(false)}
            onKeyPress={e => {
              if (
                e.key === 'Enter' &&
                !(value?.value || value?.value === '') &&
                !(customStep === 'value')
              ) {
                setFieldValue(name, { ...value, value: '' });
                setCustomStep('value');
              }
              disableSubmitOnEnter && e.key === 'Enter' && e.preventDefault();
            }}
            id={`${jItem.name}-field`}
            disabled={
              jItem.disabled === false ? false : disabled || jItem.disabled
            }
            onFocus={() => {
              setFocus(true);
            }}
            label={
              jItem.jItemProps?.labelFunc
                ? jItem?.jItemProps?.labelFunc(name, values, {
                    setFieldValue,
                  })
                : value?.label || (jItem?.label && jItem?.label !== '')
                ? jItem.label
                : 'Please enter the field title...'
            }
            name={name}
            variant={jItem.jItemProps?.variant || 'outlined'}
            // size={smDown ? 'small' : 'medium'}
            fullWidth
            value={
              value?.value || value?.value === ''
                ? value?.value
                : value?.[customStep]
            }
            defaultValue={jItem.defaultValue}
            onChange={(event: any) => {
              setFieldValue(name, {
                ...value,
                [customStep]: event.target.value,
              });
            }}
            onBlur={(event: any) => {
              setFocus(false);
              onBlur(event);
            }}
            error={
              !!(
                getIn(errors, name) &&
                (getIn(touched, name) ||
                  submitCount ||
                  jItem?.jItemProps?.touched)
              )
            }
            helperText={
              getIn(touched, name) || submitCount || jItem?.jItemProps?.touched
                ? getIn(errors, name)
                : null
            }
            {...jItem.jItemProps}
            InputProps={{
              ...jItem?.jItemProps?.InputProps,
              endAdornment:
                !jItem?.jItemProps?.customTextField?.labelOnly &&
                !jItem?.jItemProps?.customTextField?.valueOnly &&
                !(value?.value || value?.value === '') &&
                !(customStep === 'value') ? (
                  <InputAdornment position="end">
                    <Tooltip title={'Set label'}>
                      <IconButton
                        onClick={() => {
                          setFieldValue(name, { ...value, value: '' });
                          setCustomStep('value');
                        }}
                      >
                        <NavigateNext color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : (
                  <div />
                ),
              startAdornment:
                (focus || hover) &&
                (typeof jItem?.jItemProps?.InputProps?.startAdornment ===
                'function'
                  ? jItem?.jItemProps?.InputProps?.startAdornment(
                      name,
                      values,
                      { setFieldValue }
                    )
                  : jItem?.jItemProps?.InputProps?.startAdornment),
            }}
            InputLabelProps={{
              shrink: !!jItem.jItemProps?.placeholder ? true : undefined,
            }}
          />
        );
      }}
    </Field>
  );
});
