import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { JFormik } from "forms";
import { useTaskForm } from "forms/forms/useTaskForm";
import { Conversation, ProspectList, Script, Task } from "store/api/models";
import { useSelector } from "react-redux";
import { selectSkillEntities } from "store";
import { RootState } from "store/reducer";
import { TaskProspects } from "./TaskProspects";
import { selectProspectListEntities } from "store/api/prospect_list.api";
import { ScriptDetailsRedux } from "components/script/ScriptDetailsRedux";
import { TaskActionsRedux } from "./TaskActionsRedux";
import { TabPanel } from "components/tab/TabPanel";
import { selectTaskProspectListEntities } from "store/api/task_prospect_list.api";
import { sortByNumber } from "utils/sort.util";
import { AgentConversationHistory } from "components/agent/AgentConversationHistory";
import { useGetConversationsQuery } from "store/api/conversation.api";

export const TaskDetailsRedux = ({
  task,
  disabled,
  close,
  callback,
  onConversationClick,
}: {
  task: Task;
  disabled: boolean;
  close: Function;
  callback: Function;

  onConversationClick?: (conversation: Conversation) => void;
}) => {
  const { data: conversations = [] } = useGetConversationsQuery(
    {
      params: {
        nested: "all",
        task: task.id,
      },
    },
    {
      skip: !task.id,
      refetchOnMountOrArgChange: true,
    }
  );
  const skillEntities = useSelector((state: RootState) =>
    selectSkillEntities(state)
  );
  const prospectListEntities = useSelector((state: RootState) =>
    selectProspectListEntities(state)
  );
  const taskProspectListEntities = useSelector((state: RootState) =>
    selectTaskProspectListEntities(state)
  );
  const form = useTaskForm();
  const [values, setValues] = useState<any>({
    ...task,
    skills: task?.skills?.map((ts) => skillEntities[ts]) || [],
    prospect_lists:
      task?.prospect_lists && task?.prospect_lists.length > 0
        ? [...task?.prospect_lists]
            ?.sort((a, b) =>
              sortByNumber(
                taskProspectListEntities[a]?.sequence,
                taskProspectListEntities[b]?.sequence
              )
            )
            ?.map(
              (tpl) =>
                prospectListEntities[
                  taskProspectListEntities[tpl]?.prospect_list
                ]
            )
            ?.filter((l) => l) || []
        : [],
  });

  return (
    <ScriptDetailsRedux
      id={0}
      disabled={disabled}
      close={close}
      callback={callback}
      action={
        <TaskActionsRedux
          values={values}
          task={task}
          close={close}
          disabled={disabled}
          callback={callback}
        />
      }
      tab={{
        label: "TASK DETAILS",
        value: "0",
        content: (
          <TabPanel value={"0"} index={"0"}>
            <Box
              sx={{
                px: 1,
                mt: 1,
                display: "flex",
                flexDirection: "column",
                height: "90%",
                maxHeight: "90%",
              }}
            >
              <Box sx={{ px: 1, mt: 1 }}>
                <Typography variant="h6">TASK DETAILS</Typography>
              </Box>
              <Box
                sx={{
                  px: 1,
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  maxHeight: "100%",
                }}
              >
                <JFormik
                  disabled={disabled}
                  initialValues={values}
                  autoStateSave={(newValues: any) => {
                    setValues((prev: any) => ({ ...prev, ...newValues }));
                  }}
                  handleSubmit={() => {}}
                  validationSchema={form.validationSchema}
                  form={form.form}
                />
                <Box sx={{ height: "100%" }}>
                  <TaskProspects
                    disabled={disabled}
                    prospect_lists={values.prospect_lists?.filter(
                      (l: any) => l
                    )}
                    handleListUpdates={(lists: ProspectList[]) => {
                      setValues((prev: any) => ({
                        ...prev,
                        prospect_lists: lists.map((pl, index) => ({
                          ...pl,
                          sequence: index,
                        })),
                      }));
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </TabPanel>
        ),
      }}
      appendtab={{
        label: `TASK CONVERSATIONS (${conversations.length})`,
        value: "4",
        content: (
          <TabPanel value={"4"} index={"4"}>
            <AgentConversationHistory
              onClick={(conversation: Conversation) =>
                onConversationClick && onConversationClick(conversation)
              }
              variant="task"
              task={task.id}
            />
          </TabPanel>
        ),
      }}
    />
  );
};
