import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Task } from "./models";
import { RootState } from "store/reducer";
import { task_prospect_list } from "./task_prospect_list.api";

// Create an EntityAdapter for the Task entity
const taskAdapter = createEntityAdapter<Task, EntityId>({
  selectId: (task: Task) => task.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface TasksState extends EntityState<Task, EntityId> {
  // Add any additional state properties here if needed
}

const initialTaskState: TasksState = taskAdapter.getInitialState();

// Selectors for Tasks
export const {
  selectById: selectTaskById,
  selectAll: selectAllTasks,
  selectEntities: selectTaskEntities,
  selectIds: selectTaskIds,
  selectTotal: selectTaskTotal,
} = taskAdapter.getSelectors((state: RootState) => state?.task);

export const taskSlice = createSlice({
  name: "task",
  initialState: initialTaskState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(task.endpoints.getTasks.matchFulfilled),
      (state, action) => {
        taskAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        task.endpoints.createTask.matchFulfilled,
        task.endpoints.updateTask.matchFulfilled,
        task.endpoints.patchTask.matchFulfilled
      ),
      (state, action) => {
        taskAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      task.endpoints.deleteTask.matchFulfilled,
      (state, action) => {
        taskAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const task = baseApi
  .enhanceEndpoints({ addTagTypes: ["Tasks", "Task"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTasks: builder.query<Task[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "task",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Task" as const, id })),
                { type: "Tasks" as const },
              ]
            : [{ type: "Tasks" as const }],
      }),
      getTask: builder.query<Task, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `task/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Task", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createTask: builder.mutation<Task, Partial<Task>>({
        query: (request) => ({
          url: "task",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Tasks" }],
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          // const patchResult = dispatch(
          //   task.util.updateQueryData("getTasks", (draft) => {
          //     Object.assign(draft, patch);
          //   })
          // );
          try {
            await queryFulfilled;
            dispatch(
              task_prospect_list.util.invalidateTags(["TaskProspectLists"])
            );
          } catch {
            // patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(event_task.util.invalidateTags(['EventTasks']))
             */
            dispatch(task.util.invalidateTags(["Tasks"]));
          }
        },
      }),
      patchTask: builder.mutation<Task, Task>({
        query: (request) => ({
          url: `task/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.id }],
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            task.util.updateQueryData("getTask", { id }, (draft) => {
              Object.assign(draft, patch);
            })
          );
          try {
            await queryFulfilled;
            dispatch(
              task_prospect_list.util.invalidateTags(["TaskProspectLists"])
            );
          } catch {
            patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(event_task.util.invalidateTags(['EventTasks']))
             */
            dispatch(task.util.invalidateTags(["Tasks"]));
          }
        },
      }),
      updateTask: builder.mutation<Task, Task>({
        query: (request) => ({
          url: `task/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.id }],
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            task.util.updateQueryData("getTask", { id }, (draft) => {
              Object.assign(draft, patch);
            })
          );
          try {
            await queryFulfilled;
            dispatch(
              task_prospect_list.util.invalidateTags(["TaskProspectLists"])
            );
          } catch {
            patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(event_task.util.invalidateTags(['EventTasks']))
             */
            dispatch(task.util.invalidateTags(["Tasks"]));
          }
        },
      }),
      deleteTask: builder.mutation<void, number>({
        query: (id) => ({
          url: `task/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Task", id: arg },
          { type: "Tasks" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetTasksQuery,
  useGetTaskQuery,
  useCreateTaskMutation,
  usePatchTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  usePrefetch: useTasksPrefetch,
} = task;
