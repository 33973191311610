import { forwardRef } from "react";
import { Divider, Typography } from "@mui/material";
import { FTextField } from "../jformik-fields/FTextField";
import { FInputBase } from "../jformik-fields/FInputBase";
import { FAutoComplete } from "../jformik-fields/FAutoComplete";
import { FCheckbox } from "../jformik-fields/FCheckbox";
import { JIMapperProps } from "./jformik.types";
import { FLabeledInput } from "../jformik-fields/FLabeledInput";
import { FTableArray } from "../jformik-fields/FTableArray";
import { FDropzone } from "../jformik-fields/FDropzone";
import { FFieldArray } from "../jformik-fields/FFieldArray";
import { FTextFieldArray } from "../jformik-fields/FTextFieldArray";
import { FButton } from "../jformik-fields/FButton";
import { FToggleButtons } from "../jformik-fields/FToggleButtons";
import { FAttachments } from "../jformik-fields/FAttachments";
import { FPaper } from "../jformik-fields/FPaper";
import { FTypography } from "../jformik-fields/FTypography";
import { FJItem } from "../jformik-fields/FJItem";
import { FAccordion } from "../jformik-fields/FAccordion";
import { FCustomTextField } from "../jformik-fields/FCustomTextField";
import { FToggleButtonGroup } from "../jformik-fields/FToggleButtonGroup";
import { FFieldArraySelect } from "../jformik-fields/FFieldArraySelect";
import { FDatePicker } from "forms/jformik-fields/FDatePicker";
import { FDateTimePicker } from "forms/jformik-fields/FDateTimePicker";
import { FImage } from "forms/jformik-fields/FImage";
import { FEmbed } from "forms/jformik-fields/FEmbed";

export const JItemMapper = forwardRef(({ jItem }: JIMapperProps, ref) => {
  return <Mapper jItem={jItem} ref={ref} />;
});

const Mapper = forwardRef(({ jItem }: JIMapperProps, ref) => {
  switch (jItem.itemType) {
    case "component":
      return jItem?.component || <div />;
    case "attachments":
      return <FAttachments {...jItem} />;
    case "typography":
      return <FTypography {...jItem} />;
    case "custom_textfield":
      return <FCustomTextField {...jItem} ref={ref} />;
    case "textfield":
      return <FTextField {...jItem} ref={ref} />;
    case "inputbase":
      return <FInputBase {...jItem} ref={ref} />;
    case "autocomplete":
      return <FAutoComplete {...jItem} ref={ref} />;
    case "checkbox":
      return <FCheckbox {...jItem} />;
    case "labeledinput":
      return <FLabeledInput {...jItem} ref={ref} />;
    case "table_array":
      return <FTableArray {...jItem} />;
    case "divider":
      return <Divider {...jItem.jItemProps} />;
    case "dropzone":
      return <FDropzone {...jItem} />;
    case "fieldarray":
      return <FFieldArray {...jItem} />;
    case "fieldarrayselect":
      return <FFieldArraySelect {...jItem} />;
    case "textfieldarray":
      return <FTextFieldArray {...jItem} />;
    case "paper":
      return <FPaper {...jItem} />;
    case "button":
      return <FButton {...jItem} />;
    case "toggle_buttons":
      return <FToggleButtons {...jItem} />;
    case "toggle_group":
      return <FToggleButtonGroup {...jItem} />;
    case "jItem":
      return <FJItem {...jItem} />;
    case "accordion":
      return <FAccordion {...jItem} />;
    case "datepicker":
      return <FDatePicker {...jItem} />;
    case "datetimepicker":
      return <FDateTimePicker {...jItem} />;
    case "image":
      return <FImage {...jItem} />;
    case "embed":
      return <FEmbed {...jItem} />;
    default:
      return <div />;
  }
});
