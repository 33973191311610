import { useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import {
  Tabs,
  Tab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Backdrop,
  useTheme,
  Box,
  TextField,
  backdropClasses,
  Breadcrumbs,
  Link,
  Tooltip,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  useMediaQuery,
  Dialog,
  PaperProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Close,
  Save,
  Edit,
  ArrowDropDown,
  SlideshowOutlined,
} from '@mui/icons-material';
import { BackdropLoading } from '../components/BackdropLoading';
import Draggable from 'react-draggable';
import {
  ChipButtonMenu,
  ChipButtonMenuProps,
  DrawerTabProps,
} from '../drawers';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type Device = 'desktop' | 'mobile';

export interface TabDialogProps {
  title: string;
  width?: object;
  maxWidth?: object | string | number;
  status?: ChipButtonMenuProps | string;
  open?: boolean;
  onHelp?: Function;
  onClose?: Function;
  tabs: DrawerTabProps[];
  initialTab?: number;
  icons?: JSX.Element[];
  tabIcons?: JSX.Element[];
  setTitle?: Function;
  isLoading?: boolean;
  contentLoading?: boolean;
  save?: Function;
  transparentBackdrop?: boolean;
  breadcrumbs?: { label: string; onClick?: Function }[];
  device?: Device;
}

export const TabDialog = (props: TabDialogProps) => {
  const { initialTab = 0, ...rest } = props;
  const [activeTab, setActiveTab] = useState<number>(initialTab);
  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);
  return (
    <ControlledTabDialog
      {...props}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
    />
  );
};

export interface ControlledTabDialogProps {
  title: string;
  width?: object;
  maxWidth?: object | string | number;
  status?: ChipButtonMenuProps | string;
  open?: boolean;
  onHelp?: Function;
  onClose?: Function;
  tabs: DrawerTabProps[];
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  icons?: JSX.Element[];
  tabIcons?: JSX.Element[];
  // setTitle?: Function;
  // isEditing?: boolean;
  // save?: Function;
  isLoading?: boolean;
  contentLoading?: boolean;
  transparentBackdrop?: boolean;
  breadcrumbs?: { label: string; onClick?: Function }[];
  device?: Device;
}

export const ControlledTabDialog = ({
  open = false,
  width,
  maxWidth,
  onHelp,
  onClose = () => {},
  title,
  // setTitle = (value: any) => {},
  status,
  tabs,
  activeTab,
  setActiveTab,
  // save,
  icons,
  tabIcons,
  isLoading: defaultLoading = false,
  contentLoading = false,
  transparentBackdrop,
  breadcrumbs,
  device = 'desktop',
}: ControlledTabDialogProps) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setLoading] = useState<boolean>(defaultLoading);

  useEffect(() => setLoading(defaultLoading), [defaultLoading]);

  const TabsComponent = device === 'desktop' ? TabsForDesktop : TabsForMobile;
  const tabsComponentProps = { activeTab, setActiveTab, tabs, tabIcons };

  const isBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;

  const closeDialog = () => {
    setActiveTab(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      {!contentLoading ? (
        <Grid
          container
          sx={{
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          <Grid id="draggable-dialog-title" item xs={12} style={{ flex: 1 }}>
            <Toolbar
              sx={{
                py: '10px',
                ...(device === 'mobile'
                  ? { borderBottom: 1, borderColor: 'divider' }
                  : {}),
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        float: 'right',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        ml: 1,
                      }}
                    >
                      {icons &&
                        icons.map((icon, index) => (
                          <Box id={`tab-drawer-icon-${index}`} key={index}>
                            {icon}
                          </Box>
                        ))}
                      {status &&
                        (status as ChipButtonMenuProps)?.label !==
                          undefined && (
                          <Box>
                            <Chip
                              label={(status as ChipButtonMenuProps)?.label}
                              sx={{
                                backgroundColor: (status as ChipButtonMenuProps)
                                  ?.color,
                                color: 'white',
                              }}
                            />
                          </Box>
                        )}
                      <Box>
                        <Tooltip title={'Close'}>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => closeDialog()}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>

                    {isBreadcrumbs && (
                      <Box sx={{ float: 'left', py: 1 }}>
                        <Breadcrumbs>
                          {breadcrumbs.map((breadcrumb, index) => (
                            <Link
                              key={index}
                              underline="hover"
                              color="inherit"
                              onClick={() =>
                                breadcrumb.onClick && breadcrumb.onClick()
                              }
                            >
                              {breadcrumb.label}
                            </Link>
                          ))}
                        </Breadcrumbs>
                      </Box>
                    )}

                    <Box sx={{ clear: 'left' }}>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: downSm ? 28 : 34,
                          fontWeight: 700,
                          float: 'left',
                          ...(isBreadcrumbs ? {} : { lineHeight: '40px' }),
                        }}
                        color={'primary'}
                      >
                        {title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TabsComponent {...tabsComponentProps} />
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid
            item
            xs={12}
            flexGrow={90}
            sx={{
              backgroundColor: tabs[activeTab]?.backgroundColor
                ? tabs[activeTab].backgroundColor
                : 'rgba(0, 0, 0, 0.05)',
              px: !!!tabs[activeTab]?.padding ? { xs: '16px', sm: '24px' } : 0,
              py: !!!tabs[activeTab]?.padding ? { xs: '16px', sm: '24px' } : 0,
              height: '100%',
              overflowX: 'hidden',
            }}
          >
            {tabs[activeTab]?.contents}
          </Grid>
        </Grid>
      ) : (
        <BackdropLoading />
      )}
    </Dialog>
  );
};

const TransparentBackdrop = styled(Backdrop)(({ theme }) => ({
  [`&.${backdropClasses.root}`]: {
    zIndex: theme.zIndex.drawer - 20,
    backgroundColor: 'transparent',
  },
}));

type TabsProps = Pick<
  ControlledTabDialogProps,
  'activeTab' | 'setActiveTab' | 'tabs' | 'tabIcons'
>;

const TabsForDesktop = ({
  activeTab,
  setActiveTab,
  tabs,
  tabIcons,
}: TabsProps) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap={'nowrap'}
        sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}
      >
        <Grid item sx={{ maxWidth: '100%' }}>
          <Tabs
            variant="scrollable"
            scrollButtons={'auto'}
            value={activeTab}
            onChange={(event, value) => setActiveTab(value)}
          >
            {tabs.map((tab, index) => (
              <Tab
                id={`tab-drawer-tab-${index}`}
                label={tab.icon}
                key={index}
                value={index}
              />
            ))}
          </Tabs>
        </Grid>
        {tabIcons && (
          <Grid
            item
            sx={{
              marginRight: '1%',
              display: 'inline-flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            {tabIcons &&
              tabIcons.map((icon, index) => (
                <Box sx={{ marginRight: '2px' }} key={`tab-icon-${index}`}>
                  {icon}
                </Box>
              ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TabsForMobile = ({
  activeTab,
  setActiveTab,
  tabs,
  tabIcons,
}: TabsProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (event: any, index: number) => {
    setActiveTab(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            ref={anchorRef}
            size="small"
            color="primary"
            label={tabs[activeTab].icon}
            onClick={handleToggle}
            onDelete={handleToggle}
            deleteIcon={<ArrowDropDown />}
            sx={{ width: 'fit-content' }}
          />
        </Grid>

        <Grid item>
          <Typography variant="caption" color="textSecondary">
            : Click to choose other settings.
          </Typography>
        </Grid>
      </Grid>

      <Popper
        sx={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement={'bottom-start'}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {tabs.map((tab, index) => (
                    <MenuItem
                      key={`mobile-tab-${index}`}
                      selected={index === activeTab}
                      onClick={event => handleMenuItemClick(event, index)}
                      sx={{ py: 1.5 }}
                    >
                      {tab.icon}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
