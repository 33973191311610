import {
  FormBuilderProvider,
  FormBuilderContext,
} from "forms/builder/formBuilder.context";
import BranchingForm from "forms/render/BranchingForm";
import { Form } from "forms/render/types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { usePatchConversationMutation } from "store/api/conversation.api";
import { Conversation, Person, Prospect, Skill, Task } from "store/api/models";
import { selectNGPContactById } from "store/api/ngp/ngp_contact.api";
import { RootState } from "store/reducer";
import { VolunteerState } from "./agent.types";
import { sortByNumber } from "utils/sort.util";
import { useNGPContact } from "utils/ngp.util";
import { User } from "store/api/ngp/ngp_models";
import { useEffect } from "react";
import { setInitialData, setPerson } from "store/slice/formBuilder.slice";
import { BranchingFormRedux } from "forms/render/BranchingFormRedux";
import { selectUser } from "store";

export const AgentConversation = ({
  task,
  variant = "volunteer",
  state,
  setState,
  save,
  submit,
}: {
  variant?: "task" | "volunteer";
  task?: number;
  state: VolunteerState;
  setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
  save: (answers: any) => void;
  submit: (answers: any) => void;
}) => {
  const dispatch = useDispatch();
  const volunteerUser = useSelector(selectUser);
  const user: User = useNGPContact(state.selectedConversation?.vanid) as User;

  useEffect(() => {
    const { firstname, lastname, emails, phones, addresses = [] } = user || {};
    const address =
      addresses?.find((a) => a.IsPreferred) ||
      [...(addresses || [])].sort((a, b) =>
        sortByNumber(b.ContactsAddressID, a.ContactsAddressID)
      )?.[0];
    const addressString = `${address?.AddressLine1 || ""} ${
      address?.City || ""
    } ${address?.State || ""} ${address?.Zip5 || ""}`;
    const phone =
      phones?.find((a) => a.preferredPhone) ||
      [...(phones || [])].sort((a, b) =>
        sortByNumber(b.contactsPhoneId, a.contactsPhoneId)
      )?.[0];
    const email =
      emails?.find((a) => a.preferred_email) ||
      [...(emails || [])].sort((a, b) =>
        sortByNumber(b.contacts_email_id, a.contacts_email_id)
      )?.[0];
    const person = {
      firstname,
      lastname,
      addressString: addressString,
      address: address?.AddressLine1 || "",
      city: address?.City || "",
      state: address?.State || "",
      zip: address?.Zip5 || "",
      phone: phone?.phone || "",
      email: email?.email || "",
    } as Person;
    // Initialize Redux store with task data
    const task = state.selectedConversation?.form || ({} as Task);

    dispatch(
      setInitialData({
        title: task?.title || "",
        scriptId: task?.id || null,
        questions: task.form?.questions || [],
        edges: task.form?.edges || [],
        answers: task.form?.answers || {},
        disabled: false,
        person: person,
        user: volunteerUser,
        conversation: state.selectedConversation,
      })
    );
  }, [user, volunteerUser, state.selectedConversation]);
  if (state.selectedConversation) {
    return <BranchingFormRedux preview={false} save={save} submit={submit} />;
  } else {
    return <div />;
  }
};
