import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { VolunteerState } from "./agent.types";
import { useSelector } from "react-redux";
import { selectAllResults } from "store";
import { AgentConversationNotes } from "./AgentConversationNotes";
import { Flag, OutlinedFlag } from "@mui/icons-material";
import { useEffect, useState } from "react";

export const AgentDialogDisposition = ({
  state,
  setState,
  onDisposition,
  notesRef,
}: {
  state: VolunteerState;
  setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
  onDisposition: ({
    disposition,
    result,
    note,
    flagged,
  }: {
    disposition: string;
    result?: number | undefined;
    note?: string;
    flagged?: boolean;
  }) => void;
  notesRef: React.MutableRefObject<undefined>;
}) => {
  const [flagged, setFlagged] = useState<boolean>(
    (notesRef?.current as any)?.getFlagged()
  );
  const [value, setValue] = useState<string>(
    (notesRef?.current as any)?.getNote()
  );
  const handleClose = () => {
    setState((prev) => ({ ...prev, disposition: false }));
  };
  const results = useSelector(selectAllResults);
  useEffect(() => {
    setFlagged((notesRef?.current as any)?.getFlagged());
    setValue((notesRef?.current as any)?.getNote());
  }, [state.disposition, notesRef]);
  return (
    <Dialog
      open={state.disposition}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          let disposition: string = "Complete";
          let result: number | undefined;
          if (Number(formJson.disposition)) {
            //disposition is actually result
            disposition = "Complete";
            result = Number(formJson.disposition);
          } else {
            disposition = formJson.disposition;
          }
          const note = formJson.note;
          await onDisposition({ disposition, result, note: value, flagged });
        },
      }}
    >
      <DialogTitle>
        {state.fromCallHistory ? "Update Disposition" : "Exit conversation?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please leave a disposition for this conversation.
        </DialogContentText>
        <FormControl fullWidth size="small" className="nodrag">
          <InputLabel id="disposition-label">
            Conversation Disposition
          </InputLabel>
          <Select
            labelId="disposition-label"
            id="event-groups"
            size="small"
            label="Conversation Disposition"
            name="disposition"
          >
            {results
              .filter((r) => ![20, 22, 25].includes(r.id)) //filter out those that are right click onphone button
              ?.map((r) => (
                <MenuItem value={r.id}>{r.name}</MenuItem>
              ))}
            {/* {state.fromCallHistory && (
              <MenuItem value={"Created"}>Created</MenuItem>
            )}
            {state.fromCallHistory && (
              <MenuItem value={"Called"}>Called</MenuItem>
            )} */}
            <MenuItem value={"Skipped"}>Skipped</MenuItem>
            {/* <MenuItem value={"Complete"}>Complete</MenuItem> */}
          </Select>

          <DialogContentText sx={{ mb: 2 }}>
            <small>
              <i>
                To mark a specific phone number as unusable, please right click
                on it.
              </i>
            </small>
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>
            Any additional notes?
          </DialogContentText>
          <TextField
            multiline
            minRows={2}
            fullWidth
            placeholder="Add any notes and optionally flag for the admin to follow up."
            name="note"
            value={value}
            onChange={(event) => {
              (notesRef?.current as any).updateValue(
                (event?.target as any)?.value
              );
              setValue((event?.target as any)?.value);
            }}
            // sx={{ p: 2 }}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      (notesRef?.current as any).updateFlagged(!flagged);
                      setFlagged(!flagged);
                    }}
                    edge="end"
                  >
                    <Tooltip title={"Flag the note for admin follow-up"}>
                      {flagged ? (
                        <Flag fontSize="small" color="error" />
                      ) : (
                        <OutlinedFlag fontSize="small" />
                      )}
                    </Tooltip>
                  </IconButton>
                ),
              },
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
