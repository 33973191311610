import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

type MQTTQuote = {
  event_type: string;
  filename: string;
  project_id: number;
  quote_id: number;
};

type MQTTQuotes = {
  [key: string]: MQTTQuote;
};

type MQTTState = {
  quotes: MQTTQuotes;
};

export const mqttSlice = createSlice({
  name: 'mqtt',
  initialState: { quotes: {} } as MQTTState,
  reducers: {
    mqttMessage(state, action: PayloadAction<any>) {
      if (Object.keys(action.payload).includes('filename')) {
        state.quotes[action.payload.filename as string] = { ...action.payload };
      }
    },
    clearMQTT(state, action: PayloadAction<any>) {
      delete state.quotes[action.payload as string];
    },
  },
});
export const selectMQTT = createSelector(
  (state: { mqtt: MQTTState }) => state.mqtt,
  mqtt => mqtt.quotes as MQTTQuotes
);

export const { mqttMessage, clearMQTT } = mqttSlice.actions;

export const receiveMQTTMessage = createAction<any>('MQTT_MESSAGE');
