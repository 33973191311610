import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { EventType } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the EventType entity
const eventTypeAdapter = createEntityAdapter<EventType, EntityId>({
  selectId: (event_type: EventType) => event_type.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface EventTypesState extends EntityState<EventType, EntityId> {
  // Add any additional state properties here if needed
}

const initialEventTypeState: EventTypesState =
  eventTypeAdapter.getInitialState();

// Selectors for EventTypes
export const {
  selectById: selectEventTypeById,
  selectAll: selectAllEventTypes,
  selectEntities: selectEventTypeEntities,
  selectIds: selectEventTypeIds,
  selectTotal: selectEventTypeTotal,
} = eventTypeAdapter.getSelectors((state: RootState) => state?.event_type);

export const eventTypeSlice = createSlice({
  name: "event_type",
  initialState: initialEventTypeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(event_type.endpoints.getEventTypes.matchFulfilled),
      (state, action) => {
        eventTypeAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        event_type.endpoints.createEventType.matchFulfilled,
        event_type.endpoints.updateEventType.matchFulfilled,
        event_type.endpoints.patchEventType.matchFulfilled
      ),
      (state, action) => {
        eventTypeAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      event_type.endpoints.deleteEventType.matchFulfilled,
      (state, action) => {
        eventTypeAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const event_type = baseApi
  .enhanceEndpoints({ addTagTypes: ["EventTypes", "EventType"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEventTypes: builder.query<EventType[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "event_type",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "EventType" as const, id })),
                { type: "EventTypes" as const },
              ]
            : [{ type: "EventTypes" as const }],
      }),
      getEventType: builder.query<EventType, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `event_type/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "EventType", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createEventType: builder.mutation<EventType, Partial<EventType>>({
        query: (request) => ({
          url: "event_type",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "EventTypes" }],
      }),
      patchEventType: builder.mutation<EventType, EventType>({
        query: (request) => ({
          url: `event_type/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventType", id: arg.id },
        ],
      }),
      updateEventType: builder.mutation<EventType, EventType>({
        query: (request) => ({
          url: `event_type/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventType", id: arg.id },
        ],
      }),
      deleteEventType: builder.mutation<void, number>({
        query: (id) => ({
          url: `event_type/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventType", id: arg },
          { type: "EventTypes" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetEventTypesQuery,
  useGetEventTypeQuery,
  useCreateEventTypeMutation,
  usePatchEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
  usePrefetch: useEventTypesPrefetch,
} = event_type;
