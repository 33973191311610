import { createEntityAdapter, EntityState, EntityId } from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Event } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Event entity
const eventAdapter = createEntityAdapter<Event, EntityId>({
  selectId: (event: Event) => event.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface EventsState extends EntityState<Event, EntityId> {
  // Add any additional state properties here if needed
}

const initialState: EventsState = eventAdapter.getInitialState();

// Selectors for Events
export const { selectById: selectEventById, selectAll: selectAllEvents } =
  eventAdapter.getSelectors((state: RootState) => state?.event);

// Define your API slice
export const event = baseApi
  .enhanceEndpoints({ addTagTypes: ["Events", "Event"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEvents: builder.query<Event[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "event",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Event" as const, id })),
                { type: "Events" as const },
              ]
            : [{ type: "Events" as const }],
      }),
      getEvent: builder.query<Event, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `event/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createEvent: builder.mutation<Event, Partial<Event>>({
        query: (request) => ({
          url: "event",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Events" }],
      }),
      patchEvent: builder.mutation<Event, Event>({
        query: (request) => ({
          url: `event/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Event", id: arg.id },
        ],
      }),
      updateEvent: builder.mutation<Event, Event>({
        query: (request) => ({
          url: `event/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Event", id: arg.id },
        ],
      }),
      deleteEvent: builder.mutation<void, number>({
        query: (id) => ({
          url: `event/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Event", id: arg },
          { type: "Events" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetEventsQuery,
  useGetEventQuery,
  useCreateEventMutation,
  usePatchEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  usePrefetch: useEventsPrefetch,
} = event;
