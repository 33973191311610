import { Grid2 as Grid, Link, Box } from "@mui/material";
import { routeNames } from "../config";
import * as Yup from "yup";
import { HeaderShort } from "headers";
import { JFormik } from "forms";

export interface ForgotPasswordProps {
  reset?: Function;
  navigate?: Function;
}

export const ForgotPassword = ({ reset, navigate }: ForgotPasswordProps) => {
  return (
    <Box sx={{ bgcolor: "rgba(249,255,243,1)", paddingX: { xs: 2, sm: 3 } }}>
      <HeaderShort position="fixed" />
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          minHeight: 640,
        }}
      >
        <Grid size={{ xs: 12, sm: 8, md: 6, lg: 5, xl: 4 }}>
          <JFormik
            initialValues={(resource: any) => ({ email: "" })}
            validationSchema={Yup.object({
              email: Yup.string()
                .required("Email is required")
                .email("Invalid email address"),
            })}
            handleSubmit={(values: any) =>
              reset ? reset(values) : console.info(values)
            }
            form={[
              {
                name: "timeslots",
                label: "",
                itemType: "embed",
                embed: ({ values, setFieldValue }) => (
                  <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                    <a href="https://www.meckdems.org/">
                      <img
                        src="https://static.wixstatic.com/media/730684_370b1a730906433f9464d7a28f3bd821~mv2.png/v1/fill/w_192,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/730684_370b1a730906433f9464d7a28f3bd821~mv2.png"
                        alt="Mecklenburg County Democratic Party - Home"
                      />
                    </a>
                  </Grid>
                ),
              },
              {
                name: "h4",
                itemType: "typography",
                label: "Forgot Your Password?",
                jItemProps: {
                  variant: "h4",
                  sx: { textAlign: "center", fontSize: { xs: 28, sm: 34 } },
                },
              },
              {
                name: "subtitle1",
                itemType: "typography",
                label:
                  "Please enter your email address to request a password reset.",
                jItemProps: {
                  variant: "subtitle1",
                  color: "textSecondary",
                  sx: {
                    textAlign: "center",
                    marginTop: -1,
                    marginBottom: 3,
                    lineHeight: 1.5,
                  },
                },
              },
              {
                name: "email",
                itemType: "textfield",
                label: "Email",
              },
              {
                name: "submit",
                itemType: "button",
                jItemProps: { type: "submit" },
                label: "Send Email",
              },
            ]}
          />
          <Link
            component="button"
            variant="body1"
            sx={{ mt: 5 }}
            onClick={() => {
              navigate
                ? navigate(routeNames.LOGIN)
                : console.info("back_to_signin");
            }}
          >
            Back to Sign in
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
