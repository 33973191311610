import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../store';
import { routeNames } from '../config';

type PrivateRouteProps = RouteProps & {
  // tslint:disable-next-line:no-any
  element: any;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { element: Element, ...rest } = props;
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  if (isLoggedIn) {
    return <Element {...props} {...rest} />;
  } else {
    return <Navigate to={routeNames.LOGIN} state={{ from: location }} />;
  }
};
