import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Call, Device } from "@twilio/voice-sdk";

export type CapabilityToken = {
  identity: string;
  token: string;
};

export const voice = baseApi
  .enhanceEndpoints({ addTagTypes: ["Voice"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVoiceToken: builder.query<CapabilityToken, { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "voice/token",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "Voice" as const, id: "token" },
        ],
      }),
      postCallSetup: builder.mutation<any, any>({
        query: (body) => ({
          url: "voice/setup",
          method: "POST",
          body: body,
        }),
      }),
    }),
  });

// Auto-generated hooks
export const { useGetVoiceTokenQuery, usePostCallSetupMutation } = voice;

/********* */
/* reducer */
/********* */
export interface VoiceCall {
  // The unique string that we created to identify this Call resource.
  sid?: string;
  // The date and time in UTC that this resource was created specified in [RFC 2822](https://www.ietf.org/rfc/rfc2822.txt) format.
  dateCreated?: string;
  // The date and time in UTC that this resource was last updated, specified in [RFC 2822](https://www.ietf.org/rfc/rfc2822.txt) format.
  dateUpdated?: string;
  // The SID that identifies the call that created this leg.
  parentCallSid?: string;
  // The SID of the [Account](https://www.twilio.com/docs/iam/api/account) that created this Call resource.
  accountSid?: string;
  // The phone number, SIP address, Client identifier or SIM SID that received this call.
  to?: string;
  // The phone number, SIP address or Client identifier that received this call, formatted for display.
  toFormatted?: string;
  // The phone number, SIP address, Client identifier or SIM SID that made this call.
  from?: string;
  // The calling phone number, SIP address, or Client identifier formatted for display.
  fromFormatted?: string;
  // If the call was inbound, this is the SID of the IncomingPhoneNumber resource that received the call.
  phoneNumberSid?: string;
  // The current status of the call.
  status?: string;
  // The start time of the call in UTC format.
  startTime?: string;
  // The end time of the call in UTC format.
  endTime?: string;
  // The length of the call in seconds.
  duration?: string;
  // The charge for this call, in the currency associated with the account.
  price?: string;
  // The currency in which `Price` is measured in ISO 4127 format.
  priceUnit?: string;
  // A string describing the direction of the call.
  direction?: string;
  // Either `human` or `machine` if this call was initiated with answering machine detection.
  answeredBy?: string;
  // The API version used to create the call.
  apiVersion?: string;
  // The forwarding phone number if this call was an incoming call forwarded from another number.
  forwardedFrom?: string;
  // The Group SID associated with this call.
  groupSid?: string;
  // The caller's name if this call was an incoming call to a phone number with caller ID Lookup enabled.
  callerName?: string;
  // The wait time in milliseconds before the call is placed.
  queueTime?: string;
  // The unique identifier of the trunk resource that was used for this call.
  trunkSid?: string;
  // The URI of this resource, relative to `https://api.twilio.com`.
  uri?: string;
  // A list of subresources available to this call, identified by their URIs.
  subresourceUris?: { [key: string]: any };
}
export interface VoiceSlice {
  token?: string;
  identity?: string;
  callStatus?: string;
  ongoing?: boolean;
  expanded?: boolean;
  callMethod?: string;
  voiceCall?: VoiceCall;
  dial?: string;
  service?: boolean;
}

export const voiceSlice = createSlice({
  name: "voice",
  initialState: {
    callStatus: "Offline",
    ongoing: false,
    expanded: false,
    callMethod: "browser",
    service: false,
  } as VoiceSlice,
  reducers: {
    ready: (state) => {
      state.service = true;
      state.callStatus = "Ready";
    },
    dial: (state, { payload }: PayloadAction<VoiceSlice>) => {
      state.dial = payload.dial;
      state.service = payload.service ? payload.service : false;
    },
    incoming: (state) => {
      state.ongoing = true;
    },
    answer: (state) => {
      state.ongoing = true;
    },
    ignore: (state) => {
      state.ongoing = false;
    },
    setup: (state, { payload }: PayloadAction<VoiceSlice>) => {
      state.ongoing = true;
      state.callStatus = "Call Ongoing";
    },
    disconnect: (state) => {
      state.dial = undefined;
      state.ongoing = false;
      state.callStatus = "Disconnected";
    },
    token: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        voice.endpoints.getVoiceToken.matchFulfilled,
        (state, { payload }: PayloadAction<CapabilityToken>) => {
          state.token = payload.token;
          state.identity = payload.identity;
        }
      )
      .addMatcher(
        voice.endpoints.postCallSetup.matchFulfilled,
        (state, { payload }: PayloadAction<VoiceCall>) => {
          state.voiceCall = payload;
        }
      ),
});

export const selectVoice = createSelector(
  (state: { voice: VoiceSlice }) => state.voice,
  (voice: VoiceSlice) => voice as VoiceSlice
);
