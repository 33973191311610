import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

type HistoryState = {
  index?: string;
};

export const historySlice = createSlice({
  name: 'history',
  initialState: {} as HistoryState,
  reducers: {
    setIndexHistory(state, action: PayloadAction<any>) {
      if (action.payload) {
        state.index = action.payload;
      }
    },
    clearIndexHistory(state, action: PayloadAction<any>) {
      delete state.index;
    },
  },
});
export const selectIndexHistory = createSelector(
  (state: { history: HistoryState }) => state.history,
  history => history.index as string
);

export const { setIndexHistory, clearIndexHistory } = historySlice.actions;
