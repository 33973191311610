import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { TaskGroup } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the TaskGroup entity
const taskgroupAdapter = createEntityAdapter<TaskGroup, EntityId>({
  selectId: (task_group: TaskGroup) => task_group.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface TaskGroupsState extends EntityState<TaskGroup, EntityId> {
  // Add any additional state properties here if needed
}

const initialTaskGroupState: TaskGroupsState =
  taskgroupAdapter.getInitialState();

// Selectors for TaskGroups
export const {
  selectById: selectTaskGroupById,
  selectAll: selectAllTaskGroups,
  selectEntities: selectTaskGroupEntities,
  selectIds: selectTaskGroupIds,
  selectTotal: selectTaskGroupTotal,
} = taskgroupAdapter.getSelectors((state: RootState) => state?.task_group);

export const task_group = baseApi
  .enhanceEndpoints({ addTagTypes: ["TaskGroups", "TaskGroup"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTaskGroups: builder.query<TaskGroup[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "task_group",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "TaskGroup" as const,
                  id,
                })),
                { type: "TaskGroups" as const },
              ]
            : [{ type: "TaskGroups" as const }],
      }),
      getTaskGroup: builder.query<TaskGroup, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `task_group/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "TaskGroup", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createTaskGroup: builder.mutation<TaskGroup, Partial<TaskGroup>>({
        query: (request) => ({
          url: "task_group",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "TaskGroups" }],
      }),
      patchTaskGroup: builder.mutation<TaskGroup, TaskGroup>({
        query: (request) => ({
          url: `task_group/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskGroup", id: arg.id },
        ],
        // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        //   const patchResult = dispatch(
        //     task_group.util.updateQueryData("getTaskGroup", { id }, (draft) => {
        //       Object.assign(draft, patch);
        //     })
        //   );
        //   try {
        //     await queryFulfilled;
        //     dispatch(
        //       task_group_member.util.invalidateTags(["TaskProspectLists"])
        //     );
        //   } catch {
        //     patchResult.undo();

        //     /**
        //      * Alternatively, on failure you can invalidate the corresponding cache tags
        //      * to trigger a re-fetch:
        //      * dispatch(event_task.util.invalidateTags(['EventTasks']))
        //      */
        //     dispatch(task.util.invalidateTags(["Tasks"]));
        //   }
        // },
      }),
      updateTaskGroup: builder.mutation<TaskGroup, TaskGroup>({
        query: (request) => ({
          url: `task_group/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskGroup", id: arg.id },
        ],
      }),
      deleteTaskGroup: builder.mutation<void, number>({
        query: (id) => ({
          url: `task_group/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskGroup", id: arg },
          { type: "TaskGroups" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useCreateTaskGroupMutation,
  useGetTaskGroupQuery,
  useDeleteTaskGroupMutation,
  useGetTaskGroupsQuery,
  usePatchTaskGroupMutation,
  useUpdateTaskGroupMutation,
  usePrefetch: useTaskGroupPrefetch,
} = task_group;
