import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Volunteer } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Volunteer entity
const volunteerAdapter = createEntityAdapter<Volunteer, EntityId>({
  selectId: (volunteer: Volunteer) => volunteer.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface VolunteersState extends EntityState<Volunteer, EntityId> {
  // Add any additional state properties here if needed
}

const initialState: VolunteersState = volunteerAdapter.getInitialState();

// Selectors for Volunteers
export const {
  selectById: selectVolunteerById,
  selectAll: selectAllVolunteers,
  selectEntities: selectVolunteerEntities,
  selectIds: selectVolunteerIds,
  selectTotal: selectVolunteerTotal,
} = volunteerAdapter.getSelectors((state: RootState) => state?.volunteer);

export const volunteerSlice = createSlice({
  name: "volunteer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(volunteer.endpoints.getVolunteers.matchFulfilled),
      (state, action) => {
        volunteerAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        volunteer.endpoints.createVolunteer.matchFulfilled,
        volunteer.endpoints.updateVolunteer.matchFulfilled,
        volunteer.endpoints.patchVolunteer.matchFulfilled
      ),
      (state, action) => {
        volunteerAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      volunteer.endpoints.deleteVolunteer.matchFulfilled,
      (state, action) => {
        volunteerAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const volunteer = baseApi
  .enhanceEndpoints({ addTagTypes: ["Volunteers", "Volunteer"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVolunteers: builder.query<Volunteer[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "volunteer",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Volunteer" as const, id })),
                { type: "Volunteers" as const },
              ]
            : [{ type: "Volunteers" as const }],
      }),
      getVolunteer: builder.query<Volunteer, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `volunteer/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "Volunteer", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createVolunteer: builder.mutation<Volunteer, Partial<Volunteer>>({
        query: (request) => ({
          url: "volunteer",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Volunteers" }],
      }),
      patchVolunteer: builder.mutation<Volunteer, Volunteer>({
        query: (request) => ({
          url: `volunteer/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Volunteer", id: arg.id },
        ],
      }),
      updateVolunteer: builder.mutation<Volunteer, Volunteer>({
        query: (request) => ({
          url: `volunteer/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Volunteer", id: arg.id },
        ],
      }),
      deleteVolunteer: builder.mutation<void, number>({
        query: (id) => ({
          url: `volunteer/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Volunteer", id: arg },
          { type: "Volunteers" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetVolunteersQuery,
  useGetVolunteerQuery,
  useCreateVolunteerMutation,
  usePatchVolunteerMutation,
  useUpdateVolunteerMutation,
  useDeleteVolunteerMutation,
  usePrefetch: useVolunteersPrefetch,
} = volunteer;
