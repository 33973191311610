import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { TaskProspectList } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the TaskProspectList entity
const taskProspectListAdaptor = createEntityAdapter<TaskProspectList, EntityId>(
  {
    selectId: (task_prospect_list: TaskProspectList) => task_prospect_list.id,
    sortComparer: (a, b) => a.id - b.id,
  }
);

// Define the initial state using the adapter's getInitialState method
interface TaskProspectListsState
  extends EntityState<TaskProspectList, EntityId> {
  // Add any additional state properties here if needed
}

const initialState: TaskProspectListsState =
  taskProspectListAdaptor.getInitialState();

// Selectors for TaskProspectLists
export const {
  selectById: selectTaskProspectListById,
  selectEntities: selectTaskProspectListEntities,
  selectAll: selectAllTaskProspectLists,
} = taskProspectListAdaptor.getSelectors(
  (state: RootState) => state?.task_prospect_list
);

export const taskProspectListSlice = createSlice({
  name: "task_prospect_list",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        task_prospect_list.endpoints.getTaskProspectLists.matchFulfilled
        // task_prospect_list.endpoints.createTaskProspect.matchFulfilled
        // task_prospect_list.endpoints.updateTaskProspect.matchFulfilled,
        // task_prospect_list.endpoints.patchTaskProspect.matchFulfilled
      ),
      (state, action) => {
        taskProspectListAdaptor.upsertMany(state, action.payload);
      }
    );
    // builder.addMatcher(
    //   task_prospect_list.endpoints.deleteTaskProspect.matchFulfilled,
    //   (state, action) => {
    //     eventTypeAdapter.removeOne(state, action.meta.arg.originalArgs);
    //   }
    // );
  },
});

// Define your API slice
export const task_prospect_list = baseApi
  .enhanceEndpoints({ addTagTypes: ["TaskProspectLists", "TaskProspectList"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTaskProspectLists: builder.query<
        TaskProspectList[],
        { params?: object }
      >({
        query: ({ params }: { params?: object }) => ({
          url: "task_prospect_list",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "TaskProspectList" as const,
                  id,
                })),
                { type: "TaskProspectLists" as const },
              ]
            : [{ type: "TaskProspectLists" as const }],
      }),
      // getTaskProspectList: builder.query<
      //   TaskProspectList,
      //   { id: number; params?: object }
      // >({
      //   query: ({ id, params }: { id: number; params?: object }) => ({
      //     url: `task_prospect_list/${id}`,
      //     method: "GET",
      //     params: params,
      //   }),
      //   providesTags: (result, error, arg) => [
      //     { type: "TaskProspectList", id: arg.id },
      //   ],
      //   keepUnusedDataFor: 90,
      // }),
      // createTaskProspectList: builder.mutation<
      //   TaskProspectList,
      //   Partial<TaskProspectList>
      // >({
      //   query: (request) => ({
      //     url: "task_prospect_list",
      //     method: "POST",
      //     body: request,
      //   }),
      //   invalidatesTags: [{ type: "TaskProspectLists" }],
      // }),
      // patchTaskProspectList: builder.mutation<
      //   TaskProspectList,
      //   TaskProspectList
      // >({
      //   query: (request) => ({
      //     url: `task_prospect_list/${request.id}`,
      //     method: "PATCH",
      //     body: request,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "TaskProspectList", id: arg.id },
      //   ],
      // }),
      // updateTaskProspectList: builder.mutation<
      //   TaskProspectList,
      //   TaskProspectList
      // >({
      //   query: (request) => ({
      //     url: `task_prospect_list/${request.id}`,
      //     method: "PUT",
      //     body: request,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "TaskProspectList", id: arg.id },
      //   ],
      // }),
      // deleteTaskProspectList: builder.mutation<void, number>({
      //   query: (id) => ({
      //     url: `task_prospect_list/${id}`,
      //     method: "DELETE",
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "TaskProspectList", id: arg },
      //     { type: "TaskProspectLists" },
      //   ],
      // }),
    }),
  });

// Auto-generated hooks
export const {
  useGetTaskProspectListsQuery,
  // useGetTaskProspectListQuery,
  // useLazyGetTaskProspectListQuery,
  // useCreateTaskProspectListMutation,
  // usePatchTaskProspectListMutation,
  // useUpdateTaskProspectListMutation,
  // useDeleteTaskProspectListMutation,
  usePrefetch: useTaskProspectListsPrefetch,
} = task_prospect_list;
