import { statesConfig, provincesTerritoriesConfig } from "config";
import { getIn } from "formik";
import { JItem } from "forms/jformik";

export const useAddress = ({
  name: fname,
  disabled,
}: {
  name?: string;
  disabled?: boolean;
}): JItem[] => {
  const name = fname ? `${fname}.` : "";
  return [
    {
      name: `${name}address`,
      itemType: "textfield",
      disabled: disabled,
      label: "Address",
      gridProps: { size: { xs: 12 } },
    },
    {
      name: `${name}city`,
      itemType: "textfield",
      disabled: disabled,
      label: "City",
      gridProps: { size: { xs: 12, sm: 4 } },
    },
    {
      name: `${name}state`,
      label: "State",
      itemType: "autocomplete",
      disabled: disabled,
      options: Object.keys(statesConfig),
      gridProps: { size: { xs: 12, sm: 4 } },
    },
    {
      name: `${name}zipcode`,
      itemType: "textfield",
      disabled: disabled,
      label: "Postal Code",
      jItemProps: {
        type: "zipcode",
      },
      gridProps: { size: { xs: 12, sm: 4 } },
    },
    // {
    //   name: `${name}country`,
    //   label: "Country",
    //   itemType: "autocomplete",
    //   disabled: disabled,
    //   defaultValue: "USA",
    //   options: ["USA", "CA"],
    //   gridProps: { size: { xs: 12, sm: 3 } },
    // },
  ];
};

export const useCopyAddress = ({
  name: fname,
  address,
  city,
  state,
  country = "USA",
  zipcode,
  disabled = false,
}: {
  name: string;
  address: string;
  city: string;
  state: string;
  country?: string;
  zipcode: string;
  disabled?: boolean;
}): JItem[] => {
  const name = fname ? `${fname}.` : "";
  return [
    {
      precondition: (values: any) => !getIn(values, `${name}copy`),
      name: `${name}address`,
      itemType: "textfield",
      disabled: disabled,
      label: "Address",
      gridProps: { size: { xs: disabled ? 12 : 9 } },
    },
    {
      precondition: (values: any) => getIn(values, `${name}copy`),
      name: `${name}address`,
      itemType: "textfield",
      disabled: true,
      defaultValue: address,
      label: "Address",
      gridProps: { size: { xs: disabled ? 12 : 9 } },
    },
    {
      precondition: () => !disabled,
      name: `${name}copy`,
      itemType: "checkbox",
      label: "Project location",
      gridProps: { size: { xs: 3 } },
      defaultValue: true,
      jItemProps: {
        sx: { fontSize: "14px !important" },
      },
    },
    {
      precondition: (values: any) => !getIn(values, `${name}copy`),
      name: `${name}city`,
      itemType: "textfield",
      disabled: disabled,
      label: "City",
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) => getIn(values, `${name}copy`),
      name: `${name}city`,
      itemType: "textfield",
      disabled: true,
      defaultValue: city,
      label: "City",
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) =>
        getIn(values, `${name}country`) !== "CA" &&
        !getIn(values, `${name}copy`),
      name: `${name}state`,
      label: "State",
      itemType: "autocomplete",
      disabled: disabled,
      options: Object.keys(statesConfig),
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) =>
        getIn(values, `${name}country`) !== "CA" &&
        getIn(values, `${name}copy`),
      name: `${name}state`,
      label: "State",
      itemType: "autocomplete",
      disabled: true,
      defaultValue: state,
      options: Object.keys(statesConfig),
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) =>
        getIn(values, `${name}country`) === "CA" &&
        !getIn(values, `${name}copy`),
      name: `${name}province`,
      label: "Province/Territory",
      itemType: "autocomplete",
      disabled: disabled,
      options: Object.keys(provincesTerritoriesConfig),
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) =>
        getIn(values, `${name}country`) === "CA" &&
        getIn(values, `${name}copy`),
      name: `${name}province`,
      label: "Province/Territory",
      itemType: "autocomplete",
      disabled: true,
      options: Object.keys(provincesTerritoriesConfig),
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) => !getIn(values, `${name}copy`),
      name: `${name}zipcode`,
      itemType: "textfield",
      disabled: disabled,
      label: "Postal Code",
      // jItemProps: {
      //   type: 'zipcode',
      // },
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    {
      precondition: (values: any) => getIn(values, `${name}copy`),
      name: `${name}zipcode`,
      itemType: "textfield",
      disabled: true,
      defaultValue: zipcode,
      label: "Postal Code",
      // jItemProps: {
      //   type: 'zipcode',
      // },
      gridProps: { size: { xs: 12, sm: 3 } },
    },
    // {
    //   precondition: (values: any) => !getIn(values, `${name}copy`),
    //   name: `${name}country`,
    //   label: "Country",
    //   itemType: "autocomplete",
    //   disabled: disabled,
    //   defaultValue: "USA",
    //   options: ["USA", "CA"],
    //   gridProps: { size: { xs: 12, sm: 3 } },
    // },
    // {
    //   precondition: (values: any) => getIn(values, `${name}copy`),
    //   name: `${name}country`,
    //   label: "Country",
    //   itemType: "autocomplete",
    //   disabled: true,
    //   defaultValue: country,
    //   options: ["USA", "CA"],
    //   gridProps: { size: { xs: 12, sm: 3 } },
    // },
  ];
};
