import { useState, useEffect } from "react";
import {
  AccountCircleOutlined,
  CategoryOutlined,
  AttributionOutlined,
  Link,
  EditAttributes,
} from "@mui/icons-material";
import { RolesTab } from "./tabs/RolesTab";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { TabDrawerProps, ControlledTabDrawer } from "./TabDrawer";
import { TypesTab } from "./tabs/TypesTab";
import { SkillsTab } from "./tabs/SkillsTab";
import { LinksTab } from "./tabs/LinksTab";
import { ResultsTab } from "./tabs/ResultsTab";

export interface SettingsTabDrawerProps
  extends Omit<TabDrawerProps, "tabs" | "setTabs" | "title" | "status"> {}

export const SettingsTabDrawer = (props: SettingsTabDrawerProps) => {
  // useItemStatusesPrefetch({})
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const typographyProps = {
    variant: "body1",
    sx: { display: "flex", lineHeight: 1 },
  } as any;

  const [activeTab, setActiveTab] = useState<string>("types");
  useEffect(() => {
    setActiveTab("types");
  }, [props.open]);

  return (
    <ControlledTabDrawer
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      // width={{ xs: '100%' }}
      width={{ xs: "100%", lg: "75%" }}
      maxWidth={{ xs: "100%", sm: `calc(100% - 240px)` }}
      {...props}
      title={"Settings"}
      tabs={{
        types: {
          key: "types",
          sequence: 1,
          visible: true,
          icon: (
            <Typography {...typographyProps}>
              <CategoryOutlined fontSize="inherit" />
              &nbsp;{"Types"}
            </Typography>
          ),
          backgroundColor: "white",
          contents: <TypesTab />,
        },
        roles: {
          key: "roles",
          sequence: 2,
          visible: true,
          icon: (
            <Typography {...typographyProps}>
              <AccountCircleOutlined fontSize="inherit" />
              &nbsp;{"Roles"}
            </Typography>
          ),
          backgroundColor: "white",
          contents: <RolesTab />,
        },
        skills: {
          key: "skills",
          sequence: 3,
          visible: true,
          icon: (
            <Typography {...typographyProps}>
              <AttributionOutlined fontSize="inherit" />
              &nbsp;{"Skills"}
            </Typography>
          ),
          backgroundColor: "white",
          contents: <SkillsTab />,
        },
        // results: {
        //   key: "results",
        //   sequence: 4,
        //   visible: true,
        //   icon: (
        //     <Typography {...typographyProps}>
        //       <EditAttributes fontSize="inherit" />
        //       &nbsp;{"Results"}
        //     </Typography>
        //   ),
        //   backgroundColor: "white",
        //   contents: <ResultsTab />,
        // },
        links: {
          key: "links",
          sequence: 5,
          visible: true,
          icon: (
            <Typography {...typographyProps}>
              <Link fontSize="inherit" />
              &nbsp;{"Signup Links"}
            </Typography>
          ),
          backgroundColor: "white",
          contents: <LinksTab />,
        },
      }}
      device={downSm ? "mobile" : "desktop"}
    />
  );
};
