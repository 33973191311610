import { useState } from "react";
import * as Yup from "yup";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import {
  selectAllSkills,
  selectAllRoles,
  selectRoleEntities,
  selectSkillEntities,
} from "store/api";
import { useSelector } from "react-redux";
import {
  useCreateSignupLinkMutation,
  useDeleteSignupLinkMutation,
  useGetSignupLinksQuery,
  useUpdateSignupLinkMutation,
} from "store/api/signup_link.api";
import { JFormik } from "forms";
import { Skill } from "store/api/models";
import { LinkRounded } from "@mui/icons-material";
import { toast } from "react-toastify";

export const LinksTab = () => {
  const [editing /*, setEditing*/] = useState<boolean>(false);
  const { data: links = [], isLoading } = useGetSignupLinksQuery({});
  const [createLink] = useCreateSignupLinkMutation();
  const [editLink] = useUpdateSignupLinkMutation();
  const [deleteLink] = useDeleteSignupLinkMutation();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const roles = useSelector(selectAllRoles);
  const skills = useSelector(selectAllSkills);
  const roleEntities = useSelector(selectRoleEntities);
  const skillEntities = useSelector(selectSkillEntities);
  return (
    <JFormik
      initialValues={{
        links: links.map((l) => ({
          ...l,
          role: roleEntities[l.role],
          skills: JSON.parse(l.Skills)?.map((s: number) => skillEntities[s]),
        })),
      }}
      enableReinitialize={true}
      handleSubmit={async () => {}}
      loading={isLoading}
      validationSchema={
        editing
          ? Yup.object({
              role: Yup.string().required("Role name is required"),
              // type: Yup.string().nullable().required('Role type is required'),
            })
          : Yup.object({})
      }
      disabled={!editing}
      form={[
        {
          name: "links",
          itemType: "table_array",
          label: "Signup Links",
          tableProps: {
            onCreate: async (values: any) => {
              try {
                const res = await createLink({
                  ...values,
                  role: values?.role?.id,
                  skills: values?.skills?.map((s: Skill) => s.id),
                }).unwrap();
                if (res.id > 0) {
                  toast.success("Link successfully created!");
                } else {
                  toast.warning(
                    "There was an issue creating the link, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error creating the link, please try again."
                );
              }
            },
            onEdit: async (values: any) => {
              try {
                const res = await editLink({
                  ...values,
                  role: values?.role?.id,
                  skills: values?.skills?.map((s: Skill) => s.id),
                }).unwrap();
                if (res.id > 0) {
                  toast.success("Link successfully edited!");
                } else {
                  toast.warning(
                    "There was an issue editing the link, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error editing the link, please try again."
                );
              }
            },
            onDelete: async (id: number) => {
              try {
                await deleteLink(id).unwrap();
                toast.success("Link successfully deleted!");
              } catch (error) {
                toast.error(
                  "There was an error deleting the link, please try again."
                );
              }
            },
            // headers: [
            //   {
            //     name: 'name',
            //     label: '',
            //     itemType: 'textfield',
            //     jItemProps: {
            //       width: '100%',
            //       touched: true,
            //       sx: {
            //         boxSizing: 'border-box',
            //         px: { xs: 0.5 },
            //         minWidth: 180,
            //       },
            //     },
            //     gridProps: { xs: 12 },
            //   },
            //   // {
            //   //   name: 'type',
            //   //   label: "Type *",
            //   //   itemType: 'autocomplete',
            //   //   jItemProps: { width: '30%', touched: true, sx: { boxSizing: 'border-box', px: { xs: 0.5 }, minWidth: 160 } },
            //   //   gridProps: { xs: 12 },
            //   // },
            // ],
            columns: [
              {
                name: "role",
                label: "Role *",
                itemType: "autocomplete",
                options: roles,
                jItemProps: {
                  // width: "70%",
                  touched: true,
                  sx: {
                    boxSizing: "border-box",
                    px: { xs: 0.5 },
                    minWidth: 180,
                  },
                },
                gridProps: { size: { xs: 2 } },
              },
              {
                name: "skills",
                label: "Skills *",
                multiple: true,
                itemType: "autocomplete",
                jItemProps: {
                  // width: "30%",
                  touched: true,
                  sx: {
                    boxSizing: "border-box",
                    px: { xs: 0.5 },
                    minWidth: 160,
                  },
                },
                options: skills,
                gridProps: { size: { xs: 4 } },
              },
              {
                name: "url",
                itemType: "embed",
                label: "Link",
                embed: ({ value, values }) => {
                  if (value) {
                    return (
                      <Link href={value} target="_blank">
                        <LinkRounded fontSize="small" />
                      </Link>
                    );
                  } else {
                    return <div />;
                  }
                },
                gridProps: { size: { xs: 4 } },
              },
            ],
          },
          device: downMd ? "mobile" : "desktop",
        },
      ]}
    />
  );
};
