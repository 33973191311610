const _compareToSort = (fa: any, fb: any) => {
  if (fa < fb) {
    return -1;
  }

  if (fa > fb) {
    return 1;
  }

  return 0;
};

export const sortString = (a: string, b: string) => {
  const fa = (a || "").toLowerCase();
  const fb = (b || "").toLowerCase();

  return _compareToSort(fa, fb);
};

export const sortByNumber = (fa: number, fb: number) => {
  if (fa < fb) {
    return -1;
  }

  if (fa > fb) {
    return 1;
  }

  return 0;
};

export const sortByDate = (a: any, b: any) => {
  const fa = new Date(a).getTime();
  const fb = new Date(b).getTime();

  return -_compareToSort(fa, fb);
};

export function stableSort(
  array: any[],
  comparator: (a: any, b: any) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}
