import {
  Phone,
  CallEndOutlined,
  Dialpad,
  Dangerous,
  Error,
  PhoneDisabled,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  MenuProps,
  Button,
} from "@mui/material";
import { Device } from "@twilio/voice-sdk";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useCreateCallMutation,
  usePatchCallMutation,
} from "store/api/call.api";
import { usePatchConversationMutation } from "store/api/conversation.api";
import { Call, Conversation } from "store/api/models";
import { styled, alpha } from "@mui/material/styles";
import {
  acceptCall,
  destroyDevice,
  hangUp,
  initializeDevice,
  makeCall,
  rejectCall,
} from "store/slice/twilioVoice.slice";
import { AppDispatch } from "store/types/types";
import { keyframes } from "@mui/system";

// const growAndSpin = keyframes`
//   0% {
//     transform: scale(1) rotate(0deg);
//   }
//   50% {
//     transform: scale(1.5) rotate(180deg);
//   }
//   100% {
//     transform: scale(1) rotate(360deg);
//   }
// `;

// const spinBackground = keyframes`
//   0% {
//     background-position: 0% 50%;
//   }
//   100% {
//     background-position: 100% 50%;
//   }
// `;

// const spinBackground = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

const growAndContract = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const growAndContractBorder = keyframes`
  0% {
    border-width: 4px;
  }
  50% {
    border-width: 10px;
  }
  100% {
    border-width: 4px;
  }
`;

const growAndContractButton = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const rotatingBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

//Need to initialize with useTwilioVoice!
export const TwilioDialerButton = ({
  phone,
  animate = false,
  phoneid,
  conversation,
  volunteer,
  prospect,
  text,
}: {
  animate?: boolean;
  phone: string;
  phoneid?: number;
  conversation?: number;
  volunteer?: number;
  prospect?: number;
  text?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [hidden, setHidden] = useState<boolean>(false);
  const [createCall] = useCreateCallMutation();
  const [patchCall] = usePatchCallMutation();
  const [patchConversation] = usePatchConversationMutation();
  // Use useRef directly for callRecord
  const callRecordRef = useRef<Call | undefined>(undefined);

  const {
    deviceReady,
    callStatus,
    error,
    incomingCall,
    outgoingCall,
    phone: phoneNumber,
  } = useSelector((state: any) => state.twilioVoice);
  const isActive =
    phone?.replaceAll(/\D/g, "").slice(-9) ===
    phoneNumber?.replaceAll(/\D/g, "").slice(-9);
  const voiceCallback = (values: any) => {
    const currentCallRecord = callRecordRef.current;
    if (currentCallRecord) {
      callRecordRef.current = {
        ...currentCallRecord,
        form: {
          callLog: [
            ...currentCallRecord.form.callLog,
            {
              ...values,
              timeStamp: moment().format("MMMM Do YYYY hh:mm:ss a"),
            },
          ],
        },
      }; // Set directly in the ref
      patchCall(callRecordRef.current as Call);
    }
  };

  const handleMakeCall = () => {
    const connectOptions: Device.ConnectOptions = {
      params: {
        debug: "true",
        To: phone?.replaceAll(/\D/g, ""),
        service: "true",
      },
    };
    dispatch(makeCall({ params: connectOptions, callback: voiceCallback }));
  };

  const handleAcceptCall = () => {
    dispatch(acceptCall());
  };

  const handleRejectCall = () => {
    dispatch(rejectCall());
  };

  const handleHangUp = () => {
    dispatch(hangUp());
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (outgoingCall) {
        // Show a confirmation dialog
        event.preventDefault();
        event.returnValue = ""; // Legacy method for showing the dialog (required for Chrome)
      }
    };
    // Add the event listener when the component is mounted
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleHangUp, outgoingCall]);
  // States for the right-click menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  // Event handler for right-click (context menu)
  const handleRightClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setMenuAnchorEl(event.currentTarget);
  };

  // Event handler for closing the menu
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // Render UI based on the state
  if (hidden) {
    return <></>;
  } else {
    return (
      <>
        {text ? (
          <>
            {(callStatus === "connected" || callStatus === "connecting") &&
            isActive ? (
              <Tooltip
                title={"CLICK TO END CALL"}
                placement="left-end"
                arrow
                open
              >
                <Button
                  onContextMenu={handleRightClick} // Handle right-click here
                  onClick={async (event) => {
                    event.stopPropagation();
                    handleHangUp();
                  }}
                  startIcon={
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent=" "
                      variant="dot"
                      overlap="circular"
                      color={deviceReady ? "success" : "error"}
                      slotProps={{
                        badge: {
                          style: { border: "1px solid white", padding: 1 },
                        },
                      }}
                    >
                      {callStatus === "connecting" ? (
                        <Dialpad sx={{ color: "white" }} fontSize="small" />
                      ) : (
                        <CallEndOutlined
                          sx={{ color: "white" }}
                          fontSize="small"
                        />
                      )}
                    </Badge>
                  }
                  variant="outlined"
                  color={
                    isActive
                      ? callStatus === "connected"
                        ? "error"
                        : callStatus === "connecting"
                        ? "info"
                        : "secondary"
                      : "secondary"
                  }
                  sx={{
                    boxShadow: (theme) => theme.shadows[3],
                    animation: `${growAndContractButton} 1s linear infinite`,
                    "& .MuiButton-startIcon": {
                      backgroundColor: (theme) =>
                        isActive
                          ? callStatus === "connected"
                            ? theme.palette.error.main
                            : callStatus === "connecting"
                            ? theme.palette.info.light
                            : theme.palette.secondary.main
                          : theme.palette.secondary.main,
                      padding: "6px",
                      borderRadius: "4px",
                      marginRight: "8px",
                      color: "common.white",
                    },
                    m: 1,
                  }}
                >
                  {text}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  animate && (
                    <>
                      <Box sx={{ display: "block", textAlign: "right" }}>
                        {"CLICK TO CALL"} {/* This is the first tooltip text */}
                      </Box>
                      <Box sx={{ display: "block", marginTop: "5px" }}>
                        {"Right Click for more options"}
                        {/* Additional message to offset */}
                      </Box>
                    </>
                  )
                }
                placement="left-end"
                arrow
                open
              >
                <Button
                  onContextMenu={handleRightClick} // Handle right-click here
                  onClick={async (event) => {
                    event.stopPropagation();
                    await handleMakeCall();
                    if (conversation) {
                      await patchConversation({
                        id: conversation,
                        disposition: "Called",
                      } as Conversation);
                      try {
                        if (volunteer && prospect) {
                          const res = await createCall({
                            phone,
                            phoneid,
                            volunteer: volunteer,
                            form: {
                              callLog: [
                                {
                                  callStatus: "calling",
                                  timeStamp: moment().format(
                                    "MMMM Do YYYY hh:mm:ss a"
                                  ),
                                },
                              ],
                            },
                            conversation: conversation,
                            prospect: prospect,
                          }).unwrap();
                          if (res.id) {
                            callRecordRef.current = res; // Set directly in the ref
                          }
                        }
                      } catch (error) {
                        console.log("call record error");
                      }
                    }
                  }}
                  startIcon={
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent=" "
                      variant="dot"
                      overlap="circular"
                      color={deviceReady ? "success" : "error"}
                      slotProps={{
                        badge: {
                          style: { border: "1px solid white", padding: 1 },
                        },
                      }}
                    >
                      <Phone
                        sx={{
                          color: "white",
                          bgcolor: (theme) => theme.palette.secondary.main,
                        }}
                        fontSize="small"
                      />
                    </Badge>
                  }
                  variant="outlined"
                  color="secondary"
                  sx={{
                    boxShadow: (theme) => theme.shadows[3],
                    animation: animate
                      ? `${growAndContractButton} 1s linear 2`
                      : `none`,
                    "& .MuiButton-startIcon": {
                      backgroundColor: "secondary.main",
                      padding: "6px",
                      borderRadius: "4px",
                      marginRight: "8px",
                      color: "common.white",
                    },
                    m: 1,
                  }}
                >
                  {text}
                </Button>
              </Tooltip>
            )}
          </>
        ) : (
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent=" "
            variant="dot"
            overlap="circular"
            color={deviceReady ? "success" : "error"}
            slotProps={{
              badge: { style: { border: "1px solid white", padding: 1 } },
            }}
          >
            <Tooltip
              title={
                animate && (
                  <>
                    <Box sx={{ display: "block", textAlign: "right" }}>
                      {"CLICK TO CALL"} {/* This is the first tooltip text */}
                    </Box>
                    <Box sx={{ display: "block", marginTop: "5px" }}>
                      {"Right Click for more options"}
                      {/* Additional message to offset */}
                    </Box>
                  </>
                )
              }
              placement="left-end"
              arrow
              open
            >
              <Box
                sx={{
                  position: "relative",
                  bgcolor: (theme) =>
                    isActive
                      ? callStatus === "connected"
                        ? theme.palette.error.main
                        : callStatus === "connecting"
                        ? theme.palette.info.light
                        : theme.palette.secondary.main
                      : theme.palette.secondary.main,
                  borderRadius: "6px",
                  boxShadow: (theme) => theme.shadows[5],
                  // animation: animate ? `${growAndContract} 1s linear 3` : `none`,
                  animation: animate
                    ? `${growAndContract} 1s linear 2`
                    : `none`,
                  // backgroundImage: (theme) =>
                  //   animate
                  //     ? `conic-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main},${theme.palette.secondary.main}, ${theme.palette.primary.main})`
                  //     : "none",
                  "&::after": animate
                    ? {
                        content: '""',
                        position: "absolute",
                        background: "red",
                        left: "50%",
                        translate: "-50%",
                        height: "110%",
                        width: "110%",
                        borderRadius: "6px",
                        border: `3px solid red`,
                        zIndex: -1,
                        padding: "10px",
                        backgroundImage: (theme) =>
                          `conic-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.dark},${theme.palette.secondary.light}, ${theme.palette.secondary.main})`,
                      }
                    : {},
                }}
              >
                {(callStatus === "connected" || callStatus === "connecting") &&
                isActive ? (
                  <IconButton
                    onClick={async (event) => {
                      event.stopPropagation();
                      handleHangUp();
                    }}
                  >
                    {callStatus === "connecting" ? (
                      <Dialpad sx={{ color: "white" }} fontSize="small" />
                    ) : (
                      <CallEndOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                ) : (
                  <IconButton
                    onContextMenu={handleRightClick} // Handle right-click here
                    onClick={async (event) => {
                      event.stopPropagation();
                      await handleMakeCall();
                      if (conversation) {
                        await patchConversation({
                          id: conversation,
                          disposition: "Called",
                        } as Conversation);
                        try {
                          if (volunteer && prospect) {
                            const res = await createCall({
                              phone,
                              phoneid,
                              volunteer: volunteer,
                              form: {
                                callLog: [
                                  {
                                    callStatus: "calling",
                                    timeStamp: moment().format(
                                      "MMMM Do YYYY hh:mm:ss a"
                                    ),
                                  },
                                ],
                              },
                              conversation: conversation,
                              prospect: prospect,
                            }).unwrap();
                            if (res.id) {
                              callRecordRef.current = res; // Set directly in the ref
                            }
                          }
                        } catch (error) {
                          console.log("call record error");
                        }
                      }
                    }}
                  >
                    <Phone sx={{ color: "white" }} fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          </Badge>
        )}

        {/* Context Menu */}
        <StyledMenu
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={async (event) => {
              event.stopPropagation();
              if (volunteer && prospect) {
                await createCall({
                  phone,
                  phoneid,
                  volunteer: volunteer,
                  result: 25, //Disconnected result code
                  conversation: conversation,
                  prospect: prospect,
                });
                setHidden(true);
              }
              handleCloseMenu();
            }}
          >
            <Dangerous />
            Mark Disconnected
          </MenuItem>
          <MenuItem
            onClick={async (event) => {
              event.stopPropagation();
              if (volunteer && prospect) {
                await createCall({
                  phone,
                  phoneid,
                  volunteer: volunteer,
                  result: 20, //Wrong number result cod
                  conversation: conversation,
                  prospect: prospect,
                });
                setHidden(true);
              }
              handleCloseMenu();
            }}
          >
            <Error />
            Wrong Number
          </MenuItem>
          <MenuItem
            onClick={async (event) => {
              event.stopPropagation();
              if (volunteer && prospect) {
                await createCall({
                  phone,
                  phoneid,
                  volunteer: volunteer,
                  result: 22, //DO NOT CALL number result code
                  conversation: conversation,
                  prospect: prospect,
                });
                setHidden(true);
              }
              handleCloseMenu();
            }}
          >
            <PhoneDisabled />
            Do Not Call
          </MenuItem>
        </StyledMenu>
      </>
    );
  }
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));
