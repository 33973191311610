import { forwardRef, useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  IconButton,
  Tooltip,
  Typography,
  Grid2 as Grid,
  Paper,
  InputBase,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useMediaQuery,
  useTheme,
  Fab,
  Divider,
  Theme,
  styled,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import {
  FieldArray,
  FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from 'formik';
import { JItem } from '../jformik/jformik.types';
import { useJFormikContext } from '../jformik/jformik.context';
import { JItemMapper } from 'forms/jformik/JItemMapper';
import { useBreakpointQuery } from 'utils/media.util';

const FField = ({
  jItem,
  // values,
  remove,
}: {
  jItem: JItem;
  // values: any;
  remove?: Function;
}) => {
  // const { gridProps } = jItem;
  // const gridSizesResult =
  //   typeof gridProps === 'function'
  //     ? gridProps(values)
  //     : gridProps !== null
  //     ? gridProps
  //     : {};
  // const gridSizes = typeof gridSizesResult === 'object' ? gridSizesResult : {};
  const { values } = useFormikContext();
  const { disabled } = useJFormikContext();
  if (!disabled || getIn(values, jItem.name)) {
    return (
      <Paper
        elevation={3}
        sx={{
          px: 2,
          py: 1,
          my: 1,
          // mb: 5,
          bgColor: 'white',
          position: 'relative',
          border: theme => `1px solid ${theme.palette.secondary.main}`,
          // borderColor: theme => theme.palette.secondary,
        }}
      >
        <Grid size={{xs:12}} key={`${jItem.name}-grid-item-index`}>
          <JItemMapper jItem={jItem} />
        </Grid>
        {!jItem.disabled && remove && (
          <Paper
            elevation={5}
            sx={{
              position: 'absolute',
              top: { xs: -10, sm: -15 },
              right: { xs: -10, sm: -15 },
              bgcolor: theme => theme.palette.secondary.main,
              borderRadius: '100%',
            }}
          >
            <IconButton
              onClick={() => {
                remove();
              }}
              sx={{
                width: { xs: 32, sm: 40 },
                height: { xs: 32, sm: 40 },
              }}
            >
              <Delete
                sx={{
                  color: theme => theme.palette.common.white,
                  fontSize: { xs: 18, sm: 24 },
                }}
              />
            </IconButton>
          </Paper>
        )}
      </Paper>
    );
  } else {
    return <div />;
  }
};

const PreambleInputBase = styled(InputBase)((theme: Theme) => ({
  root: {
    '& input::placeholder': {
      color: theme.palette.secondary.main, // Specify the desired color here
    },
  },
}));

export const FTextFieldArray = forwardRef((jItem: JItem, ref) => {
  const itemsRef = useRef({});
  const { ref: viewRef, inView } = useInView({
    threshold: 0,
  });
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeText, setActiveText] = useState<{
    before?: string;
    cursor?: number;
    ref?: any;
    index: number;
    after?: string;
  }>({ index: 0 });
  //// Speeddial inView ///
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //////////////////
  //// Speeddial fixed ///
  const [openFixed, setOpenFixed] = useState<boolean>(false);
  const handleOpenFixed = () => setOpenFixed(true);
  const handleCloseFixed = () => setOpenFixed(false);
  //////////////////

  const smDown = useBreakpointQuery('sm-down');
  const { disabled: fDisabled = false } = useJFormikContext();
  const disabled = fDisabled || jItem.disabled;

  return (
    <FieldArray name={jItem.name} validateOnChange={false}>
      {({
        push,
        remove,
        form: { values, errors, setFieldValue },
        name,
      }: FieldArrayRenderProps) => {
        const actionArray =
          !jItem.jItemProps?.actionsprecondition ||
          jItem.jItemProps?.actionsprecondition(values)
            ? Object.values(jItem.jItemProps?.actions || {}).filter(
                (x: any) => !x?.disabled
              )
            : undefined;
        const fab =
          !jItem.jItemProps?.fab?.precondition ||
          jItem.jItemProps?.fab.precondition(values)
            ? jItem.jItemProps?.fab
            : undefined;
        const handlePush = (item: any) => {
          //Insert to text field
          let newValue = getIn(values, name);
          if (activeText.cursor !== undefined && activeText.ref) {
            let valCpy = [...newValue];
            if (newValue && Array.isArray(newValue) && newValue?.length > 0) {
              valCpy.splice(activeText.index + 1, 0, {
                ...item,
                text: activeText.after,
              });
              valCpy[activeText.index].text = activeText.before;
              newValue = valCpy;
            } else {
              newValue = [{ text: '' }, { ...item, text: '' }];
            }
          } else {
            if (newValue) {
              newValue = [...newValue, { ...item, text: '' }];
            } else {
              newValue = [{ text: '' }, { ...item, text: '' }];
            }
          }
          setFieldValue(name, newValue);
        };
        const handleRemove = (index: number) => {
          if (index > 0) {
            let newValue = getIn(values, name);
            let valCpy = [...newValue];
            if (valCpy[index - 1].text.replaceAll(' ', '') != '') {
              valCpy[index - 1].text += ` ${valCpy[index].text}`;
            } else {
              if (valCpy[index].text.replaceAll(' ', '') != '') {
                valCpy[index - 1].text += `${valCpy[index].text}`;
              } else {
                valCpy[index - 1].text = '';
              }
            }

            valCpy.splice(index, 1);
            setFieldValue(name, valCpy);
          }
        };
        const arrayerror =
          typeof getIn(errors, jItem.name) === 'string' &&
          getIn(errors, jItem.name);
        return (
          <>
            <Paper
              id={`textfieldarray-${name}`}
              elevation={3}
              sx={{
                px: { xs: 2, sm: 3, md: 5 },
                pt: 5,
                pb: 3,
                bgColor: 'white',
                // border: 1,
                position: 'relative',
                minHeight: jItem?.jItemProps?.minHeight || '500px',
                cursor: 'text',
              }}
              onClick={(event: any) => {
                if (event?.target?.id === `textfieldarray-${name}`) {
                  const sortedKeys: string[] = Object.keys(itemsRef.current)
                    .filter(
                      (k: string) => !((itemsRef.current as any)[k] == null)
                    )
                    .sort();
                  (itemsRef.current as any)[
                    sortedKeys[sortedKeys.length - 1]
                  ]?.children?.[0]?.focus();
                }
              }}
            >
              {jItem.jItemProps?.preamble &&
                (!jItem.jItemProps?.preamble?.precondition ||
                  jItem.jItemProps?.preamble.precondition({
                    ...values,
                    index: 0,
                  })) && (
                  <>
                    <InputBase
                      ref={(el: any) => {
                        (itemsRef.current as any)[`${name}.0.preamble`] = el;
                      }}
                      disabled={
                        jItem.jItemProps?.preamble?.disabled === false
                          ? false
                          : jItem.jItemProps?.preamble?.disabled || disabled
                      }
                      minRows={3}
                      // autoFocus
                      placeholder={jItem.jItemProps?.preamble?.placeholder}
                      name={`${name}.0.preamble`}
                      value={getIn(values, `${name}.0.preamble`)}
                      onChange={(event: any) => {
                        setFieldValue(`${name}.0.preamble`, event.target.value);
                      }}
                      fullWidth
                      multiline
                      sx={{
                        '& textarea::placeholder': {
                          color: theme => theme.palette.secondary.main, // Specify the desired color here
                          fontWeight: 'bold',
                          opacity: 1,
                        },
                      }}
                    />
                    {jItem.jItemProps?.preamble?.action &&
                      (!jItem.jItemProps?.preamble?.action?.precondition ||
                        jItem.jItemProps?.preamble?.action.precondition(
                          values
                        )) && (
                        <FField
                          jItem={{
                            ...jItem.jItemProps?.preamble?.action,
                            name: `${name}.0.${jItem.jItemProps?.preamble?.action?.name}`,
                            disabled:
                              jItem.jItemProps?.preamble?.action?.disabled ===
                              false
                                ? false
                                : disabled ||
                                  jItem.jItemProps?.preamble?.action?.disabled,
                          }}
                          // values={values}
                          key={`ffield-${jItem.jItemProps?.preamble?.action?.name}`}
                        />
                      )}
                    {jItem.jItemProps?.preamble?.divider !== false && (
                      <Divider
                        sx={{
                          my: 3,
                          color: theme => theme.palette.text.disabled,
                        }}
                      >
                        Original message below
                      </Divider>
                    )}
                  </>
                )}
              {/* <InputBase
                ref={(el: any) => {
                  (itemsRef.current as any)[`${name}.0.text`] = el;
                }}
                disabled={disabled || getIn(values, `${name}.0.disabled`)}
                autoFocus
                placeholder={
                  values &&
                  getIn(values, name) &&
                  getIn(values, name)?.length > 1
                    ? ''
                    : jItem.jItemProps?.placeholder
                }
                name={`${name}.0.text`}
                value={getIn(values, `${name}.0.text`)}
                onChange={(event: any) => {
                  setActiveText(prev => ({
                    ...prev,
                    before: event.target.value?.substring(
                      0,
                      event.target.selectionEnd
                    ),
                    after: event.target.value?.substring(
                      event.target.selectionEnd
                    ),
                    cursor: event.target.selectionEnd,
                    ref: `${name}.0.text`,
                    index: 0,
                  }));
                  setFieldValue(`${name}.0.text`, event.target.value);
                }}
                onFocus={(event: any) =>
                  setActiveText(prev => ({
                    ...prev,
                    before: event.target.value?.substring(
                      0,
                      event.target.selectionEnd
                    ),
                    after: event.target.value?.substring(
                      event.target.selectionEnd
                    ),
                    cursor: event.target.selectionEnd,
                    ref: `${name}.0.text`,
                    index: 0,
                  }))
                }
                onBlur={(event: any) =>
                  setActiveText(prev => ({
                    ...prev,
                    before: event.target.value?.substring(
                      0,
                      event.target.selectionEnd
                    ),
                    after: event.target.value?.substring(
                      event.target.selectionEnd
                    ),
                    cursor: event.target.selectionEnd,
                    ref: `${name}.0.text`,
                    index: 0,
                  }))
                }
                onClick={(event: any) =>
                  setActiveText(prev => ({
                    ...prev,
                    before: event.target.value?.substring(
                      0,
                      event.target.selectionEnd
                    ),
                    after: event.target.value?.substring(
                      event.target.selectionEnd
                    ),
                    cursor: event.target.selectionEnd,
                    ref: `${name}.0.text`,
                    index: 0,
                  }))
                }
                fullWidth
                multiline
              /> */}
              {values &&
              getIn(values, name) &&
              getIn(values, name)?.length > 0 ? (
                getIn(values, name)?.map((item: any, index: number) => {
                  if (
                    (!item.type || item.type === 'auto_salutation') &&
                    item.text !== undefined
                  ) {
                    return (
                      <InputBase
                        ref={(el: any) => {
                          (itemsRef.current as any)[`${name}.${index}.text`] =
                            el;
                        }}
                        disabled={
                          disabled || getIn(values, `${name}.${index}.disabled`)
                        }
                        // autoFocus
                        placeholder={
                          values &&
                          getIn(values, name) &&
                          getIn(values, name)?.length > 1
                            ? item.placeholder || ''
                            : jItem.jItemProps?.placeholder != ''
                            ? jItem.jItemProps?.placeholder
                            : item.placeholder
                        }
                        name={`${name}.${index}.text`}
                        value={getIn(values, `${name}.${index}.text`)}
                        onChange={(event: any) => {
                          setActiveText(prev => ({
                            ...prev,
                            before: event.target.value?.substring(
                              0,
                              event.target.selectionEnd
                            ),
                            after: event.target.value?.substring(
                              event.target.selectionEnd
                            ),
                            cursor: event.target.selectionEnd,
                            ref: `${name}.${index}.text`,
                            index: index,
                          }));
                          setFieldValue(
                            `${name}.${index}.text`,
                            event.target.value
                          );
                        }}
                        onFocus={(event: any) =>
                          setActiveText(prev => ({
                            ...prev,
                            before: event.target.value?.substring(
                              0,
                              event.target.selectionEnd
                            ),
                            after: event.target.value?.substring(
                              event.target.selectionEnd
                            ),
                            cursor: event.target.selectionEnd,
                            ref: `${name}.${index}.text`,
                            index: index,
                          }))
                        }
                        onBlur={(event: any) =>
                          setActiveText(prev => ({
                            ...prev,
                            before: event.target.value?.substring(
                              0,
                              event.target.selectionEnd
                            ),
                            after: event.target.value?.substring(
                              event.target.selectionEnd
                            ),
                            cursor: event.target.selectionEnd,
                            ref: `${name}.${index}.text`,
                            index: index,
                          }))
                        }
                        onClick={(event: any) =>
                          setActiveText(prev => ({
                            ...prev,
                            before: event.target.value?.substring(
                              0,
                              event.target.selectionEnd
                            ),
                            after: event.target.value?.substring(
                              event.target.selectionEnd
                            ),
                            cursor: event.target.selectionEnd,
                            ref: `${name}.${index}.text`,
                            index: index,
                          }))
                        }
                        fullWidth
                        multiline
                      />
                    );
                  } else if (
                    item.type &&
                    (!jItem.precondition ||
                      jItem.precondition({ ...values, index: index })) &&
                    (!jItem.jItemProps?.actions[item.type].precondition ||
                      jItem.jItemProps?.actions[item.type].precondition({
                        ...values,
                        index: index,
                      }))
                  ) {
                    if (jItem.jItemProps?.actions[item.type].precondition) {
                      const precondition = jItem.jItemProps?.actions[
                        item.type
                      ].precondition({
                        ...values,
                        index: index,
                      });
                    }

                    return (
                      <>
                        <FField
                          jItem={{
                            ...jItem.jItemProps?.actions[item.type]?.form,
                            name: `${name}.${index}.action`,
                            disabled: item.jItem?.disabled || disabled,
                          }}
                          // values={values}
                          key={`ffield-${index}`}
                          remove={
                            actionArray ? () => handleRemove(index) : undefined
                          }
                        />
                        <InputBase
                          ref={(el: any) => {
                            (itemsRef.current as any)[`${name}.${index}.text`] =
                              el;
                          }}
                          disabled={disabled}
                          name={`${name}.${index}.text`}
                          value={getIn(values, `${name}.${index}.text`)}
                          onChange={(event: any) => {
                            setActiveText(prev => ({
                              ...prev,
                              before: event.target.value?.substring(
                                0,
                                event.target.selectionEnd
                              ),
                              after: event.target.value?.substring(
                                event.target.selectionEnd
                              ),
                              cursor: event.target.selectionEnd,
                              ref: `${name}.${index}.text`,
                              index: index,
                            }));
                            setFieldValue(
                              `${name}.${index}.text`,
                              event.target.value
                            );
                          }}
                          onFocus={(event: any) =>
                            setActiveText(prev => ({
                              ...prev,
                              before: event.target.value?.substring(
                                0,
                                event.target.selectionEnd
                              ),
                              after: event.target.value?.substring(
                                event.target.selectionEnd
                              ),
                              cursor: event.target.selectionEnd,
                              ref: `${name}.${index}.text`,
                              index: index,
                            }))
                          }
                          onBlur={(event: any) =>
                            setActiveText(prev => ({
                              ...prev,
                              before: event.target.value?.substring(
                                0,
                                event.target.selectionEnd
                              ),
                              after: event.target.value?.substring(
                                event.target.selectionEnd
                              ),
                              cursor: event.target.selectionEnd,
                              ref: `${name}.${index}.text`,
                              index: index,
                            }))
                          }
                          onClick={(event: any) =>
                            setActiveText(prev => ({
                              ...prev,
                              before: event.target.value?.substring(
                                0,
                                event.target.selectionEnd
                              ),
                              after: event.target.value?.substring(
                                event.target.selectionEnd
                              ),
                              cursor: event.target.selectionEnd,
                              ref: `${name}.${index}.text`,
                              index: index,
                            }))
                          }
                          fullWidth
                          multiline
                        />
                      </>
                    );
                  } else {
                    return <div />;
                  }
                })
              ) : (
                <div />
              )}
              {fab && (
                <Fab
                  color="secondary"
                  aria-label="edit"
                  sx={{ position: 'absolute', top: -16, right: -16 }}
                  size={'medium'}
                >
                  <Tooltip title={fab.label}>{fab.icon}</Tooltip>
                </Fab>
              )}
              {actionArray && actionArray.length > 0 && !disabled && (
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    p: 1,
                    width: '100%',
                    boxSizing: 'border-box',
                    bottom: 0,
                    alignItems: 'flex-end',
                  }}
                  icon={
                    <SpeedDialIcon
                      ref={viewRef}
                      id="textfieldarray-speeddial"
                    />
                  }
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {actionArray?.map((action: any, index: number) => (
                    <SpeedDialAction
                      key={index}
                      id={`textfieldarray-speeddial-${index}`}
                      icon={action?.icon}
                      tooltipTitle={action?.label}
                      tooltipOpen
                      onClick={() => {
                        if (action?.form) {
                          handlePush({
                            type: action?.name,
                          });
                        }
                        handleClose();
                      }}
                    />
                  ))}
                </SpeedDial>
              )}
              {actionArray && actionArray.length > 0 && !inView && !disabled && (
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  sx={{
                    position: 'fixed',
                    right: 0,
                    p: 4,
                    width: '100%',
                    boxSizing: 'border-box',
                    bottom: 0,
                    alignItems: 'flex-end',
                  }}
                  icon={<SpeedDialIcon id="textfieldarray-speeddial" />}
                  onClose={handleCloseFixed}
                  onOpen={handleOpenFixed}
                  open={openFixed}
                >
                  {actionArray?.map((action: any, index: number) => (
                    <SpeedDialAction
                      key={index}
                      id={`textfieldarray-speeddial-${index}`}
                      icon={action?.icon}
                      tooltipTitle={action?.label}
                      tooltipOpen
                      onClick={() => {
                        if (action?.form) {
                          handlePush({
                            type: action?.name,
                          });
                        }
                        handleCloseFixed();
                      }}
                    />
                  ))}
                </SpeedDial>
              )}
            </Paper>
            {!!arrayerror && (
              <Grid>
                <Typography
                  variant="caption"
                  sx={{ color: theme => theme.palette.error.main }}
                >
                  {arrayerror}
                </Typography>
              </Grid>
            )}
          </>
        );
      }}
    </FieldArray>
  );
});
