import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider, Theme } from "@mui/material/styles";
import "./index.css";
import "./config/window.config";

import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { ItemAuthNavigator } from "navigators/ItemAuthNavigator";
import { LoginPage } from "pages/LoginPage";
import { BaseNavigator } from "navigators";
import { routeNames, theme } from "config";
import { ForgotPasswordPage } from "pages/ForgotPasswordPage";
import { ResetPasswordPage } from "pages/ResetPasswordPage";
// import { EmailPage } from 'pages/EmailPage';
// import { SignupPage } from 'pages/SignupPage';

// declare module '@mui/material/styles' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme { }
// }
createRoot(document.getElementById("root")!).render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <BaseNavigator
          baseRoutes={[
            {
              path: routeNames.FORGOT_PASSWORD,
              element: <ForgotPasswordPage />,
            },
            { path: routeNames.LOGIN, element: <LoginPage /> },
            // { path: routeNames.SIGNUP, element: <SignupPage /> },
            { path: routeNames.RESET_PASSWORD, element: <ResetPasswordPage /> },
          ]}
          authNavigator={ItemAuthNavigator}
        />
      </Router>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
