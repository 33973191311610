import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Contact } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Contact entity
const contactAdapter = createEntityAdapter<Contact, EntityId>({
  selectId: (contact: Contact) => contact.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface ContactsState extends EntityState<Contact, EntityId> {
  // Add any additional state properties here if needed
}

export const initialContactState: ContactsState =
  contactAdapter.getInitialState();

// Selectors for Contacts
export const {
  selectById: selectContactById,
  selectAll: selectAllContacts,
  selectEntities: selectContactEntities,
  selectIds: selectContactIds,
  selectTotal: selectContactTotal,
} = contactAdapter.getSelectors((state: RootState) => state?.contact);

export const contactSlice = createSlice({
  name: "contact",
  initialState: initialContactState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        contact.endpoints.getContacts.matchFulfilled
        // contact.endpoints.createContact.matchFulfilled,
        // contact.endpoints.updateContact.matchFulfilled,
        // contact.endpoints.patchContact.matchFulfilled
      ),
      (state, action) => {
        contactAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      contact.endpoints.deleteContact.matchFulfilled,
      (state, action) => {
        contactAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const contact = baseApi
  .enhanceEndpoints({ addTagTypes: ["Contacts", "Contact"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getContacts: builder.query<Contact[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "contact",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Contact" as const, id })),
                { type: "Contacts" as const },
              ]
            : [{ type: "Contacts" as const }],
      }),
      getContact: builder.query<Contact, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `contact/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Contact", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createContact: builder.mutation<Contact, Partial<Contact>>({
        query: (request) => ({
          url: "contact",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Contacts" }],
      }),
      patchContact: builder.mutation<Contact, Contact>({
        query: (request) => ({
          url: `contact/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Contact", id: arg.id },
        ],
      }),
      updateContact: builder.mutation<Contact, Contact>({
        query: (request) => ({
          url: `contact/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Contact", id: arg.id },
        ],
      }),
      deleteContact: builder.mutation<void, number>({
        query: (id) => ({
          url: `contact/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Contact", id: arg },
          { type: "Contacts" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetContactsQuery,
  useGetContactQuery,
  useCreateContactMutation,
  usePatchContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useLazyGetContactsQuery,
  usePrefetch: useContactsPrefetch,
} = contact;
