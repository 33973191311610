import * as React from "react";
import { Button, CircularProgress } from "@mui/material";
import { Field, FieldProps, FormikErrors } from "formik";
import { JItem } from "../jformik/jformik.types";
import { useJFormikContext } from "../jformik/jformik.context";

export const FEmbed = (jItem: JItem) => {
  const { disabled = false } = useJFormikContext();
  if (!disabled || jItem?.jItemProps?.type !== "submit") {
    const { sx = {}, onClick, ...restJItemProps } = jItem?.jItemProps || {};

    return (
      <Field name={jItem.name}>
        {({
          form: { isSubmitting, isValid, touched, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          field: { value },
          meta,
        }: FieldProps) => {
          if (jItem.embed) {
            return jItem.embed({ values, setFieldValue, disabled, value });
          } else {
            return <div />;
          }
        }}
      </Field>
    );
  } else {
    return <div />;
  }
};
