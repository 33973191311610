import {
  ArrowCircleDown,
  FilePresent,
  InsertPhotoSharp,
  AddCircleOutlineOutlined,
  AddCircleOutlineSharp,
  DeleteOutlineOutlined,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  List,
  ListSubheader,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { formatBytes } from '../../utils';
import { forwardRef, useEffect, useState } from 'react';
import { FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import { JItem } from '../jformik/jformik.types';
import { FCheckbox } from './FCheckbox';
import moment from 'moment';
import { useJFormikContext } from '../jformik/jformik.context';

export const FAttachments = forwardRef((jItem: JItem, ref) => {
  const { name, label } = jItem;
  const { disabled: fdisabled } = useJFormikContext();
  const disabled = fdisabled || jItem.disabled;
  return (
    <FieldArray name={name} validateOnChange={false}>
      {({ push, remove, form: { values }, name }: FieldArrayRenderProps) => {
        return (
          <Grid item xs={12}>
            <Grid container direction={'column'} wrap={'nowrap'} spacing={0}>
              {values &&
              getIn(values, name) &&
              getIn(values, name)?.length > 0 ? (
                getIn(values, name)?.map((attachment: any, index: number) => (
                  <>
                    {jItem?.jItemProps?.checkbox && (
                      <Grid
                        item
                        sx={{ mb: '5px', pr: 0 }}
                        alignSelf={'center'}
                        alignItems={'center'}
                      >
                        <FCheckbox
                          name={`${name}.${index}.checkbox`}
                          label=""
                          itemType="checkbox"
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ mb: '5px' }}>
                      <Attachment
                        key={`${name}.${index}.name`}
                        onClose={jItem?.jItemProps?.onClose}
                        onDelete={jItem?.jItemProps?.onDelete}
                        onDownload={jItem?.jItemProps?.onDownload}
                        id={attachment.id}
                        name={attachment.name}
                        size={attachment.size}
                        type={attachment.type}
                        signatures={attachment.signatures}
                        hideDetails={jItem?.jItemProps?.hideDetails}
                        disabled={disabled}
                      />
                    </Grid>
                  </>
                ))
              ) : (
                <div />
              )}
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
});

export interface AttachmentProps {
  id?: number;
  name: string;
  type: string;
  status?: string | string[];
  signatures?: {
    ats_owner: { firstname?: string; lastname?: string; email: string };
    status: string;
  }[];
  size: number;
  isLoading?: boolean;
  onClose: Function;
  onDelete?: Function;
  onDownload?: Function;
  hideDetails?: boolean;
  disabled?: boolean;
}

export const Attachment = ({
  id,
  name,
  size,
  type,
  signatures,
  isLoading: parentLoading = false,
  onClose,
  onDelete,
  onDownload,
  hideDetails,
  disabled = false,
}: AttachmentProps) => {
  let status: { [key: string]: number } = {};
  signatures
    ?.filter(sig => sig.status && sig.status !== '')
    ?.forEach(sig => {
      status[`${sig.status}`] = status[`${sig.status}`]
        ? status[`${sig.status}`] + 1
        : 1;
    });
  const file = { id, name, size, type };
  const [isLoading, setIsLoading] = useState<boolean>(parentLoading);
  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(false);
  const expanded = parentHover || childHover;
  useEffect(() => setIsLoading(parentLoading), [parentLoading]); // need to refresh state upon props update
  return (
    <Grid
      container
      sx={
        !id
          ? { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
          : { backgroundColor: 'rgba(118, 176, 65, 0.08)' }
      }
      onMouseEnter={() => {
        setParentHover(true);
      }}
      onMouseLeave={() => {
        setParentHover(false);
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item sx={{ p: '8px' }}>
              <Box sx={{ p: '1px', bgcolor: 'white' }}>
                {type?.split('/')[0] === 'image' ? (
                  <InsertPhotoSharp sx={{ fontSize: 25 }} color="secondary" />
                ) : (
                  <FilePresent sx={{ fontSize: 25 }} color="secondary" />
                )}
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                  <Typography variant="body2" color={'primary'}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" color={'secondary.dark'}>
                    &nbsp;&nbsp;{formatBytes(size, 1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            wrap="nowrap"
          >
            {!hideDetails &&
              !expanded &&
              status &&
              Object.keys(status)
                .filter(s => s !== 'Sent By')
                .sort()
                .map(s => (
                  <Grid item sx={{ marginRight: '7px' }}>
                    <Badge
                      badgeContent={status[s] > 1 ? status[s] : 0}
                      color={getChipColorFromStatus(s)}
                    >
                      <Chip
                        color={getChipColorFromStatus(s)}
                        label={s}
                        variant="filled"
                      />
                    </Badge>
                  </Grid>
                ))}
            {id && onDelete && !isLoading && !disabled && (
              <Grid item sx={{ marginRight: '2px' }}>
                <IconButton
                  size="large"
                  aria-label="display more actions"
                  edge="end"
                  color="primary"
                  onClick={async () => {
                    setIsLoading(true);
                    await onDelete(file);
                    onClose(file);
                  }}
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              </Grid>
            )}
            {id && onDownload && !isLoading && (
              <Grid item sx={{ marginRight: '2px' }}>
                <IconButton
                  size="large"
                  aria-label="display more actions"
                  edge="end"
                  color="primary"
                  onClick={async () => {
                    setIsLoading(true);
                    await onDownload(file);
                    setIsLoading(false);
                  }}
                >
                  <ArrowCircleDown />
                </IconButton>
              </Grid>
            )}
            {!id && !isLoading && !disabled && (
              <Grid item sx={{ marginRight: '2px' }}>
                <IconButton
                  size="large"
                  aria-label="display more actions"
                  edge="end"
                  color="primary"
                  onClick={() => onClose(file)}
                >
                  <AddCircleOutlineSharp sx={{ transform: 'rotate(45deg)' }} />
                </IconButton>
              </Grid>
            )}
            {isLoading && (
              <Grid item sx={{ marginRight: '2px' }}>
                <IconButton
                  size="large"
                  aria-label="display more actions"
                  edge="end"
                  color="primary"
                >
                  <CircularProgress size={18} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!hideDetails &&
        status &&
        Object.keys(status).length > 0 &&
        signatures &&
        signatures.length > 0 && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List
              dense
              onMouseEnter={() => {
                setChildHover(true);
              }}
              onMouseLeave={() => {
                setChildHover(false);
              }}
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    backgroundColor: 'transparent',
                    fontWeight: 800,
                    textDecoration: 'underline',
                  }}
                >
                  Attachment details
                </ListSubheader>
              }
            >
              {signatures
                ?.filter(s => s.status !== '')
                ?.map((sig, index) => (
                  <ListItem>
                    <ListItemAvatar sx={{ pr: 5 }}>
                      <Chip
                        color={getChipColorFromStatus(sig.status)}
                        label={sig.status}
                        variant="filled"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span>
                          <b>
                            {' '}
                            {!!sig?.ats_owner?.firstname
                              ? `${sig?.ats_owner.firstname} ${sig?.ats_owner.lastname}`
                              : sig?.ats_owner.email}
                          </b>{' '}
                          {!!sig?.ats_owner?.firstname
                            ? `(${sig?.ats_owner.email})`
                            : ``}
                        </span>
                      }
                      secondary={`Date: ${moment(
                        (sig as any).updated_at
                      ).format('MM/DD/YYYY')}`}
                    />
                  </ListItem>
                ))}
            </List>
          </Collapse>
        )}
    </Grid>
  );
};

const getChipColorFromStatus = (status: string) => {
  switch (status) {
    case 'Approved':
    case 'Approved with Markups':
    case 'approved':
    case 'Confirmed':
    case 'confirmed':
      return 'secondary';
    case 'Call Me':
    case 'call-me':
      return 'warning';
    case 'Rejected':
    case 'rejected':
    case 'Not Ready':
    case 'not-ready':
      return 'error';
    default:
      return 'default';
  }
};
