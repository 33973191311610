import { Save } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCreateTaskMutation, usePatchTaskMutation } from "store";
import { Person, Script, Task } from "store/api/models";
import {
  selectScriptById,
  useCreateScriptMutation,
  usePatchScriptMutation,
} from "store/api/script.api";
import { RootState } from "store/reducer";
import {
  setInitialData,
  setScriptId,
  setTitleError,
} from "store/slice/formBuilder.slice";

export const TaskActionsRedux = ({
  task,
  close,
  callback,
  disabled = false,
  values,
}: {
  close: Function;
  callback: Function;
  task: Task;
  disabled: boolean;
  values: any;
}) => {
  const dispatch = useDispatch();
  const [createTask] = useCreateTaskMutation();
  const [patchTask] = usePatchTaskMutation();
  // Get state from Redux store
  const questions = useSelector((state: any) => state.formBuilder.questions);
  const edges = useSelector((state: any) => state.formBuilder.edges);
  const reduxDisabled = useSelector((state: any) => state.formBuilder.disabled);

  useEffect(() => {
    // Initialize Redux store with script data
    dispatch(
      setInitialData({
        title: task?.title || "",
        scriptId: task?.id || null,
        questions: task.form?.questions || [],
        edges: task.form?.edges || [],
        answers: task.form?.answers || {},
        disabled: disabled,
        person: {
          firstname: "John",
          lastname: "Doe",
          phone: "(123) 456-7890",
          email: "john@doe.com",
          address: "5501 Josh Birmingham Pkwy",
          city: "Charlotte",
          state: "NC",
          zip: "28208",
          addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
        } as Person,
        user: {
          firstname: "Mary",
          lastname: "Mae",
          phone: "(123) 456-7890",
          email: "mary@mae.com",
          address: "5501 Josh Birmingham Pkwy",
          city: "Charlotte",
          state: "NC",
          zip: "28208",
          addressString: "5501 Josh Birmingham Pkwy Charlotte NC 28208",
        } as Person,
      })
    );
  }, []);
  if (reduxDisabled) {
    return <></>;
  } else
    return (
      <Fab
        color="primary"
        sx={{
          position: "absolute",
          right: 10,
          bottom: 10,
        }}
        onClick={async () => {
          if (values.id) {
            try {
              const res = await patchTask({
                ...values,
                form: {
                  ...(values.form || {}),
                  questions,
                  edges,
                },
              }).unwrap();
              close();
              if (res.id) {
                // setValues((values: any) => ({ ...values, id: res.id }));
                callback && callback(res);
                toast.info("Task updated successfuly");
              }
            } catch (error) {
              toast.error("Unable to update task");
            }
          } else {
            try {
              let res: any = {};
              res = await createTask({
                ...values,
                form: {
                  ...(values.form || {}),
                  questions,
                  edges,
                },
              }).unwrap();
              close();
              if (res.id) {
                // setValues((values: any) => ({ ...values, id: res.id }));
                callback && callback(res);
                toast.info("Task created successfuly");
              }
            } catch (error) {
              toast.error("Unable to create task");
            }
          }
        }}
      >
        <Save />
      </Fab>
    );
};
