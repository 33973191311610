import * as Yup from "yup";
import {
  MailOutlineOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { JFormikData } from "forms/jformik";
import { useGetRolesQuery, useGetSkillsQuery } from "store";

export const useVolunteerForm = (admin?: boolean): JFormikData => {
  const { data: skills, isLoading: skillsLoading } = useGetSkillsQuery({});
  const { data: roles, isLoading: rollsLoading } = useGetRolesQuery({});
  return {
    validationSchema: Yup.object({
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
    form: [
      // {
      //   name: "basic-fields",
      //   itemType: "typography",
      //   jItemProps: {
      //     variant: "overline",
      //     sx: { mb: "0 !important", pb: "0 !important" },
      //   },
      //   label: "Basic Information",
      // },
      // {
      //   name: "basic-fields-bottom",
      //   label: "",
      //   itemType: "divider",
      //   gridProps: { size: { sx: { paddingTop: "0px !important" } } },
      // },
      {
        name: "firstname",
        label: "First Name",
        itemType: "textfield",
        gridProps: { size: { xs: 12 } },
        jItemProps: {
          InputProps: {
            endAdornment: <PersonOutlineOutlined color="secondary" />,
          },
        },
      },
      {
        name: "lastname",
        label: "Last Name",
        itemType: "textfield",
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "email",
        label: "Email",
        itemType: "textfield",
        gridProps: { size: { xs: 12 } },
        jItemProps: {
          InputProps: {
            endAdornment: <MailOutlineOutlined color="secondary" />,
          },
        },
      },
      ...(admin
        ? [
            {
              name: "experience",
              label: "Experience Level",
              itemType: "autocomplete",
              options: ["Expert", "Intermediate", "Beginner"],
              gridProps: { size: { xs: 12 } },
            },
            {
              name: "role",
              label: "Role",
              itemType: "autocomplete",
              options: roles,
              gridProps: { size: { xs: 12 } },
            },
            {
              name: "skills",
              label: "Skills",
              itemType: "autocomplete",
              multiple: true,
              options: skills,
              gridProps: { size: { xs: 12 } },
            },
          ]
        : []),
      {
        name: "submit",
        itemType: "button",
        jItemProps: { type: "submit" },
        label: "SAVE",
        gridProps: { size: { xs: 12 } },
      },
    ],
  } as JFormikData;
};
