import { useState } from "react";
import { Box, Paper } from "@mui/material";

import { Event } from "store/api/mobilize/mobilize_models";
import { MobilizeEventList } from "./MobilizeEventList";
import { MobilizeEventDetails } from "./MobilizeEventDetails";
import { BackdropLoading } from "../BackdropLoading";
import { useGetMobilizeEventsQuery } from "store/api/mobilize/mobilize.api";
import { sortByDate } from "utils";

export const MobilizeEventsIndex = () => {
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: events = [] as Event[],
    isLoading: eventsLoading,
    refetch,
  } = useGetMobilizeEventsQuery({});
  const {
    data: cc_events = [] as Event[],
    isLoading: cc_eventsLoading,
    refetch: ccRefetch,
  } = useGetMobilizeEventsQuery({ params: { cc: true } });
  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
  };
  const handleEventEditClick = (event: Event) => {
    setEditing(true);
    setSelectedEvent(event);
  };

  // Combine and deduplicate events based on unique event ID
  const combinedEvents = [...events, ...cc_events].sort((a, b) =>
    sortByDate(a.timeslots?.[0]?.start_date, b.timeslots?.[0]?.start_date)
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {eventsLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedEvent ? 0.5 : 1,
            width: selectedEvent ? "50%" : undefined,
            maxWidth: selectedEvent ? "50%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedEvent ? 1 : 0,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Paper sx={{ p: 1, height: "100%", maxHeight: "100%" }} elevation={5}>
            <MobilizeEventList
              events={combinedEvents}
              selectedEvent={selectedEvent}
              handleEventClick={handleEventClick}
              handleEventEditClick={handleEventEditClick}
            />
          </Paper>
        </Box>
      )}

      {/* Event Details */}
      {selectedEvent && (
        <Box
          key={selectedEvent.id}
          sx={{
            flexGrow: 0.5,
            maxWidth: "50%",
            width: "50%",
            bgcolor: "transparent",
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <MobilizeEventDetails
            disabled={!editing}
            event={selectedEvent}
            close={() => {
              setSelectedEvent(undefined);
              setEditing(false);
            }}
            callback={async () => {
              await refetch();
              await ccRefetch();
              setSelectedEvent(undefined);
              setEditing(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
