import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Paper } from "@mui/material";
import { Node } from "@xyflow/react";
import {
  QuestionFieldData,
  selectRenderedQuestionIds,
} from "store/slice/formBuilder.slice";
import { RenderedQuestion } from "./RenderedQuestion";
import { BranchingFormBannerRedux } from "./BranchingFormBannerRedux";
import { useSelector } from "react-redux";

interface BranchingFormProps {
  save?: (answers: any) => void;
  submit?: (answers: any) => void;
  preview?: boolean;
}

export const BranchingFormRedux: React.FC<BranchingFormProps> = ({
  preview = true,
  submit = (answers: any) => {
    console.log(answers);
  },
  save = (answers: any) => {
    console.log(answers);
  },
}) => {
  const renderedQuestionIds: string[] = useSelector(selectRenderedQuestionIds);
  const questionRefs = useRef<HTMLDivElement[]>([]);
  // New useEffect to scroll to the latest question
  useEffect(() => {
    const nextIndex = renderedQuestionIds.length - 1;
    const nextQuestionRef = questionRefs.current[nextIndex];
    if (nextQuestionRef) {
      nextQuestionRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [renderedQuestionIds]);

  return (
    <Box
      sx={{
        maxHeight: "100%",
        height: "100%",
        px: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        elevation={5}
        sx={{
          flex: 0,
          bgcolor: (theme) => (preview ? theme.palette.divider : "white"),
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
        }}
      >
        <BranchingFormBannerRedux
          save={save}
          submit={submit}
          preview={preview}
        />
      </Paper>
      <Divider sx={{ mb: 1, flex: 0 }} />
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {renderedQuestionIds.map((id, index) => (
          <div
            ref={(el) => {
              if (el) {
                questionRefs.current[index] = el;
              }
            }}
            key={id}
          >
            <RenderedQuestion
              id={id}
              index={index}
              submit={submit}
              save={save}
              preview={preview}
            />
          </div>
        ))}
      </Box>
    </Box>
  );
};
