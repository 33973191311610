import { useEffect, useState } from "react";
import { Box, Chip, Fab, Paper, Typography } from "@mui/material";
import { Add, Description, People } from "@mui/icons-material";
import {
  selectProspectListEntities,
  useDeleteProspectListMutation,
  useGetProspectListsQuery,
} from "store/api/prospect_list.api";
import { ProspectList } from "store/api/models";
import { ProspectListList } from "./ProspectListList";
import { ProspectListDetails } from "./ProspectListDetails";
import { BackdropLoading } from "../BackdropLoading";
import { FormattedList } from "components/list/FormattedList";
import { useSelector } from "react-redux";
import { AgentTask } from "components/agent/AgentTask";
import { VolunteerState } from "components/agent/agent.types";
import {
  selectProspectEntities,
  useGetProspectsQuery,
} from "store/api/prospect.api";

export const ProspectListIndex = ({
  selectedVanID,
  setSelectedVanID,
}: {
  selectedVanID?: number;
  setSelectedVanID: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const { data: prospects = [], isLoading: prospectLoading } =
    useGetProspectsQuery(
      { params: { vanid: selectedVanID, nested: "all" } },
      { skip: !selectedVanID }
    );
  console.log(selectedVanID, prospects);
  const [state, setState] = useState<VolunteerState>({
    selectedConversation: null,
    selectedSkill: null,
    selectedMenu: null,
    isLoading: false,
    completed: false,
    welcome: true,
    disposition: false,
    callOngoing: false,
    fromCallHistory: true,
  });
  useEffect(() => {
    if (prospects && prospects.length === 1) {
      const prospect = prospects[0];
      setState({
        selectedConversation: prospect.conversations?.[0],
        selectedSkill: null,
        selectedMenu: null,
        isLoading: false,
        completed: false,
        welcome: true,
        disposition: false,
        callOngoing: false,
        fromCallHistory: true,
      });
    }
  }, [prospects]);
  const [selectedProspectList, setSelectedProspectList] =
    useState<ProspectList>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: prospect_lists = [] as ProspectList[],
    isLoading: prospect_listsLoading,
    refetch,
  } = useGetProspectListsQuery({ params: { nested: "normalize" } });
  const prospectListEntities = useSelector(selectProspectListEntities);
  const handleProspectListClick = (prospect_list: ProspectList) => {
    setEditing(false);
    setSelectedProspectList(prospect_list);
  };
  const handleProspectListEditClick = (prospect_list: ProspectList) => {
    setEditing(true);
    setSelectedProspectList(prospect_list);
  };
  const [deleteProspectList] = useDeleteProspectListMutation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        position: "relative",
        flexDirection: "row",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {prospect_listsLoading || prospectLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedProspectList ? 0.5 : 1,
            width: selectedProspectList ? "50%" : undefined,
            maxWidth: selectedProspectList ? "50%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedProspectList ? 1 : 0,
            maxHeight: "100%",
            height: "100%",
            display: !!state.selectedConversation ? "none" : undefined,
          }}
        >
          <Paper sx={{ p: 1, maxHeight: "100%", height: "100%" }} elevation={5}>
            <FormattedList
              listItems={prospect_lists?.map((s) => s.id) || []}
              onItemClick={(id: number) =>
                handleProspectListClick(prospectListEntities?.[id])
              }
              editItem={(id: number) =>
                handleProspectListEditClick(prospectListEntities?.[id])
              }
              deleteItem={(id: number) => deleteProspectList(id)}
              // duplicateItem={(id: number) => handleDuplicateScript(id)}
              primary={(id: number) => {
                const prospect_list = prospectListEntities?.[id] || {};

                return (
                  <Typography variant="overline" sx={{ fontWeight: 700 }}>
                    {prospect_list?.name}{" "}
                    {prospect_list?.form?.prospectListType && (
                      <Chip
                        label={prospect_list?.form?.prospectListType}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        sx={{ zoom: "75%", mr: 1 }}
                      />
                    )}{" "}
                    {prospect_list?.form?.fileName && (
                      <Chip
                        label={prospect_list?.form?.fileName}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        sx={{ zoom: "75%", mr: 1 }}
                      />
                    )}
                  </Typography>
                );
              }}
              icon={
                <People
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                  }}
                  fontSize="small"
                />
              }
              onClose={() => setSelectedProspectList(undefined)} //close()}
              selectedId={selectedProspectList?.id}
              itemName="prospect list"
            />
            {/* <ProspectListList
              prospect_lists={prospect_lists}
              selectedProspectList={selectedProspectList}
              close={() => {
                setSelectedProspectList(undefined);
                setEditing(false);
              }}
              handleProspectListClick={handleProspectListClick}
              handleProspectListEditClick={handleProspectListEditClick}
            /> */}
          </Paper>
        </Box>
      )}
      {!state.selectedConversation && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            left: 10,
            bottom: 50,
          }}
          onClick={() => {
            setEditing(true);
            setSelectedProspectList({} as ProspectList);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* ProspectList Details */}
      {selectedProspectList && !state.selectedConversation && (
        <Box
          sx={{
            flexGrow: 0.5,
            width: selectedProspectList ? "50%" : undefined,
            maxWidth: selectedProspectList ? "50%" : undefined,
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            maxHeight: "100%",
            height: "100%",
          }}
        >
          <ProspectListDetails
            key={selectedProspectList.id}
            disabled={!editing}
            prospect_list={selectedProspectList}
            close={() => {
              setSelectedProspectList(undefined);
              setEditing(false);
            }}
            callback={async () => {
              setEditing(false);
              await refetch();
            }}
            onProspectClick={(vanid: number) => setSelectedVanID(vanid)}
          />
        </Box>
      )}
      {state.selectedConversation && (
        <AgentTask state={state} setState={setState} variant={"task"} />
      )}
    </Box>
  );
};
