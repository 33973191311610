import {
  Button,
  buttonClasses,
  ButtonProps,
  Grid2 as Grid,
  styled,
  Typography,
} from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { JItem } from '../jformik/jformik.types';
import { useJFormikContext } from '../jformik/jformik.context';

/**
 * TODO: [FToggleButtons]
 * implement toggle buttons group action
 * selected => button variant should be contained
 */

export const FToggleButtons = (jItem: JItem) => {
  const { options: _options = [], jItemProps = {}, disabled = false } = jItem;
  const { containerGridProps = {}, itemsProps = [] } = jItemProps;
  const jFormikContext = useJFormikContext();
  const isDisabled = disabled || jFormikContext.disabled;

  return (
    <Field name={jItem.name}>
      {({
        field: { value },
        form: { isSubmitting, values, setFieldValue, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        const options =
          typeof _options === 'function' ? _options(values) : _options;
        return (
          <Grid container spacing={2} {...containerGridProps}>
            {options.map((option, index) => {
              const { gridProps = {}, buttonProps = {} } =
                itemsProps[index] || {};
              const { sx: gridSx = {}, ...gridPropsrest } = gridProps;
              const { sx: buttonSx = {}, ...buttonPropsrest } = buttonProps;
              const active =
                typeof value === 'string'
                  ? value.toLowerCase() === (option as string).toLowerCase()
                  : value
                  ? value?.value === (option as any)?.value
                  : false;
              return (
                <Grid
                  key={`toggle-button-item-${index}`}
                  sx={{
                    color: theme =>
                      disabled
                        ? theme.palette.text.disabled
                        : theme.palette.text.secondary,
                    width: `${100 / options.length}%`,
                    ...gridSx,
                  }}
                  {...gridPropsrest}
                >
                  <ToggleButton
                    fullWidth
                    disableRipple
                    disabled={isDisabled}
                    variant={'outlined'}
                    color={active ? 'primary' : 'inherit'}
                    sx={{
                      minHeight: 40,
                      ...buttonSx,
                      bgcolor: theme =>
                        active ? theme.palette.secondary.main : 'inherit',
                    }}
                    onClick={() => {
                      setFieldValue(
                        jItem.name,
                        value !== option ? option : null
                      );
                      jItem?.jItemProps?.onClick &&
                        typeof jItem?.jItemProps?.onClick === 'function' &&
                        jItem?.jItemProps?.onClick();
                    }}
                    {...buttonPropsrest}
                  >
                    {typeof option === 'string' ? option : option.label || ''}
                  </ToggleButton>
                </Grid>
              );
            })}
            {getIn(errors, jItem.name) && (
              <Grid>
                <Typography
                  variant="caption"
                  sx={{ color: theme => theme.palette.error.main }}
                >
                  {getIn(errors, jItem.name)}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }}
    </Field>
  );
};

export const ToggleButton = styled(({ ...props }: ButtonProps) => (
  <Button {...props} />
))(props => ({
  [`&.hover`]: {
    backgroundColor: 'red',
  },
  [`&.${buttonClasses.root}`]: {
    [`&.hover`]: {
      backgroundColor: 'red',
    },
  },
}));
