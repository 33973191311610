import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer, { appMiddleware } from "./reducer";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootSaga from "./saga";
import { useDispatch } from "react-redux";
import { listenerMiddleware } from "./middleware/listenerMiddleware";
import { initialContactState } from "./api/contact.api";
import { enableMapSet } from "immer";

const configStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  enableMapSet();
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      contact: initialContactState,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .concat(appMiddleware)
        .concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);
  setupListeners(store.dispatch);
  return store;
};

export const store = configStore();

export * from "./api";
