import {
  BaseQueryFn,
  FetchArgs,
  // fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { getApiAccessToken, getAuthStatus } from "../../utils";
import { Mutex } from "async-mutex";
import { apiBaseUrl, routeNames } from "../../config";
import { localStorageKey } from "../../consts";

type RootAuthState = {
  auth: {
    token?: string;
  };
};

// create a new mutex
const mutex = new Mutex();
const baseUrl = apiBaseUrl[process.env.REACT_APP_MODE || "production"];
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootAuthState)?.auth?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    } else {
      // backup look into local storage
      const token = getApiAccessToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    }
    return headers;
  },
});

export const fetchbase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  if (
    window.voluntizeapiconfig?.blockAPI &&
    (args as FetchArgs).method !== "GET"
  ) {
    //added to block updates to example project
    return { error: "FETCH_ERROR" } as any;
  }
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    (result.error.status === 401 ||
      result.error.status === 403 ||
      result.error.status === "FETCH_ERROR")
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const { data } = await baseQuery(
          {
            url: routeNames.REFRESH,
            method: "POST",
          },
          api,
          extraOptions
        );
        if (data) {
          // store the new token
          const authStatus = getAuthStatus();
          const { token } = data as any;
          if (!!authStatus && !!token) {
            const refreshdata = Object.assign(getAuthStatus(), data);
            localStorage.setItem(
              localStorageKey.auth_status,
              JSON.stringify(refreshdata)
            );
            /*** heap.io user analytics ***/
            if ("heap" in window && window.heap?.loaded) {
              window.heap.identify(refreshdata?.user?.id);
              window.heap.addUserProperties(refreshdata?.user);
            }
            /*** end ***/
            api.dispatch({ type: "auth/token", payload: token as string });
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch({ type: "auth/logout" }); //needs to match logout from user.api.ts
          }
        } else {
          api.dispatch({ type: "auth/logout" }); //needs to match logout from user.api.ts
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else if (result.error && result.error.status) {
    console.error(result);
    toast.error("There was an error trying to perform that action");
  }

  return result;
};

export const sanitizeForIdsAndNulls = (request: any) => {
  let sanitizedRequest = { ...request };
  for (var key of Object.keys(request)) {
    if (Array.isArray(request[key])) {
      //extracts ids from array of objects
      if (request[key].length > 0 && request[key][0]?.id) {
        sanitizedRequest[key] = request[key]
          .map((o: any) => (o?.id > 0 ? o?.id : undefined))
          .filter((o: any) => o);
      } else if (request[key]?.length === 0) {
        // delete empty arrays
        delete sanitizedRequest[key];
      }
    } else if (typeof request[key] === "object" && request[key]?.id) {
      // extract id
      if (request[key].id <= 0) {
        delete sanitizedRequest[key];
      } else {
        sanitizedRequest[key] = request[key].id;
      }
    } else if (typeof request[key] !== "boolean" && !!!request[key]) {
      //delete nulls
      delete sanitizedRequest[key];
    } else if (typeof request[key] === "number") {
      // delete negative numbers
      if (request[key] <= 0) delete sanitizedRequest[key];
    } else if (typeof request[key] === "string") {
      // delete empty strings numbers
      if (request[key] === "") delete sanitizedRequest[key];
    }
  }
  return sanitizedRequest;
};

export const sanitizeForNulls = (request: any) => {
  let sanitizedRequest = { ...request };
  for (var key of Object.keys(request)) {
    if (Array.isArray(request[key])) {
      //extracts ids from array of objects
      if (request[key].length > 0) {
        sanitizedRequest[key] = request[key].filter((o: any) => o);
      } else if (request[key]?.length === 0) {
        // delete empty arrays
        delete sanitizedRequest[key];
      }
    } else if (typeof request[key] === "object" && request[key]?.id) {
      // extract id
      if (request[key].id <= 0) {
        delete sanitizedRequest[key];
      }
    } else if (typeof request[key] !== "boolean" && !!!request[key]) {
      //delete nulls
      delete sanitizedRequest[key];
    } else if (typeof request[key] === "number") {
      // delete negative numbers
      if (request[key] <= 0) delete sanitizedRequest[key];
    } else if (typeof request[key] === "string") {
      // delete empty strings numbers
      if (request[key] === "") delete sanitizedRequest[key];
    }
  }
  return sanitizedRequest;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  reducerPath: "base",
  // global configuration for the api
  keepUnusedDataFor: 120, //keep unused data in cache for 2 minutes
  //refetchOnFocus causes overwritting when the window regains focus.
  //Lots of extra logic needed.
  //USE SPARINGLY AND ONLY FOR SPECIFIC APIS
  // refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchbase,
  endpoints: () => ({}),
});
