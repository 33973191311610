import { svgIconClasses, tabScrollButtonClasses } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";

// More on colors - https://www.figma.com/file/DFMp9sUBBzl4ssnGjv02Yh/Voluntize-System---MUI-for-Figma-v5.4.0?node-id=8641%3A76322
export const theme: Theme = createTheme({
  // palette: {
  //   primary: {
  //     main: "#1976d2",
  //   },
  //   secondary: {
  //     main: "#38A4DC",
  //   },
  // },
  // palette: {
  //   // TODO [Figma]: primary and secondary colors should be swapped
  //   primary: {
  //     // TODO [Figma]: primary.main should be primary.dark
  //     main: '#163B3F',
  //     light: '#318A6D',
  //     dark: '#163B3F',
  //     contrastText: '#FFFFFF',
  //   },
  //   secondary: {
  //     main: '#76B041',
  //     light: '#123456', //'#90D651',
  //     dark: '#5C9527',
  //     contrastText: '#FFFFFF',
  //   },
  //   text: {
  //     primary: '#333333',
  //     secondary: '#333333CC',
  //     disabled: '#33333366',
  //   },
  //   action: {
  //     // TODO [Figma]: There are some undefined colors
  //     active: '#0000008A',
  //     hover: '#0000000A',
  //     // hoverOpacity: '',
  //     selected: '#00000014',
  //     // selectedOpacity: '',
  //     disabled: '#00000042',
  //     disabledBackground: '#0000001F',
  //     // disabledOpacity: '',
  //     focus: '#0000001F',
  //     // focusOpacity: '',
  //     // activatedOpacity: ''
  //   },
  //   error: {
  //     main: '#D32F2F',
  //     light: '#EF5350',
  //     dark: '#C62828',
  //     contrastText: '#FFFFFF',
  //   },
  //   warning: {
  //     main: '#ED6C02',
  //     light: '#FF9800',
  //     dark: '#E65100',
  //     contrastText: '#FFFFFF',
  //   },
  //   info: {
  //     main: '#0288D1',
  //     light: '#03A9F4',
  //     dark: '#01579B',
  //     contrastText: '#FFFFFF',
  //   },
  //   success: {
  //     main: '#2E7D32',
  //     light: '#4CAF50',
  //     dark: '#1B5E20',
  //     contrastText: '#FFFFFF',
  //   },
  //   divider: '#0000001F',
  // },
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
        slotProps: {
          inputLabel: { shrink: true },
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&::before": {
            backgroundColor: "transparent",
          },
          backgroundColor: "transparent",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1210,
        },
        paper: {
          zIndex: 1220,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          lineHeight: "normal",
          // '&:hover': {
          //   backgroundColor: 'inherit',
          //   // opacity: 0.5
          // }
        },
        sizeLarge: {
          minHeight: 64,
        },
      },
    },
    // MuiSpeedDialAction: {
    //   styleOverrides: {
    //     staticTooltipLabel: {
    //       whiteSpace: "nowrap",
    //       backgroundColor: "#76B041",
    //       color: "white",
    //     },
    //   },
    // },
    // MuiFab: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#76B041",
    //       ":hover": {
    //         backgroundColor: "#76B041",
    //       },
    //     },
    //   },
    // },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "#333333CC",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#333333CC",
        },
      },
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     flexContainer: {
    //       paddingTop: "10px",
    //       paddingRight: "10px",
    //       paddingLeft: "10px",
    //       paddingBottom: "2px",
    //     },
    //     indicator: {
    //       backgroundColor: "#83AD51",
    //     },
    //     scrollButtons: {
    //       [`&.${tabScrollButtonClasses.root}`]: {
    //         width: 32,
    //         height: 32,
    //         borderRadius: "100%",
    //         alignSelf: "center",
    //         marginTop: "8px",
    //         color: "#62823d",

    //         [` .${svgIconClasses.root}`]: {
    //           fontSize: "1.5rem",
    //         },

    //         [`&.${tabScrollButtonClasses.disabled}`]: {
    //           opacity: 0.3,
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         color: '#83AD51',
    //         // boxShadow: '6px 6px 6px -3px rgb(118, 176, 65,0.8), 0px 10px 14px 1px rgb(118, 176, 65, 0.5), 0px 4px 18px 3px rgb(118, 176, 65, 0.5)'
    //         // boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
    //         // boxShadow: 'rgba(118, 176, 65, 0.18) 6px 2px 16px 0px, rgba(118, 176, 65, 0.3) -6px -2px 16px 0px'
    //         // boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px'
    //         boxShadow: '5px -3px 10px 0px rgba(118,176,65,0.75)',
    //       },
    //       textTransform: 'none',
    //       // borderBottom: '1px solid #83AD51',
    //     },
    //   },
    // },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ":hover": {
            elevation: 10,
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
        },
      },
    },
  },
  // typography: {
  //   fontFamily: ['Poppins'].join(','),
  // },
});
