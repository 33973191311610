import { toast } from "react-toastify";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  createAction,
  createSelector,
  createSlice,
  Middleware,
  PayloadAction,
} from "@reduxjs/toolkit";
import { fetchbase } from "./common.api";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { localStorageKey } from "../../consts";
import { getAuthStatus } from "../../utils";
import { routeNames } from "../../config";

/******* */
/* types */
/******* */
export interface User {
  volunteer: number;
  login: number;
  contact: number;
  subscription: number;
  email: string;
  firstname: string;
  lastname: string;
  admin: boolean;
}

export interface LoginResponse {
  token?: string;
  user?: User;
  mqtt?: Mqtt;
}

export interface Mqtt {
  endpoint: string;
  event_topic: string;
}

export interface LoginRequest {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirm: string;
}
/******* */
/* api */
/******* */
export const user = createApi({
  reducerPath: "user",
  // global configuration for the api
  tagTypes: ["User"],
  baseQuery: fetchbase,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: routeNames.LOGIN,
        method: "POST",
        body: credentials,
      }),
    }),
    signup: builder.mutation<LoginResponse, any>({
      query: (credentials) => ({
        url: routeNames.SIGNUP,
        method: "POST",
        body: credentials,
      }),
    }),
    refresh: builder.mutation<LoginResponse, void>({
      query: () => ({
        url: routeNames.REFRESH,
        method: "POST",
      }),
    }),
    forgotPw: builder.mutation<void, any>({
      query: (credentials) => ({
        url: routeNames.FORGOT_PASSWORD,
        method: "POST",
        body: credentials,
      }),
    }),
    resetPw: builder.mutation<LoginResponse, any>({
      query: (credentials) => ({
        url: routeNames.RESET_PASSWORD,
        method: "POST",
        body: credentials,
      }),
    }),
    changePw: builder.mutation<LoginResponse, any>({
      query: (credentials) => ({
        url: routeNames.CHANGE_PASSWORD,
        method: "POST",
        body: credentials,
      }),
    }),
    updateProfile: builder.mutation<LoginResponse, any>({
      query: (credentials) => ({
        url: routeNames.UPDATE_PROFILE,
        method: "PUT",
        body: credentials,
      }),
    }),
  }),
});

// Auto-generated hooks
export const {
  useLoginMutation,
  useSignupMutation,
  useRefreshMutation,
  useChangePwMutation,
  useForgotPwMutation,
  useResetPwMutation,
  useUpdateProfileMutation,
} = user;
/********* */
/* reducer */
/********* */
export const userSlice = createSlice({
  name: "auth",
  initialState: { user: {}, token: "", mqtt: {} } as LoginResponse,
  reducers: {
    logout: (state) => {
      state.user = {} as User;
      state.token = "";
      state.mqtt = {} as Mqtt;
    },
    token: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        user.endpoints.login.matchFulfilled,
        (state, { payload }: PayloadAction<LoginResponse>) => {
          const { user, token, mqtt } = payload;
          state.user = user;
          state.token = token;
          state.mqtt = mqtt;
        }
      )
      .addMatcher(
        user.endpoints.signup.matchFulfilled,
        (state, { payload }: PayloadAction<LoginResponse>) => {
          const { user, token, mqtt } = payload;
          state.user = user;
          state.token = token;
          state.mqtt = mqtt;
        }
      )
      .addMatcher(
        user.endpoints.refresh.matchFulfilled,
        (state, { payload }: PayloadAction<LoginResponse>) => {
          const { token } = payload;
          state.token = token;
        }
      ),
});

export const selectUserState = (state: any) => state.auth;
export const selectIsLoggedIn = createSelector(
  [selectUserState],
  (userState) => !!userState?.token
);
export const selectApiAccessToken = createSelector(
  [selectUserState],
  (userState) => userState?.token
);
export const selectMqtt = createSelector(
  [selectUserState],
  (userState) => userState?.mqtt
);
export const selectUser = createSelector(
  [selectUserState],
  (userState) => userState?.user
);

/******* */
/* saga */
/******* */
export const logout = userSlice.actions.logout;
export const init = createAction("auth/init");

declare global {
  interface Window {
    heap: any;
  }
}
export function* userSaga() {
  yield all([
    takeLatest(user.endpoints.login.matchFulfilled, watchLoginResult),
    takeLatest(user.endpoints.signup.matchFulfilled, watchLoginResult),
    takeLatest(
      [
        user.endpoints.forgotPw.matchFulfilled,
        user.endpoints.forgotPw.matchRejected,
      ],
      watchforgotPwResult
    ),
    takeLatest(user.endpoints.login.matchRejected, watchLoginError),
    takeLatest(logout.match, watchLogout),
    takeEvery(user.endpoints.refresh.matchFulfilled, watchRefreshToken),
  ]);
}

export function watchforgotPwResult(action: any) {
  toast.success(
    "Forgot password email will be sent to the specified email, if it is a valid account"
  );
}

export function watchLoginError(action: any) {
  toast.error(
    "Login failure, your email and/or password is incorrect. Please try again"
  );
}

export function watchLoginResult(action: any) {
  try {
    const { payload } = action;
    localStorage.setItem(localStorageKey.auth_status, JSON.stringify(payload));
    /*** heap.io user analytics ***/
    if ("heap" in window && window.heap?.loaded) {
      window.heap.identify(payload?.user?.id);
      window.heap.addUserProperties(payload?.user);
    }
    /*** end ***/

    toast.success("Successfully logged in.");
  } catch (error: any) {
    toast.error("Login failure, please try again");
  }
}

export function watchLogout(action: { type: string }) {
  try {
    console.log("LOGGING OUT-");
    localStorage.setItem(localStorageKey.auth_status, JSON.stringify(null));
  } catch (error: any) {
    toast.error(error.message);
  }
}

export function* watchRefreshToken(action: {
  type: string;
  payload: LoginResponse;
}) {
  try {
    const { token } = action.payload;
    const refreshdata = { ...getAuthStatus(), token };
    localStorage.setItem(
      localStorageKey.auth_status,
      JSON.stringify(refreshdata)
    );
    if (!!token) {
      /*** heap.io user analytics ***/
      if ("heap" in window && window.heap?.loaded) {
        window.heap.identify(refreshdata?.user?.id);
        window.heap.addUserProperties(refreshdata?.user);
      }
      /*** end ***/
    } else {
      yield put(logout());
    }
  } catch (error: any) {
    yield put(logout());
  }
}
