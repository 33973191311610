import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useResetPwMutation } from "store/api/user.api";
import { ResetPassword } from "./ResetPassword";

export const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const token = params.get("token");
  const new_account = params.get("n");
  const [resetPW] = useResetPwMutation();

  return (
    <ResetPassword
      reset={resetPW}
      navigate={navigate}
      email={email || undefined}
      token={token && token !== "" ? token : undefined}
      new_account={!!new_account}
    />
  );
};
