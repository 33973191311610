import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";
import { TemplateTextField } from "forms/fields/TemplateTextField";
import { useDispatch, useSelector } from "react-redux";
import { FilteredEventListReduxRefactor } from "forms/builder/questions/FilteredEventListReduxRefactor";
import {
  QuestionOption,
  selectAnswerById,
  selectRenderedQuestionById,
  setAnswer,
} from "store/slice/formBuilder.slice";

interface QuestionRendererProps {
  id: string;
  preview?: boolean;
  isFullScreen?: boolean;
}

export const QuestionRendererRedux: React.FC<QuestionRendererProps> = ({
  id,
  preview = true,
  isFullScreen = false,
}) => {
  const dispatch = useDispatch();
  const question = useSelector(selectRenderedQuestionById(id)) || {};
  const answer = useSelector(selectAnswerById(id)) || {};
  const activeRenderedQuestionId = useSelector(
    (state: any) => state.formBuilder.activeRenderedQuestionId
  );
  const person = useSelector((state: any) => state.formBuilder.person);
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setAnswer({ [id]: { value: event.target.value } }));
  };
  const disabledRedux = useSelector((state: any) => state.formBuilder.disabled);
  const disabled =
    activeRenderedQuestionId !== id || (!preview && disabledRedux);
  const handleCheckboxChange =
    (optionId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setAnswer({
          [id]: {
            checkboxes: {
              [optionId]: event.target.checked,
            },
          },
        })
      );
    };
  switch (question?.data?.type) {
    case "shortAnswer":
      return (
        <>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <TextField
            disabled={disabled}
            value={answer?.value || ""}
            variant="outlined"
            fullWidth
            onChange={handleValueChange}
          />
        </>
      );
    case "paragraph":
      return (
        <TextField
          disabled={disabled}
          value={answer?.value || ""}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          onChange={handleValueChange}
        />
      );
    case "script":
      return (
        <TemplateTextField
          disabled={disabled}
          inputValue={question?.data?.question}
          templates={[
            "{{firstname}}",
            "{{lastname}}",
            "{{email}}",
            "{{phone}}",
            "{{address}}",
            "{{city}}",
            "{{state}}",
            "{{zip}}",
            "{{agent_firstname}}",
            "{{agent_lastname}}",
          ]}
          //this is on purpose
          values={person as unknown as { [key: string]: string }}
        />
      );
    case "multipleChoice":
      return (
        <>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <RadioGroup onChange={handleValueChange} value={answer?.value || ""}>
            {question?.data?.options?.map((option: QuestionOption) => (
              <FormControlLabel
                disabled={disabled}
                key={option.id}
                value={option.id}
                control={<Radio />}
                label={option.option}
              />
            ))}
          </RadioGroup>
        </>
      );
    case "checkboxes":
      return (
        <>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <FormGroup>
            {question?.data?.options?.map((option: QuestionOption) => (
              <FormControlLabel
                disabled={disabled}
                key={option.id}
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={answer?.checkboxes[option.id]}
                    onChange={handleCheckboxChange(option.id)}
                  />
                }
                label={option.option}
              />
            ))}
          </FormGroup>
        </>
      );
    case "dropdown":
      return (
        <>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <Autocomplete
            disabled={disabled}
            options={question?.data?.options || []}
            getOptionLabel={(option) => option.option}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            value={answer?.value}
            onChange={(event, newValue) => {
              dispatch(setAnswer({ [id]: { value: newValue || "" } }));
            }}
          />
        </>
      );
    case "date":
      return (
        <>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <TextField
            disabled={disabled}
            label="Date"
            type="date"
            value={answer?.value}
            slotProps={{
              inputLabel: { shrink: true },
            }}
            onChange={handleValueChange}
            fullWidth
          />
        </>
      );
    case "time":
      return (
        <>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <TextField
            disabled={disabled}
            label="Time"
            type="time"
            value={answer?.value}
            slotProps={{
              inputLabel: { shrink: true },
            }}
            onChange={handleValueChange}
            fullWidth
          />
        </>
      );
    case "event":
      return (
        <Box sx={isFullScreen ? { height: "90%" } : {}}>
          <Typography variant="body1">{question?.data?.question}</Typography>
          <FilteredEventListReduxRefactor
            isRender={true}
            compact={false}
            isFullScreen={isFullScreen}
            question={question?.data}
            value={answer}
            handleQuestionPropsChange={(key: string, value: object) => {
              dispatch(setAnswer({ [id]: { [key]: value } }));
            }}
          />
        </Box>
      );
    // Add more cases for other question types if needed
    default:
      return <p>Unsupported question type</p>;
  }
};
