import { useEffect, forwardRef } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { JItem } from "../jformik/jformik.types";
import { useJFormikContext } from "../jformik/jformik.context";

export const FCheckbox = forwardRef((jItem: JItem, ref) => {
  const { label, name, jItemProps } = jItem;
  const { disabled: fdisabled = false } = useJFormikContext();
  const { values, setFieldValue } = useFormikContext();
  const disabled =
    jItem.disabled === false ? false : fdisabled || jItem.disabled;
  const defaultValue =
    typeof jItem.defaultValue === "function"
      ? jItem.defaultValue(values)
      : jItem.defaultValue;
  useEffect(() => {
    if (defaultValue && !getIn(values, jItem.name)) {
      setFieldValue(jItem.name, defaultValue);
    }
  }, [jItem.name, defaultValue]);
  return (
    <Field name={name}>
      {({
        field: { name, value, onChange, onBlur },
        form: { touched, errors, values, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) =>
        label && label !== "" ? (
          <Tooltip title={jItem.jItemProps?.tooltip}>
            <FormControlLabel
              disabled={disabled}
              disableTypography
              control={
                jItemProps?.loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Checkbox
                    defaultValue={
                      jItem.defaultValue ? jItem.defaultValue : undefined
                    }
                    disabled={disabled}
                    checked={value}
                    defaultChecked={jItem.defaultValue}
                    onChange={(e: any) => {
                      jItem.jItemProps?.onChange
                        ? jItem.jItemProps?.onChange({
                            name,
                            values,
                            setFieldValue,
                            setFieldTouched,
                            checked: e.target.checked,
                          })
                        : setFieldValue(name, e.target.checked);
                    }}
                    name={name}
                  />
                )
              }
              label={label}
              sx={{
                "& .MuiFormControlLabel-label": {
                  // whiteSpace: 'nowrap',
                  overflow: "hidden",
                  // textOverflow: 'ellipsis',
                  maxWidth: "100%", // Adjust the max-width as needed
                },
                ...jItem.jItemProps?.sx,
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={jItem.jItemProps?.tooltip}>
            <Checkbox
              defaultValue={jItem.defaultValue ? jItem.defaultValue : undefined}
              disabled={disabled}
              checked={value}
              defaultChecked={jItem.defaultValue}
              onChange={(e: any) => {
                jItem.jItemProps?.onChange
                  ? jItem.jItemProps?.onChange({
                      name,
                      values,
                      setFieldValue,
                      setFieldTouched,
                      checked: e.target.checked,
                    })
                  : setFieldValue(name, e.target.checked);
              }}
              name={name}
            />
          </Tooltip>
        )
      }
    </Field>
  );
});
