import { JFormik } from "../../forms";
import * as Yup from "yup";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  useGetEventTypesQuery,
  useCreateEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
  useGetTaskTypesQuery,
  useCreateTaskTypeMutation,
  useUpdateTaskTypeMutation,
  useDeleteTaskTypeMutation,
} from "store";
import { toast } from "react-toastify";

export const TypesTab = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: event_types = [], isLoading: eventTypesLoading } =
    useGetEventTypesQuery({});
  const [createEventType] = useCreateEventTypeMutation();
  const [editEventType] = useUpdateEventTypeMutation();
  const [deleteEventType] = useDeleteEventTypeMutation();

  // const {
  //   data: task_types = [],
  //   isLoading: taskTypesLoading,
  //   refetch: refetchTaskTypes,
  // } = useGetTaskTypesQuery({});
  // const [createTaskType] = useCreateTaskTypeMutation();
  // const [editTaskType] = useUpdateTaskTypeMutation();
  // const [deleteTaskType] = useDeleteTaskTypeMutation();

  return (
    <JFormik
      initialValues={() => ({
        // task_types: task_types,
        event_types: event_types,
      })}
      enableReinitialize
      handleSubmit={async (values, formik) => {}}
      loading={eventTypesLoading}
      validationSchema={Yup.object({})}
      disabled={true}
      form={[
        {
          name: "event_types",
          itemType: "table_array",
          label: "Event Types",
          tableProps: {
            onCreate: async (values: any) => {
              try {
                const res = await createEventType({
                  name: values.name,
                }).unwrap();
                if (res.id > 0) {
                  toast.success("Event type successfully created!");
                } else {
                  toast.warning(
                    "There was an issue creating the event type, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error creating the event type, please try again."
                );
              }
            },
            onEdit: async (values: any) => {
              try {
                const res = await editEventType(values).unwrap();
                if (res.id > 0) {
                  toast.success("Event type successfully edited!");
                } else {
                  toast.warning(
                    "There was an issue editing the event type, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error editing the event type, please try again."
                );
              }
            },
            onDelete: async (id: number) => {
              try {
                await deleteEventType(id);
                toast.success("Event type successfully deleted!");
              } catch (error) {
                toast.error(
                  "There was an error deleting the event type, please try again."
                );
              }
            },
            columns: [
              {
                name: "name",
                label: "Type *",
                itemType: "textfield",
                jItemProps: {
                  width: "70%",
                  touched: true,
                  sx: {
                    boxSizing: "border-box",
                    px: { xs: 0.5 },
                    minWidth: 180,
                  },
                },
                gridProps: { size: { xs: 12 } },
              },
            ],
          },
          device: downSm ? "mobile" : "desktop",
        },
        // {
        //   name: "task_types",
        //   itemType: "table_array",
        //   label: "Task Types",
        //   tableProps: {
        //     onCreate: (values: any) => createTaskType({ name: values.name }),
        //     onEdit: (values: any) => editTaskType(values),
        //     onDelete: (id: number) => deleteTaskType(id),
        //     columns: [
        //       {
        //         name: "name",
        //         label: "Type *",
        //         itemType: "textfield",
        //         jItemProps: {
        //           width: "70%",
        //           touched: true,
        //           sx: {
        //             boxSizing: "border-box",
        //             px: { xs: 0.5 },
        //             minWidth: 180,
        //           },
        //         },
        //         gridProps: { size: { xs: 12 } },
        //       },
        //     ],
        //   },
        //   device: downSm ? "mobile" : "desktop",
        // },
      ]}
    />
  );
};
