import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Skill } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Skill entity
const skillAdapter = createEntityAdapter<Skill, EntityId>({
  selectId: (skill: Skill) => skill.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface SkillsState extends EntityState<Skill, EntityId> {
  // Add any additional state properties here if needed
}

const initialSkillState: SkillsState = skillAdapter.getInitialState();

// Selectors for Skills
export const {
  selectById: selectSkillById,
  selectAll: selectAllSkills,
  selectEntities: selectSkillEntities,
  selectIds: selectSkillIds,
  selectTotal: selectSkillTotal,
} = skillAdapter.getSelectors((state: RootState) => state?.skill);

export const skillSlice = createSlice({
  name: "skill",
  initialState: initialSkillState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(skill.endpoints.getSkills.matchFulfilled),
      (state, action) => {
        skillAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        skill.endpoints.createSkill.matchFulfilled,
        skill.endpoints.updateSkill.matchFulfilled,
        skill.endpoints.patchSkill.matchFulfilled
      ),
      (state, action) => {
        skillAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      skill.endpoints.deleteSkill.matchFulfilled,
      (state, action) => {
        skillAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const skill = baseApi
  .enhanceEndpoints({ addTagTypes: ["Skills", "Skill"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSkills: builder.query<Skill[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "skill",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Skill" as const, id })),
                { type: "Skills" as const },
              ]
            : [{ type: "Skills" as const }],
      }),
      getSkill: builder.query<Skill, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `skill/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Skill", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createSkill: builder.mutation<Skill, Partial<Skill>>({
        query: (request) => ({
          url: "skill",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Skills" }],
      }),
      patchSkill: builder.mutation<Skill, Skill>({
        query: (request) => ({
          url: `skill/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Skill", id: arg.id },
        ],
      }),
      updateSkill: builder.mutation<Skill, Skill>({
        query: (request) => ({
          url: `skill/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Skill", id: arg.id },
        ],
      }),
      deleteSkill: builder.mutation<void, number>({
        query: (id) => ({
          url: `skill/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Skill", id: arg },
          { type: "Skills" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetSkillsQuery,
  useGetSkillQuery,
  useCreateSkillMutation,
  usePatchSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
  usePrefetch: useSkillsPrefetch,
} = skill;
