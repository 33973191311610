import { forwardRef, useState, useRef, useEffect } from "react";
import {
  TextField,
  TextFieldProps,
  Tooltip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Chip,
  InputBaseComponentProps,
  Box,
} from "@mui/material";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { useBreakpointQuery } from "../../../utils";
import { JItem } from "../../../forms/jformik/jformik.types";
import { useJFormikContext } from "../../jformik/jformik.context";
import { NavigateNext } from "@mui/icons-material";

export const FAutoComplete = forwardRef((jItem: JItem, ref) => {
  const {
    multiple = false,
    freeSolo = false,
    freeSoloFormat,
    limitTags,
    name,
    label,
    options,
    tooltip,
    jItemProps,
  } = jItem;
  const [openTooltip, setOpenTooltip] = useState<boolean | undefined>(
    jItem?.tooltip?.open
  );
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext();
  const [customStep, setCustomStep] = useState<string>(
    getIn(values, jItem.name)?.value && getIn(values, jItem.name)?.value !== ""
      ? "value"
      : "label"
  );
  const smDown = useBreakpointQuery("sm-down");
  const { disabled = false, disableSubmitOnEnter = true } = useJFormikContext();
  const filter = createFilterOptions<any>();
  useEffect(() => {
    if (jItem.defaultValue && !getIn(values, jItem.name)) {
      setFieldValue(jItem.name, jItem.defaultValue);
    }
  }, [jItem.name, jItem.defaultValue]);
  if (jItem.value) {
    return <AutocompleteComponent {...jItem} />;
  } else {
    return (
      <Field name={name} innerRef>
        {({
          field: { name, value, onBlur },
          form: { touched, errors, setFieldValue, values, submitCount },
        }: FieldProps) => {
          return (
            <div id={`${jItem.name}-field`}>
              <Autocomplete
                id={`${jItem.name}-field`}
                disabled={
                  jItem.disabled === false ? false : disabled || jItem.disabled
                }
                // size={jItem?.jItemProps?.size}
                disableClearable={jItem?.jItemProps?.disableClearable}
                multiple={multiple}
                freeSolo={freeSolo}
                blurOnSelect={freeSolo ? "mouse" : false}
                limitTags={limitTags}
                value={
                  jItem.jItemProps?.type === "custom" ? value?.value : value
                }
                defaultValue={jItem?.defaultValue}
                options={
                  options
                    ? typeof options === "function"
                      ? options(values)
                      : options
                    : []
                }
                getOptionLabel={(option: any) => {
                  if (typeof option === "string") {
                    return option;
                  } else {
                    return (
                      option?.label ||
                      option?.title ||
                      option?.name ||
                      option?.inputvalue ||
                      ""
                    );
                  }
                }}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "clear") {
                    if (jItem.jItemProps?.type === "custom") {
                      setFieldValue(name, {
                        ...value,
                        [customStep]: "",
                      });
                    } else {
                      setFieldValue(name, undefined);
                    }
                  }
                }}
                renderInput={(params: TextFieldProps) => {
                  // When value is set in the background the TextField value is not updated properly
                  // therefore we added the bottom code for fix
                  params =
                    params.inputProps?.value === ""
                      ? {
                          ...params,
                          inputProps: {
                            ...params.inputProps,
                            value: value
                              ? typeof value === "string"
                                ? value
                                : value?.label ||
                                  value?.title ||
                                  value?.name ||
                                  value?.inputvalue ||
                                  ""
                              : "",
                          },
                        }
                      : params;
                  //fix end
                  if (jItem.jItemProps?.type === "custom") {
                    const { inputProps, InputProps, ...rest } = params;
                    return (
                      <TextField
                        {...rest}
                        inputProps={
                          customStep === "value"
                            ? {
                                ...params.inputProps,
                                onChange: (event: any) => {
                                  setFieldValue(name, {
                                    ...value,
                                    [customStep]: event.target.value,
                                  });
                                  params?.inputProps?.onChange?.(event);
                                },
                                value:
                                  typeof value?.[customStep] === "string"
                                    ? value?.[customStep]
                                    : value?.[customStep]?.name || "",
                              }
                            : {
                                className: params.inputProps?.className,
                                onChange: (event: any) => {
                                  setFieldValue(name, {
                                    ...value,
                                    [customStep]: event.target.value,
                                  });
                                },
                                onBlur: (event: any) => {
                                  setFocus(false);
                                  onBlur(event);
                                },
                                onFocus: () => {
                                  setFocus(true);
                                },
                                value: value?.[customStep] || "",
                              }
                        }
                        onMouseEnter={() => setHover(true && !disabled)}
                        onMouseLeave={() => setHover(false)}
                        onKeyPress={(e) => {
                          if (
                            e.key === "Enter" &&
                            !(value?.value || value?.value === "") &&
                            !(customStep === "value")
                          ) {
                            setFieldValue(name, { ...value, value: "" });
                            setCustomStep("value");
                          }
                          disableSubmitOnEnter &&
                            e.key === "Enter" &&
                            e.preventDefault();
                        }}
                        id={`${jItem.name}-field`}
                        disabled={
                          jItem.disabled === false
                            ? false
                            : disabled || jItem.disabled
                        }
                        label={
                          jItem.jItemProps?.labelFunc
                            ? jItem?.jItemProps?.labelFunc(name, values, {
                                setFieldValue,
                              })
                            : value?.label || "Please enter the field title..."
                        }
                        name={name}
                        variant={jItem.jItemProps?.variant || "outlined"}
                        // size={
                        //   jItem?.jItemProps?.size || smDown ? 'small' : 'medium'
                        // }
                        fullWidth
                        value={value?.[customStep] || ""}
                        error={
                          !!(
                            getIn(errors, name) &&
                            (getIn(touched, name) ||
                              submitCount ||
                              jItem?.jItemProps?.touched)
                          )
                        }
                        helperText={
                          getIn(touched, name) ||
                          submitCount ||
                          jItem?.jItemProps?.touched
                            ? getIn(errors, name)
                            : null
                        }
                        {...jItem.jItemProps}
                        slotProps={{
                          input:
                            customStep === "value"
                              ? {
                                  ...params.InputProps,
                                  inputComponent:
                                    !focus &&
                                    jItem?.jItemProps?.InputProps
                                      ?.inputComponent
                                      ? jItem?.jItemProps?.InputProps
                                          ?.inputComponent
                                      : params?.InputProps?.inputComponent,
                                }
                              : {
                                  ...params?.InputProps,
                                  ...jItem?.jItemProps?.InputProps,
                                  endAdornment:
                                    !(value?.value || value?.value === "") &&
                                    !(customStep === "value") ? (
                                      <InputAdornment position="end">
                                        <Tooltip title={"Set label"}>
                                          <IconButton
                                            onClick={() => {
                                              setFieldValue(name, {
                                                ...value,
                                                value: "",
                                              });
                                              setCustomStep("value");
                                            }}
                                          >
                                            <NavigateNext color="secondary" />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    ) : undefined,
                                  startAdornment:
                                    (focus || hover) &&
                                    (typeof jItem?.jItemProps?.InputProps
                                      ?.startAdornment === "function"
                                      ? jItem?.jItemProps?.InputProps?.startAdornment(
                                          name,
                                          values,
                                          { setFieldValue }
                                        )
                                      : jItem?.jItemProps?.InputProps
                                          ?.startAdornment),
                                },
                          inputLabel: { shrink: !!value ? true : undefined },
                        }}
                      />
                    );
                  } else if (tooltip) {
                    return (
                      <Tooltip {...{ ...tooltip, open: openTooltip }}>
                        <TextField
                          id={`${jItem.name}-field`}
                          {...params}
                          disabled={
                            jItem.disabled === false
                              ? false
                              : disabled || jItem.disabled
                          }
                          onKeyPress={(e) => {
                            disableSubmitOnEnter &&
                              e.key === "Enter" &&
                              e.preventDefault();
                          }}
                          onFocus={() => {
                            if (openTooltip) {
                              setOpenTooltip(false);
                            }
                            setFocus(true);
                          }}
                          onMouseEnter={() => setHover(true && !disabled)}
                          onMouseLeave={() => setHover(false)}
                          onBlur={(event: any) => {
                            setFocus(false);
                            onBlur(event);
                          }}
                          label={label}
                          variant={jItem.jItemProps?.variant || "outlined"}
                          // size={
                          //   jItem?.jItemProps?.size || smDown
                          //     ? 'small'
                          //     : 'medium'
                          // }
                          error={!!getIn(errors, name)}
                          helperText={
                            !disabled && !jItem.disabled
                              ? getIn(errors, name)
                                ? getIn(errors, name)
                                : jItem.jItemProps?.helperText
                                ? typeof jItem.jItemProps?.helperText ===
                                  "function"
                                  ? jItem.jItemProps?.helperText(value)
                                  : jItem.jItemProps?.helperText
                                : null
                              : null
                          }
                          slotProps={{
                            input: {
                              ...params?.InputProps,
                              ...(jItem.loading
                                ? {
                                    endAdornment: (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ),
                                  }
                                : {}),
                              ...jItem?.jItemProps?.InputProps,
                              ...((jItem?.jItemProps?.InputProps
                                ?.startAdornment && {
                                startAdornment:
                                  (focus || hover) &&
                                  (typeof jItem?.jItemProps?.InputProps
                                    ?.startAdornment === "function"
                                    ? jItem?.jItemProps?.InputProps?.startAdornment(
                                        name,
                                        values,
                                        { setFieldValue }
                                      )
                                    : jItem?.jItemProps?.InputProps
                                        ?.startAdornment),
                              }) ||
                                {}),
                            },
                            inputLabel: { shrink: !!value ? true : undefined },
                          }}
                        />
                      </Tooltip>
                    );
                  } else {
                    return (
                      <TextField
                        id={`${jItem.name}-field`}
                        {...params}
                        sx={{ pr: 0 }}
                        inputProps={
                          freeSolo
                            ? {
                                ...params.inputProps,
                                onChange: (event: any) => {
                                  setFieldValue(
                                    name,
                                    freeSoloFormat
                                      ? freeSoloFormat(event.target.value)
                                      : {
                                          id: 0,
                                          label: event.target.value,
                                          name: event.target.value,
                                        }
                                  );
                                  params?.inputProps?.onChange?.(event);
                                },
                                // value:
                                //   typeof value === 'string'
                                //     ? value
                                //     : value?.label ||
                                //       value?.title ||
                                //       value?.name ||
                                //       value?.inputvalue ||
                                //       '',
                              }
                            : {
                                ...params.inputProps,
                                // onChange: (event: any) => {
                                //   setFieldValue(name, event.target.value);
                                //   params?.inputProps?.onChange?.(event);
                                // },
                                // value:
                                //   typeof value === 'string'
                                //     ? value
                                //     : value?.label ||
                                //       value?.title ||
                                //       value?.name ||
                                //       value?.inputvalue ||
                                //       '',
                              }
                        }
                        onKeyPress={(e) => {
                          disableSubmitOnEnter &&
                            e.key === "Enter" &&
                            e.preventDefault();
                        }}
                        onMouseEnter={() => setHover(true && !disabled)}
                        onMouseLeave={() => setHover(false)}
                        onFocus={() => {
                          setFocus(true);
                        }}
                        onBlur={(event: any) => {
                          setFocus(false);
                          onBlur(event);
                        }}
                        label={label}
                        variant={jItem.jItemProps?.variant || "outlined"}
                        // size={
                        //   jItem?.jItemProps?.size || smDown ? 'small' : 'medium'
                        // }
                        error={!!getIn(errors, name)}
                        helperText={
                          !disabled && !jItem.disabled
                            ? getIn(errors, name)
                              ? getIn(errors, name)
                              : jItem.jItemProps?.helperText
                              ? typeof jItem.jItemProps?.helperText ===
                                "function"
                                ? jItem.jItemProps?.helperText(value)
                                : jItem.jItemProps?.helperText
                              : null
                            : null
                        }
                        slotProps={{
                          input: {
                            ...params?.InputProps,
                            ...(jItem.loading
                              ? {
                                  endAdornment: (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ),
                                }
                              : {}),
                            ...jItem?.jItemProps?.InputProps,
                            ...((jItem?.jItemProps?.InputProps
                              ?.startAdornment && {
                              startAdornment:
                                (focus || hover) &&
                                (typeof jItem?.jItemProps?.InputProps
                                  ?.startAdornment === "function"
                                  ? jItem?.jItemProps?.InputProps?.startAdornment(
                                      name,
                                      values,
                                      { setFieldValue }
                                    )
                                  : jItem?.jItemProps?.InputProps
                                      ?.startAdornment),
                            }) ||
                              {}),
                          },
                          inputLabel: { shrink: !!value ? true : undefined },
                        }}
                        value={value}
                      />
                    );
                  }
                }}
                autoHighlight
                {...(jItem?.jItemProps?.renderTags
                  ? {
                      renderTags: (val: readonly string[], getTagProps: any) =>
                        jItem.jItemProps.renderTags(value, getTagProps),
                    }
                  : {})}
                filterOptions={(options: any[], params) => {
                  const filtered: any[] = jItemProps?.optionsFilter
                    ? jItemProps.optionsFilter(options, params)
                    : filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  if (
                    inputValue !== "" &&
                    (!filtered || filtered.length === 0) &&
                    jItem?.jItemProps?.addValue
                  ) {
                    filtered.push({
                      inputValue,
                      title: `Add: "${inputValue}"`,
                      addValue: true,
                    });
                  }

                  return filtered;
                }}
                {...jItem.jItemProps}
                onChange={async (event: any, newValue: any) => {
                  if (jItem.jItemProps?.type === "custom") {
                    if (jItemProps?.addValue && newValue?.addValue) {
                      jItemProps.addValue(name, newValue, values, {
                        setFieldValue,
                      });
                    } else {
                      if (jItem.jItemProps?.onChange) {
                        await jItem.jItemProps?.onChange(
                          {
                            ...value,
                            [customStep]: newValue,
                          },
                          name,
                          setFieldValue
                        );
                      } else {
                        setFieldValue(name, {
                          ...value,
                          [customStep]: newValue,
                        });
                      }
                    }
                  } else if (
                    jItemProps?.addValue &&
                    (newValue?.addValue ||
                      (multiple &&
                        Array.isArray(newValue) &&
                        newValue.find((v) => v?.addValue)))
                  ) {
                    if (multiple && Array.isArray(newValue)) {
                      jItemProps.addValue(
                        name,
                        newValue.find((v) => v?.addValue),
                        values,
                        {
                          setFieldValue,
                        }
                      );
                    } else {
                      jItemProps.addValue(name, newValue, values, {
                        setFieldValue,
                      });
                    }
                  } else if (freeSolo && typeof newValue === "string") {
                    setFieldValue(
                      name,
                      freeSoloFormat
                        ? freeSoloFormat(newValue)
                        : { id: 0, label: newValue, name: newValue }
                    );
                    if (jItem.jItemProps?.onChange) {
                      await jItem.jItemProps?.onChange(
                        freeSoloFormat
                          ? freeSoloFormat(newValue)
                          : { id: 0, label: newValue, name: newValue }
                      );
                    }
                  } else if (multiple) {
                    const newArray = newValue.map((value: any) =>
                      typeof value === "string"
                        ? freeSolo && freeSoloFormat
                          ? freeSoloFormat(value)
                          : { id: 0, label: value, name: value }
                        : value
                    );
                    setFieldValue(name, newArray);
                    if (jItem.jItemProps?.onChange) {
                      await jItem.jItemProps?.onChange(
                        newArray,
                        name,
                        setFieldValue
                      );
                    }
                  } else {
                    setFieldValue(name, newValue);
                    if (jItem.jItemProps?.onChange) {
                      await jItem.jItemProps?.onChange(
                        newValue,
                        name,
                        setFieldValue
                      );
                    }
                  }
                }}
              />
            </div>
          );
        }}
      </Field>
    );
  }
});

const AutocompleteComponent = forwardRef((jItem: JItem, ref) => {
  const {
    multiple = false,
    freeSolo = false,
    freeSoloFormat,
    limitTags = 3,
    name,
    label,
    options,
    tooltip,
    jItemProps,
  } = jItem;
  const { disabled = false, disableSubmitOnEnter = true } = useJFormikContext();
  const [openTooltip, setOpenTooltip] = useState<boolean | undefined>(
    jItem?.tooltip?.open
  );
  const smDown = useBreakpointQuery("sm-down");
  const filter = createFilterOptions<any>();
  return (
    <Autocomplete
      disabled={jItem.disabled}
      disableClearable={jItem?.jItemProps?.disableClearable}
      multiple={multiple}
      freeSolo={freeSolo}
      blurOnSelect={freeSolo ? "mouse" : false}
      limitTags={limitTags}
      value={jItem.value}
      options={
        options
          ? typeof options === "function"
            ? options(jItem.value)
            : options
          : []
      }
      getOptionLabel={(option: any) => {
        if (typeof option === "string") {
          return option;
        } else {
          return option?.label || option?.title || option?.name || "";
        }
      }}
      renderInput={(params: TextFieldProps) => {
        if (tooltip) {
          return (
            <Tooltip {...{ ...tooltip, open: openTooltip }}>
              <TextField
                {...params}
                id={`${jItem.name}-field`}
                onKeyPress={(e) => {
                  disableSubmitOnEnter &&
                    e.key === "Enter" &&
                    e.preventDefault();
                }}
                onFocus={() => {
                  if (openTooltip) {
                    setOpenTooltip(false);
                  }
                }}
                label={label}
                variant={jItem.jItemProps?.variant || "outlined"}
                // size={jItem?.jItemProps?.size || smDown ? 'small' : 'medium'}
                helperText={
                  !jItem.disabled
                    ? jItem.jItemProps?.helperText
                      ? jItem.jItemProps?.helperText
                      : null
                    : null
                }
                slotProps={{
                  input: {
                    ...(params?.InputProps || {}),
                    ...(jItem?.jItemProps?.InputProps || {}),
                    ...(jItem.loading
                      ? {
                          endAdornment: (
                            <CircularProgress color="inherit" size={20} />
                          ),
                        }
                      : {}),
                  },
                  inputLabel: { shrink: !!jItem.value ? true : undefined },
                }}
              />
            </Tooltip>
          );
        } else {
          return (
            <TextField
              {...params}
              id={`${jItem.name}-field`}
              onKeyPress={(e) => {
                disableSubmitOnEnter && e.key === "Enter" && e.preventDefault();
              }}
              label={label}
              variant={jItem.jItemProps?.variant || "outlined"}
              // size={jItem?.jItemProps?.size || smDown ? 'small' : 'medium'}
              helperText={
                !jItem.disabled
                  ? jItem.jItemProps?.helperText
                    ? jItem.jItemProps?.helperText
                    : null
                  : null
              }
              slotProps={{
                input: {
                  ...(params?.InputProps || {}),
                  ...(jItem?.jItemProps?.InputProps || {}),
                  ...(jItem.loading
                    ? {
                        endAdornment: (
                          <CircularProgress color="inherit" size={20} />
                        ),
                      }
                    : {}),
                },
                inputLabel: { shrink: !!jItem.value ? true : undefined },
              }}
            />
          );
        }
      }}
      autoHighlight
      // {...jItemPropsRest}
      filterOptions={(options: any[], params) => {
        const filtered: any[] = jItemProps?.optionsFilter
          ? jItemProps.optionsFilter(options, params)
          : filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        if (
          inputValue !== "" &&
          (!filtered || filtered.length === 0) &&
          jItem?.jItemProps?.addValue
        ) {
          filtered.push({
            inputValue,
            title: `Add: "${inputValue}"`,
            addValue: true,
          });
        }

        return filtered;
      }}
      {...jItem.jItemProps}
      onChange={async (event: any, newValue: any) => {
        if (freeSolo && typeof newValue === "string") {
          if (jItem.jItemProps?.onChange) {
            await jItem.jItemProps?.onChange(
              freeSoloFormat
                ? freeSoloFormat(newValue)
                : { id: 0, label: newValue, name: newValue }
            );
          }
        } else if (
          jItemProps?.addValue &&
          (newValue?.addValue ||
            (multiple &&
              Array.isArray(newValue) &&
              newValue.find((v) => v?.addValue)))
        ) {
          if (multiple && Array.isArray(newValue)) {
            jItemProps.addValue(
              name,
              newValue.find((v) => v?.addValue)
            );
          } else {
            jItemProps.addValue(name, newValue);
          }
        } else if (multiple) {
          const newArray = newValue.map((value: any) =>
            typeof value === "string"
              ? freeSolo && freeSoloFormat
                ? freeSoloFormat(value)
                : { id: 0, label: value, name: value }
              : value
          );
          if (jItem.jItemProps?.onChange) {
            await jItem.jItemProps?.onChange(newArray);
          }
        } else {
          if (jItem.jItemProps?.onChange) {
            await jItem.jItemProps?.onChange(newValue);
          }
        }
      }}
    />
  );
});
