import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  IconButton,
  Grid2 as Grid,
  Select,
  MenuItem,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Divider,
  InputLabel,
  FormControl,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import { Handle, Position, Node } from "@xyflow/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { TemplateTextField } from "forms/fields/TemplateTextField";
import {
  QuestionFieldData,
  QuestionOption,
  handleQuestionUpdate,
  selectQuestionById,
} from "store/slice/formBuilder.slice";
import { useDispatch, useSelector } from "react-redux";
import { FilteredEventListReduxRefactor } from "./questions/FilteredEventListReduxRefactor";

const templates = [
  "{{firstname}}",
  "{{lastname}}",
  "{{email}}",
  "{{phone}}",
  "{{address}}",
  "{{city}}",
  "{{state}}",
  "{{zip}}",
  "{{agent_firstname}}",
  "{{agent_lastname}}",
];

interface QuestionProps {
  id: string;
  isFlowNode?: boolean;
  nodeTitle?: string;
}

interface RenderOptionAdditionsProps {
  option: QuestionOption;
  index: number;
  id: string;
  disabled: boolean;
  isFlowNode: boolean;
  removeOption: (index: number) => void;
}

const RenderOptionAdditions: React.FC<RenderOptionAdditionsProps> = React.memo(
  ({ option, index, id, disabled, isFlowNode, removeOption }) => {
    return (
      <>
        {!disabled && (
          <IconButton onClick={() => removeOption(index)}>
            <DeleteIcon />
          </IconButton>
        )}
        {isFlowNode && (
          <Handle
            type="source"
            position={Position.Right}
            id={`${id}-option-${option.id}-next`}
            style={{
              top: "50%",
              background: "#555",
              borderRadius: 0,
              width: 10,
              height: 10,
              right: -5,
            }}
          />
        )}
      </>
    );
  }
);

interface RenderFieldProps {
  questionType: string;
  data: QuestionFieldData;
  disabled: boolean;
  options: QuestionOption[];
  handleOptionChange: (value: string, index: number) => void;
  handleQuestionChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleQuestionPropsChange: (key: string, value: object) => void;
  addOption: () => void;
  removeOption: (index: number) => void;
  id: string;
  isFlowNode: boolean;
}

const RenderField = React.memo(
  React.forwardRef<HTMLTextAreaElement | HTMLInputElement, RenderFieldProps>(
    (props, ref) => {
      const {
        questionType,
        data,
        disabled,
        options,
        handleOptionChange,
        handleQuestionChange,
        handleQuestionPropsChange,
        addOption,
        removeOption,
        id,
        isFlowNode,
      } = props;
      switch (questionType) {
        case "shortAnswer":
          return (
            <TextField
              label="Short Answer"
              variant="outlined"
              fullWidth
              disabled
            />
          );
        case "paragraph":
          return (
            <TextField
              label="Paragraph"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              disabled
            />
          );
        case "script":
          return (
            <TextField
              inputRef={ref}
              label="Additional text"
              value={data.question}
              onChange={handleQuestionChange}
              fullWidth
              disabled={disabled}
              placeholder="Type '{{' to add in a dynamic field"
              variant="outlined"
              minRows={4}
              multiline
            />
            // <TemplateTextField
            //   inputValue={data?.question || ""}
            //   templates={templates}
            //   disabled={disabled}
            //   onInputChange={(newValue: string) => handleQuestionChange(newValue)}
            // />
          );
        case "multipleChoice":
          return (
            <>
              <RadioGroup>
                {options.map((option, i) => (
                  <Stack
                    direction="row"
                    key={i}
                    alignItems="center"
                    spacing={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <FormControlLabel
                      value={option}
                      control={<Radio />}
                      label=""
                      disabled
                    />
                    <TextField
                      value={option.option}
                      disabled={disabled}
                      onChange={(e) => handleOptionChange(e.target.value, i)}
                      placeholder="Option"
                      variant="outlined"
                    />
                    <RenderOptionAdditions
                      option={option}
                      index={i}
                      id={id}
                      disabled={disabled}
                      isFlowNode={isFlowNode}
                      removeOption={removeOption}
                    />
                  </Stack>
                ))}
              </RadioGroup>
              {!disabled && <Button onClick={addOption}>Add Option</Button>}
            </>
          );
        case "checkboxes":
          return (
            <>
              {options.map((option, i) => (
                <Stack
                  direction="row"
                  key={i}
                  alignItems="center"
                  spacing={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label="" disabled />
                  <TextField
                    value={option.option}
                    onChange={(e) => handleOptionChange(e.target.value, i)}
                    placeholder="Option"
                    variant="outlined"
                    disabled={disabled}
                  />
                  <RenderOptionAdditions
                    option={option}
                    index={i}
                    id={id}
                    disabled={disabled}
                    isFlowNode={isFlowNode}
                    removeOption={removeOption}
                  />
                </Stack>
              ))}
              {!disabled && <Button onClick={addOption}>Add Option</Button>}
            </>
          );
        case "dropdown":
          return (
            <>
              <Select disabled>
                {options.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.option}
                  </MenuItem>
                ))}
              </Select>
              {options.map((option, i) => (
                <Stack
                  direction="row"
                  key={i}
                  alignItems="center"
                  spacing={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <TextField
                    value={option.option}
                    onChange={(e) => handleOptionChange(e.target.value, i)}
                    placeholder="Option"
                    variant="outlined"
                    disabled={disabled}
                  />
                  <RenderOptionAdditions
                    option={option}
                    index={i}
                    id={id}
                    disabled={disabled}
                    isFlowNode={isFlowNode}
                    removeOption={removeOption}
                  />
                </Stack>
              ))}
              {!disabled && (
                <Button onClick={addOption}>Add Dropdown Option</Button>
              )}
            </>
          );
        case "date":
          return <TextField label="Date" type="date" fullWidth disabled />;
        case "time":
          return <TextField label="Time" type="time" fullWidth disabled />;
        case "event":
          return (
            <FilteredEventListReduxRefactor
              question={data}
              handleQuestionPropsChange={handleQuestionPropsChange}
              compact={isFlowNode}
            />
          );
        case "typography":
          return (
            <TextField
              label="Additional text"
              value={data.question}
              onChange={handleQuestionChange}
              multiline
              disabled={disabled}
              fullWidth
            />
          );
        case "h1":
          return (
            <TextField
              inputRef={ref}
              label="Heading"
              disabled={disabled}
              onChange={handleQuestionChange}
              multiline
              fullWidth
            />
          );
        default:
          return null;
      }
    }
  )
);

export const QuestionReduxComponent: React.FC<QuestionProps> = ({
  id,
  isFlowNode = false,
  nodeTitle,
}) => {
  const node = useSelector(selectQuestionById(id)) || {};
  const [templateOptions, setTemplateOptions] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLTextAreaElement | HTMLInputElement | null
  >(null);
  const templateRef = useRef(null);
  const dispatch = useDispatch();
  const disabled = useSelector((state: any) => state.formBuilder.disabled);

  const {
    data = {
      id: "1",
      index: 0,
      type: "shortAnswer",
      question: "",
      options: [],
    } as QuestionFieldData,
  } = node;
  const { options = [] } = data;

  const questionType = node?.data?.type || "shortAnswer";

  useEffect(() => {
    if (nodeTitle && nodeTitle !== "") {
      dispatch(
        handleQuestionUpdate({
          ...node,
          data: { ...data, ["nodeTitle"]: nodeTitle },
        } as Node<QuestionFieldData>)
      );
    }
  }, [nodeTitle]);

  const handleTemplateClick = (template: string) => {
    // Insert the selected template into the input field at the @ position
    const value = data?.question;
    const atIndex = value.lastIndexOf("{{");
    const newValue =
      value.slice(0, atIndex) +
      template +
      value.slice(atIndex + 1).replace("{", "") +
      " ";
    setTemplateOptions([]);
    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, question: newValue },
      } as Node<QuestionFieldData>)
    );
  };

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;
    const input = event.target; // Reference to the input element

    // Get the current cursor position
    const cursorPosition = input.selectionStart;
    // Check if the two characters before the cursor position are "{{"
    if (
      cursorPosition &&
      cursorPosition >= 2 &&
      value.slice(cursorPosition - 2, cursorPosition) === "{{"
    ) {
      // Show options and filter template options based on input after @
      const searchValue = value.slice(cursorPosition).match(/^\S+/)?.[0] || "";
      if (searchValue.length > 0) {
        const filteredOptions = templates.filter((template) =>
          template.toLowerCase().includes(searchValue.toLowerCase())
        );
        setTemplateOptions(filteredOptions);
        setAnchorEl(templateRef.current);
      } else {
        setTemplateOptions(templates); // Show full list on just '@'
        setAnchorEl(templateRef.current);
      }
    } else {
      setTemplateOptions([]); // Hide options if @ is not in the string
    }
    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, question: value },
      } as Node<QuestionFieldData>)
    );
  };

  // Handle question text change
  const handleQuestionPropsChange = (key: string, value: object) => {
    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, [key]: value },
      } as Node<QuestionFieldData>)
    );
  };

  const handleQuestionTypeChange = (value: string) => {
    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, type: value, option: [] },
      } as Node<QuestionFieldData>)
    );
  };

  const handleOptionChange = (value: string, i: number) => {
    const newOptions = [...options];
    newOptions[i] = { ...newOptions[i], option: value };

    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, options: newOptions },
      } as Node<QuestionFieldData>)
    );
  };

  const addOption = () => {
    const newOptions = [
      ...options,
      {
        id: `${options.length}`,
        index: options.length,
        option: "",
      } as QuestionOption,
    ];

    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, options: newOptions },
      } as Node<QuestionFieldData>)
    );
  };

  const removeOption = (i: number) => {
    const newOptions = options.filter((_: any, index: number) => index !== i);

    dispatch(
      handleQuestionUpdate({
        ...node,
        data: { ...data, options: newOptions },
      } as Node<QuestionFieldData>)
    );
  };

  return (
    <Grid container spacing={2} sx={{ my: 1 }} key={id}>
      {!["typography", "h1", "script"].includes(questionType) && (
        <Grid size={{ xs: 8 }}>
          <TextField
            inputRef={templateRef}
            label="Question"
            variant="outlined"
            multiline
            disabled={disabled}
            value={data.question}
            onChange={(e) => handleQuestionChange(e)}
            fullWidth
          />
        </Grid>
      )}
      <Grid
        size={{
          xs: !["typography", "h1", "script"].includes(questionType) ? 4 : 12,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="question-type-label">Field Type</InputLabel>
          <Select
            fullWidth
            value={questionType}
            disabled={disabled}
            labelId="question-type-label"
            id="question-type"
            label="Field Type"
            onChange={(e) => handleQuestionTypeChange(e.target.value as string)}
            size="small"
            className="nodrag"
          >
            <MenuItem value="shortAnswer">Short Answer</MenuItem>
            <MenuItem value="paragraph">Paragraph</MenuItem>
            <Divider />
            <MenuItem value="multipleChoice">Multiple Choice</MenuItem>
            <MenuItem value="checkboxes">Checkboxes</MenuItem>
            <MenuItem value="dropdown">Dropdown</MenuItem>
            <Divider />
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="time">Time</MenuItem>
            <Divider />
            <MenuItem value="event">Event Filter</MenuItem>
            <Divider />
            <MenuItem value="script">Script Box</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <RenderField
          ref={templateRef}
          questionType={questionType}
          data={data}
          disabled={disabled}
          options={options}
          handleOptionChange={handleOptionChange}
          handleQuestionChange={handleQuestionChange}
          handleQuestionPropsChange={handleQuestionPropsChange}
          addOption={addOption}
          removeOption={removeOption}
          id={id}
          isFlowNode={isFlowNode}
        />
      </Grid>
      <Popper
        open={templateOptions.length > 0}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: 9999999 }}
      >
        <Paper>
          <List>
            {templateOptions.map((option, index) => (
              <ListItemButton
                key={index}
                onClick={() => handleTemplateClick(option)}
              >
                <ListItemText primary={option} />
              </ListItemButton>
            ))}
          </List>
        </Paper>
      </Popper>
    </Grid>
  );
};

export const QuestionRedux = React.memo(QuestionReduxComponent);
