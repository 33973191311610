import { createSelector } from "@reduxjs/toolkit";
import { Volunteer as EventVolunteer } from "./models";
import { baseApi } from "./common.api";

export const event_volunteer = baseApi
  .enhanceEndpoints({ addTagTypes: ["EventVolunteers", "EventVolunteer"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEventVolunteers: builder.query<EventVolunteer[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "event_volunteer",
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "EventVolunteer" as const,
                  id,
                })),
                "EventVolunteers",
              ]
            : ["EventVolunteers"],
      }),
      getEventVolunteer: builder.query<EventVolunteer, string>({
        query: (id) => ({
          url: `event_volunteer/${id}`,
          method: "GET",
        }),
        providesTags: (result, error, arg) => [
          { type: "EventVolunteer", id: arg },
        ],
        // configuration for an individual endpoint, overriding the api setting
        keepUnusedDataFor: 90,
      }),
      createEventVolunteer: builder.mutation({
        query: (request) => ({
          url: "event_volunteer",
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["EventVolunteers"],
      }),
      updateEventVolunteer: builder.mutation<EventVolunteer, EventVolunteer>({
        query: (request) => ({
          url: `event_volunteer/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventVolunteer", id: arg.id },
        ],
      }),
      deleteEventVolunteer: builder.mutation<EventVolunteer, number>({
        query: (id) => ({
          url: `event_volunteer/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) =>
          result
            ? [{ type: "EventVolunteer" as const, arg }, "EventVolunteers"]
            : ["EventVolunteers"],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetEventVolunteersQuery,
  useGetEventVolunteerQuery,
  useCreateEventVolunteerMutation,
  useUpdateEventVolunteerMutation,
  useDeleteEventVolunteerMutation,
  usePrefetch: useEventVolunteersPrefetch,
} = event_volunteer;
const selectEventVolunteersRoot =
  event_volunteer.endpoints.getEventVolunteers.select({});
export const selectEventVolunteers = createSelector(
  selectEventVolunteersRoot,
  (event_volunteer) => event_volunteer.data || []
);
