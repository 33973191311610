import { Close, SkipNext, Update } from "@mui/icons-material";
import { Box, Button, Grid2 as Grid, IconButton } from "@mui/material";
import { NGPContactProfile } from "components/ngp/NGPContact";
import { Conversation } from "store/api/models";
import { VolunteerState } from "./agent.types";
import { useSelector } from "react-redux";
import { selectResultEntities } from "store";
import { forwardRef } from "react";

export const AgentProspectInfo = forwardRef(
  (
    {
      variant = "volunteer",
      state,
      setState,
      onSkip,
      onDisposition,
      onClose,
    }: {
      variant?: "task" | "volunteer";
      state: VolunteerState;
      setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
      onSkip: Function;
      onDisposition: Function;
      onClose: Function;
    },
    ref
  ) => {
    const conversation = useSelector(
      (state: any) => state.formBuilder.conversation
    );
    const resultEntities = useSelector(selectResultEntities);
    return (
      <Box
        sx={{
          flexGrow: 0.35,
          // borderLeft: "1px solid #ddd",
          // p: 2,
          bgcolor: "transparent",
          position: "relative", // Allows positioning of the close button
          transition: "flex-grow 1s",
          width: "5%",
          height: "calc(100vh - 85px)",
          maxHeight: "calc(100vh - 85px)",
        }}
        // elevation={5}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <NGPContactProfile
            ref={ref}
            vanid={conversation?.vanid}
            conversation={conversation}
            onClick={(conversation: Conversation) =>
              setState((prev) => ({
                ...prev,
                selectedConversation: conversation,
              }))
            }
            buttons={
              <Grid
                size={{ xs: 8 }}
                sx={{ display: "flex" }}
                justifyItems={"flex-end"}
                alignItems={"flex-end"}
                alignContent={"flex-end"}
                justifyContent={"flex-end"}
                // flexDirection={"column-reverse"}
                flexWrap={"wrap-reverse"}
              >
                {state.fromCallHistory ? (
                  <Grid>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => onDisposition()}
                      endIcon={<Update />}
                    >
                      {conversation?.result
                        ? resultEntities[conversation?.result]?.name
                        : conversation?.disposition}
                    </Button>
                  </Grid>
                ) : (
                  <Grid>
                    <Button
                      variant="outlined"
                      onClick={() => onSkip()}
                      endIcon={<SkipNext />}
                    >
                      Skip
                    </Button>
                  </Grid>
                )}
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={() =>
                      state.fromCallHistory ? onClose() : onDisposition()
                    }
                    endIcon={<Close />}
                  >
                    {state.fromCallHistory ? "Exit" : "Contact Result"}
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </Box>
      </Box>
    );
  }
);
