import { Grid2 as Grid, Link, Box } from "@mui/material";
import { routeNames } from "../config";
import * as Yup from "yup";
import { JFormik } from "forms";
import { HeaderShort } from "headers";

export interface LoginProps {
  login?: Function;
  navigate?: Function;
  img?: string;
}

export const Login = ({ login, navigate, img }: LoginProps) => {
  return (
    <Box sx={{ bgcolor: "rgba(249,255,243,1)", paddingX: { xs: 2, sm: 3 } }}>
      <HeaderShort position="fixed" />
      <Grid
        container
        direction={"column"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          minHeight: 640,
        }}
      >
        <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
          {img && (
            <a href="https://www.meckdems.org/">
              <img
                src="https://static.wixstatic.com/media/730684_370b1a730906433f9464d7a28f3bd821~mv2.png/v1/fill/w_192,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/730684_370b1a730906433f9464d7a28f3bd821~mv2.png"
                alt="Mecklenburg County Democratic Party - Home"
              />
            </a>
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 8, md: 6, lg: 5, xl: 4 }}>
          <JFormik
            initialValues={(resource: any) => ({ email: "", password: "" })}
            validationSchema={Yup.object({
              email: Yup.string()
                .required("Email is required")
                .email("Invalid email address"),
              password: Yup.string().required("Password is required"),
            })}
            handleSubmit={(values: any) =>
              login ? login(values) : console.info(values)
            }
            form={[
              {
                name: "h4",
                itemType: "typography",
                label: "Let’s Get Started!",
                jItemProps: {
                  variant: "h4",
                  sx: { textAlign: "center", fontSize: { xs: 28, sm: 34 } },
                },
              },
              {
                name: "subtitle1",
                itemType: "typography",
                label: "Log in to your account",
                jItemProps: {
                  variant: "subtitle1",
                  color: "textSecondary",
                  sx: {
                    textAlign: "center",
                    marginTop: -1,
                    marginBottom: 3,
                    lineHeight: 1.5,
                  },
                },
              },
              {
                name: "email",
                itemType: "textfield",
                label: "Email",
                jItemProps: { size: "medium" },
              },
              {
                name: "password",
                itemType: "textfield",
                jItemProps: { type: "password", size: "medium" },
                label: "Password",
              },
              {
                name: "submit",
                itemType: "button",
                jItemProps: { type: "submit" },
                label: "Sign In",
              },
            ]}
          />
          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <Link
                  component="button"
                  variant="body1"
                  sx={{ mt: 5 }}
                  onClick={() => {
                    navigate
                      ? navigate(routeNames.FORGOT_PASSWORD)
                      : console.info("forgot_password");
                  }}
                >
                  Forgot Password?
                </Link>
              </Grid>
              <Grid>
                <Link
                  component="button"
                  variant="body1"
                  sx={{ mt: 5 }}
                  onClick={() => {
                    navigate
                      ? navigate(routeNames.SIGNUP)
                      : console.info("signup");
                  }}
                >
                  Sign Up?
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
