import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Role } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Role entity
const roleAdapter = createEntityAdapter<Role, EntityId>({
  selectId: (role: Role) => role.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface RolesState extends EntityState<Role, EntityId> {
  // Add any additional state properties here if needed
}

const initialRoleState: RolesState = roleAdapter.getInitialState();

// Selectors for Roles
export const {
  selectById: selectRoleById,
  selectAll: selectAllRoles,
  selectEntities: selectRoleEntities,
  selectIds: selectRoleIds,
  selectTotal: selectRoleTotal,
} = roleAdapter.getSelectors((state: RootState) => state?.role);

export const roleSlice = createSlice({
  name: "role",
  initialState: initialRoleState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(role.endpoints.getRoles.matchFulfilled),
      (state, action) => {
        roleAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        role.endpoints.createRole.matchFulfilled,
        role.endpoints.updateRole.matchFulfilled,
        role.endpoints.patchRole.matchFulfilled
      ),
      (state, action) => {
        roleAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      role.endpoints.deleteRole.matchFulfilled,
      (state, action) => {
        roleAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const role = baseApi
  .enhanceEndpoints({ addTagTypes: ["Roles", "Role"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRoles: builder.query<Role[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "role",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Role" as const, id })),
                { type: "Roles" as const },
              ]
            : [{ type: "Roles" as const }],
      }),
      getRole: builder.query<Role, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `role/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Role", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createRole: builder.mutation<Role, Partial<Role>>({
        query: (request) => ({
          url: "role",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Roles" }],
      }),
      patchRole: builder.mutation<Role, Role>({
        query: (request) => ({
          url: `role/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Role", id: arg.id }],
      }),
      updateRole: builder.mutation<Role, Role>({
        query: (request) => ({
          url: `role/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Role", id: arg.id }],
      }),
      deleteRole: builder.mutation<void, number>({
        query: (id) => ({
          url: `role/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Role", id: arg },
          { type: "Roles" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useCreateRoleMutation,
  usePatchRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  usePrefetch: useRolesPrefetch,
} = role;
