import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Drawer } from '@mui/material';

export interface AlertDetails {
  title: string;
  content: string;
  onConfirm: Function;
}

export interface AlertDialogProps {
  open: boolean;
  title?: any;
  content?: string;
  onClose?: Function;
  onConfirm?: Function;
  onEdit?: Function;
  confirmLabel?: string;
  sx?: any;
  PaperProps?: any;
  form?: any;
  alternate?: any;
  openAlternate?: boolean;
  closeAlternate?: Function;
}

export const AlertDialog = ({
  open,
  title,
  content,
  onEdit,
  onClose = () => {},
  onConfirm,
  confirmLabel,
  PaperProps,
  sx,
  form,
  alternate,
  openAlternate,
  closeAlternate = () => {},
}: AlertDialogProps) => {
  const boxRef = React.useRef();
  const scrollRef = React.useRef<HTMLElement>(null);
  const scrollToRef = () => {
    if (scrollRef && scrollRef.current) {
      setTimeout(function () {
        scrollRef &&
          scrollRef.current &&
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    if (!!openAlternate) {
      scrollToRef();
    }
  }, [openAlternate]);

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={() => onClose()}
      sx={{ ...(sx || {}) }}
      PaperProps={PaperProps}
    >
      <Box ref={boxRef}>
        <Drawer
          PaperProps={{
            style: {
              position: 'absolute',
              border: 'none',
            },
            sx: { width: '100%' },
          }}
          ModalProps={{
            container: boxRef?.current,
            disableEnforceFocus: true,
            style: { position: 'absolute' },
          }}
          sx={{ width: '100%' }}
          variant="persistent"
          anchor="left"
          open={!!openAlternate}
          onClose={() => closeAlternate()}
        >
          <Box>
            <span ref={scrollRef}></span>
            {alternate}
          </Box>
        </Drawer>
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          {content && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{ mb: 1, whiteSpace: 'pre-wrap' }}
            >
              {content}
            </DialogContentText>
          )}
          {form}
        </DialogContent>

        <DialogActions>
          {onConfirm ? (
            <>
              <Button color="secondary" onClick={() => onClose()} autoFocus>
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                {confirmLabel || 'Confirm'}
              </Button>
            </>
          ) : onEdit ? (
            <>
              <Button
                color="secondary"
                onClick={() => {
                  onEdit();
                }}
              >
                {'Edit'}
              </Button>
              <Button color="secondary" onClick={() => onClose()} autoFocus>
                Cancel
              </Button>
            </>
          ) : (
            <Button color="secondary" onClick={() => onClose()} autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
