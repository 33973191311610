import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
  ListItemIcon,
  Paper,
  Fab,
  Chip,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  AnimationOutlined,
  AccountCircleOutlined,
  Search as SearchIcon,
  Delete,
  Edit,
  Event as EventIcon,
  Close,
  Save,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  EventGroup,
  Event,
  MobilizeEvent,
} from "store/api/mobilize/mobilize_models";
import { sortByNumber, sortString } from "utils";
import { AlertDialog } from "dialogs";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  useCreateEventGroupMutation,
  useDeleteEventGroupMutation,
  usePatchEventGroupMutation,
} from "store/api/mobilize/mobilize_event_group.api";
import { NoMaxWidthTooltip } from "components/NoMaxWidthTooltip";

export const MobilizeEventGroupDetails = ({
  disabled,
  events: initialEvents,
  event_group: initialEventGroup,
  selectedEventGroup,
  handleEventClick,
  close,
  callback = () => {},
}: {
  disabled: boolean;
  events: Event[];
  event_group: EventGroup;
  selectedEventGroup?: EventGroup;
  handleEventClick: (event: Event) => void;
  close: Function;
  callback: Function;
}) => {
  const [event_group, setEventGroup] = useState<EventGroup>(initialEventGroup);
  const [search, setSearch] = useState<string>("");
  const [searchSelected, setSelectedSearch] = useState<string>("");
  const [events, setEvents] = useState<Event[]>(
    initialEvents.filter(
      (ie) => !event_group.events?.find((eg) => eg.mobilize_id === ie.id)
    )
  );
  const [selectedEvents, setSelectedEvents] = useState<Event[]>(
    [...(event_group?.events || [])]
      ?.sort((a, b) => sortByNumber(a.sequence, b.sequence))
      ?.map((e) => e.form) || []
  );

  const [createEventGroup] = useCreateEventGroupMutation();
  const [patchEventGroup] = usePatchEventGroupMutation();

  const remove = (list: number) => {
    const removedList = selectedEvents?.find((pl) => pl.id === list);
    if (removedList) {
      setEvents((prevLists) => [...prevLists, removedList]);
      setSelectedEvents((prev) => prev?.filter((p) => p.id !== list));
    }
  };

  // Handle dragging and dropping events between lists
  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // If there is no destination (dropped outside the list), do nothing
    if (!destination) return;

    // Handle dragging from left to right list (removing from left and adding to right)
    if (
      source.droppableId === "left-events" &&
      destination.droppableId === "right-events"
    ) {
      const draggedEventGroup = events.find(
        (event) => `${event.id}` === result.draggableId
      );

      // Remove the dragged event from the left list
      const updatedEventGroups = events.filter(
        (event) => `${event.id}` !== result.draggableId
      );

      // Add the dragged event to the right list if it's not already there
      if (draggedEventGroup) {
        setSelectedEvents((prev) => {
          const reorderedEventGroups = Array.from(prev);
          reorderedEventGroups.splice(destination.index, 0, draggedEventGroup);
          return reorderedEventGroups;
        });
        setEvents(updatedEventGroups);
      }
    }

    // Handle reordering within the right list
    if (
      source.droppableId === "right-events" &&
      destination.droppableId === "right-events"
    ) {
      setSelectedEvents((prev) => {
        const reorderedEventGroups = Array.from(prev);
        const [movedEventGroup] = reorderedEventGroups.splice(source.index, 1);
        reorderedEventGroups.splice(destination.index, 0, movedEventGroup);
        return reorderedEventGroups;
      });
    }
  };
  const [newListName, setNewListName] = useState<string>(
    event_group?.name || ""
  ); // Track the new list name
  const filteredEvents = [...(events || [])]
    ?.filter((e) => {
      return e.title?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.title, b.title));
  const filteredSelectedEvents = [...(selectedEvents || [])]?.filter((e) => {
    return e.title?.toLowerCase()?.includes(searchSelected.toLowerCase());
  });
  // ?.sort((a, b) => sortString(a.title, b.title));
  return (
    <Box
      key={event_group.id}
      sx={{
        height: "100%",
        maxHeight: "100%",
        position: "relative",
        maxWidth: "100%",
        width: "100%",
        // display: "flex",
        // flexDirection: "column",
      }}
    >
      {!disabled && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
          onClick={async () => {
            try {
              if (event_group?.id) {
                const res = await patchEventGroup({
                  id: event_group.id,
                  name: newListName,
                  events: selectedEvents.map(
                    (e, i) =>
                      ({
                        mobilize_id: e.id,
                        sequence: i,
                        form: { ...e } as Event,
                      } as MobilizeEvent)
                  ),
                } as EventGroup).unwrap();
                await callback();
                if (res.id) {
                  setEventGroup(res);
                  toast.success("Event group updated!");
                  close();
                }
              } else {
                const res = await createEventGroup({
                  name: newListName,
                  events: selectedEvents.map(
                    (e, i) =>
                      ({
                        mobilize_id: e.id,
                        sequence: i,
                        form: { ...e } as Event,
                      } as MobilizeEvent)
                  ),
                }).unwrap();
                await callback();
                if (res.id) {
                  setEventGroup(res);
                  toast.success("Event group created!");
                  close();
                }
              }
            } catch (error) {
              toast.error("Error creating event group");
            }
          }}
        >
          <Save />
        </Fab>
      )}
      <Box
        sx={{
          height: "100%",
          maxHeight: "100%",
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <IconButton
          onClick={() => close()}
          sx={{
            position: "absolute",
            top: 4,
            right: 8,
            zIndex: (theme) => theme.zIndex.fab,
          }}
        >
          <Close />
        </IconButton>

        <Paper
          sx={{ padding: 1, marginBottom: 1, maxWidth: "100%", width: "100%" }}
        >
          <Typography variant="h6">EVENT GROUP</Typography>
          <TextField
            disabled={disabled}
            size="small"
            label="Group Name"
            fullWidth
            variant="outlined"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
        </Paper>
        <Box
          sx={{
            flexGrow: 3,
            height: "100%",
            maxHeight: "100%",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "89%",
              overflowY: "scroll",
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  flexGrow: 1,
                  width: "100%",
                  maxWidth: "100%",
                  height: "100%",
                  maxHeight: "100%",
                }}
              >
                {/* Left column: Events to drag */}
                <Paper
                  sx={{
                    height: "100%",
                    maxHeight: "100%",
                    width: "49%",
                    maxWidth: "49%",
                    p: 1,
                  }}
                >
                  <Grid
                    container
                    alignItems={"bottom"}
                    alignContent={"bottom"}
                    justifyContent={"space-between"}
                    spacing={0}
                    sx={{ mb: 1 }}
                  >
                    <Grid alignSelf={"bottom"} alignContent={"end"}>
                      <Typography
                        variant="caption"
                        component={"span"}
                        sx={{ color: "black", pl: 1 }}
                      >
                        AVAILABLE MOBILIZE EVENTS
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        alignItems={"bottom"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid sx={{ textAlign: "right" }}>
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            placeholder="Search events"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(event) =>
                              setSearch((event?.target as any)?.value)
                            }
                            sx={{ maxWidth: { xs: "none", sm: 340 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ height: "100%", maxHeight: "100%" }}>
                    <Droppable
                      droppableId="left-events"
                      isDropDisabled={disabled}
                    >
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{
                            height: "100%",
                            maxHeight: "100%",
                            overflowY: "scroll",
                          }}
                          dense
                          disablePadding
                        >
                          {filteredEvents?.map(
                            (event: Event, index: number) => (
                              <Draggable
                                key={event.id}
                                draggableId={`${event.id}`}
                                index={index}
                                isDragDisabled={disabled}
                              >
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={event.id}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: 1,
                                      marginBottom: 1,
                                      border: "1px solid #ddd",
                                      borderRadius: 1,
                                    }}
                                    dense
                                  >
                                    <ListItemAvatar>
                                      <Chip
                                        label={event.event_type}
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ zoom: "75%", mr: 1 }}
                                      />
                                    </ListItemAvatar>
                                    <NoMaxWidthTooltip title={event.title}>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="overline"
                                            sx={{
                                              fontWeight: 700,
                                              width: "100%",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            {event.title}
                                          </Typography>
                                        }
                                        // secondary={`${event.contact_r?.email}`}
                                        onClick={() => handleEventClick(event)}
                                        sx={{
                                          cursor: "pointer",
                                          py: 0,
                                          my: 0,
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "100%",
                                        }}
                                      />
                                    </NoMaxWidthTooltip>
                                  </ListItem>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Box>
                </Paper>
                {/* Right column with reorderable list */}
                <Paper
                  elevation={3}
                  sx={{
                    width: "50%",
                    maxWidth: "50%",
                    padding: 1,
                    minHeight: "100%",
                  }}
                >
                  <Grid
                    container
                    alignItems={"bottom"}
                    justifyContent={"space-between"}
                    spacing={0}
                    sx={{ mb: 1 }}
                  >
                    <Grid alignContent={"end"}>
                      <Typography
                        variant="caption"
                        component={"span"}
                        sx={{ color: "black", pl: 1 }}
                      >
                        SELECTED EVENTS
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        alignItems={"bottom"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid sx={{ textAlign: "right" }}>
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            placeholder="Search events"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(event) =>
                              setSelectedSearch((event?.target as any)?.value)
                            }
                            sx={{ maxWidth: { xs: "none", sm: 340 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ height: "100%", maxHeight: "100%" }}>
                    <Droppable
                      droppableId="right-events"
                      isDropDisabled={disabled}
                    >
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{
                            height: "100%",
                            maxHeight: "100%",
                            overflowY: "scroll",
                          }}
                          dense
                          disablePadding
                        >
                          {filteredSelectedEvents.map((event, index) => (
                            <Draggable
                              key={event.id}
                              draggableId={`${event.id}`}
                              index={index}
                              isDragDisabled={disabled}
                            >
                              {(provided) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    padding: 1,
                                    marginBottom: 1,
                                    border: "1px solid #ddd",
                                    borderRadius: 1,
                                  }}
                                  dense
                                  secondaryAction={
                                    !disabled && (
                                      <IconButton
                                        onClick={() => remove(event.id)}
                                      >
                                        <Close color="secondary" />
                                      </IconButton>
                                    )
                                  }
                                >
                                  <ListItemAvatar>
                                    <Chip
                                      label={event.event_type}
                                      size="small"
                                      variant="outlined"
                                      color="secondary"
                                      sx={{ zoom: "75%", mr: 1 }}
                                    />
                                  </ListItemAvatar>

                                  <NoMaxWidthTooltip title={event.title}>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="overline"
                                          sx={{
                                            fontWeight: 700,
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          {event.title}
                                        </Typography>
                                      }
                                      // secondary={`${event.contact_r?.email}`}
                                      onClick={() => handleEventClick(event)}
                                      sx={{
                                        cursor: "pointer",
                                        py: 0,
                                        my: 0,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </NoMaxWidthTooltip>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Box>
                </Paper>
              </Box>
            </DragDropContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
