import { useEffect } from "react";
import {
  useContactsPrefetch,
  useEventsPrefetch,
  useEventTypesPrefetch,
  useRolesPrefetch,
  useSkillsPrefetch,
  useTasksPrefetch,
  useVolunteersPrefetch,
} from "store";
import { useMobilizeEventsPrefetch } from "store/api/mobilize/mobilize.api";
import { useEventGroupsPrefetch } from "store/api/mobilize/mobilize_event_group.api";
import { useProspectsPrefetch } from "store/api/prospect.api";
import { useGetSchemaQueryPrefetch } from "store/api/ngp/schema.api";
import { useProspectListsPrefetch } from "store/api/prospect_list.api";
import { useResultsPrefetch } from "store/api/result.api";
import { useTaskGroupPrefetch } from "store/api/task_group.api";
import { useTaskProspectListsPrefetch } from "store/api/task_prospect_list.api";

export const usePreloadData = (admin?: boolean) => {
  const getSchema = useGetSchemaQueryPrefetch("getSchema");
  const getContacts = useContactsPrefetch("getContacts");
  const getEventTypes = useEventTypesPrefetch("getEventTypes");
  const getRoles = useRolesPrefetch("getRoles");
  const getSkills = useSkillsPrefetch("getSkills");
  const getEvents = useEventsPrefetch("getEvents");
  const getEventGroups = useEventGroupsPrefetch("getEventGroups");
  const getVolunteers = useVolunteersPrefetch("getVolunteers");
  const getProspects = useProspectsPrefetch("getProspects");
  const getTasks = useTasksPrefetch("getTasks");
  const getTaskProspectLists = useTaskProspectListsPrefetch(
    "getTaskProspectLists"
  );
  const getTaskGroups = useTaskGroupPrefetch("getTaskGroups");
  const getProspectLists = useProspectListsPrefetch("getProspectLists");
  const getMobilizeEvents = useMobilizeEventsPrefetch("getMobilizeEvents");
  const getResults = useResultsPrefetch("getResults");

  useEffect(() => {
    if (admin) {
      getVolunteers({ params: { nested: "normalize" } });
      getProspects({ params: { nested: "all" } });
      getContacts({});
      getTasks({ params: { nested: "normalize" } });
      getTaskGroups({ params: { nested: "all" } });
      getTaskProspectLists({ params: { nested: "normalize" } });
      getProspectLists({ params: { nested: "normalize" } });
      getSchema({});
    }
    getEventTypes({});
    getEvents({ params: { nested: "all" } });
    getEventGroups({ params: { nested: "all" } });
    getRoles({});
    getSkills({});
    getMobilizeEvents({});
    getResults({});
  }, [
    admin,
    getVolunteers,
    getEvents,
    getEventGroups,
    getContacts,
    getEventTypes,
    getTasks,
    getTaskGroups,
    getRoles,
    getSkills,
    getTaskProspectLists,
    getProspectLists,
    getMobilizeEvents,
    getSchema,
    getResults,
    getProspects,
  ]);
};
