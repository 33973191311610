import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Fab,
} from "@mui/material";
import {
  Add,
  EmojiPeopleOutlined,
  EventOutlined,
  Search as SearchIcon,
} from "@mui/icons-material";
import { sortString } from "utils";
import { Attendance, Event } from "store/api/mobilize/mobilize_models";
import { AttendanceDetails } from "./AttendanceDetails";

export const AttendanceList = ({
  attendances,
  callback,
  event,
}: {
  event: Event;
  attendances: Attendance[];
  callback: Function;
}) => {
  const [selectedAttendance, setSelectedAttendance] = useState<Attendance>();
  const [search, setSearch] = useState<string>("");
  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);
  const handleOpen = () => setOpenSpeedDial(true);
  const handleClose = () => setOpenSpeedDial(false);

  const filteredAttendances = [...(attendances || [])]
    ?.filter(
      (v) =>
        v.person?.given_name?.toLowerCase()?.includes(search.toLowerCase()) ||
        v.person?.family_name?.toLowerCase()?.includes(search.toLowerCase()) ||
        v.person?.email_address?.toLowerCase()?.includes(search.toLowerCase())
    )
    ?.sort((a, b) =>
      sortString(
        `${a.person?.given_name} ${a.person?.family_name} ${a.person?.email_address}`,
        `${b.person?.given_name} ${b.person?.family_name} ${b.person?.email_address}`
      )
    );

  const ListAttendance = ({ attendance }: { attendance: Attendance }) => (
    <>
      <ListItem
        key={attendance.id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: (theme) =>
            selectedAttendance && selectedAttendance?.id === attendance.id
              ? theme.palette.divider
              : undefined,
        }}
        dense
      >
        <ListItemText
          primary={
            <Typography variant="overline" sx={{ fontWeight: 700 }}>
              {`${attendance.person?.given_name} ${attendance.person?.family_name}`}
              <Typography
                variant="overline"
                component={"small"}
                sx={{ color: (theme) => theme.palette.primary.main, ml: 1 }}
              >
                <small>({attendance.status})</small>
              </Typography>
              {attendance.referrer?.utm_source && (
                <Typography
                  variant="overline"
                  component={"small"}
                  sx={{ color: (theme) => theme.palette.secondary.main, ml: 1 }}
                >
                  <small>({attendance.referrer?.utm_source})</small>
                </Typography>
              )}
            </Typography>
          }
          onClick={() => setSelectedAttendance(attendance)}
          sx={{ cursor: "pointer", py: 0, my: 0 }}
        />
      </ListItem>
      <Divider />
    </>
  );
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {selectedAttendance ? (
        <AttendanceDetails
          attendance={selectedAttendance}
          event={event}
          disabled={!!selectedAttendance.id}
          callback={async () => {
            await callback();
            setSelectedAttendance(undefined);
          }}
          close={() => setSelectedAttendance(undefined)}
        />
      ) : (
        <Box
          sx={{
            // flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxHeight: "100%",
            position: "relative",
          }}
        >
          <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 }, flexGrow: 1 }}>
            <Grid
              container
              alignItems={"center"}
              justifyItems={"center"}
              alignContent={"center"}
              justifyContent={"space-between"}
              spacing={0}
              sx={{ my: 1 }}
            >
              <Grid>
                <Typography
                  variant="h6"
                  component={"span"}
                  sx={{ color: "black", pl: 1 }}
                >
                  ATTENDANCE LIST ({attendances.length})
                </Typography>
              </Grid>
              <Grid>
                <Grid
                  container
                  alignItems={"bottom"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Grid sx={{ textAlign: "right" }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Search Attendances"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        },
                      }}
                      onChange={(event) =>
                        setSearch((event?.target as any)?.value)
                      }
                      sx={{ maxWidth: { xs: "none", sm: 340 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: "100%", flexGrow: 3 }}>
            <List
              dense
              disablePadding
              sx={{
                maxHeight: "100%",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {filteredAttendances?.map((attendance: Attendance) => (
                <ListAttendance attendance={attendance} />
              ))}
            </List>
          </Box>
        </Box>
      )}
      <Fab
        sx={{
          position: "absolute",
          left: 10,
          bottom: 50,
        }}
        color="primary"
        onClick={() => {
          // setEditing(true);
          setSelectedAttendance({} as Attendance);
          handleClose();
        }}
      >
        <Add />
      </Fab>
    </Box>
  );
};
