import { useEffect, useState } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Paper } from "@mui/material";
import "./VisGLMap.css";

interface LatLng {
  label: string;
  coordinates?: google.maps.LatLngLiteral;
  address?: string;
}

interface Markers {
  centralLocation: LatLng;
  otherLocations?: LatLng[];
  locationCallback?: (location: google.maps.LatLngLiteral) => void;
}

export const VisMap = ({
  markers,
  active = false,
}: {
  markers: Markers;
  active?: boolean;
}) => {
  const [label, setLabel] = useState<number>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const {
    centralLocation,
    otherLocations,
    locationCallback = () => {},
  } = markers;
  const handleMouseOver = (index: number) => {
    setLabel(index); // Show label on hover
  };

  const handleMouseOut = () => {
    setLabel(undefined); // Hide label when not hovering
  };
  useEffect(() => {
    if (!active) return;
    if (centralLocation.address && centralLocation.address !== "") {
      const geocodeAddress = async () => {
        const encodedAddress = encodeURIComponent(
          centralLocation.address || ""
        );
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyBV5FmD0lsW0p9DXnz01l9CEcWPSho-e80`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setCenter(location);
          locationCallback(location);
        } else {
          console.error("Geocoding API error:", data);
        }
      };
      geocodeAddress();
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          locationCallback({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          // setLocation({
          //   lat: position.coords.latitude,
          //   lng: position.coords.longitude,
          // });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [centralLocation.address, active]);
  // if (!centralLocation.address || centralLocation.address === "") {
  //   return <div />;
  // }
  if (!center) {
    return <div>Loading map...</div>;
  }
  return (
    <Paper elevation={5} sx={{ height: "325px", width: "100%", marginTop: 4 }}>
      <APIProvider apiKey={"AIzaSyBV5FmD0lsW0p9DXnz01l9CEcWPSho-e80"}>
        <Map defaultZoom={12} defaultCenter={center} center={center}>
          <Marker
            position={center}
            onMouseOver={() => handleMouseOver(99999)}
            onMouseOut={handleMouseOut}
            label={
              label === 99999
                ? {
                    text: centralLocation.label,
                    fontSize: "16px",
                    color: "blue",
                    // Adding white background and padding for the label
                    className: "custom-label",
                  }
                : ""
            }
          />
          {otherLocations?.map((m, index) => (
            <Marker
              key={index}
              position={m.coordinates}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
              label={
                label === index
                  ? {
                      text: m.label,
                      fontSize: "16px",
                      color: "blue",
                      // Adding white background and padding for the label
                      className: "custom-label",
                    }
                  : ""
              }
            />
          ))}
        </Map>
      </APIProvider>
    </Paper>
  );
};
