import * as Yup from "yup";
import { MailOutlineOutlined } from "@mui/icons-material";
import { JFormikData } from "forms/jformik";

export const useLoginForm = (): JFormikData => {
  return {
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
    form: [
      {
        name: "email",
        label: "Email *",
        itemType: "textfield",
        gridProps: { size: { xs: 12 } },
        jItemProps: {
          InputProps: {
            endAdornment: <MailOutlineOutlined color="secondary" />,
          },
        },
      },
      // {
      //   name: "admin",
      //   label: "Admin",
      //   itemType: "autocomplete",
      //   defaultValue: "YES",
      //   options: ["YES", "NO"],
      //   gridProps: { size: { xs: 12 } },
      // },
      {
        name: "submit",
        itemType: "button",
        jItemProps: { type: "submit" },
        label: "SAVE",
        gridProps: { size: { xs: 12 } },
      },
    ],
  } as JFormikData;
};
