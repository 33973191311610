import { useMediaQuery, useTheme } from '@mui/material';

import { MenuItem } from './HeaderShort';

type UseHeaderShortMenuItemsProps = {
  menuItems: MenuItem[];
};

export const useHeaderShortMenuItems = ({
  menuItems,
}: UseHeaderShortMenuItemsProps) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const buttonItems = menuItems.filter(({ type = 'listitem' }) =>
    downSm ? false : type === 'buttonitem'
  );

  const listItems = menuItems
    .filter(({ type = 'listitem' }) => (downSm ? true : type === 'listitem'))
    .map(({ icon: _icon, label, onClick }) => {
      const Icon = _icon as any;

      return { icon: Icon ? <Icon fontSize="small" /> : null, label, onClick };
    });

  return { buttonItems, listItems };
};
