import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { TaskSkill } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the TaskSkill entity
const taskSkillAdapter = createEntityAdapter<TaskSkill, EntityId>({
  selectId: (task_skill: TaskSkill) => task_skill.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface TaskSkillsState extends EntityState<TaskSkill, EntityId> {
  // Add any additional state properties here if needed
}

const initialTaskSkillState: TaskSkillsState =
  taskSkillAdapter.getInitialState();

// Selectors for TaskSkills
export const {
  selectById: selectTaskSkillById,
  selectAll: selectAllTaskSkills,
  selectEntities: selectTaskSkillEntities,
  selectIds: selectTaskSkillIds,
  selectTotal: selectTaskSkillTotal,
} = taskSkillAdapter.getSelectors((state: RootState) => state?.task_skill);

export const taskSkillSlice = createSlice({
  name: "task_skill",
  initialState: initialTaskSkillState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(task_skill.endpoints.getTaskSkills.matchFulfilled),
      (state, action) => {
        taskSkillAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        task_skill.endpoints.createTaskSkill.matchFulfilled,
        task_skill.endpoints.updateTaskSkill.matchFulfilled,
        task_skill.endpoints.patchTaskSkill.matchFulfilled
      ),
      (state, action) => {
        taskSkillAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      task_skill.endpoints.deleteTaskSkill.matchFulfilled,
      (state, action) => {
        taskSkillAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

export const task_skill = baseApi
  .enhanceEndpoints({ addTagTypes: ["TaskSkills", "TaskSkill"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTaskSkills: builder.query<TaskSkill[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "task_skill",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "TaskSkill" as const,
                  id,
                })),
                { type: "TaskSkills" as const },
              ]
            : [{ type: "TaskSkills" as const }],
      }),
      getTaskSkill: builder.query<TaskSkill, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `task_skill/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "TaskSkill", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createTaskSkill: builder.mutation<TaskSkill, Partial<TaskSkill>>({
        query: (request) => ({
          url: "task_skill",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "TaskSkills" }],
      }),
      patchTaskSkill: builder.mutation<TaskSkill, TaskSkill>({
        query: (request) => ({
          url: `task_skill/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskSkill", id: arg.id },
        ],
      }),
      updateTaskSkill: builder.mutation<TaskSkill, TaskSkill>({
        query: (request) => ({
          url: `task_skill/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskSkill", id: arg.id },
        ],
      }),
      deleteTaskSkill: builder.mutation<void, number>({
        query: (id) => ({
          url: `task_skill/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "TaskSkill", id: arg },
          { type: "TaskSkills" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useCreateTaskSkillMutation,
  useGetTaskSkillQuery,
  useDeleteTaskSkillMutation,
  useGetTaskSkillsQuery,
  usePatchTaskSkillMutation,
  useUpdateTaskSkillMutation,
} = task_skill;
