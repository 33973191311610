import { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  Tooltip,
  ListItemIcon,
  Grid2,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete,
  Edit,
  ContentCopy,
  Description,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Script } from "store/api/models";
import {
  selectScriptEntities,
  useDeleteScriptMutation,
} from "store/api/script.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";
import { FormattedList } from "components/list/FormattedList";
import { useSelector } from "react-redux";

export const ScriptList = ({
  scripts,
  selectedScriptId,
  handleDuplicateScript = () => {},
  close,
  handleScriptClick,
  handleScriptEditClick,
}: {
  scripts: Script[];
  selectedScriptId?: number;
  handleDuplicateScript?: (id: number) => void;
  close: Function;
  handleScriptClick: (id: number) => void;
  handleScriptEditClick: (id: number) => void;
}) => {
  // const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [deleteScript] = useDeleteScriptMutation();
  const scriptEntities = useSelector(selectScriptEntities);
  const filteredScripts = [...(scripts || [])]
    ?.filter((t) => {
      return !t.title || t.title?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.title, b.title));
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <Box
        id="topflex"
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          height: "100%",
        }}
      >
        {/* <AlertDialog
          open={!!openAlert}
          title={`Delete script`}
          content={`Are you sure you want to delete this script?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteScript(openAlert);
                close();
                toast.success("Script successfully deleted");
              } catch (error) {
                toast.success("Unable to delete script");
              }
            }
            setOpenAlert(undefined);
          }}
        /> */}
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
            sx={{ px: 1 }}
          >
            <Grid>
              <Typography variant="h6" component={"span"}>
                SCRIPT LIST
              </Typography>
            </Grid>
            <Grid>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                {/* <Grid>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={(event: any, value: any) => setView(value)}
                >
                  <ToggleButton value="script">
                    <PersonOutlineOutlined />
                  </ToggleButton>
                  <ToggleButton value="skill">
                    <AnimationOutlined />
                  </ToggleButton>
                  <ToggleButton value="role">
                    <AccountCircleOutlined />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid> */}
                <Grid sx={{ textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Scripts"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    sx={{ maxWidth: { xs: "none", sm: 340 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <FormattedList
          listItems={filteredScripts?.map((s) => s.id) || []}
          onItemClick={(id: number) => handleScriptClick(id)}
          editItem={(id: number) => handleScriptEditClick(id)}
          deleteItem={(id: number) => deleteScript(id)}
          // duplicateItem={(id: number) => handleDuplicateScript(id)}
          primary={(id: number) => scriptEntities?.[id]?.title || "untitled"}
          icon={
            <Description
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
              fontSize="small"
            />
          }
          onClose={() => close()}
          selectedId={selectedScriptId}
          itemName="script"
        />
        {/* <List
          sx={{
            maxHeight: "100%",
            overflowY: "scroll",
            px: 1,
          }}
        >
          {filteredScripts?.map((script: Script) => (
            <ListItem
              key={script.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor: (theme) =>
                  selectedScript && selectedScript?.id === script.id
                    ? theme.palette.divider
                    : undefined,
                borderRadius: "4px",
                boxShadow: (theme) => theme.shadows[3],
              }}
              dense
            >
              <Grid2
                container
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "unset", mr: 2 }}>
                    <Description
                      sx={{
                        color: (theme) => theme.palette.secondary.main,
                      }}
                      fontSize="small"
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <Typography variant="overline" sx={{ fontWeight: 700 }}>
                        {script.title || "untitled"}
                      </Typography>
                    }
                    // secondary={`${script.contact_r?.email}`}
                    onClick={() => handleScriptClick(script)}
                    sx={{ cursor: "pointer", py: 0, my: 0 }}
                  />
                </Box>
                <Grid2>
                  <Box
                    sx={{
                      borderRadius: "4px",
                      border: (theme) =>
                        `1px solid ${theme.palette.secondary.main}`,
                    }}
                  >
                    <Tooltip title={"duplicate"}>
                      <IconButton
                        onClick={() =>
                          handleDuplicateScript({
                            title: "",
                            form: { ...(script.form || {}) },
                          } as Script)
                        }
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit"}>
                      <IconButton onClick={() => handleScriptEditClick(script)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete"}>
                      {openAlert && openAlert === script.id ? (
                        <IconButton>
                          <CircularProgress size={22} />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setOpenAlert(script.id)}>
                          <Delete />
                        </IconButton>
                      )}
                    </Tooltip>
                  </Box>
                </Grid2>
              </Grid2>
            </ListItem>
          ))}
        </List> */}
      </Box>
    </Box>
  );
};
