import { useState, useRef } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectUser,
  useChangePwMutation,
  useGetContactQuery,
  useUpdateProfileMutation,
} from "store/api";
import {
  Grid2 as Grid,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Save, Edit } from "@mui/icons-material";
import { JFormik } from "forms";
import { useVolunteerForm } from "forms/forms/useVolunteerForm";
import { Loading } from "components/Loading";
import { useAdminProfileForm } from "forms/forms/useAdminProfileForm";
import { useLogout } from "utils/logout.utils";

export const MyAccountTab = () => {
  const user = useSelector(selectUser);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<any>();
  const { data: contact, isLoading } = useGetContactQuery(
    { id: user.contact },
    { skip: !user.contact || user.admin }
  );
  const [updateProfile, updateProfileResults] = useUpdateProfileMutation();
  const [changepw] = useChangePwMutation();
  const volunteerForm = useVolunteerForm();
  const adminProfileForm = useAdminProfileForm();
  const onLogout = useLogout();
  if ((!contact || isLoading) && !user.admin) {
    return <Loading />;
  } else {
    return (
      <Grid container spacing={2} size={{ xs: 12 }}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h6" align="center">
            Account Information
            <Tooltip title={isEditing ? "Save" : "Edit"}>
              <IconButton
                color="primary"
                onClick={async () => {
                  if (isEditing) {
                    setEditing(false);
                    if (formRef.current) {
                      formRef.current.submitForm();
                    }
                  } else {
                    setEditing(true);
                  }
                }}
                sx={{ ml: 1 }}
              >
                {updateProfileResults.isLoading || loading ? (
                  <CircularProgress sx={{ fontSize: "0.5rem" }} />
                ) : isEditing ? (
                  <Save fontSize="small" />
                ) : (
                  <Edit fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography variant="subtitle1" align="center" color="textSecondary">
            To edit you account info click the edit icon above, make your
            changes then click the save icon.
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="warning"
            sx={{ fontWeight: "bold" }}
          >
            NOTE: You will be forced to logout and back in.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <JFormik
            disabled={!isEditing}
            formRef={formRef}
            loading={isLoading}
            enableReinitialize={true}
            initialValues={() =>
              user.admin
                ? { email: user.email }
                : {
                    ...contact,
                    id: user.volunteer,
                    name: `${contact?.firstname} ${contact?.lastname}`,
                  }
            }
            handleSubmit={async (values, formik) => {
              try {
                setEditing(false);
                setLoading(true);
                const res = await updateProfile(values);
                if ("error" in res) {
                  toast.error(
                    "Unable to update account info, please try again"
                  );
                } else {
                  toast.success("Account info update successful");
                  onLogout();
                }
                setLoading(false);
              } catch (error) {
                toast.error("Unable to update account info, please try again");
                setLoading(false);
              }
            }}
            validationSchema={
              user.admin
                ? adminProfileForm.validationSchema
                : volunteerForm.validationSchema
            }
            form={user.admin ? adminProfileForm.form : volunteerForm.form}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ mt: 3 }}>
          <JFormik
            initialValues={(resource: any) =>
              ({ password: "", passwordConfirm: "" } as any)
            }
            validationSchema={Yup.object({
              password: Yup.string()
                .nullable()
                .required("Password is required"),
              passwordConfirm: Yup.string()
                .oneOf(
                  [Yup.ref("password"), undefined],
                  "Please make sure your passwords match"
                )
                .required("Confirm password is required"),
            })}
            handleSubmit={async (values, formik) => {
              try {
                const res = await changepw(values);
                if ("error" in res) {
                  toast.error("Unable to change password, please try again");
                } else {
                  toast.success("Password change successful");
                }
              } catch (error) {
                toast.error("Unable to change password, please try again");
              }
              formik.resetForm();
            }}
            form={[
              {
                name: "h6",
                itemType: "typography",
                label: "Change Password",
                jItemProps: { variant: "h6", align: "center" },
              },
              {
                name: "subtitle1",
                itemType: "typography",
                label: `If you want to change your password please enter it below and click submit`,
                jItemProps: {
                  variant: "subtitle1",
                  align: "center",
                  color: "textSecondary",
                },
              },
              {
                name: "password",
                itemType: "textfield",
                jItemProps: { type: "password" },
                label: "Password",
              },
              {
                name: "passwordConfirm",
                itemType: "textfield",
                jItemProps: { type: "password" },
                label: "Password Confirm",
              },
              {
                name: "submit",
                itemType: "button",
                jItemProps: { type: "submit" },
                label: "Submit",
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  }
};
