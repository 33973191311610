import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { ProspectList } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the ProspectList entity
const prospectListAdapter = createEntityAdapter<ProspectList, EntityId>({
  selectId: (prospect_list: ProspectList) => prospect_list.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface ProspectListsState extends EntityState<ProspectList, EntityId> {
  // Add any additional state properties here if needed
}

const initialProspectListState: ProspectListsState =
  prospectListAdapter.getInitialState();

// Selectors for ProspectLists
export const {
  selectById: selectProspectListById,
  selectAll: selectAllProspectLists,
  selectEntities: selectProspectListEntities,
  selectIds: selectProspectListIds,
  selectTotal: selectProspectListTotal,
} = prospectListAdapter.getSelectors(
  (state: RootState) => state?.prospect_list
);

export const prospectListSlice = createSlice({
  name: "prospect_list",
  initialState: initialProspectListState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(prospect_list.endpoints.getProspectLists.matchFulfilled),
      (state, action) => {
        prospectListAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        prospect_list.endpoints.createProspectList.matchFulfilled,
        prospect_list.endpoints.updateProspectList.matchFulfilled,
        prospect_list.endpoints.patchProspectList.matchFulfilled
      ),
      (state, action) => {
        prospectListAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      prospect_list.endpoints.deleteProspectList.matchFulfilled,
      (state, action) => {
        prospectListAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const prospect_list = baseApi
  .enhanceEndpoints({ addTagTypes: ["ProspectLists", "ProspectList"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProspectLists: builder.query<ProspectList[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "prospect_list",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "ProspectList" as const,
                  id,
                })),
                { type: "ProspectLists" as const },
              ]
            : [{ type: "ProspectLists" as const }],
      }),
      getProspectList: builder.query<
        ProspectList,
        { id: number; params?: object }
      >({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `prospect_list/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "ProspectList", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createProspectList: builder.mutation<ProspectList, Partial<ProspectList>>(
        {
          query: (request) => ({
            url: "prospect_list",
            method: "POST",
            body: request,
          }),
          invalidatesTags: [{ type: "ProspectLists" }],
        }
      ),
      patchProspectList: builder.mutation<ProspectList, ProspectList>({
        query: (request) => ({
          url: `prospect_list/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "ProspectList", id: arg.id },
        ],
      }),
      updateProspectList: builder.mutation<ProspectList, ProspectList>({
        query: (request) => ({
          url: `prospect_list/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "ProspectList", id: arg.id },
        ],
      }),
      deleteProspectList: builder.mutation<void, number>({
        query: (id) => ({
          url: `prospect_list/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "ProspectList", id: arg },
          { type: "ProspectLists" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetProspectListsQuery,
  useGetProspectListQuery,
  useLazyGetProspectListQuery,
  useCreateProspectListMutation,
  usePatchProspectListMutation,
  useUpdateProspectListMutation,
  useDeleteProspectListMutation,
  usePrefetch: useProspectListsPrefetch,
} = prospect_list;
