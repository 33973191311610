import { AccountBox, Logout, MenuOutlined } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Box,
  Typography,
  Avatar,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
} from "@mui/material";
import { stringToColour } from "pages/AdminIndex";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "store";
import { Conversation, Skill, Volunteer } from "store/api/models";
import { useLogout } from "utils/logout.utils";

// Center the title in the toolbar
const ToolbarStyled = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const AgentAppBar = ({
  isMenuOpen,
  setIsMenuOpen,
  setOpenAccountDrawer,
}: // volunteer,
// state,
// setState,
{
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAccountDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  // volunteer?: Volunteer;
  // state: VolunteerState;
  // setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
}) => {
  const onLogout = useLogout();
  const user = useSelector(selectUser);
  const [anchorElAvatar, setAnchorElAvatar] = useState<null | HTMLElement>(
    null
  );

  // const [openAccountDrawer, setOpenAccountDrawer] = useState<boolean>(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // Handle avatar menu open/close
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvatar(event.currentTarget);
  };
  const handleAvatarClose = () => {
    setAnchorElAvatar(null);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#22335A",
        borderBottom: (theme) => `1px solid ${theme.palette.primary.light}`,
      }}
    >
      <ToolbarStyled>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMenu}
          sx={{ mr: 2 }}
        >
          <MenuOutlined sx={{ color: "white" }} />
        </IconButton>
        {/* Centered Title with Icon */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://static.wixstatic.com/media/730684_370b1a730906433f9464d7a28f3bd821~mv2.png/v1/fill/w_384,h_112,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/730684_370b1a730906433f9464d7a28f3bd821~mv2.png"
            style={{ maxHeight: "60px" }}
          />
          <Typography
            variant="h6"
            sx={{
              color: "white",
              py: 0.2,
              px: 1,
              fontStretch: "3px",
              borderRadius: "4px",
              border: (theme) => `4px solid ${theme.palette.primary.light}`,
              fontWeight: "700",
            }}
          >
            VOLUNTIZE
          </Typography>
        </Box>
        {/* Right-side Icons */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Notifications Icon with Badge */}
          {/* <IconButton color="inherit" onClick={handleNotificationsClick}>
          <Badge badgeContent={0} color="info">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
          {/* <Menu
          anchorEl={anchorElNotifications}
          open={Boolean(anchorElNotifications)}
          onClose={handleNotificationsClose}
        >
          <MenuList>
            <MenuItem onClick={handleNotificationsClose}>
              <ListItemIcon>
                <NotificationsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Notifications 1</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleNotificationsClose}>
              <ListItemIcon>
                <NotificationsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Notifications 2</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleNotificationsClose}>
              <ListItemIcon>
                <NotificationsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Notifications 3</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu> */}
          {/* Avatar Icon */}
          {/* <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent=" "
          // variant="dot"
          overlap="circular"
          color={active ? "success" : "error"}
          slotProps={{ badge: { style: { border: "1px solid white" } } }}
        > */}
          <IconButton onClick={handleAvatarClick} sx={{ ml: 3 }} size="small">
            <Avatar
              alt="User Avatar"
              sx={{
                bgcolor: stringToColour(user.email?.[0] || ""),
                border: "2px solid white",
              }}
              variant="rounded"
            >
              {user.email?.[0]?.toUpperCase()}
            </Avatar>
          </IconButton>
          {/* </Badge> */}
          <Menu
            anchorEl={anchorElAvatar}
            open={Boolean(anchorElAvatar)}
            onClose={handleAvatarClose}
          >
            <MenuList>
              {/* <MenuItem
              onClick={() => {
                setActive((prev) => !prev);
                handleAvatarClose();
              }}
            >
              <ListItemIcon>
                {active ? (
                  <ToggleOff fontSize="small" />
                ) : (
                  <ToggleOn fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                {active ? "Set Inactive" : "Set Active"}
              </ListItemText>
            </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setOpenAccountDrawer(true);
                  handleAvatarClose();
                }}
              >
                <ListItemIcon>
                  <AccountBox fontSize="small" />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onLogout();
                  handleAvatarClose();
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </ToolbarStyled>
    </AppBar>
  );
};
