import { Handle, Position, NodeProps, Node } from "@xyflow/react";
import { Card, CardContent, TextField, Typography } from "@mui/material";
import {
  QuestionFieldData,
  useFormBuilderContext,
} from "../formBuilder.context";
import { QuestionRedux } from "../QuestionRedux";
import React, { useState } from "react";

//v12 xyflow react you need to define a type
type QuestionNode = Node<QuestionFieldData, "questionNode">;

const QuestionNodeComponent: React.FC<NodeProps<QuestionNode>> = (node) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(node?.data?.nodeTitle);

  const handleTextClick = (): void => {
    setIsEditing(true);
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setText(event.target.value);
  };

  const handleBlur = (): void => {
    setIsEditing(false);
  };
  const { handleClickQuestion, activeQuestion } = useFormBuilderContext();
  const isActive = activeQuestion?.id === node.id || node.selected;

  return (
    <Card
      key={node.id}
      variant="outlined"
      sx={{
        // minWidth: 500,
        borderColor: isActive ? "#1976d2" : undefined,
      }}
      onClick={() => handleClickQuestion(node.id)}
      elevation={5}
    >
      <CardContent>
        {isEditing ? (
          <TextField
            value={text}
            onChange={handleTextChange}
            onBlur={handleBlur}
            variant="outlined"
            size="small"
            autoFocus
            className="nodrag"
          />
        ) : (
          <Typography variant="h6" gutterBottom onClick={handleTextClick}>
            {`${text || "Question Node"} ${
              node?.data?.index === 0 ? "(START)" : ""
            }`}
          </Typography>
        )}
        <QuestionRedux id={node.id} isFlowNode={true} nodeTitle={text} />
      </CardContent>
      {/* Target handle for incoming connections */}
      <Handle
        hidden={!node?.data?.index || node?.data?.index === 0}
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
      />
      {/* Source handle for next question connection */}
      <Handle
        type="source"
        position={Position.Bottom}
        id={`${node.id}-next`}
        style={{
          background: "#555",
          borderRadius: 0,
          width: 10,
          height: 10,
          bottom: -5,
        }}
      />
    </Card>
  );
};

// Memoizing the component
export const QuestionNode = React.memo(
  QuestionNodeComponent,
  (prevProps, nextProps) => {
    // Custom comparison function to check if the node props have changed
    // You can expand this check if you want to be more granular
    // prevProps.data === nextProps.data &&
    return (
      prevProps.id === nextProps.id && prevProps.selected === nextProps.selected
    );
  }
);
