import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Attendance } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Attendance entity
const attendanceAdapter = createEntityAdapter<Attendance, EntityId>({
  selectId: (attendance: Attendance) => attendance.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface AttendancesState extends EntityState<Attendance, EntityId> {
  // Add any additional state properties here if needed
}

const initialState: AttendancesState = attendanceAdapter.getInitialState();

// Selectors for Attendances
export const {
  selectById: selectAttendanceById,
  selectAll: selectAllAttendances,
} = attendanceAdapter.getSelectors((state: RootState) => state?.attendance);

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(attendance.endpoints.getAttendances.matchFulfilled),
      (state, action) => {
        attendanceAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        attendance.endpoints.createAttendance.matchFulfilled,
        attendance.endpoints.updateAttendance.matchFulfilled,
        attendance.endpoints.patchAttendance.matchFulfilled
      ),
      (state, action) => {
        attendanceAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      attendance.endpoints.deleteAttendance.matchFulfilled,
      (state, action) => {
        attendanceAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const attendance = baseApi
  .enhanceEndpoints({ addTagTypes: ["Attendances", "Attendance"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAttendances: builder.query<Attendance[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "attendance",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Attendance" as const,
                  id,
                })),
                { type: "Attendances" as const },
              ]
            : [{ type: "Attendances" as const }],
      }),
      getAttendance: builder.query<Attendance, { id: number; params?: object }>(
        {
          query: ({ id, params }: { id: number; params?: object }) => ({
            url: `attendance/${id}`,
            method: "GET",
            params: params,
          }),
          providesTags: (result, error, arg) => [
            { type: "Attendance", id: arg.id },
          ],
          keepUnusedDataFor: 90,
        }
      ),
      createAttendance: builder.mutation<Attendance, Partial<Attendance>>({
        query: (request) => ({
          url: "attendance",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Attendances" }],
      }),
      patchAttendance: builder.mutation<Attendance, Attendance>({
        query: (request) => ({
          url: `attendance/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Attendance", id: arg.id },
        ],
      }),
      updateAttendance: builder.mutation<Attendance, Attendance>({
        query: (request) => ({
          url: `attendance/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Attendance", id: arg.id },
        ],
      }),
      deleteAttendance: builder.mutation<void, number>({
        query: (id) => ({
          url: `attendance/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Attendance", id: arg },
          { type: "Attendances" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetAttendancesQuery,
  useGetAttendanceQuery,
  useCreateAttendanceMutation,
  usePatchAttendanceMutation,
  useUpdateAttendanceMutation,
  useDeleteAttendanceMutation,
  usePrefetch: useAttendancesPrefetch,
} = attendance;
