import { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ListItemIcon,
  Paper,
  Fab,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Search as SearchIcon,
  Task as EventIcon,
  Close,
  Save,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { TaskGroup, Task, TaskGroupMember, Skill } from "store/api/models";
import { sortByNumber, sortString } from "utils";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  useCreateTaskGroupMutation,
  usePatchTaskGroupMutation,
} from "store/api/task_group.api";
import { NoMaxWidthTooltip } from "components/NoMaxWidthTooltip";
import { useSelector } from "react-redux";
import { selectAllSkills, selectTaskEntities, selectAllTasks } from "store";
import { RootState } from "store/reducer";

export const TaskGroupDetails = ({
  disabled,
  task_group: initialTaskGroup,
  handleTaskClick,
  close,
  callback = () => {},
}: {
  disabled: boolean;
  task_group: TaskGroup;
  handleTaskClick: (task: Task) => void;
  close: Function;
  callback: Function;
}) => {
  const [hasEdits, setHasEdits] = useState<boolean>(false); // Tracks user edits

  // Original hooks with their state variables
  const skills = useSelector((state: RootState) => selectAllSkills(state));
  const initialTasks = useSelector((state: RootState) => selectAllTasks(state));
  const taskEntities = useSelector((state: RootState) =>
    selectTaskEntities(state)
  );

  // Custom setters
  const [task_group, setTaskGroupState] = useState<TaskGroup>(initialTaskGroup);
  const [search, setSearchState] = useState<string>("");
  const [searchSelected, setSelectedSearchState] = useState<string>("");
  const [tasks, setTasksState] = useState<Task[]>(
    initialTasks
      .filter((t) => t.status === "Published")
      .filter((ie) => !initialTaskGroup.tasks?.find((tg) => tg.task === ie.id))
  );
  const [selectedTasks, setSelectedTasksState] = useState<Task[]>(
    ([...(initialTaskGroup?.tasks || [])]
      ?.sort((a, b) => sortByNumber(a.sequence, b.sequence))
      .map((tg) => tasks.find((t) => t.id === tg.task))
      .filter((t) => t) as Task[]) || ([] as Task[])
  );
  const [newListName, setNewListNameState] = useState<string>(
    task_group?.name || ""
  );
  const [selectedSkills, setSelectedSkillsState] = useState<Skill[] | null>(
    task_group?.skills || ""
  );
  const [status, setStatusState] = useState<"Draft" | "Published">(
    task_group?.status || "Draft"
  );

  // Custom setter that wraps setTaskGroup and also sets hasEdits
  const setTaskGroup = (newTaskGroup: TaskGroup) => {
    setTaskGroupState(newTaskGroup);
    setHasEdits(true);
  };

  const setSearch = (newSearch: string) => {
    setSearchState(newSearch);
    setHasEdits(true);
  };

  const setSelectedSearch = (newSelectedSearch: string) => {
    setSelectedSearchState(newSelectedSearch);
    setHasEdits(true);
  };

  const setTasks = (newTasks: Task[]) => {
    setTasksState(newTasks);
    setHasEdits(true);
  };

  const setSelectedTasks = (newSelectedTasks: Task[]) => {
    setSelectedTasksState(newSelectedTasks);
    setHasEdits(true);
  };

  const setNewListName = (newListName: string) => {
    setNewListNameState(newListName);
    setHasEdits(true);
  };

  const setSelectedSkills = (newSelectedSkills: Skill[] | null) => {
    setSelectedSkillsState(newSelectedSkills);
    setHasEdits(true);
  };

  const setStatus = (newStatus: "Draft" | "Published") => {
    setStatusState(newStatus);
    setHasEdits(true);
  };

  useEffect(() => {
    if (!hasEdits) {
      setTaskGroupState(initialTaskGroup);
      setNewListNameState(task_group?.name || "");
      setSelectedSkillsState(task_group?.skills || "");
      setStatusState(task_group?.status || "Draft");
      setTasksState(
        initialTasks
          .filter((t) => t.status === "Published")
          .filter(
            (ie) => !initialTaskGroup.tasks?.find((tg) => tg.task === ie.id)
          )
      );
      setSelectedTasksState(
        ([...(initialTaskGroup?.tasks || [])]
          ?.sort((a, b) => sortByNumber(a.sequence, b.sequence))
          .map((tg) => taskEntities[tg.task])
          .filter((t) => t) as Task[]) || ([] as Task[])
      );
    }
  }, [initialTaskGroup, initialTasks, disabled, hasEdits]);
  const [createTaskGroup] = useCreateTaskGroupMutation();
  const [patchTaskGroup] = usePatchTaskGroupMutation();

  const remove = (list: number) => {
    const removedList = selectedTasks?.find((pl) => pl.id === list);
    if (removedList) {
      setTasksState((prevLists) => [...prevLists, removedList]);
      setSelectedTasksState((prev) => prev?.filter((p) => p.id !== list));
      setHasEdits(true);
    }
  };

  // Handle dragging and dropping tasks between lists
  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // If there is no destination (dropped outside the list), do nothing
    if (!destination) return;

    // Handle dragging from left to right list (removing from left and adding to right)
    if (
      source.droppableId === "left-tasks" &&
      destination.droppableId === "right-tasks"
    ) {
      const draggedTaskGroup = tasks.find(
        (task) => `${task.id}` === result.draggableId
      );

      // Remove the dragged task from the left list
      const updatedTaskGroups = tasks.filter(
        (task) => `${task.id}` !== result.draggableId
      );

      // Add the dragged task to the right list if it's not already there
      if (draggedTaskGroup) {
        setSelectedTasksState((prev) => {
          const reorderedTaskGroups = Array.from(prev);
          reorderedTaskGroups.splice(destination.index, 0, draggedTaskGroup);
          return reorderedTaskGroups;
        });
        setTasks(updatedTaskGroups);
      }
    }

    // Handle reordering within the right list
    if (
      source.droppableId === "right-tasks" &&
      destination.droppableId === "right-tasks"
    ) {
      setSelectedTasksState((prev) => {
        const reorderedTaskGroups = Array.from(prev);
        const [movedTaskGroup] = reorderedTaskGroups.splice(source.index, 1);
        reorderedTaskGroups.splice(destination.index, 0, movedTaskGroup);
        return reorderedTaskGroups;
      });
    }
    setHasEdits(true);
  };
  const filteredTasks = [...(tasks || [])]
    ?.filter((e) => {
      return e.title?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.title, b.title));
  const filteredSelectedTasks = [...(selectedTasks || [])]?.filter((e) => {
    return e.title?.toLowerCase()?.includes(searchSelected.toLowerCase());
  });
  // ?.sort((a, b) => sortString(a.title, b.title));
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!disabled && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
          onClick={async () => {
            try {
              if (task_group?.id) {
                const res = await patchTaskGroup({
                  id: task_group.id,
                  name: newListName,
                  status: status,
                  tasks: selectedTasks.map(
                    (t, i) =>
                      ({
                        task: t.id,
                        sequence: i,
                        task_group: task_group.id,
                      } as TaskGroupMember)
                  ),
                  skills: selectedSkills || [],
                } as TaskGroup).unwrap();
                await callback();
                if (res.id) {
                  setTaskGroup(res);
                  toast.success("Task group updated!");
                  close();
                }
              } else {
                const res = await createTaskGroup({
                  name: newListName,
                  status: status,
                  tasks: selectedTasks.map(
                    (t, i) =>
                      ({
                        task: t.id,
                        sequence: i,
                      } as TaskGroupMember)
                  ),
                  skills: selectedSkills || [],
                }).unwrap();
                await callback();
                if (res.id) {
                  setTaskGroup(res);
                  toast.success("Task group created!");
                  close();
                }
              }
            } catch (error) {
              toast.error("Error creating task group");
            }
          }}
        >
          <Save />
        </Fab>
      )}
      <Box
        sx={{
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <IconButton
          onClick={() => close()}
          sx={{
            position: "absolute",
            top: 4,
            right: 8,
            zIndex: (theme) => theme.zIndex.fab,
          }}
        >
          <Close />
        </IconButton>

        <Paper sx={{ padding: 1, marginBottom: 1 }}>
          <Typography variant="h6">TASK GROUP</Typography>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid size={{ xs: 5 }}>
              <TextField
                disabled={disabled}
                size="small"
                label="Group Name"
                fullWidth
                variant="outlined"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
              />
            </Grid>
            <Grid size={{ xs: 2 }}>
              <FormControl fullWidth size="small" className="nodrag">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  disabled={disabled}
                  labelId="status-label"
                  id="status"
                  size="small"
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value as "Draft" | "Published");
                  }}
                  className="nodrag"
                >
                  <MenuItem value={"Draft"}>DRAFT</MenuItem>
                  <MenuItem value={"Published"}>LIVE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Autocomplete
                multiple
                disabled={disabled}
                value={selectedSkills || []}
                options={skills}
                onChange={(event: any, newValue: Skill[] | null) => {
                  setSelectedSkills(newValue);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Skills" />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box
          sx={{
            flexGrow: 3,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  flexGrow: 1,
                  width: "100%",
                  height: "100%",
                  maxHeight: "100%",
                }}
              >
                {/* Left column: Events to drag */}
                <Paper
                  sx={{
                    height: "100%",
                    maxHeight: "100%",
                    width: "50%",
                    p: 1,
                  }}
                >
                  <Grid
                    container
                    alignItems={"bottom"}
                    alignContent={"bottom"}
                    justifyContent={"space-between"}
                    spacing={0}
                    sx={{ mb: 1 }}
                  >
                    <Grid alignSelf={"bottom"} alignContent={"end"}>
                      <Typography
                        variant="caption"
                        component={"span"}
                        sx={{ color: "black", pl: 1 }}
                      >
                        AVAILABLE TASKS
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        alignItems={"bottom"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid sx={{ textAlign: "right" }}>
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            placeholder="Search tasks"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(event) =>
                              setSearch((event?.target as any)?.value)
                            }
                            sx={{ maxWidth: { xs: "none", sm: 340 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ height: "100%", maxHeight: "100%" }}>
                    <Droppable
                      droppableId="left-tasks"
                      isDropDisabled={disabled}
                    >
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{
                            height: "100%",
                            maxHeight: "100%",
                            overflowY: "scroll",
                          }}
                          dense
                          disablePadding
                        >
                          {filteredTasks?.map((task: Task, index: number) => (
                            <Draggable
                              key={task.id}
                              draggableId={`${task.id}`}
                              index={index}
                              isDragDisabled={disabled}
                            >
                              {(provided) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={task.id}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: 1,
                                    marginBottom: 1,
                                    border: "1px solid #ddd",
                                    borderRadius: 1,
                                  }}
                                  dense
                                >
                                  <ListItemIcon>
                                    <EventIcon />
                                  </ListItemIcon>
                                  <NoMaxWidthTooltip title={task.title}>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="overline"
                                          sx={{
                                            fontWeight: 700,
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          {task.title}
                                        </Typography>
                                      }
                                      // secondary={`${task.contact_r?.email}`}
                                      // onClick={() => handleTaskClick(task)}
                                      sx={{
                                        // cursor: "pointer",
                                        py: 0,
                                        my: 0,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </NoMaxWidthTooltip>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Box>
                </Paper>
                {/* Right column with reorderable list */}
                <Paper
                  elevation={3}
                  sx={{ width: "50%", padding: 1, minHeight: "100%" }}
                >
                  <Grid
                    container
                    alignItems={"bottom"}
                    justifyContent={"space-between"}
                    spacing={0}
                    sx={{ mb: 1 }}
                  >
                    <Grid alignContent={"end"}>
                      <Typography
                        variant="caption"
                        component={"span"}
                        sx={{ color: "black", pl: 1 }}
                      >
                        SELECTED TASKS
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        alignItems={"bottom"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid sx={{ textAlign: "right" }}>
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            placeholder="Search tasks"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(event) =>
                              setSelectedSearch((event?.target as any)?.value)
                            }
                            sx={{ maxWidth: { xs: "none", sm: 340 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ height: "100%", maxHeight: "100%" }}>
                    <Droppable
                      droppableId="right-tasks"
                      isDropDisabled={disabled}
                    >
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{
                            height: "100%",
                            maxHeight: "100%",
                            overflowY: "scroll",
                          }}
                          dense
                          disablePadding
                        >
                          {filteredSelectedTasks.map((task, index) => {
                            return (
                              <Draggable
                                key={task.id}
                                draggableId={`${task.id}`}
                                index={index}
                                isDragDisabled={disabled}
                              >
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      padding: 1,
                                      marginBottom: 1,
                                      border: "1px solid #ddd",
                                      borderRadius: 1,
                                    }}
                                    dense
                                    secondaryAction={
                                      !disabled && (
                                        <IconButton
                                          onClick={() => remove(task.id)}
                                        >
                                          <Close color="secondary" />
                                        </IconButton>
                                      )
                                    }
                                  >
                                    <ListItemIcon>
                                      <EventIcon />
                                    </ListItemIcon>

                                    <NoMaxWidthTooltip title={task.title}>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="overline"
                                            sx={{
                                              fontWeight: 700,
                                              width: "100%",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            {task.title}
                                          </Typography>
                                        }
                                        // secondary={`${task.contact_r?.email}`}
                                        // onClick={() => handleTaskClick(task)}
                                        sx={{
                                          // cursor: "pointer",
                                          py: 0,
                                          my: 0,
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "100%",
                                        }}
                                      />
                                    </NoMaxWidthTooltip>
                                  </ListItem>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Box>
                </Paper>
              </Box>
            </DragDropContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
