// utils.ts

import { QuestionFieldData } from "forms/builder/formBuilder.context";
import { Form, FormMap } from "./types";
import { Node, Edge } from "@xyflow/react";

export const buildQuestionMap = (form?: Form): FormMap => {
  const questionMap = new Map<string, Node<QuestionFieldData>>();
  const edgesMap = new Map<string, Edge[]>();

  // Map questions by ID
  form?.questions?.forEach((question) => {
    questionMap.set(question.id, question);
  });

  // Map edges by source ID and sourceHandle (for options)
  form?.edges?.forEach((edge) => {
    const key = edge.sourceHandle || edge.source;
    if (!edgesMap.has(key)) {
      edgesMap.set(key, []);
    }
    edgesMap.get(key)?.push(edge);
  });

  return { questionMap, edgesMap } as FormMap;
};
