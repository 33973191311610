import * as Yup from "yup";
import {
  MailOutlineOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { JFormikData } from "forms/jformik";
import { useGetRolesQuery, useGetSkillsQuery } from "store";
import moment from "moment";
import { sortByDate } from "utils";
import { EventTimeSlot } from "./useMobilizeEventForm";
import { List } from "@mui/material";

export const useAttendanceForm = (): JFormikData => {
  return {
    validationSchema: Yup.object({
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
    form: [
      // {
      //   name: "basic-fields",
      //   itemType: "typography",
      //   jItemProps: {
      //     variant: "overline",
      //     sx: { mb: "0 !important", pb: "0 !important" },
      //   },
      //   label: "Basic Information",
      // },
      // {
      //   name: "basic-fields-bottom",
      //   label: "",
      //   itemType: "divider",
      //   gridProps: { size: { sx: { paddingTop: "0px !important" } } },
      // },
      {
        name: "person.given_name",
        label: "First Name",
        itemType: "textfield",
        gridProps: { size: { xs: 6 } },
        jItemProps: {
          InputProps: {
            endAdornment: <PersonOutlineOutlined color="secondary" />,
          },
        },
      },
      {
        name: "person.family_name",
        label: "Last Name",
        itemType: "textfield",
        gridProps: { size: { xs: 6 } },
        jItemProps: {
          InputProps: {
            endAdornment: <PersonOutlineOutlined color="secondary" />,
          },
        },
      },
      {
        name: "person.email_address",
        label: "Email",
        itemType: "textfield",
        gridProps: { size: { xs: 6 } },
        jItemProps: {
          InputProps: {
            endAdornment: <MailOutlineOutlined color="secondary" />,
          },
        },
      },
      {
        name: "person.phone_number",
        label: "Phone",
        itemType: "textfield",
        // jItemProps: { type: "phone" },
        gridProps: { size: { xs: 4 } },
      },
      {
        name: "person.postal_code",
        label: "Zip",
        itemType: "textfield",
        gridProps: { size: { xs: 2 } },
      },
      {
        precondition: (values: any) => values.status,
        name: "status",
        label: "Status",
        itemType: "autocomplete",
        options: [],
        gridProps: { size: { xs: 4 } },
      },
      {
        precondition: (values: any) => values?.referrer?.utm_source,
        name: "referrer.utm_source",
        label: "Source",
        disabled: true,
        itemType: "textfield",
        gridProps: { size: { xs: 4 } },
      },
      {
        precondition: (values: any) => values.timeslot && false,
        name: "timeslot",
        itemType: "paper",
        arrayProps: { wrap: false, noModify: true },
        jItemProps: {
          paper: { elevation: 0 },
          sx: {
            paddingTop: 3,
            paddingBottom: 4,
            paddingX: 4,
            marginTop: 2,
          },
        },
        array: [
          {
            name: "checked",
            label: "",
            defaultValue: true,
            value: true,
            itemType: "checkbox",
            gridProps: { size: { xs: 2 } },
          },
          {
            name: "start_date",
            label: "Start Date/Time",
            itemType: "datetimepicker",
            jItemProps: { type: "time", InputProps: { type: "time" } },
            gridProps: { size: { xs: 5 } },
          },
          {
            name: "end_date",
            label: "End Date/Time",
            itemType: "datetimepicker",
            gridProps: { size: { xs: 5 } },
          },
        ],
      },
      {
        name: "basic-fields",
        itemType: "typography",
        jItemProps: {
          variant: "overline",
          sx: {
            mb: "0 !important",
            pb: "0 !important",
          },
        },
        label: "Time slots",
      },
      {
        precondition: (values: any) => values.timeslot,
        name: "timeslot",
        label: "",
        itemType: "embed",
        embed: ({ values, setFieldValue, disabled }) => {
          const t = values.timeslot;
          return (
            <List dense disablePadding>
              <EventTimeSlot
                id={t?.id}
                checked={t?.checked}
                timeslot={t}
                start_date={t.start_date}
                end_date={t.end_date}
                index={0}
                values={values}
                setFieldValue={setFieldValue}
                disabled={!!disabled}
              />
            </List>
          );
        },
      },
      {
        name: "timeslots",
        label: "",
        itemType: "embed",
        embed: ({ values, setFieldValue, disabled }) => {
          return (
            <List dense disablePadding>
              {[
                ...(values.timeslots || [])?.filter(
                  (t: any) =>
                    !t.is_full && moment(t.start_date).isAfter(moment())
                ),
              ]
                .sort((b, a) =>
                  sortByDate(moment(a.start_date), moment(b.start_date))
                )
                .map((t: any, i: number) => (
                  <EventTimeSlot
                    id={t.id}
                    checked={t.checked}
                    timeslot={t}
                    signup_count={t.signup_count}
                    start_date={t.start_date}
                    end_date={t.end_date}
                    index={i}
                    values={values}
                    setFieldValue={setFieldValue}
                    disabled={!!disabled}
                  />
                ))}
            </List>
          );
        },
      },
      // {
      //   precondition: (values: any) => values.timeslots,
      //   name: "timeslots",
      //   itemType: "fieldarray",
      //   arrayProps: { wrap: false, noModify: true },
      //   jItemProps: {
      //     sx: {
      //       paddingTop: 3,
      //       paddingBottom: 4,
      //       paddingX: 4,
      //       marginTop: 2,
      //     },
      //   },
      //   array: [
      //     {
      //       name: "checked",
      //       label: "",
      //       itemType: "checkbox",
      //       gridProps: { size: { xs: 2 } },
      //     },
      //     {
      //       name: "start_date",
      //       label: "Start Date/Time",
      //       itemType: "datetimepicker",
      //       jItemProps: { type: "time", InputProps: { type: "time" } },
      //       gridProps: { size: { xs: 5 } },
      //     },
      //     {
      //       name: "end_date",
      //       label: "End Date/Time",
      //       itemType: "datetimepicker",
      //       gridProps: { size: { xs: 5 } },
      //     },
      //   ],
      // },
      {
        name: "submit",
        itemType: "button",
        jItemProps: { type: "submit" },
        label: "SAVE",
        gridProps: { size: { xs: 12 } },
      },
    ],
  } as JFormikData;
};
