import { useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { useBreakpointQuery } from "../../utils/media.util";
import { JFormikProps, JFieldsProps } from "./jformik.types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FSpotlightField } from "../jformik-fields/FSpotlightStep";
import { JItemMapper } from "./JItemMapper";
import { JFormikContext } from "./jformik.context";
import { AutoSave } from "./AutoSave";
import { BackdropLoading } from "../../components/BackdropLoading";
import { theme } from "../../config";
import * as Yup from "yup";
import { Edit, Save } from "@mui/icons-material";
import { AutoStateSave } from "./AutoStateSave";

export const JFormik = ({
  formRef,
  handleSubmit: inputHandleSubmit = (values) => {},
  initialValues,
  validationSchema,
  form,
  containerGridProps,
  autoSaveForm = false,
  loading = false,
  disabled = false,
  debug = false,
  enableReinitialize = false,
  disableSubmitOnEnter = true,
  editable = false,
  autoStateSave,
}: JFormikProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);
  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const smDown = useBreakpointQuery("sm-down");
  if (loading) {
    return <BackdropLoading />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <JFormikContext.Provider
          value={{
            disabled: isDisabled,
            disableSubmitOnEnter: disableSubmitOnEnter,
          }}
        >
          <Formik
            innerRef={formRef}
            enableReinitialize={enableReinitialize}
            initialValues={
              typeof initialValues === "function"
                ? initialValues()
                : initialValues
            }
            validationSchema={validationSchema || Yup.object()}
            onSubmit={inputHandleSubmit}
          >
            {({ handleSubmit, values, errors }) => {
              {
                errors &&
                  Object.keys(errors).length > 1 &&
                  console.error(errors);
              }
              return (
                <form onSubmit={handleSubmit}>
                  {autoSaveForm && <AutoSave />}
                  {autoStateSave && <AutoStateSave setState={autoStateSave} />}
                  <Grid
                    container
                    spacing={2} //smDown ? 2 : 3}
                    {...containerGridProps}
                    sx={{
                      ...(containerGridProps?.sx || {}),
                      position: "relative",
                    }}
                  >
                    {editable && (
                      <Paper
                        elevation={5}
                        sx={{
                          position: "absolute",
                          top: { xs: 10, sm: 15 },
                          right: 0,
                          bgcolor: (theme) => theme.palette.secondary.main,
                          borderRadius: "100%",
                        }}
                      >
                        {isDisabled ? (
                          <IconButton
                            onClick={async () => {
                              setIsDisabled(false);
                            }}
                            sx={{
                              width: { xs: 32, sm: 40 },
                              height: { xs: 32, sm: 40 },
                            }}
                          >
                            <Edit
                              sx={{
                                color: (theme) => theme.palette.common.white,
                                fontSize: { xs: 18, sm: 24 },
                              }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={isSubmitting}
                            onClick={async () => {
                              setIsSubmitting(true);
                              await inputHandleSubmit(values, null as any);
                              setIsSubmitting(false);
                              setIsDisabled(true);
                            }}
                            type="submit"
                            sx={{
                              width: { xs: 32, sm: 40 },
                              height: { xs: 32, sm: 40 },
                            }}
                          >
                            {isSubmitting ? (
                              <CircularProgress
                                sx={{
                                  color: (theme) => theme.palette.common.white,
                                }}
                                size={14}
                              />
                            ) : (
                              <Save
                                sx={{
                                  color: (theme) => theme.palette.common.white,
                                  fontSize: { xs: 18, sm: 24 },
                                }}
                              />
                            )}
                          </IconButton>
                        )}
                      </Paper>
                    )}
                    <JFields items={form} />
                  </Grid>
                  {debug && <pre>{JSON.stringify(values, null, 2)}</pre>}
                </form>
              );
            }}
          </Formik>
        </JFormikContext.Provider>
      </ThemeProvider>
    );
  }
};

export const JFields = ({ items }: JFieldsProps) => {
  const { values } = useFormikContext();
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {items ? (
          items.map((ji, index) => {
            const {
              precondition = null,
              spotlight,
              gridProps = null,
              gridAdornment,
            } = ji;
            const gridSizesResult =
              typeof gridProps === "function"
                ? gridProps(values)
                : gridProps !== null
                ? gridProps
                : {};
            const gridSizes =
              typeof gridSizesResult === "object" ? gridSizesResult : {};

            if (!precondition || precondition(values)) {
              if (spotlight) {
                return (
                  <FSpotlightField
                    spotlight={spotlight}
                    key={`${ji.name}-${index}`}
                  >
                    <Grid
                      size={{ xs: 12 }}
                      className="m-auto"
                      {...gridSizes}
                      alignContent="center"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <JItemMapper jItem={ji} />
                    </Grid>
                  </FSpotlightField>
                );
              } else if (gridAdornment) {
                return (
                  <Grid
                    size={{ xs: 12 }}
                    {...gridSizes}
                    key={`${ji.name}-${index}`}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {gridAdornment}
                      <JItemMapper jItem={ji} />
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    size={{ xs: 12 }}
                    {...gridSizes}
                    key={`${ji.name}-${index}`}
                  >
                    <JItemMapper jItem={ji} />
                  </Grid>
                );
              }
            } else {
              return <div key={`${ji.name}-${index}`} />;
            }
          })
        ) : (
          <div />
        )}
      </LocalizationProvider>
    </>
  );
};
