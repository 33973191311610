import {
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  ListItemText,
  Typography,
  Chip,
  Checkbox,
  Collapse,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectResultEntities, selectUser } from "store";
import { useGetConversationsQuery } from "store/api/conversation.api";
import { Skill, Conversation, Attendance } from "store/api/models";
import { VolunteerState } from "./agent.types";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import moment from "moment";
import { useMemo, useState } from "react";
import { selectEventEntities } from "store/api/mobilize/mobilize.api";
import { Timeslot } from "store/api/mobilize/mobilize_models";
import { sortByDate } from "utils";
import { Outreach } from "store/api/ngp/ngp_models";
import { attendance } from "store/api/attendance.api";

type CombinedConversationOutreach = {
  variant: "conversation" | "outreach";
  title: string;
  name: string;
  date: string;
  result: number;
  disposition: string;
  rdName: string;
  phone: string;
  conversation: Conversation;
  attendances: Attendance[];
};

export const AgentConversationHistory = ({
  task,
  vanid,
  variant = "volunteer",
  onClick,
  outreaches = [],
}: {
  variant?: "task" | "volunteer" | "prospect";
  task?: number;
  vanid?: number;
  onClick?: (conversation: Conversation) => void;
  outreaches?: Outreach[];
}) => {
  const user = useSelector(selectUser);
  const resultEntities = useSelector(selectResultEntities);
  const { data: conversations } = useGetConversationsQuery(
    {
      params: {
        nested: "all",
        volunteer: variant === "volunteer" ? user.volunteer : undefined,
        task: variant === "task" ? task : undefined,
        vanid: variant === "prospect" ? vanid : undefined,
      },
    },
    {
      skip:
        (variant === "volunteer" && !user.volunteer) ||
        (variant === "task" && !task) ||
        (variant === "prospect" && !vanid),
      refetchOnMountOrArgChange: true,
    }
  );
  const combined: CombinedConversationOutreach[] = useMemo(() => {
    const combined = [
      ...(conversations || [])?.map(
        (conversation) =>
          ({
            variant: "conversation",
            title: conversation?.task_r?.title || "",
            name: `${conversation?.prospect_r?.firstname} ${conversation?.prospect_r?.lastname}`,
            date: conversation.updated_at,
            result: conversation.result,
            disposition: conversation.disposition,
            rdName: conversation?.result
              ? resultEntities[conversation?.result]?.name
              : conversation?.disposition,
            attendances: conversation?.attendances,
            conversation: conversation,
            phone: conversation?.calls?.[conversation?.calls.length - 1]?.phone,
          } as CombinedConversationOutreach)
      ),
      ...(
        outreaches.filter(
          (o) =>
            !conversations?.find(
              (c) =>
                c.result === o.ResultID &&
                Math.abs(
                  moment(c.updated_at).diff(o.DateCanvassed, "minutes")
                ) < 125
            )
        ) || []
      )?.map(
        (outreach) =>
          ({
            variant: "outreach",
            title: outreach.ContactTypeName,
            date: outreach.DateCanvassed,
            result: outreach.ResultID,
            rdName: outreach.ResultShortName,
            phone: outreach.Phone,
          } as CombinedConversationOutreach)
      ),
    ];
    const combinedSorted = [...(combined || [])]?.sort((a, b) =>
      sortByDate(a.date, b.date)
    );
    return combinedSorted;
  }, [outreaches, conversations]);

  return (
    <Table component={Paper} size="small" elevation={5}>
      <TableBody>
        {combined?.map((combine) => {
          return (
            <OutreachRow
              combined={combine}
              onClick={onClick}
              variant={variant}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export const OutreachRow = ({
  combined,
  onClick,
  variant,
}: {
  variant?: "task" | "volunteer" | "prospect";
  combined: CombinedConversationOutreach;
  onClick?: (conversation: Conversation) => void;
}) => {
  const [open, setOpen] = useState(false);
  const attendances = combined.attendances;
  const mobilize_events = useSelector(selectEventEntities);
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          onClick={() =>
            onClick && combined.conversation && onClick(combined.conversation)
          }
          style={{ cursor: combined.conversation ? "pointer" : "not-allowed" }}
        >
          <ListItemText
            primary={
              <Typography variant="overline" sx={{ fontWeight: 700 }}>
                {variant === "task" && <span>{combined.title}: </span>}
                {(variant === "task" || variant === "volunteer") && (
                  <span style={{ marginLeft: 2, fontWeight: 400 }}>
                    {combined.name}
                  </span>
                )}
                {variant === "prospect" && (
                  <span style={{ marginLeft: 2, fontWeight: 400 }}>
                    {combined.phone}
                  </span>
                )}
                <Chip
                  label={`${combined.rdName}${
                    attendances?.length > 0 ? `  (${attendances?.length})` : ""
                  }`}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  sx={{ zoom: "75%", mx: 2 }}
                />
                <Chip
                  label={moment(combined.date).format("MMMM Do YYYY")}
                  size="small"
                  variant="filled"
                  sx={{ zoom: "75%" }}
                />
              </Typography>
            }
          />
        </TableCell>
        {attendances?.length > 0 && (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {attendances?.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Typography
                variant="overline"
                gutterBottom
                sx={{ fontWeight: 700 }}
              >
                <small>Attendances</small>
              </Typography>
              <Table size="small">
                <TableBody>
                  {attendances?.map((attendance: Attendance, ai: number) => (
                    <>
                      {attendance.form?.timeslots?.map(
                        (t: Timeslot, i: number) => {
                          const startDate = moment(t.start_date);
                          const endDate = moment(t.end_date);
                          // Format start and end dates
                          const formattedDate = `${startDate.format(
                            "ddd, MMM D, h:mmA"
                          )}–${endDate.format("h:mmA z")}`;
                          return (
                            <ListItemText
                              primary={
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: 500,
                                    color: (theme) =>
                                      theme.palette.secondary.main,
                                  }}
                                >
                                  <Checkbox checked={true} disabled={true} />
                                  <Typography
                                    variant="overline"
                                    sx={{
                                      fontWeight: 600,
                                      color: (theme) =>
                                        theme.palette.primary.dark,
                                    }}
                                  >
                                    {
                                      mobilize_events[attendance.form?.event_id]
                                        ?.title
                                    }
                                  </Typography>
                                  {"  "}
                                  {formattedDate}
                                </Typography>
                              }
                              sx={{ cursor: "pointer", py: 0, my: 0 }}
                            />
                          );
                        }
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
