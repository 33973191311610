import { useState } from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { JFormik } from "forms";
import {
  useCreateVolunteerMutation,
  useGetVolunteerQuery,
  usePatchVolunteerMutation,
} from "store/api/volunteer.api";
import { useVolunteerForm } from "forms/forms/useVolunteerForm";
import { toast } from "react-toastify";
import { Volunteer } from "store/api/models";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import {
  selectAllSkills,
  selectContactById,
  selectRoleById,
  selectUser,
} from "store";

export const VolunteerDetails = ({
  volunteer,
  disabled,
  close,
  callback,
}: {
  volunteer: Volunteer;
  disabled: boolean;
  close: Function;
  callback: Function;
}) => {
  const user = useSelector(selectUser);
  const contact_r = useSelector((state: RootState) =>
    selectContactById(state, volunteer?.contact || 0)
  );
  const role_r = useSelector((state: RootState) =>
    selectRoleById(state, volunteer?.role || 0)
  );
  const skills = useSelector((state: RootState) => selectAllSkills(state));
  const [createVolunteer] = useCreateVolunteerMutation();
  const [patchVoluteer] = usePatchVolunteerMutation();
  const form = useVolunteerForm(user.admin);
  return (
    <Paper
      sx={{
        bgcolor: "white",
        position: "relative", // Allows positioning of the close button
        maxHeight: "100%",
        height: "100%",
      }}
      elevation={5}
    >
      <Box
        sx={{
          maxHeight: "100%",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={() => close()}
          sx={{ position: "absolute", top: 4, right: 8 }}
        >
          <Close />
        </IconButton>
        <Box sx={{ px: 1, mt: 1 }}>
          <Typography variant="h6">
            {contact_r ? "VOLUNTEER DETAILS" : "CREATE VOLUNTEER"}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 1,
            mt: 3,
            maxHeight: "100%",
            overflowY: "scroll",
            flexGrow: 3,
          }}
        >
          <JFormik
            disabled={disabled}
            initialValues={{
              ...(contact_r || {
                firstname: "",
                lastname: "",
                email: "",
              }),
              ...volunteer,
              role: role_r || {},
              skills:
                volunteer?.skills?.map((vs) =>
                  skills?.find((s) => s.id === vs)
                ) || [],
            }}
            enableReinitialize={true}
            handleSubmit={async (values, formik) => {
              if (values.id) {
                try {
                  await patchVoluteer({
                    ...values,
                    role: values?.role?.id,
                    skills: values?.skills,
                  }).unwrap();
                  close();
                  // toast.info("Volunteer updated successfuly");
                } catch (error) {
                  toast.error("Unable to update volunteer");
                }
              } else {
                try {
                  let res: any = {};
                  res = await createVolunteer({
                    ...values,
                    role: values?.role?.id,
                    skills: values?.skills,
                  }).unwrap();
                  close();
                  callback && callback(res);
                  toast.info("Volunteer created successfuly");
                } catch (error) {
                  toast.error("Unable to create volunteer");
                }
              }
            }}
            validationSchema={form.validationSchema}
            form={form.form}
          />
        </Box>
      </Box>
    </Paper>
  );
};
