import { Backdrop, Button, Grid, Typography, useTheme } from '@mui/material';
import { useJFormikContext } from '../jformik/jformik.context';
import { useFormikContext } from 'formik';
import { ReactNode, useState } from 'react';

type SpotlightProps = {
  children: ReactNode;
};

export const FSpotlightStep = ({ children }: SpotlightProps) => {
  const { submitForm, errors } = useFormikContext();
  const { back = () => {} } = useJFormikContext();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    back();
    setOpen(false);
  };
  const { zIndex } = useTheme();
  const handleNext = async () => {
    await submitForm();
    if (Object.keys(errors).length === 0) {
      setOpen(false);
    }
  };

  return open ? (
    <Backdrop
      className="bg-gray-100"
      style={{
        position: 'absolute',
        zIndex: zIndex.modal + 1,
        flexDirection: 'column',
        width: '100%',
      }}
      open={open}
    >
      {children}
      <div className="m-5">
        <Button color="secondary" onClick={handleClose}>
          Back
        </Button>
        <Button
          color="secondary"
          variant="contained"
          className="text-white"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Backdrop>
  ) : (
    <>{children}</>
  );
};

export const FSpotlightField = ({
  spotlight,
  children,
}: {
  spotlight: string;
  children: ReactNode;
}) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography variant="h6" className="p-5 text-center">
        {spotlight}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);
