import { createSelector } from "@reduxjs/toolkit";
import { EventTask } from "./models";
import { baseApi } from "./common.api";

export const event_task = baseApi
  .enhanceEndpoints({ addTagTypes: ["EventTasks", "EventTask"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEventTasks: builder.query<EventTask[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "event_task",
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "EventTask" as const,
                  id,
                })),
                "EventTasks",
              ]
            : ["EventTasks"],
      }),
      getEventTask: builder.query<EventTask, string>({
        query: (id) => ({
          url: `event_task/${id}`,
          method: "GET",
        }),
        providesTags: (result, error, arg) => [{ type: "EventTask", id: arg }],
        // configuration for an individual endpoint, overriding the api setting
        keepUnusedDataFor: 90,
      }),
      createEventTask: builder.mutation({
        query: (request) => ({
          url: "event_task",
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["EventTasks"],
      }),
      updateEventTask: builder.mutation<EventTask, EventTask>({
        query: (request) => ({
          url: `event_task/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventTask", id: arg.id },
        ],
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            event_task.util.updateQueryData(
              "getEventTask",
              `${id}`,
              (draft) => {
                Object.assign(draft, patch);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(event_task.util.invalidateTags(['EventTasks']))
             */
            dispatch(event_task.util.invalidateTags(["EventTasks"]));
          }
        },
      }),
      deleteEventTask: builder.mutation<EventTask, number>({
        query: (id) => ({
          url: `event_task/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) =>
          result
            ? [{ type: "EventTask" as const, arg }, "EventTasks"]
            : ["EventTasks"],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetEventTasksQuery,
  useGetEventTaskQuery,
  useCreateEventTaskMutation,
  useUpdateEventTaskMutation,
  useDeleteEventTaskMutation,
  usePrefetch: useEventTasksPrefetch,
} = event_task;
const selectEventTasksRoot = event_task.endpoints.getEventTasks.select({});
export const selectEventTasks = createSelector(
  selectEventTasksRoot,
  (event_task) => event_task.data || []
);
