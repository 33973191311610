import * as Yup from "yup";
import { JFormikData } from "forms/jformik";
import { useSelector } from "react-redux";
import { selectAllSkills, selectAllTaskTypes } from "store";
import { RootState } from "store/reducer";

export const useTaskForm = (): JFormikData => {
  // const types = useSelector((state: RootState) => selectAllTaskTypes(state));
  const skills = useSelector((state: RootState) => selectAllSkills(state));
  return {
    validationSchema: Yup.object({
      title: Yup.string().required("Task title is required"),
      // type: Yup.string().required("Task type is required"),
    }),
    form: [
      {
        name: "title",
        label: "Title",
        itemType: "textfield",
        gridProps: { size: { xs: 9 } },
      },
      // {
      //   name: "type",
      //   label: "Type",
      //   itemType: "autocomplete",
      //   options: types,
      //   gridProps: { size: { xs: 3 } },
      // },
      {
        name: "status",
        label: "Status",
        itemType: "autocomplete",
        options: ["Draft", "Published"],
        defaultValue: "Draft",
        gridProps: { size: { xs: 3 } },
      },
      {
        name: "skills",
        label: "Skills",
        itemType: "autocomplete",
        multiple: true,
        options: skills,
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "form.details",
        itemType: "textfield",
        jItemProps: { multiline: true, rows: 4, variant: "outlined" },
        label: `Add a description of the task`,
        gridProps: { size: { xs: 12 } },
      },
      // {
      //   name: "start_date",
      //   label: "Start Date/Time",
      //   itemType: "datetimepicker",
      //   gridProps: { size: { xs: 6 } },
      // },
      // {
      //   name: "end_date",
      //   label: "End Date/Time",
      //   itemType: "datetimepicker",
      //   gridProps: { size: { xs: 6 } },
      // },
      // {
      //   name: "submit",
      //   itemType: "button",
      //   jItemProps: { type: "submit" },
      //   label: "SAVE",
      //   gridProps: { size: { xs: 12 } },
      // },
    ],
  } as JFormikData;
};
