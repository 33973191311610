import {
  Grid,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButtonProps,
  Badge,
} from '@mui/material';
import { useState, MouseEvent, Fragment } from 'react';
import MoreIcon from '@mui/icons-material/MoreVert';

export interface HeaderMoreIconProps {
  tooltip?: string;
  menuItems?: {
    badge?: number;
    icon?: React.ReactNode;
    label: string;
    onClick: Function;
  }[];
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  moreIconButtonProps?: IconButtonProps;
}

export const HeaderMoreIcon = ({
  tooltip,
  menuItems,
  color = 'inherit',
  moreIconButtonProps = {},
}: HeaderMoreIconProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const TooltipWrapper = tooltip ? Tooltip : Fragment;
  const tooltipWrapperProps = tooltip ? ({ title: tooltip } as any) : {};

  return (
    <>
      {menuItems && (
        <Grid item>
          <TooltipWrapper {...tooltipWrapperProps}>
            <IconButton
              id={'header-more-icon'}
              size="large"
              edge="end"
              color={color}
              onClick={handleOpenMenu}
              {...moreIconButtonProps}
            >
              <MoreIcon />
            </IconButton>
          </TooltipWrapper>

          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {menuItems.map(({ badge, icon, label, onClick }, index) => (
              <MenuItem
                id={`menu-${label}`}
                key={index}
                onClick={() => {
                  handleCloseMenu();
                  onClick();
                }}
                sx={{ minWidth: 120, minHeight: 'auto' }}
              >
                <Badge badgeContent={badge} color="secondary">
                  {icon && (
                    <ListItemIcon id={`menu-${label}`}>
                      <div id={`menu-${label}`}>{icon}</div>
                    </ListItemIcon>
                  )}
                  {label}
                </Badge>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
    </>
  );
};
