import {
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid2 as Grid,
  Box,
  Accordion,
  AccordionSummary,
  ListItemIcon,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Collapse,
  IconButton,
  TableHead,
  Checkbox,
  TableContainer,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { AgentConversationHistory } from "components/agent/AgentConversationHistory";
import { AgentConversationNotes } from "components/agent/AgentConversationNotes";
import { TwilioDialerButton } from "components/Voice/TwilioDialerButton";
import moment from "moment";
import { forwardRef, useState } from "react";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { selectUser } from "store";
import { attendance } from "store/api/attendance.api";
import {
  useCreateCallMutation,
  usePatchCallMutation,
} from "store/api/call.api";
import {
  useGetConversationsQuery,
  usePatchConversationMutation,
} from "store/api/conversation.api";
import { selectEventEntities } from "store/api/mobilize/mobilize.api";
import { Timeslot } from "store/api/mobilize/mobilize_models";
import { Attendance, Call, Conversation } from "store/api/models";
import { User, Phone } from "store/api/ngp/ngp_models";
import { sortByDate, sortByNumber } from "utils";
import { useNGPContact } from "utils/ngp.util";

export const NGPContactProfile = forwardRef(
  (
    {
      vanid,
      debug,
      onClick = () => {},
      conversation,
      buttons,
    }: {
      vanid?: string | number;
      debug?: boolean;
      onClick?: Function;
      conversation?: Conversation | null;
      buttons?: any;
    },
    ref
  ) => {
    const theme = useTheme();
    const volunteerUser = useSelector(selectUser);
    const user: User = useNGPContact(vanid) as User;
    const {
      firstname,
      lastname,
      middleName,
      occupation,
      employer,
      emails,
      phones: returnedPhones = [],
      contributions,
      outreaches,
      events,
      addresses = [],
    } = user || {};
    const phones = returnedPhones?.filter(
      (p) =>
        !p.dateSuppressed &&
        !conversation?.calls?.find(
          (c) =>
            p.contactsPhoneId === c.phoneid && [20, 22, 25].includes(c.result)
        )
    );
    const pPhone = phones?.find((p) => p.preferredPhone) || phones?.[0];
    const oPhones = phones?.length > 1 ? phones?.slice(1) : undefined;
    const pEmail = emails?.find((e) => e.preferred_email) || emails?.[0];
    const oEmails =
      emails?.length > 1
        ? emails?.filter((e) => !e.preferred_email) || emails?.slice(1)
        : undefined;
    const address =
      addresses?.find((a) => a.IsPreferred) ||
      [...addresses].sort((a, b) =>
        sortByNumber(b.ContactsAddressID, a.ContactsAddressID)
      )?.[0];
    const addressString = `${address?.AddressLine1 || ""} ${
      address?.City || ""
    } ${address?.State || ""} ${address?.Zip5 || ""}`;
    const { data: history = [] } = useGetConversationsQuery(
      { params: { nested: "all", vanid: vanid } },
      { skip: !vanid }
    );

    const handleCopy = () => {
      navigator.clipboard.writeText(`${vanid}`);
    };
    return (
      <>
        <Box sx={{ flexGrow: 1, maxHeight: "65%" }}>
          <Card
            sx={{
              width: "100%",
              height: "100%",
              margin: "auto",
              overflowY: "scroll",
              border: (theme) => `1px solid ${theme.palette.primary.light}`,
            }}
            elevation={8}
          >
            {user && (
              <CardContent>
                {/* Basic Information */}
                <Grid
                  container
                  sx={{ width: "100%" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid size={buttons ? { xs: 4 } : undefined}>
                    <Box sx={{ mb: 1, mx: 1 }} onClick={() => handleCopy()}>
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{ fontWeight: "bold" }}
                      >
                        {firstname} {middleName} {lastname}{" "}
                      </Typography>
                      {occupation && employer && (
                        <Typography
                          component="span"
                          sx={{ ml: 1.5 }}
                          color="text.secondary"
                        >
                          <small>
                            <br></br>({occupation} at {employer})
                          </small>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {buttons}
                </Grid>
                <Divider
                  sx={{
                    borderColor: (theme) => theme.palette.secondary.main,
                    mb: 1,
                  }}
                />
                {/* Contact Information */}
                {/* <Typography
                variant="overline"
                sx={{
                  fontWeight: 900,
                  color: (theme) => theme.palette.primary.main,
                  mr: 10,
                }}
              >
                Contact Information
              </Typography> */}
                <Grid container justifyItems={"flex-start"} spacing={1}>
                  <Grid
                    size={{ xs: 12 }}
                    sx={{
                      px: 1,
                      borderRadius: 2,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 700,
                          color: (theme) => theme.palette.primary.main,
                          mr: 10,
                        }}
                      >
                        ADDRESS
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {addressString}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    size={{ xs: 6, xl: 6 }}
                    sx={{
                      px: 1,
                      borderRadius: 2,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 700,
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      PHONE NUMBERS
                    </Typography>
                    {phones?.length >= 1 && oPhones ? (
                      <Accordion>
                        {pPhone && (
                          <AccordionSummary
                            expandIcon={oPhones && <ExpandMore />}
                          >
                            <ListItem
                              key={"primaryphone"}
                              dense
                              disableGutters
                              disablePadding
                            >
                              {/* <ListItemIcon sx={{ py: 0, my: 0 }}> */}
                              <TwilioDialerButton
                                animate={true}
                                phone={pPhone.phone}
                                text={`${
                                  pPhone.phoneTypeId === "H" ? "Home" : "Cell"
                                }: ${pPhone.phone}`}
                                phoneid={pPhone.contactsPhoneId}
                                conversation={conversation?.id}
                                volunteer={volunteerUser.volunteer}
                                prospect={conversation?.prospect}
                              />
                              {/* </ListItemIcon>
                              <ListItemText
                                primary={`${
                                  pPhone.phoneTypeId === "H" ? "Home" : "Cell"
                                }: ${pPhone.phone}`}
                              /> */}
                            </ListItem>
                          </AccordionSummary>
                        )}
                        {oPhones && (
                          <AccordionDetails>
                            <List dense disablePadding>
                              {oPhones?.map((phone, index) => {
                                return (
                                  <ListItem
                                    key={index}
                                    dense
                                    disableGutters
                                    disablePadding
                                    sx={{
                                      borderBottom: (theme) =>
                                        `1px solid ${theme.palette.divider}`,
                                    }}
                                  >
                                    {/* <ListItemIcon sx={{ py: 0, my: 0 }}> */}
                                    <TwilioDialerButton
                                      phone={phone.phone}
                                      text={`${
                                        phone.phoneTypeId === "H"
                                          ? "Home"
                                          : "Cell"
                                      }: ${phone.phone}`}
                                      phoneid={phone.contactsPhoneId}
                                      conversation={conversation?.id}
                                      volunteer={volunteerUser.volunteer}
                                      prospect={conversation?.prospect}
                                    />
                                    {/* </ListItemIcon>
                                    <ListItemText
                                      primary={`${
                                        phone.phoneTypeId === "H"
                                          ? "Home"
                                          : "Cell"
                                      }: ${phone.phone}`}
                                    /> */}
                                  </ListItem>
                                );
                              })}
                            </List>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ) : (
                      phones?.length === 1 && (
                        <List dense disablePadding>
                          <ListItem
                            dense
                            disableGutters
                            disablePadding
                            sx={{
                              borderBottom: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                            }}
                          >
                            {/* <ListItemIcon sx={{ py: 0, my: 0 }}> */}
                            <TwilioDialerButton
                              animate={true}
                              text={`${
                                phones?.[0]?.phoneTypeId === "H"
                                  ? "Home"
                                  : "Cell"
                              }: ${phones?.[0]?.phone}`}
                              phone={phones?.[0]?.phone}
                              phoneid={phones?.[0]?.contactsPhoneId}
                              conversation={conversation?.id}
                              volunteer={volunteerUser.volunteer}
                              prospect={conversation?.prospect}
                            />
                            {/* </ListItemIcon>
                            <ListItemText
                              primary={`${
                                phones?.[0]?.phoneTypeId === "H"
                                  ? "Home"
                                  : "Cell"
                              }: ${phones?.[0]?.phone}`}
                            /> */}
                          </ListItem>
                        </List>
                      )
                    )}
                  </Grid>
                  <Grid
                    size={{ xs: 6, xl: 6 }}
                    sx={{
                      px: 1,
                      borderRadius: 2,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 700,
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      EMAILS
                    </Typography>
                    {emails?.length >= 1 && oEmails ? (
                      <Accordion>
                        {pEmail && (
                          <AccordionSummary
                            expandIcon={oPhones && <ExpandMore />}
                          >
                            <ListItem
                              key={"primaryemail"}
                              dense
                              disableGutters
                              disablePadding
                            >
                              <ListItemText primary={pEmail.email} />
                            </ListItem>
                          </AccordionSummary>
                        )}
                        {oEmails && (
                          <AccordionDetails>
                            <List dense disablePadding>
                              {oEmails?.map((email, index) => (
                                <ListItem
                                  key={index}
                                  dense
                                  disableGutters
                                  disablePadding
                                  sx={{
                                    borderBottom: (theme) =>
                                      `1px solid ${theme.palette.divider}`,
                                  }}
                                >
                                  <ListItemText primary={email.email} />
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ) : (
                      emails?.length === 1 && (
                        <List dense disablePadding>
                          <ListItem
                            dense
                            disableGutters
                            disablePadding
                            sx={{
                              borderBottom: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                            }}
                          >
                            <ListItemText primary={emails?.[0]?.email} />
                          </ListItem>
                        </List>
                      )
                    )}
                  </Grid>
                </Grid>
                {/* <Typography
                variant="overline"
                sx={{
                  fontWeight: 900,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Additional Information
              </Typography> */}
                {/* <Divider
                sx={{
                  borderColor: (theme) => theme.palette.primary.main,
                  my: 1,
                }}
              /> */}
                <Box
                  sx={{
                    mt: 1,
                    px: 1,
                    borderRadius: 2,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  {/* Events */}
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 700,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    EVENTS
                    {"   "}
                    {!events || events?.length === 0 ? (
                      <small>(No event signups)</small>
                    ) : (
                      <small>({events?.length})</small>
                    )}
                  </Typography>
                  <List
                    sx={{ maxHeight: "100px", overflowY: "scroll" }}
                    dense
                    disablePadding
                  >
                    {events?.length > 0 &&
                      [...(events || [])]
                        ?.sort((a, b) =>
                          sortByDate(
                            a?.event_r?.DateOffsetBegin,
                            b?.event_r?.DateOffsetBegin
                          )
                        )
                        ?.map((event, index) => {
                          const color = event.eventName.includes(
                            "(from MyC Event ID"
                          )
                            ? theme.palette.secondary.dark
                            : event.eventName.includes("(from CC Event ID")
                            ? theme.palette.primary.dark
                            : "black";
                          const highlightcolor = event.eventName.includes(
                            "(from MyC Event ID"
                          )
                            ? theme.palette.primary.dark
                            : event.eventName.includes("(from CC Event ID")
                            ? theme.palette.secondary.dark
                            : theme.palette.primary.dark;
                          const cleanTitle = event?.eventName?.replace(
                            /\s*\(.*\)/,
                            ""
                          );
                          return (
                            <ListItem
                              key={index}
                              dense
                              disableGutters
                              disablePadding
                              sx={{
                                pl: 1,
                                mt: 0.25,
                                py: 0,
                                borderRadius: "4px",
                                border: (theme) => `1px solid ${color}`,
                                color: color,
                              }}
                            >
                              <ListItemText
                                sx={{ m: 0 }}
                                primary={
                                  <Typography
                                    variant="overline"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    {cleanTitle}
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      <Chip
                                        label={
                                          <small
                                            style={{ color: highlightcolor }}
                                          >
                                            Status:{" "}
                                            {
                                              event.event_status_r
                                                ?.EventStatusName
                                            }
                                          </small>
                                        }
                                        size="small"
                                        variant="outlined"
                                        // color="secondary"
                                        sx={{
                                          zoom: "85%",
                                          mx: 1,
                                          color: highlightcolor,
                                          borderColor: highlightcolor,
                                        }}
                                      />
                                      <Chip
                                        label={
                                          <small
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            {moment(
                                              event.event_r?.DateOffsetBegin
                                            ).format("MMMM Do YYYY")}
                                          </small>
                                        }
                                        size="small"
                                        variant="filled"
                                        // color="secondary"
                                        sx={{
                                          zoom: "85%",
                                          color: highlightcolor,
                                          borderColor: highlightcolor,
                                        }}
                                      />
                                    </span>
                                  </Typography>
                                }
                              />
                            </ListItem>
                          );
                        })}
                  </List>
                </Box>
                {/* <Divider sx={{ my: 2 }} /> */}
                <Box
                  sx={{
                    mt: 1,
                    px: 1,
                    borderRadius: 2,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  {/* Contributions */}
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 700,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    CONTRIBUTIONS
                    {"   "}
                    {!contributions || contributions?.length === 0 ? (
                      <small>(No contributions)</small>
                    ) : (
                      <small>
                        (Total: $
                        {contributions.reduce((p, c) => p + c.Amount, 0)})
                      </small>
                    )}
                  </Typography>
                  <List
                    sx={{ maxHeight: "100px", overflowY: "scroll" }}
                    dense
                    disablePadding
                  >
                    {contributions?.length > 0 &&
                      [...(contributions || [])]
                        ?.sort((a, b) =>
                          sortByDate(a?.DateReceived, b?.DateReceived)
                        )
                        ?.map((contribution, index) => (
                          <ListItem
                            key={index}
                            dense
                            disableGutters
                            disablePadding
                            sx={{
                              borderBottom: (theme) =>
                                `1px solid ${theme.palette.divider}`,
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  variant="overline"
                                  sx={{ fontWeight: 700 }}
                                >
                                  {`$${contribution.Amount}`}
                                  <Chip
                                    label={
                                      <small>
                                        {moment(
                                          contribution.DateReceived
                                        ).format("MMMM Do YYYY")}
                                      </small>
                                    }
                                    size="small"
                                    variant="filled"
                                    sx={{
                                      zoom: "85%",
                                      mx: 1,
                                    }}
                                  />
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                  </List>
                </Box>
              </CardContent>
            )}
            {debug && user && (
              <Accordion sx={{ p: 0 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <small>Raw info</small>
                </AccordionSummary>
                <AccordionSummary>
                  <ReactJson src={user} collapsed={true} />
                </AccordionSummary>
              </Accordion>
            )}
          </Card>
        </Box>
        {user && (
          <Paper
            elevation={3}
            sx={{
              mt: 0.5,
              py: 1,
              px: 2,
              bgcolor: "white",
              flexGrow: 1,
              minHeight: "200px",
              maxHeight: "300px",
              overflowY: "scroll",
              border: (theme) => `1px solid ${theme.palette.primary.light}`,
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              Notes
            </Typography>
            <Divider
              sx={{
                borderColor: (theme) => theme.palette.secondary.main,
              }}
            />
            <AgentConversationNotes
              ref={ref}
              prospect={conversation?.prospect}
            />
          </Paper>
        )}
        {user && (
          <Paper
            elevation={3}
            sx={{
              mt: 0.5,
              p: 2,
              bgcolor: "white",
              flexGrow: 1,
              maxHeight: "100%",
              overflowY: "scroll",
              border: (theme) => `1px solid ${theme.palette.primary.light}`,
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              CONTACT HISTORY
            </Typography>
            <Divider
              sx={{
                borderColor: (theme) => theme.palette.secondary.main,
              }}
            />
            <Table component={Paper} size="small" elevation={5}>
              <TableBody>
                <AgentConversationHistory
                  variant="prospect"
                  vanid={Number(vanid)}
                  onClick={(conversation: Conversation) =>
                    onClick(conversation)
                  }
                  outreaches={outreaches}
                />
              </TableBody>
            </Table>
          </Paper>
        )}
      </>
    );
  }
);
