import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, PathRouteProps } from 'react-router-dom';
import { routeNames } from '../config';
import { useOnlineOffline } from 'utils';
import { PrivateRoute } from './PrivateRoute';

export interface BaseNavigatorProps {
  baseRoutes: PathRouteProps[];
  authNavigator: any;
}

export const BaseNavigator = ({
  baseRoutes,
  authNavigator,
}: BaseNavigatorProps) => {
  useOnlineOffline();
  return (
    <>
      <Routes>
        {baseRoutes.map((props, index) => (
          <Route {...props} key={index} />
        ))}
        <Route
          path={`${routeNames.HOME}*`}
          element={<PrivateRoute element={authNavigator} />}
        />
      </Routes>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default BaseNavigator;
