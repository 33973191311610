import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { baseApi } from "../common.api";
import { Attendance, AttendanceResponse, Event } from "./mobilize_models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Event entity
const eventAdapter = createEntityAdapter<Event, EntityId>({
  selectId: (event_group: Event) => event_group.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface EventsState extends EntityState<Event, EntityId> {
  // Add any additional state properties here if needed
}

const initialEventState: EventsState = eventAdapter.getInitialState();

// Selectors for Events
export const {
  selectById: selectEventById,
  selectAll: selectAllEvents,
  selectEntities: selectEventEntities,
  selectIds: selectEventIds,
  selectTotal: selectEventTotal,
} = eventAdapter.getSelectors((state: RootState) => state?.event);

export const eventSlice = createSlice({
  name: "event",
  initialState: initialEventState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(mobilize.endpoints.getMobilizeEvents.matchFulfilled),
      (state, action) => {
        eventAdapter.upsertMany(state, action.payload);
      }
    );
    // builder.addMatcher(
    //   isAnyOf(
    //     mobilize.endpoints.getMobilizeEvent.matchFulfilled
    //   ),
    //   (state, action) => {
    //     eventAdapter.upsertOne(state, action.payload);
    //   }
    // );
    // builder.addMatcher(
    //   mobilize.endpoints.deleteEvent.matchFulfilled,
    //   (state, action) => {
    //     eventAdapter.removeOne(state, action.meta.arg.originalArgs);
    //   }
    // );
  },
});

export const mobilize = baseApi
  .enhanceEndpoints({ addTagTypes: ["MobilizeEvents", "MobilizeEvent"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMobilizeEvents: builder.query<Event[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "mobilize/events",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "MobilizeEvent" as const,
                  id,
                })),
                { type: "MobilizeEvents" as const },
              ]
            : [{ type: "MobilizeEvents" as const }],
      }),
      getMobilizeEvent: builder.query<
        Attendance[],
        { id: number; params?: object }
      >({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `mobilize/events/${id}`,
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "MobilizeEvent" as const, id: arg.id },
        ],
      }),
      createMobilizeAttendance: builder.mutation<Attendance, Attendance>({
        query: (request) => {
          return {
            url: `mobilize/events/${request.event_id}`,
            method: "POST",
            body: request,
          };
        },
        invalidatesTags: (args) => [
          { type: "MobilizeEvent" as const, id: args?.id },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetMobilizeEventsQuery,
  useLazyGetMobilizeEventsQuery,
  usePrefetch: useMobilizeEventsPrefetch,
  useGetMobilizeEventQuery,
  useCreateMobilizeAttendanceMutation,
} = mobilize;
