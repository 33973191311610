import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Fab,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { JFormik } from "forms";
import {
  useCreateTaskMutation,
  usePatchTaskMutation,
} from "store/api/task.api";
import { useTaskForm } from "forms/forms/useTaskForm";
import { toast } from "react-toastify";
import { ProspectList, Script, Task } from "store/api/models";
import { Close, Save } from "@mui/icons-material";
import { FormBuilder } from "forms/builder/FormBuilder";
import { FlowFormBuilder } from "forms/builder/FlowFormBuilder";
import {
  FormBuilderContext,
  FormBuilderProvider,
} from "forms/builder/formBuilder.context";
import { ReactFlowProvider } from "@xyflow/react";
import BranchingForm from "forms/render/BranchingForm";
import { useDispatch, useSelector } from "react-redux";
import { selectAllSkills, selectSkillEntities } from "store";
import { RootState } from "store/reducer";
import { TaskProspects } from "./TaskProspects";
import { selectProspectListEntities } from "store/api/prospect_list.api";
import { FormBuilderRedux } from "forms/builder/FormBuilderRedux";
import { FlowFormBuilderRedux } from "forms/builder/FlowFormBuilderRedux";
import { BranchingFormRedux } from "forms/render/BranchingFormRedux";
import { selectScriptById } from "store/api/script.api";
import { setDisabled, setInitialData } from "store/slice/formBuilder.slice";

export const TaskDetails = ({
  task,
  disabled,
  close,
  callback,
}: {
  task: Task;
  disabled: boolean;
  close: Function;
  callback: Function;
}) => {
  const dispatch = useDispatch();
  const [createTask] = useCreateTaskMutation();
  const [patchTask] = usePatchTaskMutation();
  const skillEntities = useSelector((state: RootState) =>
    selectSkillEntities(state)
  );
  const prospectListEntities = useSelector((state: RootState) =>
    selectProspectListEntities(state)
  );

  const form = useTaskForm();
  const [values, setValues] = useState<any>({
    ...task,
    skills: task?.skills?.map((ts) => skillEntities[ts]) || [],
    prospect_lists: task?.prospect_lists || [],
  });

  const [value, setValue] = useState("1");
  const handleChange = (task: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const formRef = useRef<any>(null);

  // useEffect(() => {
  //   // Initialize Redux store with script data
  //   // if (disabled) {
  //   dispatch(
  //     setInitialData({
  //       title: task?.title || "",
  //       scriptId: task?.id || null,
  //       questions: task.form?.questions || [],
  //       edges: task.form?.edges || [],
  //       answers: task.form?.answers || {},
  //       disabled: disabled,
  //     })
  //   );
  //   // }
  // }, []);

  // Update disabled state in Redux when prop changes
  useEffect(() => {
    dispatch(setDisabled(disabled));
  }, [disabled, dispatch]);
  return (
    <ReactFlowProvider key={task.id}>
      <FormBuilderProvider
        questions={task.form?.questions}
        edges={task.form?.edges}
        disabled={disabled}
      >
        <FormBuilderContext.Consumer>
          {({ questions, edges, disabled }) => (
            <Paper
              key={task.id}
              sx={{
                flexGrow: 1,
                p: 1,
                position: "relative", // Allows positioning of the close button
                height: "100%",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
              }}
              elevation={5}
            >
              {!disabled && (
                <Fab
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: 10,
                    bottom: 10,
                  }}
                  onClick={async () => {
                    if (values.id) {
                      try {
                        const res = await patchTask({
                          ...values,
                          form: {
                            ...(values.form || {}),
                            questions,
                            edges,
                          },
                        }).unwrap();
                        close();
                        if (res.id) {
                          // setValues((values: any) => ({ ...values, id: res.id }));
                          callback && callback(res);
                          toast.info("Task updated successfuly");
                        }
                      } catch (error) {
                        toast.error("Unable to update task");
                      }
                    } else {
                      try {
                        let res: any = {};
                        res = await createTask({
                          ...values,
                          form: {
                            ...(values.form || {}),
                            questions,
                            edges,
                          },
                        }).unwrap();
                        close();
                        if (res.id) {
                          // setValues((values: any) => ({ ...values, id: res.id }));
                          callback && callback(res);
                          toast.info("Task created successfuly");
                        }
                      } catch (error) {
                        toast.error("Unable to create task");
                      }
                    }
                  }}
                >
                  <Save />
                </Fab>
              )}

              {/* {!disabled && (
                <Button
                  variant="contained"
                  onClick={async () => {
                    if (formRef.current) {
                      const { setFieldValue, submitForm } = formRef.current;
                      await submitForm();
                      close();
                    }
                  }}
                  sx={{ position: "absolute", top: 8, left: 8, m: 1 }}
                >
                  {task?.id ? "SAVE TASK" : "CREATE TASK"}
                </Button>
              )} */}
              <IconButton
                onClick={() => close()}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <Close />
              </IconButton>
              <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4 }}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                  <Tab label="TASK DETAILS" value="1" />
                  <Tab label="SCRIPT QUESTIONS" value="2" />
                  <Tab label="SCRIPT LOGIC" value="3" />
                  <Tab label="SCRIPT PREVIEW" value="4" />
                </Tabs>
              </Box>

              <TabPanel value={value} index={"1"}>
                <Box sx={{ px: 1, mt: 1 }}>
                  <Typography variant="h6">TASK DETAILS</Typography>
                </Box>
                <Box sx={{ px: 1, mt: 3 }}>
                  <JFormik
                    // formRef={formRef}
                    disabled={disabled}
                    initialValues={values}
                    autoStateSave={(newValues: any) => {
                      setValues((prev: any) => ({ ...prev, ...newValues }));
                    }}
                    handleSubmit={() => {}}
                    validationSchema={form.validationSchema}
                    form={form.form}
                  />
                  <TaskProspects
                    disabled={disabled}
                    prospect_lists={values.prospect_lists?.filter(
                      (l: any) => l
                    )}
                    handleListUpdates={(lists: ProspectList[]) => {
                      setValues((prev: any) => ({
                        ...prev,
                        prospect_lists: lists.map((pl, index) => ({
                          ...pl,
                          sequence: index,
                        })),
                      }));
                    }}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={"2"}>
                <FormBuilderRedux />
              </TabPanel>
              <TabPanel value={value} index={"3"}>
                <FlowFormBuilderRedux />
              </TabPanel>
              <TabPanel value={value} index={"4"}>
                <BranchingFormRedux />
              </TabPanel>
            </Paper>
          )}
        </FormBuilderContext.Consumer>
      </FormBuilderProvider>
    </ReactFlowProvider>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        display: value === index ? "flex" : undefined,
        flexGrow: 1,
        flexDirection: "column",
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
