import { useState } from "react";
import * as Yup from "yup";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  useGetSkillsQuery,
  useDeleteSkillMutation,
  useUpdateSkillMutation,
  useCreateSkillMutation,
} from "store/api";
import { JFormik } from "forms";
import { sortString } from "utils";
import { toast } from "react-toastify";

export const SkillsTab = () => {
  const [editing /*, setEditing*/] = useState<boolean>(false);
  const { data: skills = [], isLoading } = useGetSkillsQuery({});
  const [createSkill] = useCreateSkillMutation();
  const [editSkill] = useUpdateSkillMutation();
  const [deleteSkill] = useDeleteSkillMutation();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <JFormik
      initialValues={() => ({
        skills: [...(skills || [])].sort((a, b) => sortString(a.name, b.name)),
      })}
      handleSubmit={async (values, formik) => {}}
      loading={isLoading}
      validationSchema={
        editing
          ? Yup.object({
              name: Yup.string().required("Skill name is required"),
              // type: Yup.string().nullable().required('Skill type is required'),
            })
          : Yup.object({})
      }
      disabled={!editing}
      form={[
        {
          name: "skills",
          itemType: "table_array",
          label: "Skills",
          tableProps: {
            onCreate: async (values: any) => {
              try {
                const res = await createSkill(values).unwrap();
                if (res.id > 0) {
                  toast.success("Skill successfully created!");
                } else {
                  toast.warning(
                    "There was an issue creating the skill, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error creating the skill, please try again."
                );
              }
            },
            onEdit: async (values: any) => {
              try {
                const res = await editSkill(values).unwrap();
                if (res.id > 0) {
                  toast.success("Skill successfully edited!");
                } else {
                  toast.warning(
                    "There was an issue editing the skill, please try again."
                  );
                }
              } catch (error) {
                toast.error(
                  "There was an error editing the skill, please try again."
                );
              }
            },
            onDelete: async (id: number) => {
              try {
                await deleteSkill(id).unwrap();
                toast.success("Skill successfully deleted!");
              } catch (error) {
                toast.error(
                  "There was an error deleting the skill, please try again."
                );
              }
            },
            columns: [
              {
                name: "name",
                label: "Skill *",
                itemType: "textfield",
                jItemProps: {
                  width: "70%",
                  touched: true,
                  sx: {
                    boxSizing: "border-box",
                    px: { xs: 0.5 },
                    minWidth: 180,
                  },
                },
                gridProps: { size: { xs: 8 } },
              },
              // {
              //   name: "weighting",
              //   label: "Weighting",
              //   itemType: "textfield",
              //   jItemProps: {
              //     type: "number",
              //     width: "70%",
              //     touched: true,
              //     sx: {
              //       boxSizing: "border-box",
              //       px: { xs: 0.5 },
              //       minWidth: 180,
              //     },
              //   },
              //   gridProps: { size: { xs: 4 } },
              // },
            ],
          },
          device: downMd ? "mobile" : "desktop",
        },
      ]}
    />
  );
};
