import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, Paper } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{ borderRadius: '10px' }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export interface ChipButtonMenuItemProps {
  label: string;
  icon?: JSX.Element;
  onClick: Function;
}

export type ChipButtonMenuProps = Omit<ButtonProps, 'color'> & {
  menuItems: ChipButtonMenuItemProps[];
  disabled?: boolean;
  label: string;
  color?: string; //"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined
};

export const ChipButtonMenu = ({
  menuItems,
  disabled = false,
  label: inLabel,
  color,
  sx = {},
}: ChipButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(inLabel);
  useEffect(() => setLabel(inLabel), [inLabel]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const menuWidth = anchorEl && anchorEl.offsetWidth ? anchorEl.offsetWidth : 0;
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <Paper
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{ borderRadius: '20px' }}
      elevation={1}
    >
      <Button
        id="demo-customized-button"
        variant="contained"
        disabled={disabled}
        disableElevation
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          handleClick(event);
        }}
        endIcon={
          isHover || loading ? (
            !loading ? (
              <KeyboardArrowDownIcon />
            ) : (
              <CircularProgress color="inherit" size={14} />
            )
          ) : undefined
        }
        sx={{
          borderRadius: '20px',
          border: '1px solid white',
          backgroundColor: color,
          color: 'white',
          '&:hover': {
            // class selector
            backgroundColor: color,
            opacity: 0.75,
          },
          ...sx,
          py: 0.5,
        }}
      >
        {label}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: menuWidth ? { width: { xs: menuWidth, sm: 'auto' } } : {},
        }}
      >
        {menuItems &&
          menuItems
            ?.filter(mi => mi.label != label)
            ?.map((menuItem, index) => (
              <MenuItem
                key={index}
                onClick={event => {
                  setLoading(true);
                  event.stopPropagation();
                  event.preventDefault();
                  menuItem.onClick();
                  handleClose(event);
                  setLoading(false);
                  setLabel(menuItem.label);
                }}
                disableRipple
              >
                {menuItem.icon}
                {menuItem.label}
              </MenuItem>
            ))}
      </StyledMenu>
    </Paper>
  );
};
