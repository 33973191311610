import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useOnlineOffline = () => {
  useEffect(() => {
    window.addEventListener('online', online);
    window.addEventListener('offline', offline);
    return function cleanup() {
      window.removeEventListener('online', online);
      window.removeEventListener('offline', offline);
    };
  });

  const online = () =>
    toast.info('Internet connection restored, please refresh the page.');

  const offline = () => toast.warning('Lost internet connection');
};
