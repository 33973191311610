import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { baseApi } from "../common.api";
import { EventGroup } from "./mobilize_models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the EventGroup entity
const eventGroupAdapter = createEntityAdapter<EventGroup, EntityId>({
  selectId: (event_group: EventGroup) => event_group.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface EventGroupsState extends EntityState<EventGroup, EntityId> {
  // Add any additional state properties here if needed
}

const initialEventGroupState: EventGroupsState =
  eventGroupAdapter.getInitialState();

// Selectors for EventGroups
export const {
  selectById: selectEventGroupById,
  selectAll: selectAllEventGroups,
  selectEntities: selectEventGroupEntities,
  selectIds: selectEventGroupIds,
  selectTotal: selectEventGroupTotal,
} = eventGroupAdapter.getSelectors((state: RootState) => state?.event_group);

export const eventGroupSlice = createSlice({
  name: "event_group",
  initialState: initialEventGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(mobilize_event_group.endpoints.getEventGroups.matchFulfilled),
      (state, action) => {
        eventGroupAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        mobilize_event_group.endpoints.createEventGroup.matchFulfilled,
        mobilize_event_group.endpoints.updateEventGroup.matchFulfilled,
        mobilize_event_group.endpoints.patchEventGroup.matchFulfilled
      ),
      (state, action) => {
        eventGroupAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      mobilize_event_group.endpoints.deleteEventGroup.matchFulfilled,
      (state, action) => {
        eventGroupAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

export const mobilize_event_group = baseApi
  .enhanceEndpoints({ addTagTypes: ["EventGroups", "EventGroup"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEventGroups: builder.query<EventGroup[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "event_group",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "EventGroup" as const,
                  id,
                })),
                { type: "EventGroups" as const },
              ]
            : [{ type: "EventGroups" as const }],
      }),
      getEventGroup: builder.query<EventGroup, { id: number; params?: object }>(
        {
          query: ({ id, params }: { id: number; params?: object }) => ({
            url: `event_group/${id}`,
            method: "GET",
            params: params,
          }),
          providesTags: (result, error, arg) => [
            { type: "EventGroup", id: arg.id },
          ],
          keepUnusedDataFor: 90,
        }
      ),
      createEventGroup: builder.mutation<EventGroup, Partial<EventGroup>>({
        query: (request) => ({
          url: "event_group",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "EventGroups" }],
      }),
      patchEventGroup: builder.mutation<EventGroup, EventGroup>({
        query: (request) => ({
          url: `event_group/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventGroup", id: arg.id },
        ],
      }),
      updateEventGroup: builder.mutation<EventGroup, EventGroup>({
        query: (request) => ({
          url: `event_group/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventGroup", id: arg.id },
        ],
      }),
      deleteEventGroup: builder.mutation<void, number>({
        query: (id) => ({
          url: `event_group/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "EventGroup", id: arg },
          { type: "EventGroups" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useCreateEventGroupMutation,
  useGetEventGroupQuery,
  useDeleteEventGroupMutation,
  useGetEventGroupsQuery,
  usePatchEventGroupMutation,
  useUpdateEventGroupMutation,
  usePrefetch: useEventGroupsPrefetch,
} = mobilize_event_group;
