import { useState } from "react";
import { Box, Paper, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useGetEventsQuery } from "store/api/event.api";
import { Event } from "store/api/models";
import { EventList } from "./EventList";
import { EventDetails } from "./EventDetails";
import { BackdropLoading } from "../BackdropLoading";

export const EventsIndex = () => {
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: events = [] as Event[],
    isLoading: eventsLoading,
    refetch,
  } = useGetEventsQuery({ params: { nested: "all" } });
  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
  };
  const handleEventEditClick = (event: Event) => {
    setEditing(true);
    setSelectedEvent(event);
  };
  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);
  const handleOpen = () => setOpenSpeedDial(true);
  const handleClose = () => setOpenSpeedDial(false);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {eventsLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedEvent ? 0.5 : 1,
            width: selectedEvent ? "50%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedEvent ? 1 : 0,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Paper sx={{ p: 1, height: "100%", maxHeight: "100%" }} elevation={5}>
            <EventList
              events={events}
              selectedEvent={selectedEvent}
              handleEventClick={handleEventClick}
              handleEventEditClick={handleEventEditClick}
            />
          </Paper>
        </Box>
      )}

      <Fab
        color="primary"
        sx={{
          position: "absolute",
          left: 10,
          bottom: 50,
        }}
        onClick={() => {
          setEditing(true);
          setSelectedEvent({} as Event);
        }}
      >
        <Add />
      </Fab>

      {/* Event Details */}
      {selectedEvent && (
        <Box
          sx={{
            flexGrow: 0.5,
            maxWidth: "50%",
            width: "50%",
            bgcolor: "transparent",
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <EventDetails
            disabled={!editing}
            event={selectedEvent}
            close={() => {
              setSelectedEvent(undefined);
              setEditing(false);
            }}
            callback={async () => {
              await refetch();
              setSelectedEvent(undefined);
              setEditing(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
