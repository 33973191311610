import { Skill, Conversation } from "store/api/models";

export const drawerWidthOpen = 250;
export const drawerWidthCollapsed = 60;
export const toolbarHeight = 64;

export interface VolunteerState {
  selectedSkill: Skill | null;
  selectedConversation: Conversation | null;
  selectedVanID?: number | null;
  fromCallHistory?: boolean;
  selectedMenu: number | null;
  isLoading: boolean;
  completed: boolean;
  disposition: boolean;
  welcome: boolean;
  callOngoing: boolean;
}
