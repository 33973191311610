import * as React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { JItem } from '../jformik/jformik.types';
import { useJFormikContext } from '../jformik/jformik.context';

export interface LoadingButtonProps {
  label: string;
  loading: boolean;
}

export const FButton = (jItem: JItem) => {
  const { disabled = false } = useJFormikContext();
  if (!disabled || jItem?.jItemProps?.type !== 'submit') {
    const { sx = {}, onClick, ...restJItemProps } = jItem?.jItemProps || {};

    return (
      <Field name={jItem.name}>
        {({
          form: { isSubmitting, isValid, touched, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }: FieldProps) => (
          <Button
            variant="contained"
            color="secondary"
            // size="small"
            disabled={!touched || jItem.disabled}
            fullWidth
            onClick={() => {
              if (jItem?.jItemProps?.type === 'submit') {
                setFieldValue('cansubmit', 'submitclicked');
              }
              onClick &&
                typeof onClick === 'function' &&
                onClick({ setFieldValue, values });
            }}
            endIcon={
              isSubmitting &&
              jItem?.jItemProps?.type === 'submit' && (
                <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
              )
            }
            sx={{ minHeight: { xs: 48, sm: 64 }, ...sx }}
            {...restJItemProps}
          >
            {jItem.label}
          </Button>
        )}
      </Field>
    );
  } else {
    return <div />;
  }
};
