import { forwardRef, useState, useMemo, useEffect } from "react";
import {
  IconButton,
  Tooltip,
  Typography,
  Grid2 as Grid,
  CircularProgress,
  Box,
  ButtonBase,
  Paper,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  styled,
  accordionDetailsClasses,
  accordionClasses,
  paperClasses,
} from "@mui/material";
import { Layers, LayersOutlined } from "@mui/icons-material";
import {
  FieldArray,
  FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from "formik";
import { Add, Delete, Edit, Save } from "@mui/icons-material";
import { useBreakpointQuery } from "../../utils";
import { JItem } from "../jformik/jformik.types";
import { JItemMapper } from "../jformik/JItemMapper";
import { useJFormikContext } from "../jformik/jformik.context";
import { AlertDialog } from "../../dialogs/AlertDialog";

const FField = ({ jItem }: { jItem: JItem }) => {
  const { gridProps } = jItem;
  const { values } = useFormikContext();
  const gridSizesResult =
    typeof gridProps === "function"
      ? gridProps(values)
      : gridProps !== null
      ? gridProps
      : {};
  const gridSizes = typeof gridSizesResult === "object" ? gridSizesResult : {};
  return (
    <Grid {...gridSizes} key={`${jItem.name}-index`}>
      <JItemMapper jItem={jItem} />
    </Grid>
  );
};

const FRow = ({
  jItem,
  row,
  index,
  remove,
}: {
  jItem: JItem;
  row: any;
  index: number;
  remove: Function;
}) => {
  const {
    name,
    label,
    array,
    arrayProps = { wrap: false, noModify: false },
    disabled: jDisabled,
    device = "desktop",
  } = jItem;
  const { values = {} } = useFormikContext();
  const { disabled: fDisabled = false } = useJFormikContext();
  const { validateForm } = useFormikContext();
  const disabled = fDisabled || jDisabled;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const smDown = useBreakpointQuery("sm-down");
  const [isClickExpanded, setIsClickExpanded] = useState<boolean>(false);

  const isActionColumn =
    !arrayProps.wrap &&
    ((disabled &&
      !jItem.jItemProps?.onEdit &&
      jItem.jItemProps?.onCreate &&
      !getIn(values, `${jItem.name}.${index}`)?.id) ||
      (disabled && jItem.jItemProps?.onEdit) ||
      (!disabled && !arrayProps.noModify) ||
      (jItem.jItemProps?.onDelete &&
        (isEditing || !getIn(values, `${jItem.name}.${index}`)?.id)));
  // (disabled || arrayProps.noModify) &&
  // ((!disabled && !arrayProps.noModify) ||
  //   jItem.jItemProps?.onCreate ||
  //   jItem.jItemProps?.onEdit ||
  //   jItem.jItemProps?.onDelete);

  const disableDelete = jItem.jItemProps?.disableDelete
    ? jItem.jItemProps?.disableDelete(values, index)
    : false;

  const ActionCellWrapperForDesktop = ({ children = null } = {} as any) => {
    return (
      <Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
          }}
        >
          {children}
        </Grid>
      </Grid>
    );
  };

  const ActionCellWrapperForMobile = ({ children = null } = {} as any) => {
    return (
      <Grid>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "center", justifyContent: "flex-start" }}
        >
          {children}
        </Grid>
      </Grid>
    );
  };
  const ActionCell = () => {
    return (
      <>
        {disabled &&
          !jItem.jItemProps?.onEdit &&
          jItem.jItemProps?.onCreate &&
          !getIn(values, `${jItem.name}.${index}`)?.id && (
            <Grid>
              {device === "desktop" ? (
                <IconButton
                  onClick={async () => {
                    if (jItem?.jItemProps?.onCreate) {
                      const isValid = await validateForm();
                      if (Object.keys(isValid).length === 0) {
                        await jItem.jItemProps.onCreate(
                          getIn(values, `${jItem.name}.${index}`),
                          values,
                          jItem.name,
                          index
                        );
                        setIsEditing(false);
                      }
                    }
                  }}
                  size="large"
                >
                  <Save />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Save />}
                  onClick={async () => {
                    if (jItem?.jItemProps?.onCreate) {
                      const isValid = await validateForm();
                      if (Object.keys(isValid).length === 0) {
                        await jItem.jItemProps.onCreate(
                          getIn(values, `${jItem.name}.${index}`),
                          values,
                          jItem.name,
                          index
                        );
                        setIsEditing(false);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              )}
            </Grid>
          )}

        {disabled && jItem.jItemProps?.onEdit && (
          <>
            {readOnly ? (
              <Grid>
                <IconButton disabled>
                  <Edit sx={{ visibility: "hidden" }} />
                </IconButton>
              </Grid>
            ) : (
              <Grid>
                {isEditing || !getIn(values, `${jItem.name}.${index}`)?.id ? (
                  <>
                    {device === "desktop" ? (
                      <IconButton
                        onClick={async () => {
                          if (
                            jItem?.jItemProps?.onEdit &&
                            jItem?.jItemProps?.onCreate
                          ) {
                            const isValid = await validateForm();
                            if (Object.keys(isValid).length === 0) {
                              if (
                                !getIn(values, `${jItem.name}.${index}`)?.id
                              ) {
                                await jItem.jItemProps.onCreate(
                                  getIn(values, `${jItem.name}.${index}`),
                                  values,
                                  jItem.name,
                                  index
                                );
                              } else {
                                await jItem.jItemProps.onEdit(
                                  getIn(values, `${jItem.name}.${index}`),
                                  values,
                                  jItem.name,
                                  index
                                );
                              }
                              setIsEditing(false);
                            }
                          }
                        }}
                        size="large"
                      >
                        <Save />
                      </IconButton>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<Save />}
                        onClick={async () => {
                          if (
                            jItem?.jItemProps?.onEdit &&
                            jItem?.jItemProps?.onCreate
                          ) {
                            const isValid = await validateForm();
                            if (Object.keys(isValid).length === 0) {
                              if (
                                !getIn(values, `${jItem.name}.${index}`)?.id
                              ) {
                                await jItem.jItemProps.onCreate(
                                  getIn(values, `${jItem.name}.${index}`),
                                  values,
                                  jItem.name,
                                  index
                                );
                              } else {
                                await jItem.jItemProps.onEdit(
                                  getIn(values, `${jItem.name}.${index}`)
                                );
                              }
                              setIsEditing(false);
                            }
                          }
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {device === "desktop" ? (
                      <IconButton
                        onClick={() => {
                          setIsEditing(true);
                        }}
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<Edit />}
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </>
                )}
              </Grid>
            )}
          </>
        )}

        {((!disabled && !arrayProps.noModify) ||
          (jItem.jItemProps?.onDelete &&
            (isEditing || !getIn(values, `${jItem.name}.${index}`)?.id))) &&
          !disableDelete && (
            <Grid>
              {device === "desktop" ? (
                <IconButton
                  onClick={() => {
                    if (!getIn(values, `${jItem.name}.${index}`)?.id) {
                      remove(index);
                      setIsEditing(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  size="large"
                >
                  <Delete />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Delete />}
                  onClick={() => {
                    if (!getIn(values, `${jItem.name}.${index}`)?.id) {
                      remove(index);
                      setIsEditing(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          )}
      </>
    );
  };

  const GridRow = () => (
    <Grid
      container
      spacing={smDown ? 2 : 3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap={smDown ? "wrap" : arrayProps?.wrap ? "wrap" : "nowrap"}
      key={index}
      sx={{ mb: "15px" }}
    >
      {array &&
        array
          .filter(
            (item: JItem, cIndex: number) =>
              !item.precondition ||
              item.precondition({ ...((values as object) || {}), index: index })
          )
          .map((item: JItem, cIndex: number) => (
            <FField
              jItem={{
                ...item,
                name: `${name}.${index}.${item.name}`,
                disabled:
                  isEditing &&
                  item.jItemProps?.disabledFunc &&
                  typeof item.jItemProps?.disabledFunc === "function"
                    ? item.jItemProps?.disabledFunc(
                        (values as any)?.[jItem.name]?.[index]
                      )
                    : isEditing ||
                      ((getIn(values, `${name}.${index}.${item.name}`)?.new ||
                        getIn(values, `${name}.${index}`)?.new) &&
                        (jItem.jItemProps?.onCreate ||
                          jItem.jItemProps?.onDelete ||
                          jItem.jItemProps?.onEdit))
                    ? false
                    : item?.disabled,
              }}
              key={`ffield-${cIndex}}`}
            />
          ))}
      {device === "desktop" && isActionColumn && (
        <ActionCellWrapperForDesktop>
          <ActionCell />
        </ActionCellWrapperForDesktop>
      )}
      {device === "mobile" && isActionColumn && (
        <ActionCellWrapperForMobile>
          <ActionCell />
        </ActionCellWrapperForMobile>
      )}
      {!disabled && !arrayProps.noModify && !isActionColumn && (
        <>
          {arrayProps.wrap ? (
            <Paper
              elevation={5}
              sx={{
                position: "absolute",
                top: { xs: -10, sm: -15 },
                right: { xs: -10, sm: -15 },
                bgcolor: (theme) => theme.palette.secondary.main,
                borderRadius: "100%",
              }}
            >
              <IconButton
                onClick={async () => {
                  if (jItem.jItemProps?.onDelete) {
                    // setIsDeleting(index)
                    await jItem.jItemProps?.onDelete(
                      row.id,
                      (deleted: boolean) => {
                        if (deleted) {
                          remove(isDeleting);
                        }
                      }
                    );
                  } else {
                    remove(isDeleting);
                  }
                  setIsDeleting(false);
                }}
                sx={{
                  width: { xs: 32, sm: 40 },
                  height: { xs: 32, sm: 40 },
                }}
              >
                <Delete
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    fontSize: { xs: 18, sm: 24 },
                  }}
                />
              </IconButton>
            </Paper>
          ) : (
            <Grid size={{ xs: 1 }} textAlign={"right"}>
              <IconButton
                onClick={async () => {
                  setIsDeleting(true);
                  setOpen(true);
                }}
                size="small"
              >
                {isDeleting ? <CircularProgress size={18} /> : <Delete />}
              </IconButton>
            </Grid>
          )}{" "}
        </>
      )}
    </Grid>
  );
  const accordion =
    jItem.jItemProps?.accordion &&
    typeof jItem.jItemProps?.accordion === "function"
      ? jItem.jItemProps?.accordion(values, row, index, name)
      : undefined;
  const readOnly =
    jItem.jItemProps?.readOnly &&
    jItem.jItemProps?.readOnly((values as any)?.[jItem.name]?.[index]);
  return (
    <Paper
      elevation={arrayProps.wrap ? 3 : 0}
      sx={
        arrayProps.wrap
          ? {
              px: { xs: 2, sm: 3, md: 5 },
              pt: 5,
              pb: 3,
              mb: 5,
              bgColor: "white",
              borderRadius: 1,
              position: "relative",
            }
          : {}
      }
    >
      <AlertDialog
        open={open}
        title={`Delete ${jItem.label}?`}
        content={`Are you sure you want to delete this ${jItem.label}?`}
        onClose={() => {
          setIsDeleting(false);
          setOpen(false);
        }}
        onConfirm={async () => {
          if (jItem.jItemProps?.onDelete) {
            // setIsDeleting(index)
            await jItem.jItemProps?.onDelete(row.id, (deleted: boolean) => {
              if (deleted) {
                remove(index);
              }
            });
          } else {
            remove(index);
          }
          setIsDeleting(false);
          setIsEditing(false);
          setOpen(false);
        }}
      />
      {accordion ? (
        <Box sx={{ mb: isClickExpanded ? 2 : 0 }}>
          <Accordion
            elevation={isClickExpanded ? 5 : 0}
            sx={{ py: isClickExpanded ? 1 : 0, my: isClickExpanded ? 1 : 0 }}
            expanded={isClickExpanded}
          >
            <AccordionSummary sx={{ pl: 0 }}>
              <>
                <Tooltip
                  title={
                    isClickExpanded ? "Click to collapse" : "Click to expand"
                  }
                >
                  <IconButton
                    id={"layers-expand"}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setIsClickExpanded(!isClickExpanded);
                    }}
                    sx={{ mt: 0, pb: 0 }}
                  >
                    {isClickExpanded ? (
                      <LayersOutlined
                        sx={{
                          WebkitFilter: "invert(50%)",
                          filter: "invert(50%)",
                        }}
                      />
                    ) : (
                      <Layers />
                    )}
                  </IconButton>
                </Tooltip>

                {GridRow()}
              </>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 0, pl: 10 }}>
              <FField
                jItem={{
                  ...accordion,
                  name: `${name}.${index}.${accordion?.name}`,
                }}
                key={`ffield-accordion-details-${name}-${index}-${accordion?.name}`}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : (
        GridRow()
      )}
    </Paper>
  );
};

export const FFieldArray = forwardRef((jItem: JItem, ref) => {
  const {
    name,
    label,
    array,
    arrayProps = { wrap: false, noModify: false },
    disabled: jDisabled,
  } = jItem;
  const { values } = useFormikContext();
  const smDown = useBreakpointQuery("sm-down");
  const { disabled: fDisabled = false } = useJFormikContext();
  const disabled = jDisabled === false ? false : fDisabled || jDisabled;
  const [defaultValuesPushObject, setDefaultValuesPushObject] =
    useState<object>();
  // const [open, setOpen] = useState<boolean>(false);
  const pushObject = useMemo(
    () =>
      array
        ? array.reduce((obj, item) => {
            if (!item.precondition || item.precondition(values)) {
              return { ...obj, [item.name]: item.defaultValue || null };
            } else {
              return obj;
            }
          }, {})
        : {},
    [array]
  );
  // const [isDeleting, setIsDeleting] = useState<number>(-1);
  useEffect(() => {
    //add a key of `${name}PushObject` to formik values to have default values on push
    if (!defaultValuesPushObject) {
      const defaultValuesObject = getIn(values, `${name}PushObject`);
      if (defaultValuesObject) {
        setDefaultValuesPushObject(defaultValuesObject);
      }
    }
  }, [array, values, name, defaultValuesPushObject]);
  return (
    <FieldArray name={name} validateOnChange={false}>
      {({
        push,
        remove,
        form: { values, errors },
        name,
      }: FieldArrayRenderProps) => {
        const arrayerror =
          typeof getIn(errors, jItem.name) === "string" &&
          getIn(errors, jItem.name);
        return (
          <Grid sx={{ marginTop: 1 }}>
            {values &&
            getIn(values, name) &&
            getIn(values, name)?.length > 0 ? (
              getIn(values, name)?.map((row: any, index: number) => {
                return (
                  <FRow
                    jItem={jItem}
                    row={row}
                    index={index}
                    remove={remove}
                    key={`row-${index}-${jItem.name}`}
                  />
                );
              })
            ) : (
              <div />
            )}
            {!!arrayerror && (
              <Grid>
                <Typography
                  variant="caption"
                  sx={{ color: (theme) => theme.palette.error.main }}
                >
                  {arrayerror}
                </Typography>
              </Grid>
            )}
            {((!disabled && !arrayProps.noModify) ||
              jItem.jItemProps?.onCreate) && (
              <>
                {arrayProps.wrap ? (
                  <Grid>
                    <ButtonBase
                      sx={{
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23CCC' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        borderRadius: 1,
                        width: "100%",
                        minHeight: 60,
                      }}
                      onClick={() => {
                        push({
                          ...(defaultValuesPushObject || pushObject),
                          new: true,
                        });
                      }}
                    >
                      <Box
                        id={`add-${label.replaceAll(" ", "-")}`}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: (theme) => theme.palette.text.secondary,
                          textTransform: "uppercase",
                        }}
                      >
                        <Add sx={{ color: "inherit" }} />
                        <Typography
                          variant="subtitle2"
                          color="inherit"
                          sx={{ lineHeight: 1, marginLeft: 1.5 }}
                        >
                          {jItem.jItemProps?.addLabel || `Add ${label}`}
                        </Typography>
                      </Box>
                    </ButtonBase>
                  </Grid>
                ) : (
                  <Typography variant="caption" id="tableTitle" component="div">
                    <Tooltip title={`Add ${jItem.label}`}>
                      <IconButton
                        id={`add-${label.toLowerCase().replaceAll(" ", "-")}`}
                        onClick={() => {
                          push({
                            ...(defaultValuesPushObject || pushObject),
                            new: true,
                          });
                        }}
                        size="small"
                      >
                        <Add color="secondary" />
                      </IconButton>
                    </Tooltip>{" "}
                    {jItem.jItemProps?.addLabel || `Add ${label}`}
                  </Typography>
                )}
              </>
            )}
          </Grid>
        );
      }}
    </FieldArray>
  );
});

const CAccordion = styled(Accordion)(({ expanded }) => ({
  [`${paperClasses.root}`]: {
    margin: expanded ? "100 !important" : 0,
  },
  [`${accordionClasses.root}`]: {
    //   margin: expanded ? 100 : 0,
    // },
    "& .Mui-expanded": {
      margin: expanded ? "100 !important" : 0,
    },
  },
}));

const CAccordionSummary = styled(AccordionSummary)(() => ({
  "& .Mui-expanded": {
    margin: 0,
  },
}));

const CAccordionDetails = styled(AccordionDetails)(() => ({
  [`${accordionDetailsClasses.root}`]: {
    padding: 0,
  },
}));
