import { Box, IconButton, Paper, Typography } from "@mui/material";
import { JFormik } from "forms";
import { Close } from "@mui/icons-material";
import { useAttendanceForm } from "forms/forms/useAttendanceForm";
import { Attendance, Event } from "store/api/mobilize/mobilize_models";
import { useCreateMobilizeAttendanceMutation } from "store/api/mobilize/mobilize.api";
import { toast } from "react-toastify";
import moment from "moment";
import { Person } from "store/api/models";
import { useCreateAttendanceMutation } from "store/api/attendance.api";
import { useSelector } from "react-redux";
import { selectUser } from "store";
import { useFormBuilderContext } from "forms/builder/formBuilder.context";

export const AttendanceDetails = ({
  attendance,
  event,
  disabled = true,
  close,
  callback,
  person,
  signupCounts,
}: {
  attendance: Attendance;
  event: Event;
  disabled?: boolean;
  close: Function;
  callback: Function;
  person?: Person;
  signupCounts?: {
    [key: number]: number;
  };
}) => {
  const user = useSelector(selectUser);
  const conversation = useSelector(
    (state: any) => state.formBuilder.conversation
  );
  const form = useAttendanceForm();
  const [createMobilizeAttendance] = useCreateMobilizeAttendanceMutation();
  const [createAttendance] = useCreateAttendanceMutation();
  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: 1,
        bgcolor: "white",
        position: "relative", // Allows positioning of the close button
        height: "100%",
        overflow: "scroll",
      }}
      elevation={5}
    >
      <IconButton
        onClick={() => close()}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          backgroundColor: (theme) => theme.palette.secondary.main,
        }}
      >
        <Close sx={{ color: "white" }} />
      </IconButton>
      <Box sx={{ px: 1, mt: 1 }}>
        <Typography variant="h6">
          {disabled ? "ATTENDANCE DETAILS" : "SUBMIT ATTENDANCE: "}
        </Typography>
        <Typography sx={{ color: (theme) => theme.palette.primary.dark }}>
          <small>{event.title}</small>
        </Typography>
      </Box>
      <Box sx={{ px: 1, mt: 3 }}>
        <JFormik
          disabled={disabled}
          initialValues={{
            ...attendance,
            person: {
              ...(attendance.person || {}),
              given_name: attendance?.person?.given_name || person?.firstname,
              family_name: attendance?.person?.family_name || person?.lastname,
              email_address:
                (attendance?.person as any)?.email_addresses?.filter(
                  (e: any) => e.primary
                )?.[0]?.address || person?.email,
              phone_number:
                (attendance?.person as any)?.phone_numbers?.filter(
                  (p: any) => p.primary
                )?.[0]?.number || person?.phone,
              postal_code:
                (attendance?.person as any)?.postal_addresses?.filter(
                  (p: any) => p.primary
                )?.[0]?.postal_code || person?.zip,
            },
            timeslots: !disabled
              ? event.timeslots?.map((t) => ({
                  ...t,
                  start_date: moment(t.start_date * 1000),
                  end_date: moment(t.end_date * 1000),
                  signup_count: signupCounts?.[t.id],
                }))
              : undefined,
            timeslot:
              disabled && attendance.timeslot
                ? {
                    ...attendance.timeslot,
                    start_date: moment(attendance.timeslot?.start_date * 1000),
                    end_date: moment(attendance.timeslot?.end_date * 1000),
                    signup_count: signupCounts?.[attendance.timeslot?.id],
                  }
                : undefined,
          }}
          enableReinitialize={true}
          handleSubmit={async (values, formik) => {
            try {
              let res: any = {};
              const updatedValues = {
                event_id: event.id,
                ...values,
                timeslots: values?.timeslots
                  ?.filter((t: any) => t.checked)
                  .map((t: any) => ({ ...t, timeslot_id: t.id })),
              };
              if (conversation) {
                res = await createAttendance({
                  volunteer: user.volunteer,
                  form: updatedValues,
                  conversation: conversation?.id,
                  prospect: conversation?.prospect,
                }).unwrap();
              }
              await createMobilizeAttendance(updatedValues).unwrap();
              close();
              callback &&
                callback({
                  id: res?.id,
                  volunteer: user.volunteer,
                  form: updatedValues,
                  conversation: conversation?.id,
                  prospect: conversation?.prospect,
                });
              toast.info("Attendance created successfuly");
            } catch (error) {
              toast.error("Unable to create attendance");
            }
          }}
          form={form.form}
        />
      </Box>
    </Paper>
  );
};
