import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete,
  Edit,
  ContentCopy,
  CalendarViewDay,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { EventGroup } from "store/api/mobilize/mobilize_models";
import { useDeleteEventGroupMutation } from "store/api/mobilize/mobilize_event_group.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";

export const MobilizeEventGroups = ({
  event_groups,
  selectedEventGroup,
  handleDuplicateEventGroup = (event_group: EventGroup) => {},
  close,
  handleEventGroupClick,
  handleEventGroupEditClick,
}: {
  event_groups: EventGroup[];
  selectedEventGroup?: EventGroup;
  handleDuplicateEventGroup?: (event_group: EventGroup) => void;
  close: Function;
  handleEventGroupClick: (event_group: EventGroup) => void;
  handleEventGroupEditClick: (event_group: EventGroup) => void;
}) => {
  const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [deleteEventGroup] = useDeleteEventGroupMutation();

  const filteredEventGroups = [...(event_groups || [])]
    ?.filter((t) => {
      return t.name?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.name, b.name));
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <Box sx={{ flexGrow: 1, flexDirection: "column", display: "flex" }}>
        <AlertDialog
          open={!!openAlert}
          title={`Delete event_group`}
          content={`Are you sure you want to delete this event group?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteEventGroup(openAlert);
                close();
                toast.success("EventGroup successfully deleted");
              } catch (error) {
                toast.success("Unable to delete event_group");
              }
            }
            setOpenAlert(undefined);
          }}
        />
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid>
              <Typography variant="h6" component={"span"}>
                EVENT GROUPS
              </Typography>
            </Grid>
            <Grid>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid sx={{ textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Event Groups"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    sx={{ maxWidth: { xs: "none", sm: 340 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <FormattedList
          listItems={filteredEventGroups}
          onItemClick={(event_group: EventGroup) =>
            handleEventGroupClick(event_group)
          }
          editItem={(event_group: EventGroup) =>
            handleEventGroupEditClick(event_group)
          }
          deleteItem={(id: number) => deleteEventGroup(id)}
          duplicateItem={(event_group: EventGroup) =>
            handleDuplicateEventGroup({
              ...event_group,
              id: 0,
            } as EventGroup)
          }
          primary={(event_group: EventGroup) => event_group.name || "untitled"}
          icon={
            <CalendarViewDay
              sx={{ color: (theme) => theme.palette.secondary.main }}
              fontSize="small"
            />
          }
          onClose={() => close()}
          selected={selectedEventGroup}
          itemName="event group"
        /> */}
        <List
          dense
          disablePadding
          sx={{
            height: "100%",
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          {filteredEventGroups?.map((event_group: EventGroup) => (
            <>
              <ListItem
                key={event_group.id}
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: (theme) =>
                    selectedEventGroup &&
                    selectedEventGroup?.id === event_group.id
                      ? theme.palette.divider
                      : undefined,
                }}
                dense
                secondaryAction={
                  <Box
                    sx={{
                      borderRadius: "4px",
                      border: (theme) =>
                        `1px solid ${theme.palette.secondary.main}`,
                    }}
                  >
                    <Tooltip title={"duplicate"}>
                      <IconButton
                        onClick={() =>
                          handleDuplicateEventGroup({
                            ...event_group,
                            id: 0,
                          } as EventGroup)
                        }
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit"}>
                      <IconButton
                        onClick={() => handleEventGroupEditClick(event_group)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete"}>
                      {openAlert && openAlert === event_group.id ? (
                        <IconButton>
                          <CircularProgress size={22} />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => setOpenAlert(event_group.id)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Tooltip>
                  </Box>
                }
              >
                <ListItemIcon>
                  <CalendarViewDay
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                    fontSize="small"
                  />
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Typography variant="overline" sx={{ fontWeight: 700 }}>
                      {event_group.name}
                    </Typography>
                  }
                  // secondary={`${event_group.contact_r?.email}`}
                  onClick={() => handleEventGroupClick(event_group)}
                  sx={{ cursor: "pointer", py: 0, my: 0 }}
                />
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};
