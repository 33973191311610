import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Prospect } from "./models";
import { RootState } from "../reducer";

// Create an EntityAdapter for the Prospect entity
const prospectAdapter = createEntityAdapter<Prospect, EntityId>({
  selectId: (prospect: Prospect) => prospect.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface ProspectsState extends EntityState<Prospect, EntityId> {
  // Add any additional state properties here if needed
}

export const initialProspectState: ProspectsState =
  prospectAdapter.getInitialState();

// Selectors for Prospects
export const {
  selectById: selectProspectById,
  selectAll: selectAllProspects,
  selectEntities: selectProspectEntities,
  selectIds: selectProspectIds,
  selectTotal: selectProspectTotal,
} = prospectAdapter.getSelectors((state: RootState) => state?.prospect);

export const prospectSlice = createSlice({
  name: "prospect",
  initialState: initialProspectState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        prospect.endpoints.getProspects.matchFulfilled
        // prospect.endpoints.createProspect.matchFulfilled,
        // prospect.endpoints.updateProspect.matchFulfilled,
        // prospect.endpoints.patchProspect.matchFulfilled
      ),
      (state, action) => {
        prospectAdapter.upsertMany(state, action.payload);
      }
    );
    // builder.addMatcher(
    //   prospect.endpoints.deleteProspect.matchFulfilled,
    //   (state, action) => {
    //     prospectAdapter.removeOne(state, action.meta.arg.originalArgs);
    //   }
    // );
  },
});

// Define your API slice
export const prospect = baseApi
  .enhanceEndpoints({ addTagTypes: ["Prospects", "Prospect"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProspects: builder.query<Prospect[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "/prospect",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ vanid }) => ({
                  type: "Prospect" as const,
                  vanid,
                })),
                { type: "Prospects" as const },
              ]
            : [{ type: "Prospects" as const }],
      }),
      getProspect: builder.query<Prospect, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `/prospect/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "Prospect", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      // createProspect: builder.mutation<Prospect, Partial<Prospect>>({
      //   query: (request) => ({
      //     url: "prospect",
      //     method: "POST",
      //     body: request,
      //   }),
      //   invalidatesTags: [{ type: "Prospects" }],
      // }),
      // patchProspect: builder.mutation<Prospect, Prospect>({
      //   query: (request) => ({
      //     url: `prospect/${request.id}`,
      //     method: "PATCH",
      //     body: request,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "Prospect", id: arg.id },
      //   ],
      // }),
      // updateProspect: builder.mutation<Prospect, Prospect>({
      //   query: (request) => ({
      //     url: `prospect/${request.id}`,
      //     method: "PUT",
      //     body: request,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "Prospect", id: arg.id },
      //   ],
      // }),
      // deleteProspect: builder.mutation<void, number>({
      //   query: (id) => ({
      //     url: `prospect/${id}`,
      //     method: "DELETE",
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "Prospect", id: arg },
      //     { type: "Prospects" },
      //   ],
      // }),
    }),
  });

// Auto-generated hooks
export const {
  useGetProspectsQuery,
  useGetProspectQuery,
  // useCreateProspectMutation,
  // usePatchProspectMutation,
  // useUpdateProspectMutation,
  // useDeleteProspectMutation,
  useLazyGetProspectsQuery,
  usePrefetch: useProspectsPrefetch,
} = prospect;
