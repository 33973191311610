import {
  createListenerMiddleware,
  addListener,
  isAnyOf,
} from "@reduxjs/toolkit";
import { contact } from "store/api";
import { volunteer } from "store/api/volunteer.api";

import { RootState } from "store/reducer";
import { AppDispatch } from "store/types/types";

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening.withTypes<
  RootState,
  AppDispatch
>();
export type AppStartListening = typeof startAppListening;

export const addAppListener = addListener.withTypes<RootState, AppDispatch>();
export type AppAddListener = typeof addAppListener;

const volunteerListeners = (startAppListening: AppStartListening) => {
  startAppListening({
    matcher: isAnyOf(
      volunteer.endpoints.createVolunteer.matchFulfilled,
      volunteer.endpoints.patchVolunteer.matchFulfilled,
      volunteer.endpoints.updateVolunteer.matchFulfilled,
      volunteer.endpoints.deleteVolunteer.matchFulfilled
    ),
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(contact.util.invalidateTags(["Contacts"]));
    },
  });
};

volunteerListeners(startAppListening);
