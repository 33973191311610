import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "../common.api";
import { User } from "./ngp_models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the User entity
const ngpcontactAdapter = createEntityAdapter<User, EntityId>({
  selectId: (ngpcontact: User) => ngpcontact.vanid,
  sortComparer: (a, b) => a.vanid - b.vanid,
});

// Define the initial state using the adapter's getInitialState method
interface ContactsState extends EntityState<User, EntityId> {
  // Add any additional state properties here if needed
}

export const initialContactState: ContactsState =
  ngpcontactAdapter.getInitialState();

// Selectors for Contacts
export const {
  selectById: selectNGPContactById,
  selectAll: selectAllNGPContacts,
  selectEntities: selectNGPContactEntities,
  selectIds: selectNGPContactIds,
  selectTotal: selectNGPContactTotal,
} = ngpcontactAdapter.getSelectors((state: RootState) => state?.ngpcontact);

export const ngpcontactSlice = createSlice({
  name: "ngpcontact",
  initialState: initialContactState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(ngpcontact.endpoints.getNGPContacts.matchFulfilled),
      (state, action) => {
        ngpcontactAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(ngpcontact.endpoints.getNGPContact.matchFulfilled),
      (state, action) => {
        ngpcontactAdapter.upsertOne(state, action.payload);
      }
    );
  },
});

// Define your API slice
export const ngpcontact = baseApi
  .enhanceEndpoints({ addTagTypes: ["NGPContacts", "User"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getNGPContacts: builder.query<User[], { params?: { table: string } }>({
        query: ({ params }: { params?: { table: string } }) => ({
          url: "ngp/contacts",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ vanid }) => ({
                  type: "User" as const,
                  vanid,
                })),
                { type: "NGPContacts" as const },
              ]
            : [{ type: "NGPContacts" as const }],
      }),
      getNGPContact: builder.query<
        User,
        { vanid: number; params?: { table: string } }
      >({
        query: ({
          vanid,
          params,
        }: {
          vanid: number;
          params?: { table: string };
        }) => ({
          url: `ngp/contacts/${vanid}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "User", vanid: `${arg.vanid}-${arg?.params?.table}` },
        ],
        keepUnusedDataFor: 90,
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetNGPContactsQuery,
  useGetNGPContactQuery,
  useLazyGetNGPContactsQuery,
  usePrefetch: useContactsPrefetch,
} = ngpcontact;
