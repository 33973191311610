import {
  Drawer,
  List,
  Tooltip,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectSkillEntities } from "store";
import { Volunteer } from "store/api/models";
import { RootState } from "store/reducer";
import {
  VolunteerState,
  drawerWidthOpen,
  drawerWidthCollapsed,
} from "./agent.types";
import { toast } from "react-toastify";

export const AgentMenu = ({
  isMenuOpen,
  volunteer,
  state,
  setState,
}: {
  isMenuOpen: boolean;
  volunteer?: Volunteer;
  state: VolunteerState;
  setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
}) => {
  const skills = useSelector((state: RootState) => selectSkillEntities(state));
  return (
    <Drawer
      elevation={5}
      variant="permanent"
      PaperProps={{ sx: { backgroundColor: "#4F5C7B" } }}
      sx={{
        width: isMenuOpen ? drawerWidthOpen : drawerWidthCollapsed,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isMenuOpen ? drawerWidthOpen : drawerWidthCollapsed,
          boxSizing: "border-box",
          top: "64px", // Ensure the drawer starts below the AppBar
        },
      }}
    >
      <List>
        {volunteer?.skills?.map((skill, index: number) => (
          <Tooltip
            title={skills[skill]?.name}
            placement="right"
            disableHoverListener={isMenuOpen}
          >
            <ListItemButton
              key={skill}
              sx={{
                justifyContent: "flex-start",
                color: "white",
                bgcolor: (theme) =>
                  state.selectedMenu === skill
                    ? theme.palette.info.light
                    : undefined,
                "&:hover": {
                  bgcolor: (theme) => theme.palette.info.dark,
                },
              }}
              onClick={() => {
                if (state.callOngoing) {
                  toast.warning(
                    "Please complete the call before submitting the form. Thank you!"
                  );
                  return;
                }
                setState((prev) => ({
                  ...prev,
                  state: "selectedSkill",
                  selectedMenu: skill,
                  selectedConversation: null,
                  selectedSkill: skills[skill],
                  fromCallHistory: false,
                  isLoading: false,
                }));
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: (theme) => theme.palette.secondary.main,
                    height: 30,
                    width: 30,
                    p: 0,
                    fontWeight: 900,
                  }}
                >
                  {skills[skill]?.name?.[0]}
                </Avatar>
              </ListItemAvatar>
              {isMenuOpen && (
                <ListItemText
                  primary={<b>{skills[skill]?.name}</b>}
                  sx={{
                    color: "white",
                    fontWeight: 900,
                    textTransform: "uppercase",
                  }}
                />
              )}
            </ListItemButton>
          </Tooltip>
        ))}
        <Divider
          sx={{
            borderWidth: 10,
            bgcolor: (theme) => theme.palette.primary.dark,
          }}
        />
        <Tooltip
          title={"Call History"}
          placement="right"
          disableHoverListener={isMenuOpen}
        >
          <ListItemButton
            key={"history"}
            sx={{
              justifyContent: "flex-start",
              color: "white",
              bgcolor: (theme) =>
                state.selectedMenu === -1
                  ? theme.palette.info.light
                  : undefined,
              "&:hover": {
                bgcolor: (theme) => theme.palette.info.dark,
              },
            }}
            onClick={() => {
              if (state.callOngoing) {
                toast.warning(
                  "Please complete the call before submitting the form. Thank you!"
                );
                return;
              }
              setState((prevState) =>
                prevState.selectedMenu === -1
                  ? {
                      ...prevState,
                      state: "history",
                      selectedSkill: null,
                      selectedConversation: null,
                      selectedMenu: null,
                      isLoading: false,
                      completed: false,
                      fromCallHistory: false,
                    }
                  : {
                      ...prevState,
                      state: "history",
                      selectedSkill: null,
                      selectedConversation: null,
                      selectedMenu: -1,
                      fromCallHistory: false,
                      isLoading: false,
                      completed: false,
                    }
              );
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: (theme) => theme.palette.secondary.main,
                  height: 30,
                  width: 30,
                  p: 0,
                  fontWeight: 900,
                }}
              >
                H
              </Avatar>
            </ListItemAvatar>
            {isMenuOpen && (
              <ListItemText
                primary={<b>Call History</b>}
                sx={{
                  color: "white",
                  fontWeight: 900,
                  textTransform: "uppercase",
                }}
              />
            )}
          </ListItemButton>
        </Tooltip>
        {/* Add more menu items here */}
      </List>
    </Drawer>
  );
};
