import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Call } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Call entity
const callAdapter = createEntityAdapter<Call, EntityId>({
  selectId: (call: Call) => call.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface CallsState extends EntityState<Call, EntityId> {
  // Add any additional state properties here if needed
}

const initialState: CallsState = callAdapter.getInitialState();

// Selectors for Calls
export const { selectById: selectCallById, selectAll: selectAllCalls } =
  callAdapter.getSelectors((state: RootState) => state?.call);

export const callSlice = createSlice({
  name: "call",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(call.endpoints.getCalls.matchFulfilled),
      (state, action) => {
        callAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        call.endpoints.createCall.matchFulfilled,
        call.endpoints.updateCall.matchFulfilled,
        call.endpoints.patchCall.matchFulfilled
      ),
      (state, action) => {
        callAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      call.endpoints.deleteCall.matchFulfilled,
      (state, action) => {
        callAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const call = baseApi
  .enhanceEndpoints({ addTagTypes: ["Calls", "Call"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCalls: builder.query<Call[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "call",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Call" as const,
                  id,
                })),
                { type: "Calls" as const },
              ]
            : [{ type: "Calls" as const }],
      }),
      getCall: builder.query<Call, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `call/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Call", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createCall: builder.mutation<Call, Partial<Call>>({
        query: (request) => ({
          url: "call",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Calls" }],
      }),
      patchCall: builder.mutation<Call, Call>({
        query: (request) => ({
          url: `call/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Call", id: arg.id }],
      }),
      updateCall: builder.mutation<Call, Call>({
        query: (request) => ({
          url: `call/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Call", id: arg.id }],
      }),
      deleteCall: builder.mutation<void, number>({
        query: (id) => ({
          url: `call/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Call", id: arg },
          { type: "Calls" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetCallsQuery,
  useGetCallQuery,
  useCreateCallMutation,
  usePatchCallMutation,
  useUpdateCallMutation,
  useDeleteCallMutation,
  usePrefetch: useCallsPrefetch,
} = call;
