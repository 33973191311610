import { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete,
  Edit,
  AdminPanelSettings,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Login } from "store/api/models";
import {
  selectLoginEntities,
  useDeleteLoginMutation,
} from "store/api/login.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";
import moment from "moment";
import { FormattedList } from "components/list/FormattedList";
import { useSelector } from "react-redux";

export const LoginList = ({
  logins,
  selectedLogin = {} as Login,
  handleLoginClick = () => {},
  handleLoginEditClick = () => {},
  close,
}: {
  logins: Login[];
  selectedLogin?: Login;
  handleLoginClick?: (login: Login) => void;
  handleLoginEditClick?: (login: Login) => void;
  close: Function;
}) => {
  const loginEntities = useSelector(selectLoginEntities);
  const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [deleteLogin] = useDeleteLoginMutation();

  const filteredLogins = [...(logins || [])]
    ?.filter((l) => l.email.toLowerCase()?.includes(search.toLowerCase()))
    ?.sort((a, b) => sortString(a.email, b.email));
  const ListLogin = ({ login }: { login: Login }) => (
    <>
      <ListItem
        key={login.id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: (theme) =>
            selectedLogin && selectedLogin?.id === login.id
              ? theme.palette.divider
              : undefined,
        }}
        dense
        secondaryAction={
          <Box
            sx={{
              borderRadius: "4px",
              border: (theme) => `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            <Tooltip title={"Edit"}>
              <IconButton onClick={() => handleLoginEditClick(login)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Delete"}>
              {openAlert && openAlert === login.id ? (
                <IconButton>
                  <CircularProgress size={22} />
                </IconButton>
              ) : (
                <IconButton onClick={() => setOpenAlert(login.id)}>
                  <Delete />
                </IconButton>
              )}
            </Tooltip>
          </Box>
        }
      >
        <ListItemIcon>
          <AdminPanelSettings color="secondary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="overline" sx={{ fontWeight: 700 }}>
              {`${login.email}`}
            </Typography>
          }
          secondary={
            login?.last_logged_in &&
            moment(login.last_logged_in).isSameOrAfter(moment().startOf("year"))
              ? `Last logged in: ${moment(
                  login.last_logged_in
                ).toLocaleString()}`
              : `Not yet logged in`
          }
          onClick={() => handleLoginClick(login)}
          sx={{ cursor: "pointer", py: 0, my: 0 }}
        />
      </ListItem>
      <Divider />
    </>
  );
  return (
    <Box
      sx={{
        maxHeight: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          maxHeight: "100%",
          height: "100%",
        }}
      >
        <AlertDialog
          open={!!openAlert}
          title={`Delete login`}
          content={`Are you sure you want to delete this login?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteLogin(openAlert);
                toast.success("Login successfully deleted");
              } catch (error) {
                toast.success("Unable to delete contact");
              }
            }
            setOpenAlert(undefined);
          }}
        />
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid>
              <Typography variant="h6" component={"span"} sx={{ px: 1 }}>
                ADMIN LIST
              </Typography>
            </Grid>
            <Grid>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid sx={{ textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Logins"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    sx={{ maxWidth: { xs: "none", sm: 340 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <FormattedList
          listItems={filteredLogins.map((l) => l.id)}
          primary={(id: number) => loginEntities?.[id]?.email}
          secondary={(id: number) => {
            const login = loginEntities?.[id];
            return login?.last_logged_in &&
              moment(login.last_logged_in).isSameOrAfter(
                moment().startOf("year")
              )
              ? `Last logged in: ${moment(
                  login.last_logged_in
                ).toLocaleString()}`
              : `Not yet logged in`;
          }}
          icon={<AdminPanelSettings color="secondary" />}
          onItemClick={(id: number) => handleLoginClick(loginEntities?.[id])}
          editItem={(id: number) => handleLoginEditClick(loginEntities?.[id])}
          deleteItem={(id: number) => deleteLogin(id)}
          selectedId={selectedLogin.id}
          itemName="login"
          onClose={() => close()}
        />
        {/* <List
          dense
          disablePadding
          sx={{
            maxHeight: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {filteredLogins?.map((login: Login) => (
            <ListLogin login={login} />
          ))}
        </List> */}
      </Box>
    </Box>
  );
};
