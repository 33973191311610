import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete,
  Edit,
  ContentCopy,
  Dvr,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { TaskGroup } from "store/api/models";
import { useDeleteTaskGroupMutation } from "store/api/task_group.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";

export const TaskGroups = ({
  task_groups,
  selectedTaskGroup,
  handleDuplicateTaskGroup = (task_group: TaskGroup) => {},
  close,
  handleTaskGroupClick,
  handleTaskGroupEditClick,
}: {
  task_groups: TaskGroup[];
  selectedTaskGroup?: TaskGroup;
  handleDuplicateTaskGroup?: (task_group: TaskGroup) => void;
  close: Function;
  handleTaskGroupClick: (task_group: TaskGroup) => void;
  handleTaskGroupEditClick: (task_group: TaskGroup) => void;
}) => {
  const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [deleteTaskGroup] = useDeleteTaskGroupMutation();

  const filteredTaskGroups = [...(task_groups || [])]
    ?.filter((t) => {
      return t.name?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.name, b.name));
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <Box sx={{ flexGrow: 1, flexDirection: "column", display: "flex" }}>
        <AlertDialog
          open={!!openAlert}
          title={`Delete task group`}
          content={`Are you sure you want to delete this event group?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteTaskGroup(openAlert);
                close();
                toast.success("TaskGroup successfully deleted");
              } catch (error) {
                toast.success("Unable to delete task group");
              }
            }
            setOpenAlert(undefined);
          }}
        />
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid>
              <Typography variant="h6" component={"span"}>
                TASK GROUPS
              </Typography>
            </Grid>
            <Grid>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid sx={{ textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Task Groups"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    sx={{ maxWidth: { xs: "none", sm: 340 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <List
          dense
          disablePadding
          sx={{
            height: "100%",
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          {filteredTaskGroups?.map((task_group: TaskGroup) => (
            <>
              <ListItem
                key={task_group.id}
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: (theme) =>
                    selectedTaskGroup && selectedTaskGroup?.id === task_group.id
                      ? theme.palette.divider
                      : undefined,
                }}
                dense
                secondaryAction={
                  <Box
                    sx={{
                      borderRadius: "4px",
                      border: (theme) =>
                        `1px solid ${theme.palette.secondary.main}`,
                    }}
                  >
                    <Tooltip title={"duplicate"}>
                      <IconButton
                        onClick={() =>
                          handleDuplicateTaskGroup({
                            ...task_group,
                            id: 0,
                          } as TaskGroup)
                        }
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit"}>
                      <IconButton
                        onClick={() => handleTaskGroupEditClick(task_group)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete"}>
                      {openAlert && openAlert === task_group.id ? (
                        <IconButton>
                          <CircularProgress size={22} />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setOpenAlert(task_group.id)}>
                          <Delete />
                        </IconButton>
                      )}
                    </Tooltip>
                  </Box>
                }
              >
                <ListItemIcon>
                  <Dvr
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                    fontSize="small"
                  />
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Typography variant="overline" sx={{ fontWeight: 700 }}>
                      {task_group.name}
                    </Typography>
                  }
                  // secondary={`${task_group.contact_r?.email}`}
                  onClick={() => handleTaskGroupClick(task_group)}
                  sx={{ cursor: "pointer", py: 0, my: 0 }}
                />
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};
