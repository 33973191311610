import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  CircularProgress,
  Box,
  TextField,
  Tab,
  Tabs,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { useGetSchemaQuery, Schema } from "store/api/ngp/schema.api";
import { TabPanel } from "components/tab/TabPanel";
import { useNGPContact } from "utils/ngp.util";
import { AccountCircle, Close } from "@mui/icons-material";
import { NGPContactProfile } from "components/ngp/NGPContact";
import ReactJson from "react-json-view";

interface SchemaTreeViewProps {
  open: boolean; // Prop to control whether the drawer is open
  selectedVanID: number | undefined;
  onClose: () => void; // Function to close the drawer
}

// Function to transform schema data to TreeViewBaseItem structure
const buildTreeViewData = (schema: Schema): TreeViewBaseItem[] => {
  const schemaTree: TreeViewBaseItem = {
    id: "schema",
    label: schema?.schema,
    children: schema?.tables?.map((table) => ({
      id: table.table_name,
      label: table.table_name,
      children: table.columns.map((column) => ({
        id: `${table.table_name}-${column.column_name}`,
        label: `${column.column_name} (${column.data_type})`,
      })),
    })),
  };

  return [schemaTree];
};

const filterTreeData = (
  treeData: TreeViewBaseItem[],
  searchTerm: string
): TreeViewBaseItem[] => {
  if (!searchTerm) {
    return treeData;
  }
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return treeData
    .map((table) => {
      // Filter columns based on the search term
      const filteredColumns = table.children?.filter(
        (column) =>
          column.label.toLowerCase().includes(lowerCaseSearchTerm) ||
          column.children?.find((c) =>
            c.label.toLowerCase().includes(lowerCaseSearchTerm)
          )
      );

      // Check if table name or any column matches the search term
      if (
        table.label.toLowerCase().includes(lowerCaseSearchTerm) ||
        filteredColumns?.length
      ) {
        return { ...table, children: filteredColumns };
      }
      return null;
    })
    .filter(Boolean) as TreeViewBaseItem[];
};

export const SchemaDrawer = ({
  open,
  selectedVanID,
  onClose,
}: SchemaTreeViewProps) => {
  const [searchTerm, setSearchTerm] = useState(
    selectedVanID ? `${selectedVanID}` : ""
  );
  const [vanIdSearch, setVanIdSearch] = useState<string | undefined>(
    selectedVanID ? `${selectedVanID}` : undefined
  );
  useEffect(() => {
    if (selectedVanID) {
      setValue("2");
      setVanIdSearch(`${selectedVanID}`);
    } else {
      setValue("1");
      setVanIdSearch(undefined);
    }
  }, [selectedVanID]);
  // Fetch schema from the backend API using RTK Query
  const {
    data: schema = {} as Schema,
    error,
    isLoading,
  } = useGetSchemaQuery({});
  // Transform schema data into TreeViewBaseItem[]
  const treeViewData = buildTreeViewData(schema);
  // Filter the tree view data based on the search term
  const filteredData = filterTreeData(treeViewData, searchTerm);
  const [value, setValue] = useState(selectedVanID ? "1" : "2");
  const handleChange = (script: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography color="error">Failed to load schema</Typography>;
  }
  return (
    <Drawer
      anchor="right"
      open={open || !!selectedVanID}
      onClose={onClose}
      variant="temporary"
    >
      <Box
        sx={{
          height: "100vh",
          width: "50vw",
          padding: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 1 }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="SCHEMA" value="1" sx={{ fontWeight: 700 }} />
            <Tab label="VANID VIEWER" value="2" sx={{ fontWeight: 700 }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={"1"} sx={{ flex: 2 }}>
          <Typography
            variant="h6"
            sx={{ my: 1, fontWeight: 700, textTransform: "uppercase" }}
          >
            {schema.schema} tables
          </Typography>
          {/* Search Input */}
          <TextField
            label="Search Tables/Columns"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          {/* <ReactJson src={schema} collapsed={true} /> */}
          <RichTreeView
            defaultExpandedItems={["grid"]}
            slots={{ item: CustomTreeItem }}
            items={filteredData}
          />
        </TabPanel>
        <TabPanel value={value} index={"2"} sx={{ flex: 2 }}>
          <Typography
            variant="h6"
            sx={{ my: 1, fontWeight: 700, textTransform: "uppercase" }}
          >
            CONTACT INFO
          </Typography>
          {/* Search Input */}
          <TextField
            label="Search by VanId"
            variant="outlined"
            fullWidth
            value={vanIdSearch || ""}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setVanIdSearch(e.target.value)}
            sx={{ marginBottom: 2 }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {vanIdSearch && vanIdSearch !== "" ? (
                      <IconButton onClick={() => setVanIdSearch(undefined)}>
                        <Close />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setVanIdSearch("121597460")}>
                        <AccountCircle />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              },
            }}
          />
          <Box>
            <NGPContactProfile vanid={vanIdSearch} />
          </Box>
        </TabPanel>
      </Box>
    </Drawer>
  );
};

const VariableTypography = ({ text }: { text?: string }) => (
  <>{text && <Typography variant="h6">{text}</Typography>}</>
);

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0, 1.2),
    ...theme.applyStyles("light", {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    }),
    ...theme.applyStyles("dark", {
      color: theme.palette.primary.contrastText,
    }),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  ...theme.applyStyles("light", {
    color: theme.palette.grey[800],
  }),
}));
