import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectIsLoggedIn, useLoginMutation, useRefreshMutation } from "store";
import { getAuthStatus } from "utils";
import { Login } from "./Login";

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [login, loginResult] = useLoginMutation();
  const [refresh] = useRefreshMutation();
  const from = (location?.state as any)?.from;

  useEffect(() => {
    if (isLoggedIn) {
      if (from?.pathname) {
        navigate(`${from.pathname}${from.search}${from.hash}`);
      } else {
        navigate("/");
      }
    } else {
      const authStatus = getAuthStatus();
      if (authStatus) {
        refresh();
      }
    }
  }, [isLoggedIn, navigate, from, refresh, loginResult.isSuccess]);

  return (
    <Login
      img={
        "https://mobilizeamerica.imgix.net/uploads/organization/MCDP%20Social%20Media%20Logo_20220406143438659632.png?auto=format"
      }
      login={login}
      navigate={navigate}
    />
  );
};
