import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Field, FieldProps } from 'formik';
import { JItem } from '../jformik/jformik.types';
import { JItemMapper } from '../jformik/JItemMapper';
import { Divider } from '@mui/material';
import { useJFormikContext } from '../jformik/jformik.context';

export interface LoadingButtonProps {
  label: string;
  loading: boolean;
}

const FField = ({ jItem, values }: { jItem: JItem; values: any }) => {
  const { gridProps } = jItem;
  const gridSizesResult =
    typeof gridProps === 'function'
      ? gridProps(values)
      : gridProps !== null
      ? gridProps
      : {};
  const gridSizes = typeof gridSizesResult === 'object' ? gridSizesResult : {};
  return (
    <Grid item xs={12} {...gridSizes} key={`${jItem.name}-index`}>
      <JItemMapper jItem={jItem} />
    </Grid>
  );
};

export const FAccordion = (jItem: JItem) => {
  const { disabled: fDisabled = false } = useJFormikContext();
  const disabled =
    jItem.disabled === false ? false : fDisabled || jItem.disabled;
  return (
    <Field name={jItem.name}>
      {({ field: { name, value }, form: { values } }: FieldProps) => {
        return (
          <Accordion elevation={0}>
            <AccordionSummary sx={{ pl: 0 }} expandIcon={<ExpandMoreIcon />}>
              <FField
                jItem={{
                  ...jItem.jItemProps?.summary,
                  name,
                  disabled: disabled,
                }}
                values={values}
                key={`ffield-accordion-summary-${jItem.jItemProps?.summary?.name}}`}
              />
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 3 }}>
              <Typography
                variant="overline"
                sx={{
                  color: theme =>
                    !!disabled ? theme.palette.text.disabled : 'inherit',
                }}
              >
                <u> {jItem.jItemProps?.details_label}</u>
              </Typography>
              <FField
                jItem={{
                  ...jItem.jItemProps?.details,
                  name: `${name.replace(
                    jItem.jItemProps?.summary?.name,
                    jItem.jItemProps?.details?.name
                  )}`,
                  disabled: disabled,
                }}
                values={values}
                key={`ffield-accordion-details-${jItem.jItemProps?.summary?.name}}`}
              />
            </AccordionDetails>
          </Accordion>
        );
      }}
    </Field>
  );
};
