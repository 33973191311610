import { Button, Input, Grid2 as Grid } from "@mui/material";
import Papa from "papaparse"; // Library to parse CSV
import { saveAs } from "file-saver"; // Library to download files
import { Person } from "store/api/models";
import { InputFileUpload } from "forms/fields/InputFileField";
import { CloudDownload } from "@mui/icons-material";

export const CSVProspectUpload = ({
  disabled,
  setParsedData,
  form,
  setForm,
}: {
  disabled: boolean;
  setParsedData: React.Dispatch<React.SetStateAction<Person[]>>;
  form: Record<string, any>;
  setForm: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}) => {
  // Example CSV generation
  const handleDownloadExample = () => {
    const csvContent = `VanID,firstname,lastname,phone,email,address,city,state,zip
1,John,Doe,1234567890,john.doe@example.com,123 Street St, City, IL, 12345
2,Jane,Doe,0987654321,jane.doe@example.com,456 Road Rd, City, NC, 54321`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "example.csv");
  };

  // Handle CSV file upload
  const handleFileUpload = (file: File | null) => {
    // const file = e.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results: any) => {
          // Parse CSV into JSON and store it
          setParsedData(results.data as Person[]);
          setForm((prevForm) => ({ ...prevForm, parsedData: results.data }));
        },
        skipEmptyLines: true,
      });
      setForm((prevForm) => ({ ...prevForm, fileName: file.name }));
    }
  };

  return (
    <Grid container justifyContent={"space-between"}>
      {/* CSV Upload Input */}
      <Grid>
        <InputFileUpload
          disabled={disabled}
          value={form?.fileName}
          handleFileUpload={handleFileUpload}
        />
      </Grid>
      {/* Button to download example CSV */}
      <Grid>
        <Button
          disabled={disabled}
          variant="outlined"
          color="secondary"
          onClick={handleDownloadExample}
          startIcon={<CloudDownload />}
        >
          Download Example CSV
        </Button>
      </Grid>
    </Grid>
  );
};
