import { useRef, useState } from "react";
import {
  Box,
  Button,
  Grid2,
  IconButton,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { JFormik } from "forms";
import { toast } from "react-toastify";
import { Event } from "store/api/mobilize/mobilize_models";
import { Close, LinkRounded } from "@mui/icons-material";
import { useGetMobilizeEventQuery } from "store/api/mobilize/mobilize.api";
import { AttendanceList } from "./AttendanceList";
import { useMobilizeEventForm } from "forms/forms/useMobilizeEventForm";
import moment from "moment";
import { VisMap } from "./VisGLMap";
import { TabPanel } from "components/tab/TabPanel";

export const MobilizeEventDetails = ({
  event,
  disabled,
  close,
  callback,
}: {
  event: Event;
  disabled: boolean;
  close: Function;
  callback: Function;
}) => {
  const { data: event_attendances, refetch } = useGetMobilizeEventQuery(
    {
      id: event.id,
      params: { cc: event?.sponsor?.id === 37434 },
    },
    { skip: !event.id, refetchOnMountOrArgChange: true }
  );
  let signupCounts: { [key: number]: number } = {};
  event.timeslots?.forEach((t, i) => {
    event_attendances?.forEach((e) => {
      if (e.status !== "Cancelled") {
        signupCounts[event.timeslots?.[i]?.id] =
          (signupCounts[event.timeslots?.[i]?.id] || 0) +
          [...(e.timeslots || []), ...(e.timeslot ? [e.timeslot] : [])].filter(
            (ts) => ts.id === t.id
          ).length;
      }
    });
  });

  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const formRef = useRef<any>(null);
  return (
    <Paper
      key={event.id}
      sx={{
        p: 1,
        position: "relative", // Allows positioning of the close button
        height: "100%",
        maxHeight: "100%",
      }}
      elevation={5}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        {!disabled && (
          <Button
            variant="contained"
            // onClick={() => close()}
            onClick={async () => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
              close();
            }}
            sx={{ position: "absolute", top: 8, left: 8, m: 1 }}
          >
            {event?.id ? "SAVE EVENT" : "CREATE EVENT"}
          </Button>
        )}
        <IconButton
          onClick={() => close()}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: (theme) => theme.zIndex.fab,
          }}
        >
          <Close />
        </IconButton>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="EVENT DETAILS" value="1" />
            <Tab label="EVENT ATTENDANCES" value="2" />
          </Tabs>
        </Box>

        <TabPanel value={value} index={"1"}>
          <Box
            sx={{
              height: "100%",
              maxHeight: "100%",
              overflowY: "scroll",
            }}
          >
            <Box
              sx={{
                p: 1,
                mt: 1,
              }}
            >
              <Typography variant="h6">
                MOBILIZE EVENT DETAILS{" "}
                {event.browser_url && (
                  <Link href={event.browser_url} target="_blank">
                    <LinkRounded fontSize="small" />
                  </Link>
                )}
              </Typography>
            </Box>
            <Box sx={{ p: 1, mt: 1 }}>
              <MobilizeEventDetailsOnly
                event={event}
                formRef={formRef}
                signupCounts={signupCounts}
              />
              <Grid2>
                <VisMap
                  active={true}
                  markers={{
                    centralLocation: {
                      label: event.title,
                      address: `${event.location?.address_lines?.[0]} ${event.location?.locality} ${event.location?.region} ${event.location?.postal_code}`,
                    },
                  }}
                />
              </Grid2>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={"2"}>
          <AttendanceList
            key={event.id}
            event={event}
            attendances={event_attendances || []}
            callback={() => refetch()}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
};

export const MobilizeEventDetailsOnly = ({
  event,
  formRef,
  submit = false,
  signupCounts,
}: {
  event: Event;
  signupCounts: {
    [key: number]: number;
  };
  formRef?: React.MutableRefObject<any>;
  submit?: boolean;
}) => {
  const form = useMobilizeEventForm(submit);
  return (
    <JFormik
      formRef={formRef}
      disabled={true}
      initialValues={{
        ...event,
        timeslots: event.timeslots?.map((t) => ({
          ...t,
          start_date: moment(t.start_date * 1000),
          end_date: moment(t.end_date * 1000),
          signup_count: signupCounts[t.id],
        })),
      }}
      enableReinitialize={true}
      handleSubmit={async (values, formik) => {}}
      // validationSchema={form.validationSchema}
      form={form.form}
    />
  );
};
