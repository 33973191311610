import { useState, useMemo, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
  ListItemIcon,
  Paper,
  Chip,
} from "@mui/material";
import { Search as SearchIcon, Close } from "@mui/icons-material";
import { sortByNumber, sortString } from "utils";
import { AlertDialog } from "dialogs";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  selectAllProspectLists,
  selectProspectListEntities,
  useCreateProspectListMutation,
  useDeleteProspectListMutation,
  usePatchProspectListMutation,
} from "store/api/prospect_list.api";
import { NoMaxWidthTooltip } from "components/NoMaxWidthTooltip";
import { ProspectList, TaskProspectList } from "store/api/models";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { selectTaskProspectListEntities } from "store/api/task_prospect_list.api";

export const TaskProspects = ({
  disabled,
  prospect_lists: initialProspectLists,
  handleListUpdates = (lists: ProspectList[]) => {},
}: {
  disabled: boolean;
  prospect_lists: ProspectList[];
  handleListUpdates?: (lists: ProspectList[]) => void;
}) => {
  const allProspectLists = useSelector((state: RootState) =>
    selectAllProspectLists(state)
  );
  const prospectListEntities = useSelector((state: RootState) =>
    selectProspectListEntities(state)
  );
  const taskProspectListEntities = useSelector((state: RootState) =>
    selectTaskProspectListEntities(state)
  );
  const [search, setSearch] = useState<string>("");
  const [searchSelected, setSelectedSearch] = useState<string>("");
  const [prospect_lists, setProspectLists] = useState<ProspectList[]>(
    allProspectLists?.filter(
      (prospect_list) =>
        !initialProspectLists?.find((p) => prospect_list.id === p?.id)
    )
  );
  const [selectedProspectLists, setSelectedProspectLists] =
    useState<ProspectList[]>(initialProspectLists);

  const remove = (list: number) => {
    const removedList = selectedProspectLists?.find((pl) => pl.id === list);
    if (removedList) {
      setProspectLists((prevLists) => [...prevLists, removedList]);
      const updatedSelectedList = selectedProspectLists?.filter(
        (p) => p.id !== list
      );
      setSelectedProspectLists(updatedSelectedList);
      handleListUpdates(updatedSelectedList);
    }
  };
  // Handle dragging and dropping prospect_lists between lists
  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // If there is no destination (dropped outside the list), do nothing
    if (!destination) return;

    // Handle dragging from left to right list (removing from left and adding to right)
    if (
      source.droppableId === "left-prospect_lists" &&
      destination.droppableId === "right-prospect_lists"
    ) {
      const draggedProspectList = prospectListEntities[result.draggableId];

      // Remove the dragged prospect_list from the left list
      const updatedProspectLists = prospect_lists.filter(
        (prospect_list) => `${prospect_list.id}` !== result.draggableId
      );

      // Add the dragged prospect_list to the right list if it's not already there
      if (draggedProspectList) {
        const reorderedProspectLists = Array.from(selectedProspectLists);
        reorderedProspectLists.splice(
          destination.index,
          0,
          draggedProspectList
        );
        setSelectedProspectLists(reorderedProspectLists);
        handleListUpdates(reorderedProspectLists);
        // setSelectedProspectLists((prev) => {
        //   const reorderedProspectLists = Array.from(prev);
        //   reorderedProspectLists.splice(
        //     destination.index,
        //     0,
        //     draggedProspectList
        //   );
        //   return reorderedProspectLists;
        // });
        setProspectLists(updatedProspectLists);
      }
    }

    // Handle reordering within the right list
    if (
      source.droppableId === "right-prospect_lists" &&
      destination.droppableId === "right-prospect_lists"
    ) {
      const reorderedProspectLists = Array.from(selectedProspectLists);
      const [movedProspectList] = reorderedProspectLists.splice(
        source.index,
        1
      );
      reorderedProspectLists.splice(destination.index, 0, movedProspectList);
      setSelectedProspectLists(reorderedProspectLists);
      handleListUpdates(reorderedProspectLists);
      // setSelectedProspectLists((prev) => {
      //   const reorderedProspectLists = Array.from(prev);
      //   const [movedProspectList] = reorderedProspectLists.splice(
      //     source.index,
      //     1
      //   );
      //   reorderedProspectLists.splice(destination.index, 0, movedProspectList);
      //   return reorderedProspectLists;
      // });
    }
  };

  const filteredProspectLists = [...(prospect_lists || [])]
    ?.filter((e) => {
      return e.name?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.sort((a, b) => sortString(a.name, b.name));

  const filteredSelectedProspectLists = [
    ...(selectedProspectLists || []),
  ]?.filter((e) => {
    return e.name?.toLowerCase()?.includes(searchSelected.toLowerCase());
  });

  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Box
          sx={{
            flexGrow: 3,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  flexGrow: 1,
                  width: "100%",
                  height: "100%",
                  maxHeight: "100%",
                }}
              >
                {/* Left column: Events to drag */}
                <Paper
                  sx={{
                    height: "100%",
                    maxHeight: "100%",
                    width: "50%",
                    p: 1,
                  }}
                >
                  <Grid
                    container
                    alignItems={"bottom"}
                    alignContent={"bottom"}
                    justifyContent={"space-between"}
                    spacing={0}
                    sx={{ mb: 1 }}
                  >
                    <Grid alignSelf={"bottom"} alignContent={"end"}>
                      <Typography
                        variant="caption"
                        component={"span"}
                        sx={{ color: "black", pl: 1 }}
                      >
                        AVAILABLE PROSPECT LISTS
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        alignItems={"bottom"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid sx={{ textAlign: "right" }}>
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            placeholder="Search prospect_lists"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(prospect_list) =>
                              setSearch((prospect_list?.target as any)?.value)
                            }
                            sx={{ maxWidth: { xs: "none", sm: 340 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ height: "100%", maxHeight: "100%" }}>
                    <Droppable
                      droppableId="left-prospect_lists"
                      isDropDisabled={disabled}
                    >
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{
                            height: "100%",
                            maxHeight: "100%",
                            overflowY: "scroll",
                          }}
                          dense
                          disablePadding
                        >
                          {filteredProspectLists?.map(
                            (prospect_list: ProspectList, index: number) => (
                              <Draggable
                                key={prospect_list.id}
                                draggableId={`${prospect_list.id}`}
                                index={index}
                                isDragDisabled={disabled}
                              >
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={prospect_list.id}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: 1,
                                      marginBottom: 1,
                                      border: "1px solid #ddd",
                                      borderRadius: 1,
                                    }}
                                    dense
                                  >
                                    <ListItemAvatar>
                                      <Chip
                                        label={
                                          prospect_list.form?.prospectListType
                                        }
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ zoom: "75%", mr: 1 }}
                                      />
                                    </ListItemAvatar>
                                    <NoMaxWidthTooltip
                                      title={prospect_list.name}
                                    >
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="overline"
                                            sx={{
                                              fontWeight: 700,
                                              width: "100%",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            {prospect_list.name}
                                          </Typography>
                                        }
                                        // secondary={`${prospect_list.contact_r?.email}`}
                                        // onClick={() =>
                                        //   handleEventClick(prospect_list)
                                        // }
                                        sx={{
                                          cursor: "pointer",
                                          py: 0,
                                          my: 0,
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "100%",
                                        }}
                                      />
                                    </NoMaxWidthTooltip>
                                  </ListItem>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Box>
                </Paper>
                {/* Right column with reorderable list */}
                <Paper
                  elevation={3}
                  sx={{ width: "50%", padding: 1, minHeight: "100%" }}
                >
                  <Grid
                    container
                    alignItems={"bottom"}
                    justifyContent={"space-between"}
                    spacing={0}
                    sx={{ mb: 1 }}
                  >
                    <Grid alignContent={"end"}>
                      <Typography
                        variant="caption"
                        component={"span"}
                        sx={{ color: "black", pl: 1 }}
                      >
                        SELECTED PROSPECT LISTS
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        alignItems={"bottom"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid sx={{ textAlign: "right" }}>
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            placeholder="Search prospect_lists"
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              },
                            }}
                            onChange={(prospect_list) =>
                              setSelectedSearch(
                                (prospect_list?.target as any)?.value
                              )
                            }
                            sx={{ maxWidth: { xs: "none", sm: 340 } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ height: "100%", maxHeight: "100%" }}>
                    <Droppable
                      droppableId="right-prospect_lists"
                      isDropDisabled={disabled}
                    >
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{
                            height: "100%",
                            maxHeight: "100%",
                            overflowY: "scroll",
                          }}
                          dense
                          disablePadding
                        >
                          {filteredSelectedProspectLists.map(
                            (prospect_list, index) => (
                              <Draggable
                                key={prospect_list.id}
                                draggableId={`${prospect_list.id}`}
                                index={index}
                                isDragDisabled={disabled}
                              >
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      padding: 1,
                                      marginBottom: 1,
                                      border: "1px solid #ddd",
                                      borderRadius: 1,
                                    }}
                                    dense
                                    secondaryAction={
                                      !disabled && (
                                        <IconButton
                                          onClick={() =>
                                            remove(prospect_list.id)
                                          }
                                        >
                                          <Close color="secondary" />
                                        </IconButton>
                                      )
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Chip
                                        label={
                                          prospect_list.form?.prospectListType
                                        }
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ zoom: "75%", mr: 1 }}
                                      />
                                    </ListItemAvatar>

                                    <NoMaxWidthTooltip
                                      title={prospect_list.name}
                                    >
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="overline"
                                            sx={{
                                              fontWeight: 700,
                                              width: "100%",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            {prospect_list.name}
                                          </Typography>
                                        }
                                        // secondary={`${prospect_list.contact_r?.email}`}
                                        // onClick={() =>
                                        //   handleEventClick(prospect_list)
                                        // }
                                        sx={{
                                          cursor: "pointer",
                                          py: 0,
                                          my: 0,
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "100%",
                                        }}
                                      />
                                    </NoMaxWidthTooltip>
                                  </ListItem>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Box>
                </Paper>
              </Box>
            </DragDropContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
