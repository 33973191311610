import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Login } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the Login entity
const loginAdapter = createEntityAdapter<Login, EntityId>({
  selectId: (login: Login) => login.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface LoginsState extends EntityState<Login, EntityId> {
  // Add any additional state properties here if needed
}

export const initialLoginState: LoginsState = loginAdapter.getInitialState();

// Selectors for Logins
export const {
  selectById: selectLoginById,
  selectAll: selectAllLogins,
  selectEntities: selectLoginEntities,
  selectIds: selectLoginIds,
  selectTotal: selectLoginTotal,
} = loginAdapter.getSelectors((state: RootState) => state?.login);

export const loginSlice = createSlice({
  name: "login",
  initialState: initialLoginState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        login.endpoints.getLogins.matchFulfilled
        // login.endpoints.createLogin.matchFulfilled,
        // login.endpoints.updateLogin.matchFulfilled,
        // login.endpoints.patchLogin.matchFulfilled
      ),
      (state, action) => {
        loginAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      login.endpoints.deleteLogin.matchFulfilled,
      (state, action) => {
        loginAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const login = baseApi
  .enhanceEndpoints({ addTagTypes: ["Logins", "Login"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogins: builder.query<Login[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "login",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Login" as const, id })),
                { type: "Logins" as const },
              ]
            : [{ type: "Logins" as const }],
      }),
      getLogin: builder.query<Login, { id: number; params?: object }>({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `login/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [{ type: "Login", id: arg.id }],
        keepUnusedDataFor: 90,
      }),
      createLogin: builder.mutation<Login, Partial<Login>>({
        query: (request) => ({
          url: "login",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "Logins" }],
      }),
      patchLogin: builder.mutation<Login, Login>({
        query: (request) => ({
          url: `login/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Login", id: arg.id },
        ],
      }),
      updateLogin: builder.mutation<Login, Login>({
        query: (request) => ({
          url: `login/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Login", id: arg.id },
        ],
      }),
      deleteLogin: builder.mutation<void, number>({
        query: (id) => ({
          url: `login/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Login", id: arg },
          { type: "Logins" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetLoginsQuery,
  useGetLoginQuery,
  useCreateLoginMutation,
  usePatchLoginMutation,
  useUpdateLoginMutation,
  useDeleteLoginMutation,
  useLazyGetLoginsQuery,
  usePrefetch: useLoginsPrefetch,
} = login;
