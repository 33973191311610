import { useFormikContext } from "formik";
import debounce from "lodash.debounce";
import { useCallback, useEffect } from "react";

export const useFormikAutoSave = ({ debounceMs = 5000 }) => {
  const formik = useFormikContext();
  const debouncedSubmit = useCallback(
    debounce(() => {
      if (formik) {
        return formik.submitForm();
      }
    }, debounceMs),
    [formik?.submitForm, debounceMs]
  );

  useEffect(() => {
    if (formik.dirty) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, formik?.values]);
  /// IF AUTSAVE SEEMS TO NOT BE WORKING
  /// ITS PROBABLY BECAUSE THE VALIDATION IS FAILING!!!
  useEffect(() => {
    return function cleanup() {
      if (formik.dirty) {
        debouncedSubmit.cancel();
        formik.submitForm();
      }
    };
  }, [formik.dirty]);
};
