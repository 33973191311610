import { Paper, Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswers,
  selectAnswerById,
  selectQuestions,
  handleNext,
  handleRestart,
  selectAnswers,
  selectActiveRenderedQuestion,
} from "store/slice/formBuilder.slice";
import { QuestionRendererRedux } from "./QuestionRendererRedux";
import { useEffect, useState } from "react";

interface RenderedQuestionProps {
  submit?: Function;
  save?: Function;
  preview?: boolean;
  id: string;
  index: number;
}

export const RenderedQuestion = ({
  id,
  index,
  preview = true,
  submit = (values: any) => {
    console.log(values);
  },
  save = (values: any) => {
    console.log(values);
  },
}: RenderedQuestionProps) => {
  const dispatch = useDispatch();
  const answers = useSelector(selectAnswers);
  const completed = useSelector((state: any) => state.formBuilder.completed);
  const disabled = useSelector((state: any) => state.formBuilder.disabled);
  const conversation = useSelector(
    (state: any) => state.formBuilder.conversation
  );
  const renderedQuestionIds = useSelector(
    (state: any) => state.formBuilder.renderedQuestionIds
  );
  const activeRenderedQuestionId = useSelector(
    (state: any) => state.formBuilder.activeRenderedQuestionId
  );

  const activeRenderedQuestion = useSelector(selectActiveRenderedQuestion);
  const answer = useSelector(selectAnswerById(id));
  const [isFullScreen, setIsFullScreen] = useState(false);
  // useEffect(() => {
  //   if (activeRenderedQuestion?.data?.type === "event" && !isFullScreen) {
  //     setIsFullScreen(true);
  //   }
  // }, [activeRenderedQuestion?.data?.type]);
  if (completed && renderedQuestionIds[renderedQuestionIds.length - 1] === id) {
    return (
      <Box sx={{ display: "flex", width: "100%", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={conversation?.disposition === "Complete"}
          onClick={() => {
            if (completed) {
              if (!disabled && !preview) {
                submit(answers);
              } else {
                alert("Form complete");
                dispatch(setAnswers({}));
                dispatch(handleRestart());
              }
            } else {
              if (!disabled) {
                save(answers);
              }
              dispatch(handleNext());
            }
          }}
          sx={{ m: "auto", px: 5, py: 1 }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Submit Form
          </Typography>
        </Button>
      </Box>
    );
  } else {
    return (
      <Paper
        elevation={5}
        sx={{
          py: 2,
          px: 4,
          mb: 2,
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
          position: isFullScreen ? "fixed" : "relative",
          top: isFullScreen ? 0 : "auto",
          left: isFullScreen ? 0 : "auto",
          width: isFullScreen ? "100vw" : "auto",
          height: isFullScreen ? "100vh" : "auto",
          zIndex: isFullScreen ? 1300 : "auto",
          overflow: isFullScreen ? "auto" : "visible",
        }}
        key={id}
      >
        <QuestionRendererRedux
          id={id}
          preview={preview}
          isFullScreen={isFullScreen}
        />
        {activeRenderedQuestionId === id && (
          <Box sx={{ mt: 2, textAlign: "center", px: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                (!answer || ("value" in answer && answer.value === "")) &&
                !["script", "event"].includes(
                  activeRenderedQuestion?.data?.type
                )
              }
              onClick={() => {
                if (isFullScreen) {
                  setIsFullScreen(false);
                }
                if (!disabled) {
                  save(answers);
                }
                dispatch(handleNext());
              }}
              sx={{ px: 5, py: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Next Question
              </Typography>
            </Button>
            {activeRenderedQuestion?.data?.type === "event" && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsFullScreen(!isFullScreen)}
                sx={{ ml: 2, px: 3, py: 1 }}
              >
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {isFullScreen ? "Close Full Screen" : "Expand to Full Screen"}
                </Typography>
              </Button>
            )}
          </Box>
        )}
      </Paper>
    );
  }
};
