import { Grid2 as Grid, Link, Box } from "@mui/material";
import { routeNames } from "../config";
import * as Yup from "yup";
import { JFormik } from "forms";
import { HeaderShort } from "headers";

export interface ResetPasswordProps {
  reset?: Function;
  email?: string;
  new_account?: boolean;
  navigate?: Function;
  token?: string;
}

export const ResetPassword = ({
  reset,
  email,
  new_account,
  navigate,
  token,
}: ResetPasswordProps) => {
  return (
    <Box sx={{ bgcolor: "rgba(249,255,243,1)", paddingX: { xs: 2, sm: 3 } }}>
      <HeaderShort position="fixed" />
      <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
        <a href="https://www.meckdems.org/">
          <img
            src="https://static.wixstatic.com/media/730684_370b1a730906433f9464d7a28f3bd821~mv2.png/v1/fill/w_192,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/730684_370b1a730906433f9464d7a28f3bd821~mv2.png"
            alt="Mecklenburg County Democratic Party - Home"
          />
        </a>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          minHeight: 640,
        }}
      >
        <Grid size={{ xs: 12, sm: 8, md: 6, lg: 5, xl: 4 }}>
          <JFormik
            initialValues={(resource: any) => ({
              email: email,
              password: "",
              passwordConfirm: "",
            })}
            validationSchema={Yup.object({
              password: Yup.string().required("Password is required"),
              passwordConfirm: Yup.string()
                .nullable()
                .oneOf(
                  [Yup.ref("password"), null],
                  "Please make sure your passwords match"
                )
                .required("Confirm password is required"),
            })}
            handleSubmit={async (values: any) => {
              if (reset) {
                await reset({ ...values, token: token });
                navigate && navigate(routeNames.LOGIN);
              }
            }}
            form={[
              {
                name: "h4",
                itemType: "typography",
                label:
                  !email && !token
                    ? "Invalid link"
                    : new_account
                    ? "Your invited to signup!"
                    : "Reset Password",
                jItemProps: {
                  variant: "h4",
                  sx: { textAlign: "center", fontSize: { xs: 28, sm: 34 } },
                },
              },
              {
                name: "subtitle1",
                itemType: "typography",
                label:
                  email || token
                    ? `Enter a new password for account: ${
                        email ? `${email}` : ""
                      }`
                    : `The link entered is not correct or no longer valid`,
                jItemProps: {
                  variant: "subtitle1",
                  color: "textSecondary",
                  sx: {
                    textAlign: "center",
                    marginTop: -1,
                    marginBottom: 3,
                    lineHeight: 1.5,
                  },
                },
              },
              {
                precondition: () => (!!email || !!token) && !!new_account,
                name: "firstname",
                itemType: "textfield",
                label: "First Name",
              },
              {
                precondition: () => (!!email || !!token) && !!new_account,
                name: "lastname",
                itemType: "textfield",
                label: "Last Name",
              },
              {
                precondition: () => !!email || !!token,
                name: "email",
                disabled: !new_account,
                itemType: "textfield",
                label: "Email",
              },
              {
                precondition: () => !!email || !!token,
                name: "password",
                itemType: "textfield",
                jItemProps: { type: "password" },
                label: "Password",
              },
              {
                precondition: () => !!email || !!token,
                name: "passwordConfirm",
                itemType: "textfield",
                jItemProps: { type: "password" },
                label: "Password Confirm",
              },
              {
                precondition: () => !!email || !!token,
                name: "submit",
                itemType: "button",
                jItemProps: { type: "submit" },
                label: new_account ? "Set My Password" : "Reset My Password",
              },
            ]}
          />
          <Link
            component="button"
            variant="body1"
            sx={{ mt: 5 }}
            onClick={() => {
              navigate
                ? navigate(routeNames.LOGIN)
                : console.info("back_to_signin");
            }}
          >
            Back to Sign in
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
