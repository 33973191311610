import React from 'react';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NoMaxWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '50vh', //'none',
    whiteSpace: 'pre-line',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary}`,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    // boxShadow: '0px 6px 6px -3px rgb(118, 176, 65,0.8), 0px 10px 14px 1px rgb(118, 176, 65, 0.5), 0px 4px 18px 3px rgb(118, 176, 65, 0.5)',
    fontSize: 11,
  },
}));
