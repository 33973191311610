import { Prospect } from "components/prospect/Prospect";
import { AdminIndex } from "pages/AdminIndex";
import { VolunteerIndex } from "pages/VolunteerIndex";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectUser } from "store";

export const ItemAuthNavigator = () => {
  const user = useSelector(selectUser);
  return (
    <Routes>
      {user.admin && <Route path="/prospect/:vanid" element={<AdminIndex />} />}
      <Route
        path="/"
        element={user.admin ? <AdminIndex /> : <VolunteerIndex />}
      />
    </Routes>
  );
};
