import { Description, ContentCopy, Edit, Delete } from "@mui/icons-material";
import {
  ListItem,
  Grid2,
  List,
  Box,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { AlertDialog } from "dialogs";
import { useState } from "react";
import { toast } from "react-toastify";

interface FormattedListProps {
  listItems: number[];
  selectedId?: number;
  deleteItem?: Function;
  editItem?: Function;
  duplicateItem?: Function;
  onItemClick: Function;
  onClose: Function;
  primary: Function;
  secondary?: Function;
  icon: any;
  itemName: string;
}

export const FormattedList = ({
  listItems,
  selectedId,
  icon,
  deleteItem,
  editItem,
  primary,
  secondary,
  duplicateItem,
  onItemClick,
  onClose,
  itemName,
}: FormattedListProps) => {
  const [openAlert, setOpenAlert] = useState<number>();
  return (
    <List
      sx={{
        maxHeight: "100%",
        overflowY: "scroll",
        px: 1,
      }}
    >
      <AlertDialog
        open={!!openAlert}
        title={`Delete ${itemName}`}
        content={`Are you sure you want to delete this ${itemName}?`}
        onClose={() => {
          setOpenAlert(undefined);
        }}
        onConfirm={async () => {
          if (openAlert && openAlert > 0 && deleteItem) {
            try {
              await deleteItem(openAlert);
              onClose();
              toast.success(`The ${itemName} successfully deleted`);
            } catch (error) {
              toast.success(`Unable to delete ${itemName}`);
            }
          }
          setOpenAlert(undefined);
        }}
      />
      {listItems?.map((id: number) => (
        <ListItem
          key={id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: (theme) =>
              selectedId && selectedId === id
                ? theme.palette.divider
                : undefined,
            borderRadius: "4px",
            boxShadow: (theme) => theme.shadows[2],
            my: 1,
          }}
          // dense
        >
          <Grid2
            container
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <ListItemIcon sx={{ minWidth: "unset", mr: 2 }}>
                {icon}
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="overline" sx={{ fontWeight: 700 }}>
                    {primary(id) || "untitled"}
                  </Typography>
                }
                onClick={() => onItemClick(id)}
                sx={{ cursor: "pointer", py: 0, my: 0 }}
                secondary={secondary ? secondary(id) : undefined}
              />
            </Box>
            <Grid2 alignSelf={"center"}>
              <Box
                sx={{
                  borderRadius: "4px",
                  border: (theme) =>
                    `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                {duplicateItem && (
                  <Tooltip title={"Duplicate"}>
                    <IconButton onClick={() => duplicateItem(id)}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                )}
                {editItem && (
                  <Tooltip title={"Edit"}>
                    <IconButton onClick={() => editItem(id)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
                {deleteItem && (
                  <Tooltip title={"Delete"}>
                    {openAlert && openAlert === id ? (
                      <IconButton>
                        <CircularProgress size={22} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setOpenAlert(id)}>
                        <Delete />
                      </IconButton>
                    )}
                  </Tooltip>
                )}
              </Box>
            </Grid2>
          </Grid2>
        </ListItem>
      ))}
    </List>
  );
};
