import React, { useEffect, useMemo, useState } from "react";
import {
  Paper,
  TextField,
  MenuItem,
  Select,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Box,
  Grid2 as Grid,
  FormControl,
  InputLabel,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Autocomplete,
  ListItemAvatar,
  alpha,
} from "@mui/material";
import { VisMap } from "components/mobilize/VisGLMap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Attendance,
  Event,
  EventGroup,
} from "store/api/mobilize/mobilize_models";
import moment from "moment";
import { MobilizeEventDetailsOnly } from "components/mobilize/MobilizeEventDetails";
import { AddTask, Close, LinkRounded, Visibility } from "@mui/icons-material";
import { BackdropLoading } from "components/BackdropLoading";
import { AttendanceDetails } from "components/mobilize/AttendanceDetails";
import { Person } from "store/api/models";
import { useSelector } from "react-redux";
import {
  selectAllEvents,
  useGetMobilizeEventQuery,
} from "store/api/mobilize/mobilize.api";
import { selectAllEventGroups } from "store/api/mobilize/mobilize_event_group.api";
import {
  QuestionFieldData,
  selectAnswerById,
} from "store/slice/formBuilder.slice";
import { AttendancesList } from "components/mobilize/AttendancesList";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

// Haversine formula to calculate the distance between two lat/lng points
const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const toRad = (x: number) => (x * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKm = R * c; // Distance in kilometers
  return distanceInKm * 0.621371; // Convert to miles
};

const EventFilters = ({
  filters,
  setFilters,
  disabled = false,
}: {
  filters: any;
  setFilters: Function;
  disabled?: boolean;
}) => {
  const { userAddress, proximity, eventType, startDate, endDate, eventGroup } =
    filters || { eventGroup: 0 };

  const event_groups = useSelector(selectAllEventGroups);
  const events = useSelector(selectAllEvents);
  const eventTypes = useMemo(() => {
    return [
      ...new Set<string>(
        !eventGroup || eventGroup === 0
          ? events.map((e) => e.event_type).filter((x: any) => !!x)
          : event_groups
              .find((eg) => eg.id === eventGroup)
              ?.events?.map((e) => e?.form?.event_type)
              .filter((x: any) => !!x)
      ),
    ].sort();
  }, [events, event_groups, eventGroup]);
  return (
    <Box>
      <Grid
        container
        alignItems={"bottom"}
        // justifyContent={"space-between"}
        spacing={1}
      >
        <Grid size={{ xs: 12 }}>
          <Typography variant="caption" gutterBottom>
            Filter Volunteer Events
          </Typography>
        </Grid>
        <Grid size={{ xs: 8 }}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Your Address"
            value={userAddress}
            onChange={(e) =>
              setFilters((prev: any) => ({
                ...prev,
                userAddress: e.target.value as string,
              }))
            }
          />
        </Grid>
        <Grid size={{ xs: 4 }}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Proximity (in miles)"
            type="number"
            value={proximity}
            onChange={(e) =>
              setFilters((prev: any) => ({
                ...prev,
                proximity: Number(e.target.value),
              }))
            }
          />
        </Grid>
        {/* <Grid size={{ xs: 1 }}>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setFilters((prev: any) => ({
                eventGroup: prev.eventGroup,
              }));
            }}
          >
            <FilterListOff />
          </IconButton>
        </Grid> */}
        <Grid size={{ xs: 4 }}>
          <Autocomplete
            disabled={disabled}
            options={eventTypes}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Event Type" variant="outlined" />
            )}
            id="event-types"
            size="small"
            value={eventType}
            onChange={(e, v) => {
              setFilters((prev: any) => ({
                ...prev,
                eventType: v,
              }));
            }}
            className="nodrag"
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid size={{ xs: 4 }}>
            <DatePicker
              disabled={disabled}
              value={
                startDate && moment(startDate).isValid()
                  ? moment(startDate)
                  : undefined
              }
              onChange={(date) =>
                setFilters((prev: any) => ({
                  ...prev,
                  startDate: date,
                }))
              }
              slotProps={{ textField: { fullWidth: true } }}
              label="Start date range"
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <DatePicker
              disabled={disabled}
              value={
                endDate && moment(endDate).isValid()
                  ? moment(endDate)
                  : undefined
              }
              onChange={(date) =>
                setFilters((prev: any) => ({
                  ...prev,
                  endDate: date,
                }))
              }
              slotProps={{ textField: { fullWidth: true } }}
              label="End date range"
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
    </Box>
  );
};

interface FilteredEvent {
  id: number;
  name: string;
  location: { latitude: number; longitude: number };
  eventType: string;
  date: Date;
  distance: number; // Distance in miles
  disabled?: boolean;
}

export const FilteredEventListReduxRefactor = ({
  question,
  handleQuestionPropsChange,
  isRender = false,
  value,
  compact = false,
  isFullScreen = false,
}: {
  handleQuestionPropsChange: Function;
  question: QuestionFieldData;
  isRender?: boolean;
  value?: any;
  compact?: boolean;
  isFullScreen?: boolean;
}) => {
  const answer = useSelector(selectAnswerById(question.id));
  const event_groups = useSelector(selectAllEventGroups);
  const person: Person = useSelector((state: any) => state.formBuilder.person);
  const activeQuestionId = useSelector(
    (state: any) => state.formBuilder.activeQuestionId
  );
  const activeRenderedQuestionId = useSelector(
    (state: any) => state.formBuilder.activeRenderedQuestionId
  );
  const reduxDisabled = useSelector((state: any) => state.formBuilder.disabled);

  const isActive = isRender
    ? activeRenderedQuestionId === question.id
    : activeQuestionId === question.id;
  const disabled = isActive
    ? isRender && activeRenderedQuestionId === question.id
      ? false
      : reduxDisabled
    : reduxDisabled;
  const [filters, setFilters] = useState<any>({
    ...(value?.filters || {}),
    eventGroup: question?.filters?.eventGroup || question?.eventGroup || 0,
    userAddress: `${person?.address || ""} ${person?.city || ""} ${
      person?.state || ""
    } ${person?.zip || ""}`,
  });

  return (
    <Box
      sx={
        isFullScreen
          ? { height: "100%", maxHeight: "100%", width: "100%" }
          : !isRender && !compact && isActive
          ? { maxHeight: "60vh", height: "60vh", pt: 2 }
          : { pt: 2 }
      }
    >
      {!isRender && (
        <Grid size={{ xs: 4 }}>
          <FormControl fullWidth size="small" className="nodrag">
            <InputLabel id="event-groups-label">Event Group</InputLabel>
            <Select
              disabled={disabled}
              labelId="event-groups-label"
              id="event-groups"
              size="small"
              label="Event Group"
              value={value?.eventGroup}
              onChange={(e) => {
                handleQuestionPropsChange(
                  "eventGroup",
                  e.target.value as number
                );
                setFilters((prev: any) => ({
                  ...prev,
                  eventGroup: e.target.value as number,
                }));
              }}
              className="nodrag"
            >
              <MenuItem value={0}>All</MenuItem>
              {event_groups?.map((eg) => (
                <MenuItem value={eg.id}>{eg.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <EventFilters
        disabled={disabled}
        filters={filters}
        setFilters={(filters: any) => {
          handleQuestionPropsChange("filters", filters);
          setFilters(filters);
        }}
      />
      {((!compact && isActive) || answer) && (
        <EventTabs
          showAllTabs={!compact && isActive}
          answer={answer}
          isFullScreen={isFullScreen}
          filters={filters}
          setFilters={setFilters}
          disabled={disabled}
          question={question}
          handleQuestionPropsChange={handleQuestionPropsChange}
          value={value}
        />
      )}
    </Box>
  );
};

// Sample type definition for a time slot
interface TimeSlot {
  id: number;
  start_date: number; // Assuming Unix timestamp in seconds
  end_date: number; // Assuming Unix timestamp in seconds
  is_full: boolean;
  capacity: number | null;
  signup_count: number;
}

const timeSummary = (timeSlots: TimeSlot[]) => {
  if (timeSlots.length === 0) {
    return "No remaining dates!";
  }
  // Find the earliest start date and latest end date
  const earliestStartDate = Math.min(
    ...timeSlots.map((slot) => slot.start_date)
  );
  const latestEndDate = Math.max(...timeSlots.map((slot) => slot.end_date));

  // Convert the Unix timestamps to readable date strings
  const formatDateTime = (unixTimestamp: number): string => {
    return moment(unixTimestamp * 1000).format("MMMM Do YYYY hh:mm a"); // Multiplied by 1000 to convert seconds to milliseconds
  };

  // Create the summary string
  const summary = `${formatDateTime(earliestStartDate)} to ${formatDateTime(
    latestEndDate
  )}`;

  return summary;
};

const EventTabs = ({
  question,
  handleQuestionPropsChange,
  value,
  disabled,
  filters,
  setFilters,
  showAllTabs,
  answer,
  isFullScreen = false,
}: {
  handleQuestionPropsChange: Function;
  question: QuestionFieldData;
  value?: any;
  disabled: boolean;
  showAllTabs: boolean;
  filters: any;
  setFilters: Function;
  answer?: any;
  isFullScreen?: boolean;
}) => {
  // const answer = useSelector(selectAnswerById(question.id));
  const events = useSelector(selectAllEvents);
  const event_groups = useSelector(selectAllEventGroups);
  const person: Person = useSelector((state: any) => state.formBuilder.person);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>(events);
  const [userLocation, setUserLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [tabValue, setTabValue] = useState(showAllTabs ? 0 : 2);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const { proximity, eventType, startDate, endDate, eventGroup } =
      filters || {};
    const availableEvents =
      !eventGroup || eventGroup === 0
        ? events
        : (
            event_groups.find((eg) => eg.id === eventGroup) as EventGroup
          )?.events?.map((e) => e.form) || [];
    const filtered = availableEvents
      .map((event) => {
        const distance =
          userLocation?.lat && userLocation?.lng
            ? calculateDistance(
                userLocation.lat,
                userLocation.lng,
                event.location?.location?.latitude!,
                event.location?.location?.longitude!
              )
            : 0;

        const distanceMatch = proximity ? distance <= Number(proximity) : true; // Use user-defined proximity in miles
        const eventTypeMatch = eventType
          ? event.event_type === eventType
          : true;
        const isBetween = () => {
          var result = false;
          if (
            startDate &&
            endDate &&
            moment(startDate).isValid() &&
            moment(endDate).isValid()
          ) {
            for (const t of event?.timeslots.filter(
              (t: any) =>
                !t.is_full && moment(t.start_date * 1000).isAfter(moment())
            )) {
              result =
                result ||
                moment(t?.start_date! * 1000)
                  .startOf("day")
                  .isBetween(startDate, endDate) ||
                (moment(t?.start_date! * 1000)
                  .startOf("day")
                  .isSameOrAfter(startDate) &&
                  moment(t?.end_date! * 1000)
                    .startOf("day")
                    .isSameOrBefore(endDate));

              if (result) break;
            }
          } else if (startDate && moment(startDate).isValid()) {
            for (const t of event?.timeslots.filter(
              (t: any) =>
                !t.is_full && moment(t.start_date * 1000).isAfter(moment())
            )) {
              result =
                result ||
                moment(t?.start_date! * 1000)
                  .startOf("day")
                  .isSameOrAfter(startDate);
              if (result) break;
            }
          } else if (endDate && moment(endDate).isValid()) {
            for (const t of event?.timeslots.filter(
              (t: any) =>
                !t.is_full && moment(t.start_date * 1000).isAfter(moment())
            )) {
              result =
                result ||
                moment(t?.start_date! * 1000)
                  .startOf("day")
                  .isSameOrBefore(endDate);
              if (result) break;
            }
          } else {
            result = true;
          }
          return result;
        };
        return distanceMatch && eventTypeMatch && isBetween()
          ? { ...event, distance }
          : null;
      })
      .filter((event) => event !== null) as Event[];

    setFilteredEvents(filtered);
    setLoading(false);
  }, [userLocation, events, event_groups, filters]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [selectedAttendance, setSelectedAttendance] = useState<Attendance>();
  const { data: event_attendances, refetch } = useGetMobilizeEventQuery(
    {
      id: selectedEvent?.id || 0,
      params: { cc: selectedEvent?.sponsor?.id === 37434 },
    },
    { skip: !selectedEvent?.id, refetchOnMountOrArgChange: true }
  );
  let signupCounts: { [key: number]: number } = {};
  selectedEvent?.timeslots?.forEach((t, i) => {
    event_attendances?.forEach((e: Attendance) => {
      if (e.status !== "Cancelled") {
        signupCounts[selectedEvent.timeslots?.[i]?.id] =
          (signupCounts[selectedEvent.timeslots?.[i]?.id] || 0) +
          [...(e.timeslots || []), ...(e.timeslot ? [e.timeslot] : [])].filter(
            (ts) => ts.id === t.id
          ).length;
      }
    });
  });
  if (loading) {
    return <BackdropLoading relative={true} />;
  } else {
    return (
      <Box
        sx={
          isFullScreen
            ? { height: "100%", maxHeight: "80%" }
            : { height: "75%" }
        }
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          {showAllTabs && <Tab value={0} label="List View" />}
          {showAllTabs && <Tab value={1} label="Map View" />}
          {answer?.signups && <Tab value={2} label="Event Signups" />}
        </Tabs>

        <Box
          hidden={tabValue !== 0}
          sx={isFullScreen ? { height: "100%", overflowY: "scroll" } : {}}
        >
          {/* <Typography variant="h6">Filtered Events</Typography> */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              p: 1,
              height: "100%",
              // bgcolor: (theme) => theme.palette.divider,
            }}
          >
            <Box
              sx={{
                flexGrow: selectedEvent ? 0.25 : 1,
                width: selectedEvent ? "25%" : undefined,
                transition: "flex-grow 0.3s",
                pr: selectedEvent ? 1 : 0,
                height: isFullScreen ? "100%" : "38vh",
                maxHeight: isFullScreen ? "100%" : "38vh",
                overflowY: "scroll",
              }}
              className="nodrag nowheel"
            >
              {filteredEvents.length > 0 ? (
                <List dense disablePadding>
                  {filteredEvents.map((event) => {
                    return (
                      <>
                        <ListItem
                          key={event?.id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: "4px",
                            bgcolor: (theme) =>
                              event?.sponsor?.id === 1545
                                ? alpha(
                                    theme.palette.secondary.light,
                                    selectedEvent &&
                                      selectedEvent?.id === event?.id
                                      ? 0.3
                                      : 0.1
                                  )
                                : alpha(
                                    theme.palette.primary.light,
                                    selectedEvent &&
                                      selectedEvent?.id === event?.id
                                      ? 0.3
                                      : 0.1
                                  ),
                            border: (theme) =>
                              `${
                                selectedEvent && selectedEvent?.id === event.id
                                  ? 4
                                  : 2
                              }px solid ${
                                selectedEvent && selectedEvent?.id === event.id
                                  ? "black"
                                  : event?.sponsor?.id === 1545
                                  ? theme.palette.secondary.light
                                  : theme.palette.primary.light
                              }`,
                          }}
                          dense
                          secondaryAction={
                            !selectedEvent && !selectedAttendance ? (
                              <Box
                                sx={{
                                  borderRadius: "4px",
                                  border: (theme) =>
                                    `1px solid ${theme.palette.secondary.main}`,
                                }}
                              >
                                <Tooltip title={"View Event Details"}>
                                  <IconButton
                                    disabled={disabled}
                                    onClick={() => {
                                      setSelectedEvent(event);
                                      // setSelectedAttendance({} as Attendance);
                                    }}
                                  >
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={"Sign Up!"}>
                                  <IconButton
                                    disabled={disabled}
                                    onClick={() => {
                                      setSelectedEvent(event);
                                      setSelectedAttendance({} as Attendance);
                                    }}
                                  >
                                    <AddTask />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ) : undefined
                          }
                        >
                          <ListItemAvatar>
                            <Gauge
                              value={
                                event?.timeslots?.filter((t) => t.is_full)
                                  ?.length
                              }
                              valueMax={event?.timeslots?.length}
                              startAngle={-110}
                              endAngle={110}
                              width={80}
                              height={50}
                              // startAngle={0}
                              // endAngle={360}
                              innerRadius="70%"
                              outerRadius="100%"
                              sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                  fontSize: 12,
                                  transform: "translate(0px, 0px)",
                                },
                              }}
                              text={({ value, valueMax }) =>
                                `${Math.round(
                                  (100 * (value || 0)) / valueMax
                                )}%`
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant="overline"
                                sx={{ fontWeight: 700 }}
                              >
                                {event.title}{" "}
                                <small
                                  style={{
                                    marginLeft: 2,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  (
                                  {`${
                                    event?.timeslots?.filter((t) => !t.is_full)
                                      ?.length
                                  }/${
                                    event?.timeslots?.length
                                  } slots available`}
                                  )
                                </small>
                              </Typography>
                            }
                            secondary={
                              <span>
                                {event.location.locality
                                  ? `Distance: ${(
                                      event as Event & {
                                        distance: number;
                                      }
                                    ).distance?.toFixed(2)} miles`
                                  : "Virtual event"}{" "}
                                <Typography
                                  variant={"caption"}
                                  component={"span"}
                                  sx={{
                                    ml: 5,
                                    color: (theme) =>
                                      theme.palette.primary.dark,
                                  }}
                                >
                                  {event.timeslots
                                    ? `${timeSummary(
                                        event.timeslots.filter(
                                          (t: any) =>
                                            !t.is_full &&
                                            moment(t.start_date * 1000).isAfter(
                                              moment()
                                            )
                                        ) ||
                                          [
                                            (event as any).timeslot as TimeSlot,
                                          ].filter(
                                            (t: any) =>
                                              !t.is_full &&
                                              moment(
                                                t.start_date * 1000
                                              ).isAfter(moment())
                                          )
                                      )}`
                                    : ""}
                                </Typography>
                              </span>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </>
                    );
                  })}
                </List>
              ) : (
                <Typography>No events match your filters.</Typography>
              )}
            </Box>
            {/* Event Details */}
            {selectedEvent && !selectedAttendance && (
              <Paper
                elevation={3}
                sx={{
                  flexGrow: 0.75,
                  maxWidth: "75%",
                  width: "75%",
                  bgcolor: "transparent",
                  position: "relative", // Allows positioning of the close button
                  transition: "flex-grow 1s",
                  height: isFullScreen ? "100%" : "38vh",
                  maxHeight: isFullScreen ? "100%" : "38vh",
                  overflowY: "scroll",
                  py: "10px",
                  px: 1,
                }}
                className="nodrag nowheel"
              >
                <Box sx={{ px: 1, mb: 2 }}>
                  <Typography variant="h6">
                    MOBILIZE EVENT DETAILS{" "}
                    {selectedEvent.browser_url && (
                      <Link href={selectedEvent.browser_url} target="_blank">
                        <LinkRounded fontSize="small" />
                      </Link>
                    )}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => setSelectedEvent(undefined)}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    bgcolor: (theme) => theme.palette.info.main,
                    zIndex: 999,
                  }}
                >
                  <Close sx={{ color: "white" }} />
                </IconButton>
                <Box sx={{ mt: 0 }}>
                  <MobilizeEventDetailsOnly
                    event={selectedEvent}
                    submit={true}
                    signupCounts={signupCounts}
                  />
                </Box>
              </Paper>
            )}
            {selectedEvent && selectedAttendance && (
              <Box
                sx={{
                  flexGrow: 0.75,
                  maxWidth: "75%",
                  width: "75%",
                  bgcolor: "transparent",
                  position: "relative", // Allows positioning of the close button
                  transition: "flex-grow 1s",
                  height: isFullScreen ? "100%" : "40vh",
                  maxHeight: isFullScreen ? "100%" : "40vh",
                  // overflowY: "scroll",
                  py: "10px",
                  px: 1,
                }}
                className="nodrag nowheel"
              >
                <AttendanceDetails
                  person={person}
                  attendance={selectedAttendance}
                  event={selectedEvent}
                  disabled={!!selectedAttendance.id || disabled}
                  callback={async (res: any) => {
                    // await callback();
                    handleQuestionPropsChange("signups", [
                      ...(value?.signups || []),
                      res,
                    ]);
                    setSelectedAttendance(undefined);
                  }}
                  close={() => setSelectedAttendance(undefined)}
                  signupCounts={signupCounts}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          hidden={tabValue !== 1}
          sx={isFullScreen ? { height: "100%" } : { height: "325px" }}
        >
          <VisMap
            markers={{
              centralLocation: {
                address: filters.userAddress,
                ...userLocation,
                label: "Your address",
              },
              otherLocations: filteredEvents?.map((e) => ({
                label: e.title,
                coordinates: {
                  lat: e.location?.location?.latitude!,
                  lng: e.location?.location?.longitude!,
                },
              })),
              locationCallback: (location) => setUserLocation(location),
            }}
            active={true}
          />
        </Box>
        {answer?.signups && (
          <Box
            hidden={tabValue !== 2}
            sx={{
              height: isFullScreen ? "100%" : showAllTabs ? "325px" : undefined,
              maxHeight: isFullScreen ? "100%" : "325px",
            }}
          >
            <AttendancesList
              attendances={answer.signups?.map((s: any) => s.form)}
            />
          </Box>
        )}
      </Box>
    );
  }
};
