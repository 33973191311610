import { forwardRef, useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { useBreakpointQuery } from "../../utils/media.util";
import { JItem } from "../jformik/jformik.types";
import { useJFormikContext } from "../jformik/jformik.context";
import { Edit, Save } from "@mui/icons-material";

export const FTextField = forwardRef((jItem: JItem, ref) => {
  const smDown = useBreakpointQuery("sm-down");
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean | undefined>(
    jItem?.tooltip?.open
  );
  const [localDisabled, setLocalDisabled] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const { disabled: jDisabled = false, disableSubmitOnEnter = true } =
    useJFormikContext();
  const disabled =
    jItem.disabled === false ? false : jDisabled || jItem.disabled;

  const { values, setFieldValue } = useFormikContext();
  const [customStep, setCustomStep] = useState<string>(
    getIn(values, jItem.name)?.value && getIn(values, jItem.name)?.value !== ""
      ? "value"
      : jItem.jItemProps?.customStep || "label"
  );
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const normalizePhone = (value: string) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length > 0 && onlyNums.length < 4)
      return `${onlyNums.slice(0, 3)}`;
    if (onlyNums.length > 0 && onlyNums.length < 7)
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10
    )}`;
  };
  const normalizeNumber = (value: string) => {
    if (!value) return value;
    return `${value}`
      ?.replace(/[^\d]/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const defaultValue =
    typeof jItem.defaultValue === "function"
      ? jItem.defaultValue(values)
      : jItem.defaultValue;
  useEffect(() => {
    if (defaultValue && !getIn(values, jItem.name)) {
      setFieldValue(jItem.name, defaultValue);
    }
  }, [jItem.name, defaultValue]);
  return (
    <Field
      name={jItem.name}
      validate={
        jItem?.jItemProps?.type === "zipcode"
          ? (value: any) => {
              const country = getIn(
                values,
                jItem.name?.replace("zipcode", "country")
              );
              let regex = /^\d{5}(?:[-\s]\d{4})?$/;
              if (country === "CA") {
                regex =
                  /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} \d{1}[A-Z]{1}\d{1}$/i;
              }
              if (!value || regex.test(value)) {
                return;
              } else {
                return "Invalid Zipcode";
              }
            }
          : undefined
      }
    >
      {({
        field: { name, value, onChange, onBlur },
        form: { touched, values, errors, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        if (jItem.jItemProps?.type === "password") {
          return (
            <TextField
              id={`${jItem.name}-field`}
              onKeyPress={(e) => {
                disableSubmitOnEnter && e.key === "Enter" && e.preventDefault();
              }}
              disabled={
                jItem.disabled === false ? false : disabled || jItem.disabled
              }
              label={
                jItem.jItemProps?.labelFunc
                  ? jItem?.jItemProps?.labelFunc(name, values, {
                      setFieldValue,
                    })
                  : jItem.label
              }
              placeholder={jItem.jItemProps?.placeholder || jItem.label || ""}
              name={name}
              variant={jItem.jItemProps?.variant || "outlined"}
              // size={smDown ? 'small' : 'medium'}
              fullWidth
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              onBlur={onBlur}
              error={
                !!(
                  getIn(errors, name) &&
                  (getIn(touched, name) ||
                    submitCount ||
                    jItem?.jItemProps?.touched)
                )
              }
              helperText={
                getIn(touched, name) ||
                submitCount ||
                jItem?.jItemProps?.touched
                  ? getIn(errors, name)
                  : null
              }
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                inputLabel: { shrink: !!value ? true : undefined },
              }}
              {...jItem.jItemProps}
              {...(showPassword ? { type: "text" } : { type: "password" })}
            />
          );
        } else if (jItem.jItemProps?.type === "local-disabled") {
          return (
            <TextField
              onMouseEnter={() => setHover(true && !disabled)}
              onMouseLeave={() => setHover(false)}
              onKeyPress={(e) => {
                disableSubmitOnEnter && e.key === "Enter" && e.preventDefault();
              }}
              id={`${jItem.name}-field`}
              disabled={localDisabled}
              label={
                jItem.jItemProps?.labelFunc
                  ? jItem?.jItemProps?.labelFunc(name, values, {
                      setFieldValue,
                    })
                  : jItem.label
              }
              name={name}
              variant={jItem.jItemProps?.variant || "outlined"}
              // size={smDown ? 'small' : 'medium'}
              fullWidth
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              onBlur={onBlur}
              error={
                !!(
                  getIn(errors, name) &&
                  (getIn(touched, name) ||
                    submitCount ||
                    jItem?.jItemProps?.touched)
                )
              }
              helperText={
                getIn(touched, name) ||
                submitCount ||
                jItem?.jItemProps?.touched
                  ? getIn(errors, name)
                  : null
              }
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setLocalDisabled((localDisabled) => !localDisabled)
                        }
                        edge="end"
                        sx={{
                          visibility:
                            hover || !localDisabled ? "visible" : "hidden",
                        }}
                      >
                        {localDisabled ? <Edit /> : <Save />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                inputLabel: { shrink: !!value ? true : undefined },
              }}
              {...jItem.jItemProps}
            />
          );
        } else if (jItem.jItemProps?.textFieldType === "custom") {
          return (
            <TextField
              onMouseEnter={() => setHover(true && !disabled)}
              onMouseLeave={() => setHover(false)}
              onKeyPress={(e) => {
                if (
                  e.key === "Enter" &&
                  !(value?.value || value?.value === "") &&
                  !(customStep === "value")
                ) {
                  setFieldValue(name, { ...value, value: "" });
                  setCustomStep("value");
                }
                disableSubmitOnEnter && e.key === "Enter" && e.preventDefault();
              }}
              id={`${jItem.name}-field`}
              disabled={
                jItem.disabled === false ? false : disabled || jItem.disabled
              }
              onFocus={() => {
                setFocus(true);
              }}
              label={
                jItem.jItemProps?.labelFunc
                  ? jItem?.jItemProps?.labelFunc(name, values, {
                      setFieldValue,
                    })
                  : value?.label || "Please enter the field title..."
              }
              name={name}
              variant={jItem.jItemProps?.variant || "outlined"}
              // size={smDown ? 'small' : 'medium'}
              fullWidth
              value={
                value?.value || value?.value === ""
                  ? value?.value
                  : value?.[customStep]
              }
              defaultValue={defaultValue}
              onChange={(event: any) => {
                setFieldValue(name, {
                  ...value,
                  [customStep]: event.target.value,
                });
              }}
              onBlur={(event: any) => {
                setFocus(false);
                onBlur(event);
              }}
              error={
                !!(
                  getIn(errors, name) &&
                  (getIn(touched, name) ||
                    submitCount ||
                    jItem?.jItemProps?.touched)
                )
              }
              helperText={
                getIn(touched, name) ||
                submitCount ||
                jItem?.jItemProps?.touched
                  ? getIn(errors, name)
                  : null
              }
              {...jItem.jItemProps}
              slotProps={{
                input: {
                  ...jItem?.jItemProps?.InputProps,
                  endAdornment:
                    !(value?.value || value?.value === "") &&
                    !(customStep === "value") ? (
                      <InputAdornment position="end">
                        <Tooltip title={"Set label"}>
                          <IconButton
                            onClick={() => {
                              setFieldValue(name, { ...value, value: "" });
                              setCustomStep("value");
                            }}
                          >
                            <NavigateNext color="secondary" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      <div />
                    ),
                  startAdornment:
                    (focus || hover) &&
                    (typeof jItem?.jItemProps?.InputProps?.startAdornment ===
                    "function"
                      ? jItem?.jItemProps?.InputProps?.startAdornment(
                          name,
                          values,
                          { setFieldValue }
                        )
                      : jItem?.jItemProps?.InputProps?.startAdornment),
                },
                inputLabel: { shrink: !!value ? true : undefined },
              }}
            />
          );
        } else if (jItem.tooltip) {
          return (
            <Tooltip {...{ ...jItem.tooltip, open: openTooltip }}>
              <TextField
                disabled={
                  jItem.disabled === false ? false : disabled || jItem.disabled
                }
                onKeyPress={(e) => {
                  disableSubmitOnEnter &&
                    e.key === "Enter" &&
                    e.preventDefault();
                }}
                onFocus={() => {
                  if (openTooltip) {
                    setOpenTooltip(false);
                  }
                }}
                label={
                  jItem.jItemProps?.labelFunc
                    ? jItem?.jItemProps?.labelFunc(name, values, {
                        setFieldValue,
                      })
                    : jItem.label
                }
                name={name}
                variant={jItem.jItemProps?.variant || "outlined"}
                // size={smDown ? 'small' : 'medium'}
                fullWidth
                value={value}
                defaultValue={defaultValue}
                onChange={
                  jItem?.jItemProps?.type === "phone"
                    ? (event) =>
                        setFieldValue(name, normalizePhone(event.target.value))
                    : jItem?.jItemProps?.type === "formatted_number"
                    ? (event) =>
                        setFieldValue(name, normalizeNumber(event.target.value))
                    : onChange
                }
                onBlur={onBlur}
                error={
                  !!(
                    getIn(errors, name) &&
                    (getIn(touched, name) || submitCount)
                  )
                }
                helperText={
                  getIn(touched, name) || submitCount
                    ? getIn(errors, name)
                    : null
                }
                {...jItem.jItemProps}
                slotProps={{
                  input: {
                    ...(jItem.jItemProps?.InputProps || {}),
                    endAdornment: jItem?.jItemProps?.InputProps?.endAdornment,
                    startAdornment:
                      typeof jItem?.jItemProps?.InputProps?.startAdornment ===
                      "function"
                        ? jItem?.jItemProps?.InputProps?.startAdornment(
                            name,
                            values,
                            { setFieldValue }
                          )
                        : jItem?.jItemProps?.InputProps?.startAdornment,
                    // type: jItem.input_type || 'text',
                  },
                  inputLabel: { shrink: !!value ? true : undefined },
                }}
              />
            </Tooltip>
          );
        } else {
          return (
            <>
              <TextField
                disabled={
                  jItem.disabled === false ? false : disabled || jItem.disabled
                }
                label={
                  jItem.jItemProps?.labelFunc
                    ? jItem?.jItemProps?.labelFunc(name, values, {
                        setFieldValue,
                      })
                    : jItem.label
                }
                onKeyPress={(e) => {
                  disableSubmitOnEnter &&
                    e.key === "Enter" &&
                    !jItem?.jItemProps?.multiline &&
                    e.preventDefault();
                }}
                name={name}
                variant={jItem.jItemProps?.variant || "outlined"}
                // size={smDown ? 'small' : 'medium'}
                // size={jItem?.jItemProps?.size}
                fullWidth
                value={value}
                defaultValue={defaultValue}
                onChange={
                  jItem?.jItemProps?.type === "phone"
                    ? (event) =>
                        setFieldValue(name, normalizePhone(event.target.value))
                    : jItem?.jItemProps?.type === "formatted_number"
                    ? (event) =>
                        setFieldValue(name, normalizeNumber(event.target.value))
                    : onChange
                }
                onBlur={onBlur}
                error={
                  !!(
                    getIn(errors, name) &&
                    (getIn(touched, name) ||
                      submitCount ||
                      jItem?.jItemProps?.touched)
                  )
                }
                helperText={
                  getIn(touched, name) ||
                  submitCount ||
                  jItem?.jItemProps?.touched
                    ? getIn(errors, name)
                    : null
                }
                {...jItem.jItemProps}
                slotProps={{
                  input: {
                    ...(jItem.jItemProps?.InputProps || {}),
                    endAdornment: jItem?.jItemProps?.InputProps?.endAdornment,
                    startAdornment:
                      typeof jItem?.jItemProps?.InputProps?.startAdornment ===
                      "function"
                        ? jItem?.jItemProps?.InputProps?.startAdornment(
                            name,
                            values,
                            { setFieldValue }
                          )
                        : jItem?.jItemProps?.InputProps?.startAdornment,
                    // type: jItem.input_type || 'text',
                  },
                  inputLabel: { shrink: !!value ? true : undefined },
                }}
              />
            </>
          );
        }
      }}
    </Field>
  );
});
