import {
  CircularProgress,
  Typography,
  Grid2 as Grid,
  Box,
} from "@mui/material";

export interface LoadingProps {
  text?: string;
  color?: "inherit" | "primary" | "secondary";
  size?: number;
}

export const Loading = ({ text, color, size }: LoadingProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={1} sx={{ textAlign: "center" }}>
        <Grid size={{ xs: 12 }}>
          {text !== " " && (
            <Typography variant="h6">{text ? text : "Loading"}</Typography>
          )}
        </Grid>

        <Grid size={{ xs: 12 }}>
          <CircularProgress
            color={color ? color : "primary"}
            size={size ? size : 60}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
