import { Grid2, Paper, Box, Typography, Button } from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser } from "store";
import { Skill, Conversation, Volunteer } from "store/api/models";
import { VolunteerState } from "./agent.types";

export const AgentIdle = ({
  // isMenuOpen,
  // volunteer,
  onClick,
  state,
  setState,
}: {
  // isMenuOpen: boolean;
  // volunteer?: Volunteer;
  onClick: Function;
  state: VolunteerState;
  setState: React.Dispatch<React.SetStateAction<VolunteerState>>;
}) => {
  const user = useSelector(selectUser);
  return (
    <Grid2
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%", height: "100%" }}
    >
      <Paper
        elevation={8}
        sx={{
          width: "60%",
          height: "40%",
          borderRadius: "8px",
          p: 8,
          border: (theme) => `3px solid ${theme.palette.secondary.main}`,
        }}
      >
        <Grid2
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: "100%",
            height: "100%",
            p: 2,
            textAlign: "center",
          }}
        >
          {!state.selectedSkill && (
            <Typography variant="h6">
              Please click on one of the menu options to the left to proceed.
            </Typography>
          )}
          {(state.completed || state.selectedSkill) && (
            <Box sx={{ textAlign: "center" }}>
              {state.completed && (
                <Box sx={{ width: "100%", display: "flex", pl: "auto" }}>
                  <ConfettiExplosion
                    colors={["red", "white", "blue"]}
                    style={{ left: "50%" }}
                  />
                </Box>
              )}
              <Typography
                variant="h4"
                // sx={{ color: (theme) => theme.palette.primary.main }}
              >
                {state.completed
                  ? "Great job! Ready to make another call?"
                  : "Please click the button below when you are ready to be assigned a call."}
              </Typography>

              <Button
                variant="contained"
                onClick={(e) => onClick(e)}
                sx={{ px: 3, py: 1, mt: 4 }}
              >
                <Typography
                  variant="h5"
                  // sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  I am ready!
                </Typography>
              </Button>
            </Box>
          )}
        </Grid2>
      </Paper>
    </Grid2>
  );
};
