import React, {
  useState,
  useRef,
  ChangeEvent,
  useMemo,
  useEffect,
} from "react";
import {
  TextField,
  Popper,
  List,
  ListItemText,
  Paper,
  ListItemButton,
} from "@mui/material";

export const TemplateTextField = ({
  templates,
  values,
  inputValue,
  onInputChange = () => {},
  disabled = false,
}: {
  templates: string[];
  values?: { [key: string]: string };
  inputValue?: string;
  onInputChange?: (newValue: string) => void;
  disabled?: boolean;
}) => {
  const [value, setValue] = useState<string>(inputValue || "");
  const [templateOptions, setTemplateOptions] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const templateRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => setValue(inputValue || ""), [inputValue]);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    onInputChange(inputValue);
    // Find the last typed {{ symbol in the string
    const atIndex = inputValue.lastIndexOf("{{");
    if (atIndex !== -1) {
      // Show options and filter template options based on input after @
      const searchValue =
        inputValue.slice(atIndex + 1).match(/^\S+/)?.[0] || "";
      if (searchValue.length > 0) {
        const filteredOptions = templates.filter((template) =>
          template.toLowerCase().includes(searchValue.toLowerCase())
        );
        setTemplateOptions(filteredOptions);
        setAnchorEl(templateRef.current);
      } else {
        setTemplateOptions(templates); // Show full list on just '@'
        setAnchorEl(templateRef.current);
      }
    } else {
      setTemplateOptions([]); // Hide options if @ is not in the string
    }
  };

  const handleTemplateClick = (template: string) => {
    // Insert the selected template into the input field at the @ position
    const atIndex = value.lastIndexOf("{{");
    const newValue =
      value.slice(0, atIndex) +
      template +
      value.slice(atIndex + 1).replace("{", "") +
      " ";
    setValue(newValue);
    onInputChange(newValue);
    setTemplateOptions([]);
  };

  return (
    <div>
      <TextField
        fullWidth
        inputRef={templateRef}
        value={value}
        disabled={disabled || !!values}
        onChange={handleInputChange}
        placeholder="Type '{{' to add in a dynamic field"
        variant="outlined"
        minRows={4}
        multiline
      />

      <Popper
        open={templateOptions.length > 0}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <Paper>
          <List>
            {templateOptions.map((option, index) => (
              <ListItemButton
                key={index}
                onClick={() => handleTemplateClick(option)}
              >
                <ListItemText primary={option} />
              </ListItemButton>
            ))}
          </List>
        </Paper>
      </Popper>
    </div>
  );
};
