declare global {
  interface Window {
    voluntizeapiconfig?: {
      apiBaseUrl: { [key: string]: string };
      routeNames: { [key: string]: string };
      blockAPI?: boolean;
    };
  }
}

export const routeNames = {
  // Unauth pages
  FORGOT_PASSWORD: "/a/forgot-password",
  LOGIN: "/a/login",
  REFRESH: "/a/refresh",
  SIGNUP: "/a/signup",
  RESET_PASSWORD: "/a/reset-password",
  CHANGE_PASSWORD: "/a/change-password",
  UPDATE_PROFILE: "/a/update-profile",
  EMAIL: "/e/email",

  //General pages
  HOME: "/",
};

export const apiBaseUrl: {
  [key: string]: string;
} = {
  local: "http://localhost:5000", //golang server
  development: "https://api-dev.voluntize.us",
  production: "https://api.voluntize.us",
};

window.voluntizeapiconfig = {
  apiBaseUrl: apiBaseUrl,
  routeNames: routeNames,
};
