import { useState } from "react";
import { Box, Fab, Paper } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  selectVolunteerEntities,
  useGetVolunteersQuery,
} from "store/api/volunteer.api";
import { Login, Volunteer } from "store/api/models";
import { VolunteerList } from "./VolunteerList";
import { VolunteerDetails } from "./VolunteerDetails";
import { BackdropLoading } from "../BackdropLoading";
import { useGetLoginsQuery } from "store/api/login.api";
import { FormattedList } from "components/list/FormattedList";
import { useSelector } from "react-redux";

export const VolunteersIndex = () => {
  const [selectedVolunteer, setSelectedVolunteer] = useState<Volunteer>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: volunteers = [] as Volunteer[],
    isLoading: volunteersLoading,
    refetch,
  } = useGetVolunteersQuery({ params: { nested: "normalize" } });
  const volunteerEntities = useSelector(selectVolunteerEntities);
  const { data: logins = [] as Login[], isLoading: loginsLoading } =
    useGetLoginsQuery({ params: { admin: false } });
  const handleVolunteerClick = (volunteer: Volunteer) => {
    setEditing(false);
    setSelectedVolunteer(volunteer);
  };
  const handleVolunteerEditClick = (volunteer: Volunteer) => {
    setEditing(true);
    setSelectedVolunteer(volunteer);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        position: "relative",
        flexDirection: "row",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {volunteersLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedVolunteer ? 0.5 : 1,
            width: selectedVolunteer ? "50%" : undefined,
            maxWidth: selectedVolunteer ? "50%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedVolunteer ? 1 : 0,
            maxHeight: "100%",
            height: "100%",
          }}
        >
          <Paper sx={{ p: 1, maxHeight: "100%", height: "100%" }} elevation={5}>
            {/* <FormattedList
              listItems={volunteers.map((v) => v.id)}
              primary={(id: number) => volunteerEntities?.[id]?.email}
              secondary={(id: number) => {
                const login = loginEntities?.[id];
                return login?.last_logged_in &&
                  moment(login.last_logged_in).isSameOrAfter(
                    moment().startOf("year")
                  )
                  ? `Last logged in: ${moment(
                      login.last_logged_in
                    ).toLocaleString()}`
                  : `Not yet logged in`;
              }}
              icon={<AdminPanelSettings color="secondary" />}
              onItemClick={(id: number) =>
                handleLoginClick(loginEntities?.[id])
              }
              editItem={(id: number) =>
                handleVolunteerEditClick(volunteerEntities?.[id])
              }
              deleteItem={(id: number) => deleteVol(id)}
              selectedId={selectedLogin.id}
              itemName="login"
              onClose={() => close()}
            /> */}
            <VolunteerList
              volunteers={volunteers}
              logins={logins}
              selectedVolunteer={selectedVolunteer}
              handleVolunteerClick={handleVolunteerClick}
              handleVolunteerEditClick={handleVolunteerEditClick}
            />
          </Paper>
        </Box>
      )}

      <Fab
        color="primary"
        sx={{
          position: "absolute",
          left: 10,
          bottom: 50,
        }}
        onClick={() => {
          setEditing(true);
          setSelectedVolunteer({} as Volunteer);
        }}
      >
        <Add />
      </Fab>

      {/* Volunteer Details */}
      {selectedVolunteer && (
        <Box
          sx={{
            flexGrow: 0.5,
            width: selectedVolunteer ? "50%" : undefined,
            maxWidth: selectedVolunteer ? "50%" : undefined,
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            maxHeight: "100%",
            height: "100%",
          }}
        >
          <VolunteerDetails
            disabled={!editing}
            volunteer={selectedVolunteer}
            close={() => {
              setSelectedVolunteer(undefined);
              setEditing(false);
            }}
            callback={async () => {
              setEditing(false);
              await refetch();
            }}
          />
        </Box>
      )}
    </Box>
  );
};
