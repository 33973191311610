import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Collapse,
  ListItemButton,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import { Attendance, Event } from "store/api/mobilize/mobilize_models";
import { useSelector } from "react-redux";
import { selectEventEntities } from "store/api/mobilize/mobilize.api";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";

export const AttendancesList = ({
  attendances,
}: {
  attendances: Attendance[];
}) => {
  const mobilize_events = useSelector(selectEventEntities);
  return (
    <Box sx={{ height: "100%", flexGrow: 3 }}>
      <List
        sx={{
          maxHeight: "100%",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {attendances?.map((attendance: Attendance, index: number) => (
          <EventListAttendance
            attendance={attendance}
            index={index}
            event={mobilize_events[attendance.event_id]}
          />
        ))}
      </List>
    </Box>
  );
};

export const EventListAttendance = ({
  attendance,
  event,
  index,
}: {
  attendance: Attendance;
  index: number;
  event: Event;
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem key={index} dense disableGutters disablePadding sx={{ ml: 2 }}>
        <ListItemButton onClick={handleClick}>
          <ListItemText
            primary={
              <Typography variant="overline" sx={{ fontWeight: 700 }}>
                {event?.title}
              </Typography>
            }
            sx={{ cursor: "pointer", py: 0, my: 0 }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding sx={{ pl: 4 }}>
          {attendance.timeslots?.map((t, i) => {
            const startDate = moment(t.start_date);
            const endDate = moment(t.end_date);
            // Format start and end dates
            const formattedDate = `${startDate.format(
              "ddd, MMM D, h:mmA"
            )}–${endDate.format("h:mmA z")}`;
            return (
              <ListItem
                key={attendance?.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                dense
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 500,
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      <Checkbox checked={true} disabled={true} />
                      {formattedDate}
                    </Typography>
                  }
                  sx={{ cursor: "pointer", py: 0, my: 0 }}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
