import { useState } from "react";
import { Box, Fab, Paper } from "@mui/material";

import { EventGroup, Event } from "store/api/mobilize/mobilize_models";
import { MobilizeEventDetails } from "./MobilizeEventDetails";
import { BackdropLoading } from "../BackdropLoading";
import { useGetMobilizeEventsQuery } from "store/api/mobilize/mobilize.api";
import { MobilizeEventGroups } from "./MobilizeEventGroups";
import { Add, CalendarViewDay } from "@mui/icons-material";
import { useGetEventGroupsQuery } from "store/api/mobilize/mobilize_event_group.api";
import { MobilizeEventGroupDetails } from "./MobilizeEventGroupDetails";
import { FormattedList } from "components/list/FormattedList";

export const MobilizeEventGroupsIndex = () => {
  const [selectedEventGroup, setSelectedEventGroup] = useState<EventGroup>();
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: event_groups = [] as EventGroup[],
    isLoading: eventGroupsLoading,
    refetch,
  } = useGetEventGroupsQuery({ params: { nested: "all" } });

  const { data: events = [], isLoading: eventsLoading } =
    useGetMobilizeEventsQuery({});

  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
  };
  const handleEventGroupClick = (event: EventGroup) => {
    setEditing(false);
    setSelectedEventGroup(event);
  };
  const handleEventGroupEditClick = (event: EventGroup) => {
    setEditing(true);
    setSelectedEventGroup(event);
  };
  const handleDuplicateEventGroup = (event_group: EventGroup) => {
    setEditing(true);
    setSelectedEventGroup({ ...event_group, name: "" });
  };

  return (
    <Box
      id="top"
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {eventsLoading || eventGroupsLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedEventGroup ? 0.25 : 1,
            width: selectedEventGroup ? "25%" : undefined,
            maxWidth: selectedEventGroup ? "25%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedEventGroup ? 1 : 0,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Paper sx={{ p: 1, height: "100%", maxHeight: "100%" }} elevation={5}>
            {/* <FormattedList
              listItems={prospect_lists?.map((s) => s.id) || []}
              onItemClick={(id: number) =>
                handleProspectListClick(prospectListEntities?.[id])
              }
              editItem={(id: number) =>
                handleProspectListEditClick(prospectListEntities?.[id])
              }
              deleteItem={(id: number) => deleteProspectList(id)}
              // duplicateItem={(id: number) => handleDuplicateScript(id)}
              primary={(id: number) => {
                const prospect_list = prospectListEntities?.[id] || {};

                return (
                  <Typography variant="overline" sx={{ fontWeight: 700 }}>
                    {prospect_list?.name}{" "}
                    {prospect_list?.form?.prospectListType && (
                      <Chip
                        label={prospect_list?.form?.prospectListType}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        sx={{ zoom: "75%", mr: 1 }}
                      />
                    )}{" "}
                    {prospect_list?.form?.fileName && (
                      <Chip
                        label={prospect_list?.form?.fileName}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        sx={{ zoom: "75%", mr: 1 }}
                      />
                    )}
                  </Typography>
                );
              }}
              icon={
                <CalendarViewDay
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                    fontSize="small"
                  />
              }
              onClose={() => setSelectedEventGroup(undefined)} //close()}
              selectedId={selectedEventGroup?.id}
              itemName="event group"
            /> */}
            <MobilizeEventGroups
              event_groups={event_groups}
              selectedEventGroup={selectedEventGroup}
              handleDuplicateEventGroup={handleDuplicateEventGroup}
              handleEventGroupClick={handleEventGroupClick}
              handleEventGroupEditClick={handleEventGroupEditClick}
              close={() => setSelectedEventGroup(undefined)}
            />
          </Paper>
        </Box>
      )}
      {!selectedEventGroup && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            left: 10,
            bottom: 10,
          }}
          onClick={() => {
            setEditing(true);
            setSelectedEventGroup({} as EventGroup);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* EventGroup Details */}
      {selectedEventGroup && (
        <Box
          key={selectedEventGroup.id || Date.now()}
          sx={{
            flexGrow: 0.75,
            maxWidth: "75%",
            width: "75%",
            bgcolor: "transparent",
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          {selectedEvent ? (
            <MobilizeEventDetails
              disabled={true}
              event={selectedEvent}
              close={() => {
                setSelectedEvent(undefined);
                setEditing(false);
              }}
              callback={async () => {
                await refetch();
                setSelectedEvent(undefined);
                setEditing(false);
              }}
            />
          ) : (
            <MobilizeEventGroupDetails
              events={events}
              disabled={!editing}
              event_group={selectedEventGroup}
              close={() => {
                setSelectedEventGroup(undefined);
                setEditing(false);
              }}
              handleEventClick={handleEventClick}
              callback={async () => {
                await refetch();
                setSelectedEvent(undefined);
                setEditing(false);
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
