import React, { useCallback } from "react";
import {
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  EdgeChange,
  OnEdgesDelete,
  OnNodesDelete,
  Node,
  NodeChange,
  Edge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import cloneDeep from "lodash/cloneDeep";
import { QuestionNode } from "./nodes/QuestionNode";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Grid2 as Grid,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import {
  addQuestion,
  setEdges,
  setQuestions,
  QuestionFieldData,
  handleNodesUpdate,
} from "store/slice/formBuilder.slice";
import { FormTemplateCopy } from "./FormTemplateCopy";
import { FormTemplateCopyRedux } from "./FormTemplateCopyRedux";
// import debounce from "lodash.debounce";÷

const nodeTypes = { questionNode: QuestionNode };

export const FlowFormBuilderRedux: React.FC = () => {
  const dispatch = useDispatch();

  // Get state from Redux
  const nodes: Node<QuestionFieldData>[] = useSelector(
    (state: any) => state.formBuilder.questions
  );
  const edges: Edge[] = useSelector((state: any) => state.formBuilder.edges);
  const memoizedNodes = React.useMemo(() => nodes, [nodes]);
  const memoizedEdges = React.useMemo(() => edges, [edges]);
  const disabled: boolean = useSelector(
    (state: any) => state.formBuilder.disabled
  );

  // Update nodes
  const onNodesChange = useCallback(
    (changes: NodeChange<Node<QuestionFieldData>>[]) => {
      const testUpdate = changes[0];
      //disregard dimension or null position changes
      if (
        testUpdate.type === "dimensions" ||
        (testUpdate.type === "position" &&
          (!testUpdate.position?.x || !testUpdate.position?.y))
      ) {
        return;
      }
      dispatch(handleNodesUpdate(changes));
    },
    [dispatch]
  );

  // Update edges
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const updatedEdges = applyEdgeChanges(changes, edges);
      dispatch(setEdges(updatedEdges));
    },
    [dispatch, edges]
  );

  // On connect new edge
  const onConnect = useCallback(
    (params: Connection) => {
      const newEdges = addEdge({ ...params, animated: true }, edges);
      dispatch(setEdges(newEdges));
    },
    [dispatch, edges]
  );

  const onEdgesDelete: OnEdgesDelete = useCallback(
    (deletedEdges) => {
      const updatedEdges = edges.filter((e: any) => !deletedEdges.includes(e));
      dispatch(setEdges(updatedEdges));
    },
    [dispatch, edges]
  );

  // Handler for deleting nodes
  const onNodesDelete: OnNodesDelete = useCallback(
    (deletedNodes) => {
      const updatedNodes = nodes
        .filter((node) => !deletedNodes.find((dn) => dn.id === node.id))
        .map((i, index) => ({ ...i, data: { ...i.data, index } }));
      dispatch(setQuestions(updatedNodes));

      const updatedEdges = edges.filter(
        (edge) =>
          !deletedNodes.find(
            (node) => node.id === edge.source || node.id === edge.target
          )
      );
      dispatch(setEdges(updatedEdges));
    },
    [dispatch, nodes, edges]
  );

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <Paper elevation={5} sx={{ m: 1 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            pt: 1,
            px: 2,
            mb: 1,
            bgcolor: (theme) => theme.palette.divider,
          }}
        >
          <Grid>
            <Typography variant="h6" sx={{ mb: 2 }}>
              SCRIPT LOGIC BUILDER{" "}
              {!disabled && (
                <Tooltip title="Add question">
                  <IconButton
                    onClick={() => dispatch(addQuestion({}))}
                    sx={{ ml: 2, bgcolor: (theme) => theme.palette.info.main }}
                  >
                    <Add sx={{ color: "white" }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          </Grid>
          {!disabled && (
            <Grid>
              <FormTemplateCopyRedux />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Divider sx={{ mb: 1 }} />
      <ReactFlow
        nodes={memoizedNodes}
        edges={memoizedEdges}
        nodeTypes={nodeTypes}
        nodesDraggable={!disabled}
        nodesConnectable={!disabled}
        elementsSelectable={!disabled}
        onNodesChange={!disabled ? onNodesChange : undefined}
        onEdgesChange={!disabled ? onEdgesChange : undefined}
        onConnect={!disabled ? onConnect : undefined}
        onEdgesDelete={!disabled ? onEdgesDelete : undefined}
        onNodesDelete={!disabled ? onNodesDelete : undefined}
        deleteKeyCode={!disabled ? ["Backspace", "Delete"] : []}
        minZoom={0.1}
        maxZoom={0.9}
        // onlyRenderVisibleElements={true}
        fitView
      />
    </Box>
  );
};
