import { Reducer, Middleware, combineReducers } from "redux";
import {
  baseApi,
  contactSlice,
  eventTypeSlice,
  init,
  logout,
  roleSlice,
  skillSlice,
  taskSlice,
  taskTypeSlice,
  user,
  userSlice,
  volunteerSlice,
} from "store/api";
import { attendanceSlice } from "store/api/attendance.api";
import { conversationSlice } from "store/api/conversation.api";
import { loginSlice } from "store/api/login.api";
import { eventSlice } from "store/api/mobilize/mobilize.api";
import { eventGroupSlice } from "store/api/mobilize/mobilize_event_group.api";
import { ngpcontactSlice } from "store/api/ngp/ngp_contact.api";
import { prospectSlice } from "store/api/prospect.api";
import { prospectListSlice } from "store/api/prospect_list.api";
import { resultSlice } from "store/api/result.api";
import { scriptSlice } from "store/api/script.api";
import { signupLinkSlice } from "store/api/signup_link.api";
import { taskProspectListSlice } from "store/api/task_prospect_list.api";
import { taskSkillSlice } from "store/api/task_skill.api";
import { voiceSlice } from "store/api/voice.api";
import { formBuilderSlice } from "store/slice/formBuilder.slice";
import { historySlice } from "store/slice/history.slice";
import { mqttSlice } from "store/slice/mqtt.slice";
import { twilioVoiceSlice } from "store/slice/twilioVoice.slice";
import { getAuthStatus } from "utils";

export const appMiddleware: Middleware<any>[] = [
  user.middleware,
  baseApi.middleware,
];

const appReducer: Reducer<any> = combineReducers({
  [user.reducerPath]: user.reducer,
  [userSlice.name]: userSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [historySlice.name]: historySlice.reducer,
  [voiceSlice.name]: voiceSlice.reducer,
  [contactSlice.name]: contactSlice.reducer,
  [skillSlice.name]: skillSlice.reducer,
  [roleSlice.name]: roleSlice.reducer,
  [eventTypeSlice.name]: eventTypeSlice.reducer,
  [taskTypeSlice.name]: taskTypeSlice.reducer,
  [taskSkillSlice.name]: taskSkillSlice.reducer,
  [taskSlice.name]: taskSlice.reducer,
  [prospectListSlice.name]: prospectListSlice.reducer,
  [taskProspectListSlice.name]: taskProspectListSlice.reducer,
  [eventGroupSlice.name]: eventGroupSlice.reducer,
  [eventSlice.name]: eventSlice.reducer,
  [mqttSlice.name]: mqttSlice.reducer,
  [ngpcontactSlice.name]: ngpcontactSlice.reducer,
  [conversationSlice.name]: conversationSlice.reducer,
  [volunteerSlice.name]: volunteerSlice.reducer,
  [attendanceSlice.name]: attendanceSlice.reducer,
  [signupLinkSlice.name]: signupLinkSlice.reducer,
  [formBuilderSlice.name]: formBuilderSlice.reducer,
  [scriptSlice.name]: scriptSlice.reducer,
  [twilioVoiceSlice.name]: twilioVoiceSlice.reducer,
  [loginSlice.name]: loginSlice.reducer,
  [resultSlice.name]: resultSlice.reducer,
  [prospectSlice.name]: prospectSlice.reducer,
});

const rootReducer: Reducer<any> = (state: any, action) => {
  switch (action.type) {
    case init.toString(): {
      const auth = getAuthStatus();
      if (auth) {
        return appReducer({ auth: auth }, action);
      } else {
        return appReducer({}, action);
      }
    }
    case logout.toString(): {
      return appReducer(undefined, action);
    }
    default: {
      return appReducer(state, action);
    }
  }
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
