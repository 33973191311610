import { useRef, useState } from "react";
import { Box, BoxProps, IconButton, Paper, Typography } from "@mui/material";
import { JFormik } from "forms";
import { useEventForm } from "forms/forms/useEventForm";
import { toast } from "react-toastify";
import { Event } from "store/api/models";
import { Close } from "@mui/icons-material";
import {
  selectEventTypeById,
  useCreateEventMutation,
  useGetEventVolunteersQuery,
  usePatchEventMutation,
} from "store";
import { RootState } from "store/reducer";
import { useSelector } from "react-redux";
import { useGetEventTasksQuery } from "store/api/event_task.api";

export const EventDetails = ({
  event,
  disabled,
  close,
  callback,
}: {
  event: Event;
  disabled: boolean;
  close: Function;
  callback: Function;
}) => {
  const type_r = useSelector((state: RootState) =>
    selectEventTypeById(state, event?.type || 0)
  );
  const { data: volunteers = [], isLoading: isVolunteersLoading } =
    useGetEventVolunteersQuery({ params: { event: event.id, nested: "all" } });
  const { data: tasks = [], isLoading: isTasksLoading } = useGetEventTasksQuery(
    { params: { event: event.id, nested: "all" } }
  );
  const [createEvent] = useCreateEventMutation();
  const [patchEvent] = usePatchEventMutation();
  const form = useEventForm();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const formRef = useRef<any>(null);
  return (
    <Paper
      key={event.id}
      sx={{
        p: 1,
        position: "relative", // Allows positioning of the close button
        height: "100%",
        maxHeight: "100%",
        overflowY: "scroll",
      }}
      elevation={5}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          // maxHeight: "100%",
        }}
      >
        {/* {!disabled && (
          <Button
            variant="contained"
            // onClick={() => close()}
            onClick={async () => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
              close();
            }}
            sx={{ position: "absolute", top: 8, left: 8, m: 1 }}
          >
            {event?.id ? "SAVE EVENT" : "CREATE EVENT"}
          </Button>
        )} */}
        <IconButton
          onClick={() => close()}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4 }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="EVENT DETAILS" value="1" />
            <Tab label="EVENT TASKS" value="2" />
            <Tab label="AVAILABLE VOLUNTEERS" value="3" />
          </Tabs>
        </Box>

        <TabPanel value={value} index={"1"}> */}
        <Box sx={{ px: 1, mt: 1 }}>
          <Typography variant="h6">EVENT DETAILS</Typography>
        </Box>
        <Box sx={{ px: 1, mt: 3 }}>
          <JFormik
            formRef={formRef}
            disabled={disabled}
            initialValues={{ ...event, type: type_r, timeslots: [] }}
            enableReinitialize={true}
            handleSubmit={async (values, formik) => {
              if (values.id) {
                try {
                  await patchEvent({
                    ...values,
                    type: values?.type?.id,
                  }).unwrap();
                  close();
                  toast.info("Event updated successfuly");
                } catch (error) {
                  toast.error("Unable to update event");
                }
              } else {
                try {
                  let res: any = {};
                  res = await createEvent({
                    ...values,
                    type: values?.type?.id,
                  }).unwrap();
                  close();
                  callback && callback(res);
                  if (res.id) {
                    // setValues((values:any) => ({...values, id: res.id}));
                    toast.success("Event updated!");
                  }
                  toast.info("Event created successfuly");
                } catch (error) {
                  toast.error("Unable to create event");
                }
              }
            }}
            validationSchema={form.validationSchema}
            form={form.form}
          />
        </Box>
        {/* </TabPanel>
        <TabPanel value={value} index={"2"}>
          <Box sx={{ px: 1, mt: 1 }}>
            <Typography variant="h6">SCRIPT FORM BUILDER</Typography>
          </Box>
          <Box sx={{ px: 1, mt: 3, width: "100%" }}> */}
        {/* <FormBuilder /> */}
        {/* <TaskList
            tasks={tasks.map((t) => t.task_r)}
            selectedTask={selectedTask}
            handleTaskClick={handleTaskClick}
            handleTaskEditClick={handleTaskEditClick}
          /> */}
        {/* </Box>
        </TabPanel>
        <TabPanel value={value} index={"3"}>
          <VolunteerList volunteers={volunteers} />
        </TabPanel> */}
      </Box>
    </Paper>
  );
};

interface TabPanelProps extends BoxProps {
  // children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ height: "100%", maxHeight: "100%", overflowY: "scroll" }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
