import {
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { JItem } from '../jformik/jformik.types';
import { useJFormikContext } from '../jformik/jformik.context';

/**
 * TODO: [FToggleButtons]
 * implement toggle buttons group action
 * selected => button variant should be contained
 */

export const FToggleButtonGroup = (jItem: JItem) => {
  const { options: _options = [], jItemProps = {}, disabled = false } = jItem;
  const { containerGridProps = {}, itemsProps = [] } = jItemProps;
  const jFormikContext = useJFormikContext();
  const isDisabled = disabled || jFormikContext.disabled;

  return (
    <Field name={jItem.name}>
      {({
        field: { value, name },
        form: { isSubmitting, values, setFieldValue, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        const options =
          typeof _options === 'function' ? _options(values) : _options;
        return (
          <ToggleButtonGroup
            color="secondary"
            // size="small"
            value={value}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newSelections: string[]
            ) => {
              setFieldValue(name, newSelections);
            }}
          >
            {Array.isArray(jItem?.options) &&
              jItem?.options?.map((o: any) => (
                <ToggleButton value={o.value}>{o.label}</ToggleButton>
              ))}
          </ToggleButtonGroup>
        );
      }}
    </Field>
  );
};
