import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const InputFileUpload = ({
  disabled,
  value,
  handleFileUpload,
}: {
  disabled: boolean;
  value: string;
  handleFileUpload: (file: File | null) => void;
}) => {
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      disabled={disabled}
    >
      {value ? `${value} uploaded!` : `Upload files`}
      <VisuallyHiddenInput
        type="file"
        onChange={(event) => {
          const fileList = event.target.files;
          const files = fileList ? Array.from(fileList) : [];
          handleFileUpload(files[0]);
        }}
        // multiple
      />
    </Button>
  );
};
