import { forwardRef } from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Field, FieldProps, getIn } from "formik";
import { JItem } from "../jformik/jformik.types";
import { useBreakpointQuery } from "../../utils";
import moment from "moment";
import { useJFormikContext } from "../jformik/jformik.context";

export const FDatePicker = forwardRef((jItem: JItem, ref) => {
  const { label, name } = jItem;
  const smDown = useBreakpointQuery("sm-down");
  const { disabled = false, disableSubmitOnEnter = true } = useJFormikContext();
  return (
    <Field name={name} ref={ref}>
      {({
        field: { name, value, onChange, onBlur },
        form: { touched, errors, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled={disabled || jItem.disabled}
              label={label}
              // format="MM/dd/yyyy"
              value={
                value && moment(value).isValid()
                  ? moment(value).local()
                  : jItem.defaultValue && moment(jItem.defaultValue).isValid()
                  ? moment(jItem.defaultValue).local()
                  : null
              }
              onChange={(date) => {
                setFieldValue(
                  name,
                  date && moment(date).isValid()
                    ? jItem.jItemProps?.datetimeformat
                      ? date
                      : moment(date).format("YYYY-MM-DD")
                    : null
                );
              }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onKeyPress={(e) => {
                      disableSubmitOnEnter &&
                        e.key === "Enter" &&
                        e.preventDefault();
                    }}
                    // size={smDown ? 'small' : 'medium'}
                    error={
                      !!(
                        getIn(errors, name) &&
                        (getIn(touched, name) || submitCount)
                      )
                    }
                    helperText={
                      jItem.jItemProps?.helperText
                        ? jItem.jItemProps?.helperText
                        : getIn(touched, name) || submitCount
                        ? getIn(errors, name)
                        : null
                    }
                  />
                ),
              }}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
});
