import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Typography,
  Grid2 as Grid,
  Chip,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import {
  useGetSchemaQuery,
  Schema,
  Table,
  Column,
} from "store/api/ngp/schema.api";
import {
  Close as CloseIcon,
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon,
  CheckCircle,
} from "@mui/icons-material";

interface Join {
  table: Table | null; // The table being joined
  leftTable: Table | null; // Left table in the ON condition
  leftColumn: Column | null; // Column from the left table
  rightTable: Table | null; // Left table in the ON condition
  rightColumn: Column | null; // Column from the right (joined) table
  joinType?: JoinType; // Join type (e.g., INNER JOIN, LEFT JOIN)
}

interface Filter {
  table: Table | null;
  column: Column | null;
  operator: string;
  value: string;
}

interface SelectedTable {
  table: Table;
  alias: string;
}

interface SelectedColumn {
  table: Table;
  column: Column;
  alias: string;
}

interface SortOrder {
  column?: SelectedColumn | null;
  direction?: "ASC" | "DESC" | "";
}

type JoinType = "INNER JOIN" | "LEFT JOIN" | "RIGHT JOIN" | "FULL JOIN";

const operatorOptions = ["=", "!=", ">", "<", "LIKE", "IS NOT NULL", "IS NULL"]; // Operator options
const sortDirections = ["ASC", "DESC"]; // Sorting directions
const namespaceOptions = ["ngpsync"]; // Namespace options
const joinTypes = ["INNER JOIN", "LEFT JOIN", "RIGHT JOIN", "FULL JOIN"]; // Join types

export const QueryBuilder = ({
  disabled,
  form,
  setForm,
  testQuery,
}: {
  disabled: boolean;
  form: Record<string, any>;
  setForm: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  testQuery: (query: string) => void;
}) => {
  const [namespace, setNamespace] = useState<string>(
    form?.namespace || "ngpsync"
  );
  const [schemaData, setSchemaData] = useState<Schema | null>(
    form?.schemaData || null
  );
  const [selectedTable, setSelectedTable] = useState<Table | null>(
    form?.selectedTable || null
  );

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumn[]>(
    form?.selectedColumns || []
  );
  const [tableAliases, setTableAliases] = useState<{ [key: string]: string }>(
    {}
  );

  const [joins, setJoins] = useState<Join[]>(form?.joins || []);
  const [filters, setFilters] = useState<Filter[]>(form?.filters || []);
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    form?.sortOrder || { column: null, direction: "" }
  );
  const [limit, setLimit] = useState<number | "">(form?.limit || "");
  const [query, setQuery] = useState<string>(form?.query || "");
  const [manualQueryUpdate, setManualQueryUpdate] = useState<string>(
    form?.query || ""
  );
  const [showCopied, setShowCopied] = useState(false);

  // Fetch schema data based on the selected namespace
  const { data: schema, error, isLoading } = useGetSchemaQuery({});

  useEffect(() => {
    if (schema) {
      setSchemaData(schema);
      // Reset selections when namespace changes
      // setSelectedTable(null);
      // setSelectedColumns([]);
      // setJoins([]);
      // setFilters([]);
      // setSortOrder({ column: null, direction: "" });
      // setLimit("");
      // setQuery("");
    }
  }, [schema]);

  // useEffect(() => {
  //   if (form) {
  //     // Reset selections when namespace changes
  //     setSelectedTable(form?.selectedTable);
  //     setSelectedColumns([]);
  //     setJoins([]);
  //     setFilters([]);
  //     setSortOrder({ column: null, direction: "" });
  //     setLimit("");
  //     setQuery("");
  //   }
  // }, [form]);

  const handleAddJoin = () => {
    setJoins([
      ...joins,
      {
        table: null,
        leftTable: null,
        leftColumn: null,
        rightColumn: null,
        joinType: "INNER JOIN",
      } as Join,
    ]);
    setForm((prevForm) => ({
      ...prevForm,
      joins: [
        ...joins,
        {
          table: null,
          leftTable: null,
          leftColumn: null,
          rightColumn: null,
          joinType: "INNER JOIN",
        } as Join,
      ],
    }));
  };

  const handleRemoveJoin = (index: number) => {
    const newJoins = [...joins];
    newJoins.splice(index, 1);
    setJoins(newJoins);
    setForm((prevForm) => ({ ...prevForm, joins: newJoins }));
  };

  const handleAddFilter = () => {
    setFilters([
      ...filters,
      { table: null, column: null, operator: "=", value: "" },
    ]);
    setForm((prevForm) => ({
      ...prevForm,
      filters: [
        ...(prevForm.filters || []),
        { table: null, column: null, operator: "=", value: "" },
      ],
    }));
  };

  const handleRemoveFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
    setForm((prevForm) => ({ ...prevForm, filters: newFilters }));
  };

  const getAvailableTables = (): Table[] => {
    const tables = [selectedTable, ...joins?.map((j) => j.table)]?.filter(
      Boolean
    );
    return tables as Table[];
  };

  const buildQuery = useMemo(() => {
    let queryString = "SELECT ";
    if (!selectedTable) {
      setQuery(queryString);
      return;
    }

    // Table aliases
    // const tableAliases: { [key: string]: string } = {};
    const allTables = [selectedTable, ...joins.map((j) => j.table)].filter(
      Boolean
    ) as Table[];

    let cpyTableAliases = { ...tableAliases };
    let updateAliases = false;
    allTables.forEach((table, index) => {
      if (!cpyTableAliases[table.table_name]) {
        updateAliases = true;
        cpyTableAliases[table.table_name] = `t${index + 1}`;
      }
    });
    if (updateAliases) {
      setTableAliases(cpyTableAliases);
    }

    // Columns
    queryString +=
      selectedColumns.length === 0
        ? `*`
        : selectedColumns
            .map((selCol) => {
              const tableAlias = tableAliases[selCol.table.table_name];
              const columnName = `"${selCol.column.column_name}"`;
              const aliasPart = selCol.alias ? ` AS "${selCol.alias}"` : "";
              return `${tableAlias}.${columnName}${aliasPart}`;
            })
            .join(", ");

    // From
    const mainTableAlias = tableAliases[selectedTable.table_name];
    queryString += ` FROM "${namespace}"."${selectedTable.table_name}" ${mainTableAlias}`;

    // Joins
    joins.forEach((join) => {
      if (
        join.table &&
        join.leftTable &&
        join.leftColumn &&
        join.rightColumn &&
        join.joinType
      ) {
        const leftTableAlias = tableAliases[join.leftTable.table_name];
        const rightTableAlias = tableAliases[join.table.table_name];
        const leftColumnName = `"${join.leftColumn.column_name}"`;
        const rightColumnName = `"${join.rightColumn.column_name}"`;
        queryString += ` ${join.joinType} "${namespace}"."${join.table.table_name}" ${rightTableAlias} ON ${leftTableAlias}.${leftColumnName} = ${rightTableAlias}.${rightColumnName}`;
      }
    });

    // Filters
    if (filters.length > 0) {
      const filterStrings = filters
        .filter(
          (f) => f.table && f.column && (f.value || f.operator.includes("IS"))
        )
        .map((f) => {
          const tableAlias = tableAliases[f.table!.table_name];
          return `${tableAlias}."${f.column!.column_name}" ${f.operator} ${
            f.value ? `'${f.value}'` : ""
          }`;
        });
      if (filterStrings.length > 0) {
        queryString += " WHERE " + filterStrings.join(" AND ");
      }
    }

    // Sort Order
    if (sortOrder.column && sortOrder.direction) {
      const tableAlias = tableAliases[sortOrder.column.table.table_name];
      queryString += ` ORDER BY ${tableAlias}."${sortOrder.column.column.column_name}" ${sortOrder.direction}`;
    }

    // Limit
    if (limit) {
      queryString += ` LIMIT ${limit}`;
    }

    setQuery(queryString);
    setForm((prevForm) => ({ ...prevForm, query: queryString }));
    setShowCopied(false);
  }, [
    selectedTable,
    sortOrder,
    selectedColumns,
    limit,
    filters,
    joins,
    tableAliases,
  ]);

  const parseQuery = useMemo(() => {
    if (!manualQueryUpdate) return;
    const query = manualQueryUpdate;

    let queryParts = query.trim().split(/\s+/); // Split by whitespace

    // Initialize variables for storing parsed values
    let parsedSelectedTable: Table | null = null;
    let parsedTables: SelectedTable[] = [];
    let parsedSelectedColumns: SelectedColumn[] = [];
    let parsedJoins: Join[] = [];
    let parsedFilters: Filter[] = [];
    let parsedSortOrder: SortOrder = {};
    let parsedLimit = "";

    // Utility regex patterns for matching parts of the query
    const tableRegex = /ngpsync\."+(.*?)"+\s+(\w+)/gis;
    const selectRegex = /^SELECT\s+(.*)\s+FROM/is;
    const fromRegex = /FROM\s+"?(.*?)"?\."(.*?)"\s+(\w+)/is;
    const joinRegex =
      /(\w+)\s+JOIN\s+"+(.*?)"+."+(.*?)"+\s+(\w+)\s+ON\s+(\w+)\."+(.*?)"+\s*=\s*(\w+)\."+(.*?)"+/is;
    const whereRegex = /WHERE\s+(.*)/is;
    const orderByRegex = /ORDER\s+BY\s+(\w+)\."(.*?)"\s+(ASC|DESC)/is;
    const limitRegex = /LIMIT\s+(\d+)/is;

    // Extract all tables and aliases columns
    let tableMatches;
    let parsedTableAliases: { [key: string]: string } = {};
    while ((tableMatches = tableRegex.exec(query)) !== null) {
      const [tableName, alias] = tableMatches.slice(1);

      parsedTableAliases[tableName] = alias;
      parsedTables.push({
        table: {
          table_name: tableName,
          columns: [],
        },
        alias: alias,
      } as SelectedTable);
    }

    // Extract SELECT columns
    const selectMatch = query.match(selectRegex);
    if (selectMatch && selectMatch[1]) {
      const columnsString = selectMatch[1].trim();
      parsedSelectedColumns = columnsString.split(",").map((col) => {
        let columnDetails = col.trim().split(/\s+AS\s+/i);
        let [tableAlias, columnName] = columnDetails[0].split(".");
        let alias = columnDetails[1] ? columnDetails[1].replace(/"/g, "") : "";
        return {
          table: { table_name: tableAlias?.replace(/"/g, "") },
          column: { column_name: columnName?.replace(/"/g, "") },
          alias: alias,
        } as SelectedColumn;
      });
    }

    // Extract FROM and main table
    const fromMatch = query.match(fromRegex);
    if (fromMatch) {
      const [namespace, tableName, alias] = fromMatch.slice(1);
      parsedSelectedTable = {
        table_name: tableName,
        namespace: namespace,
        alias: alias,
      } as Table;
    }

    // Extract JOINS
    let joinMatches;
    while ((joinMatches = joinRegex.exec(query)) !== null) {
      parsedJoins.push({
        joinType: joinMatches[1],
        table: { table_name: joinMatches[3], namespace: joinMatches[2] },
        leftTable: { table_name: joinMatches[5] },
        leftColumn: { column_name: joinMatches[6] },
        rightTable: { table_name: joinMatches[7] },
        rightColumn: { column_name: joinMatches[8] },
      } as Join);
    }

    // Extract WHERE clause and filters
    const whereMatch = query.match(whereRegex);
    if (whereMatch) {
      const filterConditions = whereMatch[1].split(" AND ");
      parsedFilters = filterConditions.map((condition) => {
        const filterParts = condition.split(/\s+(=|>|<|>=|<=|!=|IS)\s+/);
        const [tableAlias, columnName] = filterParts[0].split(".");
        return {
          table: { table_name: tableAlias.replace(/"/g, "") },
          column: { column_name: columnName.replace(/"/g, "") },
          operator: filterParts[1],
          value: filterParts[2] ? filterParts[2].replace(/'/g, "") : null,
        } as Filter;
      });
    }

    // Extract ORDER BY
    const orderByMatch = query.match(orderByRegex);
    if (orderByMatch) {
      parsedSortOrder = {
        column: {
          table: { table_name: orderByMatch[1] },
          column: { column_name: orderByMatch[2] },
        },
        direction: orderByMatch[3],
      } as SortOrder;
    }

    // Extract LIMIT
    const limitMatch = query.match(limitRegex);
    if (limitMatch) {
      parsedLimit = `${parseInt(limitMatch[1], 10)}`;
    }

    // Set the state based on the parsed values
    setTableAliases(parsedTableAliases);
    setSelectedTable(parsedSelectedTable);
    setSelectedColumns(parsedSelectedColumns);
    setJoins(parsedJoins);
    setFilters(parsedFilters);
    setSortOrder(parsedSortOrder);
    setLimit(Number(parsedLimit));
  }, [manualQueryUpdate]); // Only re-run when query changes

  const handleCopy = () => {
    navigator.clipboard
      .writeText(query)
      .then(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 1500);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <Grid container spacing={2}>
      {/* Namespace Selection */}
      <Grid size={{ xs: 12 }}>
        <Autocomplete
          disabled={disabled}
          options={namespaceOptions}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField {...params} label="Namespace" variant="outlined" />
          )}
          value={namespace}
          onChange={(event, newValue) => {
            setNamespace(newValue || "");
            setForm((prevForm) => ({ ...prevForm, namespace: newValue }));
          }}
        />
      </Grid>

      {/* Select Table */}
      <Grid size={{ xs: selectedTable ? 8 : 12 }}>
        <Autocomplete
          options={schemaData ? schemaData.tables : []}
          getOptionLabel={(option) => option.table_name}
          renderInput={(params) => (
            <TextField {...params} label="Select Table" variant="outlined" />
          )}
          value={selectedTable}
          onChange={(event, newValue) => {
            setSelectedTable(newValue);
            setSelectedColumns([]);
            setForm((prevForm) => ({
              ...prevForm,
              selectedTable: newValue,
              selectedColumns: [],
            }));
          }}
          filterOptions={(options: any[], params) => {
            let filtered: any[] = [];
            const option = options.find(
              (option) => option.table_name === params.inputValue
            );
            if (option) {
              filtered.push(option);
            } else {
              filtered = createFilterOptions<any>()(options, params);
            }
            return filtered;
          }}
          disabled={disabled}
        />
      </Grid>
      {selectedTable && (
        <Grid size={{ xs: 4 }}>
          <TextField
            onChange={(e) => {
              const newValue = e.target.value as string;
              setTableAliases((prevAliases) => ({
                ...prevAliases,
                [selectedTable.table_name]: newValue,
              }));
              setSelectedTable(
                (prev) =>
                  ({
                    ...(prev || {}),
                    alias: newValue,
                  } as Table)
              );
              setForm((prevForm) => ({
                ...prevForm,
                selectedTable: newValue,
                selectedColumns: [],
              }));
            }}
            label="Alias"
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
      )}

      {/* Select Columns */}
      {selectedTable && (
        <Grid size={{ xs: 12 }}>
          <Autocomplete
            disabled={disabled}
            multiple
            options={getAvailableTables()
              ?.map((table) =>
                table.columns?.map((column) => ({ table, column }))
              )
              ?.flat()}
            getOptionLabel={(option) =>
              `${option.table.table_name}.${option.column.column_name}`
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      margin: "2px",
                      border: "1px solid #ccc",
                      borderRadius: "16px",
                      padding: "4px 8px",
                      backgroundColor: "#e0e0e0",
                    }}
                  >
                    <Typography variant="body2" sx={{ mr: 4 }}>
                      {`${option.table.table_name}.${option.column.column_name}`}
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Alias"
                      variant="standard"
                      value={
                        selectedColumns.find(
                          (selCol) =>
                            selCol.table.table_name ===
                              option.table.table_name &&
                            selCol.column.column_name ===
                              option.column.column_name
                        )?.alias || ""
                      }
                      onChange={(e) => {
                        const newSelectedColumns = selectedColumns.map(
                          (selCol) => {
                            if (
                              selCol.table.table_name ===
                                option.table.table_name &&
                              selCol.column.column_name ===
                                option.column.column_name
                            ) {
                              return {
                                ...selCol,
                                alias: e.target.value,
                              };
                            }
                            return selCol;
                          }
                        );
                        setSelectedColumns(newSelectedColumns);
                        setForm((prevForm) => ({
                          ...prevForm,
                          selectedColumns: newSelectedColumns,
                        }));
                      }}
                      style={{ width: 80 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onFocus={(event) => {
                        event.stopPropagation();
                      }}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                    {!disabled && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          const newSelectedColumns = selectedColumns.filter(
                            (selCol) =>
                              !(
                                selCol.table.table_name ===
                                  option.table.table_name &&
                                selCol.column.column_name ===
                                  option.column.column_name
                              )
                          );
                          setSelectedColumns(newSelectedColumns);
                          setForm((prevForm) => ({
                            ...prevForm,
                            selectedColumns: newSelectedColumns,
                          }));
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Columns"
                variant="outlined"
                placeholder="Select Columns"
                helperText={
                  <Typography variant="subtitle2" color="secondary">
                    Must select VanID and alias the relevant columns for
                    firstname,lastname, and phone.
                  </Typography>
                }
              />
            )}
            value={selectedColumns.map((selCol) => ({
              table: selCol.table,
              column: selCol.column,
            }))}
            onChange={(event, newValue) => {
              const newSelectedColumns: SelectedColumn[] = newValue.map(
                (option) => {
                  const existing = selectedColumns.find(
                    (selCol) =>
                      selCol.table.table_name === option.table.table_name &&
                      selCol.column.column_name === option.column.column_name
                  );
                  return existing || { ...option, alias: "" };
                }
              );
              setSelectedColumns(newSelectedColumns);
              setForm((prevForm) => ({
                ...prevForm,
                selectedColumns: newSelectedColumns,
              }));
            }}
          />
        </Grid>
      )}

      {/* Joins */}
      {joins.map((join, index) => (
        <React.Fragment key={index}>
          {/* Join Type */}
          <Grid size={{ xs: 2 }}>
            <Autocomplete
              disabled={disabled}
              options={joinTypes as JoinType[]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Join Type" variant="outlined" />
              )}
              value={join.joinType}
              onChange={(event, newValue) => {
                const newJoins = [...joins];
                newJoins[index].joinType = newValue || "INNER JOIN";
                setJoins(newJoins);
                setForm((prevForm) => ({ ...prevForm, joins: newJoins }));
              }}
            />
          </Grid>

          {/* Select Join Table */}
          <Grid size={{ xs: 3 }}>
            <Autocomplete
              disabled={disabled}
              options={schemaData ? schemaData.tables : []}
              getOptionLabel={(option) => option.table_name}
              renderInput={(params) => (
                <TextField {...params} label="Join Table" variant="outlined" />
              )}
              value={join.table}
              onChange={(event, newValue) => {
                const newJoins = [...joins];
                newJoins[index].table = newValue;
                newJoins[index].rightColumn = null;
                setJoins(newJoins);
                setForm((prevForm) => ({ ...prevForm, joins: newJoins }));
              }}
            />
          </Grid>

          {/* Left Table */}
          <Grid size={{ xs: 2 }}>
            <Autocomplete
              disabled={disabled}
              options={getAvailableTables()}
              getOptionLabel={(option) => option.table_name}
              renderInput={(params) => (
                <TextField {...params} label="Left Table" variant="outlined" />
              )}
              value={join.leftTable}
              onChange={(event, newValue) => {
                const newJoins = [...joins];
                newJoins[index].leftTable = newValue;
                newJoins[index].leftColumn = null;
                setJoins(newJoins);
                setForm((prevForm) => ({ ...prevForm, joins: newJoins }));
              }}
            />
          </Grid>

          {/* Left Column */}
          <Grid size={{ xs: 2 }}>
            <Autocomplete
              disabled={disabled}
              options={join.leftTable ? join.leftTable.columns : []}
              getOptionLabel={(option) => option.column_name}
              renderInput={(params) => (
                <TextField {...params} label="Left Column" variant="outlined" />
              )}
              value={join.leftColumn}
              onChange={(event, newValue) => {
                const newJoins = [...joins];
                newJoins[index].leftColumn = newValue;
                setJoins(newJoins);
                setForm((prevForm) => ({ ...prevForm, joins: newJoins }));
              }}
            />
          </Grid>

          {/* Right Column */}
          <Grid size={{ xs: 2 }}>
            <Autocomplete
              disabled={disabled}
              options={join.table ? join.table.columns : []}
              getOptionLabel={(option) => option.column_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Right Column"
                  variant="outlined"
                />
              )}
              value={join.rightColumn}
              onChange={(event, newValue) => {
                const newJoins = [...joins];
                newJoins[index].rightColumn = newValue;
                setJoins(newJoins);
                setForm((prevForm) => ({ ...prevForm, joins: newJoins }));
              }}
            />
          </Grid>

          {/* Remove Join Button */}
          <Grid size={{ xs: 1 }}>
            <Button
              disabled={disabled}
              variant="outlined"
              color="secondary"
              onClick={() => handleRemoveJoin(index)}
            >
              Remove
            </Button>
          </Grid>
        </React.Fragment>
      ))}

      {selectedTable && (
        <Grid size={{ xs: 12 }}>
          <Button
            disabled={disabled}
            variant="outlined"
            onClick={handleAddJoin}
          >
            Add Join
          </Button>
        </Grid>
      )}

      {/* Filters */}
      {filters.map((filter, index) => (
        <React.Fragment key={index}>
          {/* Select Table */}
          <Grid size={{ xs: 3 }}>
            <Autocomplete
              disabled={disabled}
              options={getAvailableTables()}
              getOptionLabel={(option) => option.table_name}
              renderInput={(params) => (
                <TextField {...params} label="Table" variant="outlined" />
              )}
              value={filter.table}
              onChange={(event, newValue) => {
                const newFilters = [...filters];
                newFilters[index].table = newValue;
                newFilters[index].column = null;
                setFilters(newFilters);
                setForm((prevForm) => ({ ...prevForm, filters: newFilters }));
              }}
            />
          </Grid>

          {/* Select Column */}
          <Grid size={{ xs: 3 }}>
            <Autocomplete
              disabled={disabled}
              options={filter.table ? filter.table.columns : []}
              getOptionLabel={(option) => option.column_name}
              renderInput={(params) => (
                <TextField {...params} label="Column" variant="outlined" />
              )}
              value={filter.column}
              onChange={(event, newValue) => {
                const newFilters = [...filters];
                newFilters[index].column = newValue;
                setFilters(newFilters);
                setForm((prevForm) => ({ ...prevForm, filters: newFilters }));
              }}
            />
          </Grid>

          {/* Operator */}
          <Grid size={{ xs: !filter?.operator?.includes("IS") ? 2 : 5 }}>
            <Autocomplete
              disabled={disabled}
              options={operatorOptions}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Operator" variant="outlined" />
              )}
              value={filter.operator}
              onChange={(event, newValue) => {
                const newFilters = [...filters];
                newFilters[index].operator = newValue || "";
                if (newValue?.includes("IS")) {
                  newFilters[index].value = "";
                }
                setFilters(newFilters);
                setForm((prevForm) => ({ ...prevForm, filters: newFilters }));
              }}
            />
          </Grid>

          {/* Value */}
          {!filter?.operator?.includes("IS") && (
            <Grid size={{ xs: 3 }}>
              <TextField
                disabled={disabled}
                label="Value"
                variant="outlined"
                value={filter.value}
                onChange={(event) => {
                  const newFilters = [...filters];
                  newFilters[index].value = event.target.value;
                  setFilters(newFilters);
                  setForm((prevForm) => ({ ...prevForm, filters: newFilters }));
                }}
                fullWidth
              />
            </Grid>
          )}

          {/* Remove Filter Button */}
          <Grid size={{ xs: 1 }}>
            <Button
              disabled={disabled}
              variant="outlined"
              color="secondary"
              onClick={() => handleRemoveFilter(index)}
            >
              Remove
            </Button>
          </Grid>
        </React.Fragment>
      ))}

      {selectedTable && (
        <Grid size={{ xs: 12 }}>
          <Button
            disabled={disabled}
            variant="outlined"
            onClick={handleAddFilter}
          >
            Add Filter
          </Button>
        </Grid>
      )}

      {/* Sort Order */}
      {selectedTable && (
        <Grid container size={{ xs: 12 }} spacing={2}>
          <Grid size={{ xs: 6 }}>
            <Autocomplete
              disabled={disabled}
              options={selectedColumns}
              getOptionLabel={(option) =>
                `${option.table.table_name}.${option.column.column_name}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Sort By" variant="outlined" />
              )}
              value={sortOrder.column}
              onChange={(event, newValue) => {
                setSortOrder((prev) => ({ ...prev, column: newValue || null }));
                setForm((prevForm) => ({
                  ...prevForm,
                  column: newValue || null,
                }));
              }}
            />
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Autocomplete
              disabled={disabled}
              options={sortDirections}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Direction" variant="outlined" />
              )}
              value={sortOrder.direction}
              onChange={async (event, newValue) => {
                await setSortOrder(
                  (prev) =>
                    ({
                      ...prev,
                      direction: newValue || "",
                    } as SortOrder)
                );
                await setForm((prevForm) => ({
                  ...prevForm,
                  direction: newValue || "",
                }));
              }}
            />
          </Grid>
        </Grid>
      )}

      {/* Limit */}
      {selectedTable && (
        <Grid size={{ xs: 12 }}>
          <TextField
            disabled={disabled}
            label="Limit"
            variant="outlined"
            type="number"
            value={limit}
            onChange={(event) => {
              setLimit((Number(event.target.value) as number) || "");
              setForm((prevForm) => ({
                ...prevForm,
                limit: (Number(event.target.value) as number) || "",
              }));
            }}
            fullWidth
          />
        </Grid>
      )}

      {/* Build Query Button
      {selectedTable && (
        <Grid size={{ xs: 12 }}>
          <Button
            variant="contained"
            disabled={disabled}
            color="primary"
            onClick={buildQuery}
          >
            Build Query
          </Button>
        </Grid>
      )} */}

      {/* Display Query */}
      {query && (
        <Grid size={{ xs: 12 }}>
          <Typography variant="overline" sx={{ fontWeight: "bold" }}>
            Generated SQL Query:{" "}
            <IconButton onClick={() => testQuery(query)}>
              <CheckCircle color="secondary" />
            </IconButton>
          </Typography>
          <TextField
            disabled={disabled || true}
            fullWidth
            size="small"
            value={query}
            onChange={(event) => {
              const newQuery = event.target.value as string;
              setQuery(newQuery);
              setManualQueryUpdate(newQuery);
            }}
            multiline
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton size="small" onClick={handleCopy} edge="end">
                    {showCopied ? (
                      <CheckIcon fontSize="small" />
                    ) : (
                      <ContentCopyIcon fontSize="small" />
                    )}
                  </IconButton>
                ),
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
