import { useState } from "react";
import { Box, Fab, Paper } from "@mui/material";
import { Add, Save } from "@mui/icons-material";
import { useGetScriptsQuery } from "store/api/script.api";
import { Script } from "store/api/models";
import { ScriptList } from "./ScriptList";
import { BackdropLoading } from "../BackdropLoading";
import { ScriptDetailsRedux } from "./ScriptDetailsRedux";

export const ScriptsIndex = () => {
  const [selectedScriptId, setSelectedScriptId] = useState<number>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: scripts = [] as Script[],
    isLoading: scriptsLoading,
    refetch,
  } = useGetScriptsQuery({});
  const handleScriptClick = (script: number) => {
    setSelectedScriptId(script);
  };
  const handleScriptEditClick = (script: number) => {
    setEditing(true);
    setSelectedScriptId(script);
  };
  const handleDuplicateScript = (script: number) => {
    setEditing(true);
    setSelectedScriptId(script);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {scriptsLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedScriptId !== undefined ? 0.25 : 1,
            width: selectedScriptId !== undefined ? "25%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedScriptId !== undefined ? 1 : 0,
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <Paper sx={{ p: 1, height: "100%", maxHeight: "100%" }} elevation={5}>
            <ScriptList
              scripts={scripts}
              selectedScriptId={selectedScriptId}
              handleDuplicateScript={handleDuplicateScript}
              close={() => setSelectedScriptId(undefined)}
              handleScriptClick={handleScriptClick}
              handleScriptEditClick={handleScriptEditClick}
            />
          </Paper>
        </Box>
      )}

      {selectedScriptId === undefined && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            left: 10,
            bottom: 10,
          }}
          onClick={() => {
            setEditing(true);
            setSelectedScriptId(0);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* Script Details */}
      {selectedScriptId !== undefined && (
        <Box
          key={selectedScriptId}
          sx={{
            flexGrow: 0.75,
            maxWidth: "75%",
            width: "75%",
            bgcolor: "transparent",
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            height: "100%",
            maxHeight: "100%",
          }}
        >
          <ScriptDetailsRedux
            disabled={!editing}
            id={selectedScriptId}
            close={() => {
              setSelectedScriptId(undefined);
              setEditing(false);
            }}
            callback={async () => {
              await refetch();
              setSelectedScriptId(undefined);
              setEditing(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
