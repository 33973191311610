import {
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  ListItemText,
  Typography,
  Chip,
  Checkbox,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  ListItem,
  List,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectResultEntities, selectUser } from "store";
import { useGetConversationsQuery } from "store/api/conversation.api";
import { ProspectNote } from "store/api/models";
import { sortByDate } from "utils";
import {
  useGetProspectNotesQuery,
  usePatchProspectNoteMutation,
  useUpdateProspectNoteMutation,
} from "store/api/prospect_note.api";
import { Flag, OutlinedFlag } from "@mui/icons-material";
import { forwardRef, useImperativeHandle, useState } from "react";
import moment from "moment";

export const AgentConversationNotes = forwardRef(
  ({ prospect }: { prospect?: number }, ref) => {
    const user = useSelector(selectUser);
    const [flagged, setFlagged] = useState<boolean>(false);
    const [value, setValue] = useState<string>();

    useImperativeHandle(
      ref,
      () => ({
        getNote: () => {
          return value;
        },
        getFlagged: () => {
          return flagged;
        },
        updateValue: (updatedValue: string) => {
          setValue(updatedValue);
        },
        updateFlagged: (updatedFlagged: boolean) => {
          setFlagged(updatedFlagged);
        },
      }),
      [setValue, setFlagged, value, flagged]
    );

    const { data: notes } = useGetProspectNotesQuery(
      {
        params: {
          nested: "all",
          prospect: prospect,
        },
      },
      {
        skip: !prospect,
        refetchOnMountOrArgChange: true,
      }
    );
    return (
      <Table
        component={Paper}
        size="small"
        elevation={0}
        ref={ref as any}
        sx={{ height: "100%", p: 0 }}
      >
        <TextField
          multiline
          minRows={2}
          fullWidth
          placeholder="Add any notes and optionally flag for the admin to follow up."
          value={value}
          onChange={(event) => setValue((event?.target as any)?.value)}
          sx={{ p: 2 }}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => setFlagged((prev: boolean) => !prev)}
                  edge="end"
                >
                  <Tooltip title={"Flag the note for admin follow-up"}>
                    {flagged ? (
                      <Flag fontSize="small" color="error" />
                    ) : (
                      <OutlinedFlag fontSize="small" />
                    )}
                  </Tooltip>
                </IconButton>
              ),
            },
          }}
        />
        <List>
          {[...(notes || [])]
            ?.sort((a, b) => sortByDate(a.created_at, b.created_at))
            ?.map((note) => {
              return <NoteRow key={note?.id} note={note} />;
            })}
        </List>
      </Table>
    );
  }
);

export const NoteRow = ({ note }: { note: ProspectNote }) => {
  const user = useSelector(selectUser);
  const [updateNote] = useUpdateProspectNoteMutation();
  return (
    <ListItem sx={{ "& > *": { borderBottom: "unset" } }}>
      <TextField
        value={note.form?.note}
        fullWidth
        multiline
        disabled
        sx={{
          // Styles for the label when disabled
          "& .Mui-disabled": {
            color: "black", // Label color when disabled
            opacity: 0.8,
            WebkitTextFillColor: "unset",
          },
        }}
        slotProps={{
          input: {
            sx: {},
            endAdornment: (
              <Box sx={{ display: "flex" }}>
                {/* <IconButton
                  size="small"
                  onClick={() => {
                    if (user.admin) {
                      updateNote({ ...note, flagged: !note.flagged });
                    }
                  }}
                  edge="end"
                  disabled={!user.admin}
                >
                  <Tooltip title={"Flag the note for admin follow-up"}>
                    {note.flagged ? (
                      <Flag fontSize="small" color="error" />
                    ) : (
                      <OutlinedFlag fontSize="small" />
                    )}
                  </Tooltip>
                </IconButton> */}
                <IconButton
                  size="small"
                  onClick={() => {
                    if (user.admin) {
                      updateNote({ ...note, flagged: !note.flagged });
                    }
                  }}
                  edge="end"
                  disabled={!user.admin}
                >
                  <Tooltip title={"Flag the note for admin follow-up"}>
                    {note.flagged ? (
                      <Flag fontSize="small" color="error" />
                    ) : (
                      <OutlinedFlag fontSize="small" />
                    )}
                  </Tooltip>
                </IconButton>
              </Box>
            ),
          },
        }}
        helperText={moment(note.created_at).format("ddd, MMM D, h:mmA")}
      />
    </ListItem>
  );
};
