import { createSelector } from "@reduxjs/toolkit";
import { ProspectNote } from "./models";
import { baseApi } from "./common.api";

export const prospect_note = baseApi
  .enhanceEndpoints({
    addTagTypes: ["FlaggedProspectNotes", "ProspectNotes", "ProspectNote"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProspectNotes: builder.query<ProspectNote[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "prospect_note",
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) =>
          result
            ? !!(arg.params as any)?.flagged
              ? [
                  ...result.map(({ id }) => ({
                    type: "ProspectNote" as const,
                    id,
                  })),
                  "ProspectNotes",
                  "FlaggedProspectNotes",
                ]
              : [
                  ...result.map(({ id }) => ({
                    type: "ProspectNote" as const,
                    id,
                  })),
                  "ProspectNotes",
                ]
            : ["ProspectNotes"],
      }),
      getProspectNote: builder.query<ProspectNote, string>({
        query: (id) => ({
          url: `prospect_note/${id}`,
          method: "GET",
        }),
        providesTags: (result, error, arg) => [
          { type: "ProspectNote", id: arg },
        ],
        // configuration for an individual endpoint, overriding the api setting
        keepUnusedDataFor: 90,
      }),
      createProspectNote: builder.mutation({
        query: (request) => ({
          url: "prospect_note",
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["ProspectNotes", "FlaggedProspectNotes"],
      }),
      patchProspectNote: builder.mutation<ProspectNote, ProspectNote>({
        query: (request) => ({
          url: `prospect_note/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "ProspectNote", id: arg.id },
          "ProspectNotes",
          "FlaggedProspectNotes",
        ],
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            prospect_note.util.updateQueryData(
              "getProspectNote",
              `${id}`,
              (draft) => {
                Object.assign(draft, patch);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(prospect_note.util.invalidateTags(['ProspectNotes']))
             */
            dispatch(prospect_note.util.invalidateTags(["ProspectNotes"]));
          }
        },
      }),
      updateProspectNote: builder.mutation<ProspectNote, ProspectNote>({
        query: (request) => ({
          url: `prospect_note/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "ProspectNote", id: arg.id },
          "ProspectNotes",
          "FlaggedProspectNotes",
        ],
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            prospect_note.util.updateQueryData(
              "getProspectNote",
              `${id}`,
              (draft) => {
                Object.assign(draft, patch);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(prospect_note.util.invalidateTags(['ProspectNotes']))
             */
            dispatch(prospect_note.util.invalidateTags(["ProspectNotes"]));
          }
        },
      }),
      deleteProspectNote: builder.mutation<ProspectNote, number>({
        query: (id) => ({
          url: `prospect_note/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) =>
          result
            ? [{ type: "ProspectNote" as const, arg }, "ProspectNotes"]
            : ["ProspectNotes"],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetProspectNotesQuery,
  useGetProspectNoteQuery,
  useCreateProspectNoteMutation,
  useUpdateProspectNoteMutation,
  usePatchProspectNoteMutation,
  useDeleteProspectNoteMutation,
  usePrefetch: useProspectNotesPrefetch,
} = prospect_note;
const selectProspectNotesRoot = prospect_note.endpoints.getProspectNotes.select(
  {}
);
export const selectProspectNotes = createSelector(
  selectProspectNotesRoot,
  (prospect_note) => prospect_note.data || []
);
