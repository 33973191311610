import moment from 'moment';

export const formattedDate = (date: Date) => {
  return moment(date).format(
    moment(date).isAfter(moment(date).startOf('day').add(4, 'hours')) &&
      !moment(date).isAfter(moment(date).endOf('day').subtract(4, 'hours'))
      ? 'MMM D YYYY h:mm a'
      : 'MMM D YYYY'
  );
};

export const hasValidUSTime = (date?: Date | string) => {
  return (
    !!date &&
    moment(date).isValid() &&
    moment(date).isAfter(moment(date).startOf('day').add(4, 'hours')) &&
    !moment(date).isAfter(moment(date).endOf('day').subtract(4, 'hours'))
  );
};
