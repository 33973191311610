import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  RestartAlt,
} from "@mui/icons-material";
import {
  Typography,
  MobileStepper,
  Button,
  Tooltip,
  IconButton,
  Grid2 as Grid,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleBack,
  handleNext,
  handleRestart,
  selectActiveRenderedQuestion,
  selectQuestionIds,
  selectRenderedQuestionIds,
} from "store/slice/formBuilder.slice";

interface BranchingFormBannerReduxProps {
  submit?: Function;
  save?: Function;
  preview?: boolean;
}

export const BranchingFormBannerRedux = ({
  preview = true,
  submit = (values: any) => {
    console.log(values);
  },
  save = (values: any) => {
    console.log(values);
  },
}: BranchingFormBannerReduxProps) => {
  const dispatch = useDispatch();
  const disabledRedux = useSelector((state: any) => state.formBuilder.disabled);
  const disabled = preview ? false : disabledRedux;
  const renderedQuestionIds: string[] = useSelector(selectRenderedQuestionIds);
  const questionIds = useSelector(selectQuestionIds);
  const activeRenderedQuestion = useSelector(selectActiveRenderedQuestion);

  return (
    <Grid
      container
      justifyContent={"space-evenly"}
      alignItems={"center"}
      spacing={0}
      sx={{
        pt: 1,
        px: 2,
        mb: 1,
      }}
    >
      <Grid>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          SCRIPT {preview && "FORM PREVIEW"}
        </Typography>
      </Grid>
      <Grid>
        <MobileStepper
          variant="progress"
          steps={questionIds?.length || 0}
          position="static"
          activeStep={activeRenderedQuestion?.data?.index}
          sx={{ maxWidth: 400, minWidth: 400, bgcolor: "transparent" }}
          nextButton={
            <Button
              size="small"
              onClick={() => dispatch(handleNext())}
              disabled={
                disabled ||
                activeRenderedQuestion?.data?.index === questionIds?.length
              }
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => dispatch(handleBack())}
              disabled={disabled || renderedQuestionIds.length <= 1}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Grid>
      <Grid>
        <Tooltip title="Restart form">
          <IconButton
            disabled={disabled}
            onClick={() => dispatch(handleRestart())}
            sx={{ ml: 0, bgcolor: (theme) => theme.palette.info.main }}
          >
            <RestartAlt sx={{ color: "white" }} fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
