import { Paper, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "store/slice/formBuilder.slice";

export const ScriptTitleRedux = () => {
  const dispatch = useDispatch();

  // Get state from Redux store
  const title = useSelector((state: any) => state.formBuilder.title);
  const titleError = useSelector((state: any) => state.formBuilder.titleError);
  const reduxDisabled = useSelector((state: any) => state.formBuilder.disabled);
  return (
    <Paper sx={{ padding: 1, marginBottom: 1, flex: 1 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        SCRIPT DETAILS
      </Typography>
      <TextField
        disabled={reduxDisabled}
        size="small"
        label="Script title"
        fullWidth
        variant="outlined"
        value={title}
        onChange={(e) => dispatch(setTitle(e.target.value))}
        error={!!titleError}
        helperText={
          titleError ? (
            <Typography variant="caption" color="error">
              {titleError}
            </Typography>
          ) : undefined
        }
      />
    </Paper>
  );
};
