import { forwardRef, useState, useMemo, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableBody,
  Toolbar,
  Tooltip,
  Typography,
  Button,
  Grid2 as Grid,
} from "@mui/material";
import {
  FieldArray,
  FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from "formik";
import { Add, Delete, Save, Edit } from "@mui/icons-material";
import { JItem } from "../jformik/jformik.types";
import { JItemMapper } from "../jformik/JItemMapper";
import { JFormikContext, useJFormikContext } from "../jformik/jformik.context";
import { AlertDialog } from "../../dialogs/AlertDialog";

const FTableCell = ({
  jItem,
  isEdit = true,
  openTooltip,
  setOpenTooltip,
}: {
  jItem: JItem;
  isEdit?: boolean;
  openTooltip?: boolean;
  setOpenTooltip: Function;
}) => {
  return (
    <TableCell align="left" {...jItem.jItemProps}>
      <div
        onFocus={() => {
          if (openTooltip) {
            setOpenTooltip(false);
          }
        }}
      >
        <JItemMapper jItem={{ ...jItem, readonly: !isEdit }} />
      </div>
    </TableCell>
  );
};

const FTableRow = ({
  index,
  jItem,
  remove,
  isEdit = true,
  openTooltip,
  setOpenTooltip,
  device,
}: {
  index: number;
  jItem: JItem;
  remove: (index: number) => void;
  isEdit?: boolean;
  openTooltip?: boolean;
  setOpenTooltip: Function;
  device?: "desktop" | "mobile";
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { disabled: jdisabled = false } = useJFormikContext();
  const { values, validateForm } = useFormikContext();
  const [isEditing, setIsEditing] = useState<boolean>(
    !!(values as any)?.[jItem.name]?.[index]?.push
  );
  const readOnly =
    jItem.tableProps?.readOnly &&
    jItem.tableProps?.readOnly((values as any)?.[jItem.name]?.[index]);
  const disabled = jdisabled || jItem.disabled;
  const disableDelete = jItem.tableProps?.disableDelete
    ? jItem.tableProps?.disableDelete(values, index)
    : false;
  const isActionColumn =
    (!disabled && !readOnly) || // Delete
    (!readOnly &&
      disabled &&
      jItem.tableProps?.onCreate &&
      !jItem.tableProps?.onEdit &&
      !(values as any)?.[jItem.name]?.[index]?.id) || //Save
    (!readOnly && disabled && jItem.tableProps?.onEdit) || // Edit
    (!readOnly && disabled && jItem.tableProps?.onDelete && isEditing); // Delete -> TODO: Ask to Heesh what's the difference between this and the above deleting

  const ActionCellWrapperForDesktop = ({ children = null } = {} as any) => {
    return (
      <TableCell
        padding="checkbox"
        component="div"
        sx={{ borderBottom: "none" }}
      >
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
          }}
        >
          {children}
        </Grid>
      </TableCell>
    );
  };

  const ActionCellWrapperForMobile = ({ children = null } = {} as any) => {
    return (
      <TableRow sx={{ "&:hover": { boxShadow: "none" }, borderBottom: "none" }}>
        <TableCell
          colSpan={jItem.tableProps?.columns.length}
          sx={{ pt: 0, pb: 2, px: 1 }}
        >
          <Grid
            container
            spacing={2}
            sx={{ alignItems: "center", justifyContent: "flex-start" }}
          >
            {children}
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const ActionCell = () => {
    return (
      <>
        {!disabled && !readOnly && (
          <Grid>
            {device === "desktop" ? (
              <IconButton onClick={() => remove(index)} size="large">
                <Delete />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Delete />}
                onClick={() => remove(index)}
              >
                Delete
              </Button>
            )}
          </Grid>
        )}

        {!readOnly &&
          disabled &&
          jItem.tableProps?.onCreate &&
          !jItem.tableProps?.onEdit &&
          !(values as any)?.[jItem.name]?.[index]?.id && (
            <Grid >
              {device === "desktop" ? (
                <IconButton
                  onClick={async () => {
                    if (jItem?.tableProps?.onCreate) {
                      const isValid = await validateForm();
                      if (Object.keys(isValid).length === 0) {
                        await jItem.tableProps.onCreate(
                          (values as any)?.[jItem.name]?.[index]
                        );
                        setIsEditing(false);
                      }
                    }
                  }}
                  size="large"
                >
                  <Save />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Save />}
                  onClick={async () => {
                    if (jItem?.tableProps?.onCreate) {
                      const isValid = await validateForm();
                      if (Object.keys(isValid).length === 0) {
                        await jItem.tableProps.onCreate(
                          (values as any)?.[jItem.name]?.[index]
                        );
                        setIsEditing(false);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              )}
            </Grid>
          )}

        {!readOnly && disabled && jItem.tableProps?.onEdit && (
          <Grid >
            {isEditing ? (
              <>
                {device === "desktop" ? (
                  <IconButton
                    onClick={async () => {
                      if (
                        jItem?.tableProps?.onEdit &&
                        jItem?.tableProps?.onCreate
                      ) {
                        const isValid = await validateForm();
                        if (Object.keys(isValid).length === 0) {
                          if (!(values as any)?.[jItem.name]?.[index]?.id) {
                            await jItem.tableProps.onCreate(
                              (values as any)?.[jItem.name]?.[index]
                            );
                          } else {
                            await jItem.tableProps.onEdit(
                              (values as any)?.[jItem.name]?.[index]
                            );
                          }
                          setIsEditing(false);
                        }
                      }
                    }}
                    size="large"
                  >
                    <Save />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<Save />}
                    onClick={async () => {
                      if (
                        jItem?.tableProps?.onEdit &&
                        jItem?.tableProps?.onCreate
                      ) {
                        const isValid = await validateForm();
                        if (Object.keys(isValid).length === 0) {
                          if (!(values as any)?.[jItem.name]?.[index]?.id) {
                            await jItem.tableProps.onCreate(
                              (values as any)?.[jItem.name]?.[index]
                            );
                          } else {
                            await jItem.tableProps.onEdit(
                              (values as any)?.[jItem.name]?.[index]
                            );
                          }
                          setIsEditing(false);
                        }
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
              </>
            ) : (
              <>
                {device === "desktop" ? (
                  <IconButton onClick={() => setIsEditing(true)} size="large">
                    <Edit />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<Edit />}
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </Button>
                )}
              </>
            )}
          </Grid>
        )}

        {!readOnly &&
          !disableDelete &&
          disabled &&
          jItem.tableProps?.onDelete &&
          isEditing && (
            <Grid >
              {device === "desktop" ? (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  size="large"
                >
                  <Delete />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Delete />}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          )}
      </>
    );
  };

  return (
    <>
      <AlertDialog
        open={open}
        title={`Delete ${jItem.label}?`}
        content={`Are you sure you want to delete this ${jItem.label}?`}
        onClose={() => {
          setOpen(false);
          setIsEditing(false);
        }}
        onConfirm={async () => {
          if (jItem.tableProps?.onDelete) {
            if (!!(values as any)?.[jItem.name]?.[index]?.id) {
              await jItem.tableProps?.onDelete(
                (values as any)?.[jItem.name]?.[index]?.id
              );
            }
            remove && remove(index);
          }
          setOpen(false);
          setIsEditing(false);
        }}
      />
      <JFormikContext.Provider
        value={{ disabled: readOnly ? true : isEditing ? false : disabled }}
      >
        <TableRow
          key={index}
          sx={
            device === "mobile" && isActionColumn
              ? { "&:hover": { boxShadow: "none" }, borderBottom: "none" }
              : { borderBottom: "none" }
          }
        >
          {jItem.tableProps?.columns &&
            jItem.tableProps.columns?.map((column: JItem, cIndex: number) => {
              const { jItemProps, ...restJItemProps } = column;
              const { sx = {}, ...restSx } = jItemProps || {};
              return (
                <FTableCell
                  jItem={{
                    ...restJItemProps,
                    name: `${jItem.name}.${index}.${column.name}`,
                    tooltip: undefined,
                    disabled:
                      isEditing &&
                      jItemProps?.disabledFunc &&
                      typeof jItemProps?.disabledFunc === "function"
                        ? jItemProps?.disabledFunc(
                            (values as any)?.[jItem.name]?.[index]
                          )
                        : isEditing
                        ? false
                        : jItem.disabled,
                    jItemProps: {
                      ...restSx,
                      sx: {
                        ...sx,
                        ...(device === "mobile" && isActionColumn
                          ? { border: "none" }
                          : { borderBottom: "none" }),
                      },
                    },
                  }}
                  key={cIndex}
                  openTooltip={openTooltip}
                  setOpenTooltip={setOpenTooltip}
                />
              );
            })}

          {device === "desktop" && isActionColumn && (
            <ActionCellWrapperForDesktop>
              <ActionCell />
            </ActionCellWrapperForDesktop>
          )}
        </TableRow>

        {device === "mobile" && isActionColumn && (
          <ActionCellWrapperForMobile>
            <ActionCell />
          </ActionCellWrapperForMobile>
        )}
      </JFormikContext.Provider>
    </>
  );
};

export const FTableArray = forwardRef((jItem: JItem, ref) => {
  const { name, label, tableProps, device = "desktop" } = jItem;
  const { values } = useFormikContext();
  const { disabled = false } = useJFormikContext();
  const [openTooltip, setOpenTooltip] = useState<boolean | undefined>(
    jItem?.tooltip?.open
  );
  const [defaultValuesPushObject, setDefaultValuesPushObject] =
    useState<object>();
  const pushObject = useMemo(
    () =>
      tableProps?.columns
        ? tableProps?.columns.reduce(
            (obj, item) => ({ ...obj, [item.name]: "" }),
            {}
          )
        : {},
    [tableProps]
  );
  useEffect(() => {
    //add a key of `${name}PushObject` to formik values to have default values on push
    if (!defaultValuesPushObject) {
      const defaultValuesObject = getIn(values, `${name}PushObject`);
      if (defaultValuesObject) {
        setDefaultValuesPushObject(defaultValuesObject);
      }
    }
  }, [tableProps, values, name, defaultValuesPushObject]);
  const headers = useMemo(() => tableProps?.headers, [tableProps]);
  return (
    <FieldArray name={name} validateOnChange={false}>
      {({ push, remove, form: { values } }: FieldArrayRenderProps) => {
        return (
          <>
            {label && label !== "" && (
              <Typography variant="h6" component="div">
                {label}
              </Typography>
            )}
            <TableContainer sx={{ position: "relative" }}>
              <Table size={"medium"}>
                {headers && (
                  <TableHead>
                    <TableRow sx={{ borderBottom: "none" }}>
                      {headers &&
                        headers?.map((header: JItem, index: number) => {
                          if (header.tooltip) {
                            return (
                              <TableCell
                                align="left"
                                width={header?.jItemProps?.width}
                                key={index}
                                sx={header?.jItemProps?.sx}
                              >
                                <Tooltip
                                  {...{ ...header.tooltip, open: openTooltip }}
                                >
                                  <div
                                    onFocus={() => {
                                      if (openTooltip) {
                                        setOpenTooltip(false);
                                      }
                                    }}
                                    onClick={() => {
                                      if (openTooltip) {
                                        setOpenTooltip(false);
                                      }
                                    }}
                                  >
                                    {header.label}
                                  </div>
                                </Tooltip>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                align="left"
                                width={header?.jItemProps?.width}
                                key={index}
                                sx={header?.jItemProps?.sx}
                              >
                                {header.label}
                              </TableCell>
                            );
                          }
                        })}
                      <TableCell align="left" padding="none" width={"5%"} />
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {values && values[name] && values[name]?.length > 0 ? (
                    values[name]?.map((row: any, index: number) => {
                      return (
                        <FTableRow
                          index={index}
                          remove={remove}
                          jItem={jItem}
                          setOpenTooltip={setOpenTooltip}
                          openTooltip={openTooltip}
                          device={device}
                        />
                      );
                    })
                  ) : (
                    <tr />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {(!disabled || jItem.tableProps?.onCreate) && (
              <Typography variant="caption" id="tableTitle" component="div">
                <Tooltip title={`Add ${jItem.label}`}>
                  <IconButton
                    onClick={() =>
                      push(
                        defaultValuesPushObject ? { defaultValuesPushObject, push: "new" } : {
                          ...pushObject,
                          push: "new",
                        }
                      )
                    }
                    size="small"
                    id={`table-array-add-${label
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    <Add color="secondary" />
                  </IconButton>
                </Tooltip>{" "}
                {jItem.jItemProps?.addLabel || `Add ${label}`}
              </Typography>
            )}
          </>
        );
      }}
    </FieldArray>
  );
});
