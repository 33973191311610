import * as React from "react";
import {
  AppBar,
  Button,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import MenuIcon from "@mui/icons-material/Menu";
// import { VoluntizeWhiteLogo } from '../logos/VoluntizeWhiteLogo';
import { HeaderMoreIcon, HeaderMoreIconProps } from "./HeaderMoreIcon";
import { useHeaderShortMenuItems } from "./useHeaderShortMenuItems";

type Position = "fixed" | "absolute" | "sticky" | "static" | "relative";

export type MenuItem = {
  icon?: OverridableComponent<any>;
  label: string;
  onClick: Function;
  // Use type argument to decide the display mode of menu item
  type?: "listitem" | "buttonitem";
};

export interface HeaderShortProps
  extends Omit<HeaderMoreIconProps, "menuItems"> {
  menuItems?: MenuItem[];
  position?: Position;
  onLogoClick?: Function;
  onMenuIconClick?: Function;
  drawerWidth?: number;
}

export const HeaderShort = ({
  onLogoClick,
  tooltip,
  menuItems = [],
  position = "static",
  moreIconButtonProps,
  onMenuIconClick,
  drawerWidth,
}: HeaderShortProps) => {
  const { buttonItems, listItems } = useHeaderShortMenuItems({ menuItems });
  return (
    <AppBar
      component={"nav"}
      position={position}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Container maxWidth={"xl"}>
        <Toolbar disableGutters sx={{ maxHeight: "74px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {onMenuIconClick && (
              <Grid item sx={{ mr: 2, display: { sm: "none" } }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => onMenuIconClick()}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid
              item
              sx={{
                marginLeft: 0,
                cursor: onLogoClick ? "pointer" : "default",
              }}
              onClick={() => onLogoClick && onLogoClick()}
            >
              {/* <VoluntizeWhiteLogo /> */}
            </Grid>
            <Grid item sx={{ marginRight: "1%" }}>
              <Grid
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                wrap="nowrap"
              >
                {buttonItems &&
                  buttonItems.length > 0 &&
                  buttonItems.map(({ icon, label, onClick }, index) => {
                    const Icon = icon;

                    return Icon ? (
                      <Grid item sx={{ marginRight: "1%" }} key={index}>
                        <Tooltip title={label}>
                          <IconButton color="inherit" onClick={() => onClick()}>
                            <Icon id={`headershort-icon-${index}`} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : null;
                  })}

                {listItems && listItems.length > 0 && (
                  <div id={"header-short-menu"}>
                    <HeaderMoreIcon
                      tooltip={tooltip}
                      menuItems={listItems}
                      moreIconButtonProps={moreIconButtonProps}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
