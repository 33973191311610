import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Chip,
  Tooltip,
  ListItemButton,
} from "@mui/material";
import {
  useCreateProspectListMutation,
  useGetProspectListQuery,
  useLazyGetProspectListQuery,
  usePatchProspectListMutation,
} from "store/api/prospect_list.api";
import { toast } from "react-toastify";
import { Person, ProspectList } from "store/api/models";
import {
  CheckCircle,
  Close,
  ExpandMore,
  HowToVote,
  Info,
  Save,
  Close as CloseIcon,
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { CSVProspectUpload } from "./CSVProspectUpload";
import { QueryBuilder } from "components/query/QueryBuilder";
import { NoMaxWidthTooltip } from "components/NoMaxWidthTooltip";

export const ProspectListDetails = ({
  prospect_list: initialProspectList,
  disabled,
  close,
  callback,
  onProspectClick,
}: {
  prospect_list: ProspectList;
  disabled: boolean;
  close: Function;
  callback: Function;
  onProspectClick: (vanid: number) => void;
}) => {
  const [prospect_list, setProspectList] =
    useState<ProspectList>(initialProspectList);
  const [form, setForm] = useState<Record<string, any>>(
    initialProspectList.form || {}
  );
  const [parsedData, setParsedData] = useState<Person[]>([]);
  const [prospectListType, setProspectListType] = useState<string>(
    initialProspectList.form?.prospectListType || ""
  );
  const [newListName, setNewListName] = useState<string>(
    initialProspectList?.name || ""
  ); // Track the new list name
  const [newListDescription, setNewListDescription] = useState<string>(
    initialProspectList?.form?.description || ""
  ); // Track the new list name
  const [createProspectList] = useCreateProspectListMutation();
  const [patchProspectList] = usePatchProspectListMutation();
  const { data: prospectListData = {} as ProspectList } =
    useGetProspectListQuery(
      { id: initialProspectList.id },
      { skip: !initialProspectList.id, refetchOnMountOrArgChange: true }
    );
  const [rawQuery, setRawQuery] = useState<string>(
    initialProspectList?.form?.rawQuery || ""
  );
  const [showCopied, setShowCopied] = useState(false);
  useEffect(() => {
    if (prospectListData && initialProspectList.id) {
      setForm(prospectListData?.form);
      setParsedData(prospectListData?.results as Person[]);
    }
  }, [prospectListData, initialProspectList]);
  const [getProspectList] = useLazyGetProspectListQuery();
  const testQuery = async (query: string) => {
    const res = await getProspectList({
      id: 0,
      params: { query: query },
    }).unwrap();
    setParsedData(res.results as Person[]);
  };

  useEffect(() => {
    setProspectListType(initialProspectList.form?.prospectListType || "");
    setNewListName(initialProspectList?.name || "");
    setNewListDescription(initialProspectList?.form?.description || "");
    setForm(initialProspectList?.form);
    setRawQuery(initialProspectList?.form?.rawQuery || "");
    setProspectList(initialProspectList);
  }, [initialProspectList]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string
  ) => {
    setProspectListType(newType);
    setForm((prevForm) => ({ ...prevForm, prospectListType: newType }));
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(rawQuery)
      .then(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 1500);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };
  return (
    <Paper
      sx={{
        bgcolor: "white",
        position: "relative", // Allows positioning of the close button
        maxHeight: "100%",
        height: "100%",
      }}
      elevation={5}
    >
      {!disabled && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
          onClick={async () => {
            try {
              if (prospect_list?.id && prospect_list.id > 0) {
                const res = await patchProspectList({
                  id: prospect_list.id,
                  name: newListName,
                  form: { ...form, description: newListDescription },
                } as any).unwrap();
                await callback();
                if (res.id) {
                  setProspectList(res);
                  close();
                  toast.success("Prospect list updated!");
                }
              } else {
                const res = await createProspectList({
                  name: newListName,
                  form: { ...form, description: newListDescription },
                }).unwrap();
                await callback();
                if (res.id) {
                  setProspectList(res);
                  close();
                  toast.success("Prospect list created!");
                }
              }
            } catch (error) {
              toast.error("Error creating prospect list");
            }
          }}
        >
          <Save />
        </Fab>
      )}
      <Box
        sx={{
          maxHeight: "100%",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={() => close()}
          sx={{ position: "absolute", top: 4, right: 8 }}
        >
          <Close />
        </IconButton>
        <Box sx={{ px: 1, my: 1 }}>
          <Typography sx={{ mb: 1 }} variant="h6">
            PROSPECT LIST DETAILS{" "}
            <small>(Total: {parsedData?.length || 0})</small>
          </Typography>
          <TextField
            sx={{ mb: 1 }}
            disabled={disabled}
            size="small"
            label="Prospect List Name"
            fullWidth
            variant="outlined"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
          <TextField
            disabled={disabled}
            size="small"
            label="Prospect List Description"
            multiline
            minRows={2}
            fullWidth
            variant="outlined"
            value={newListDescription}
            onChange={(e) => setNewListDescription(e.target.value)}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            p: 1,
            maxHeight: "100%",
            overflowY: "scroll",
            flexGrow: 3,
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="overline">
                {" "}
                List creation details{" "}
                {prospectListType && (
                  <Chip
                    label={prospectListType}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    sx={{ zoom: "75%", mr: 1 }}
                  />
                )}
                {form?.fileName && (
                  <Chip
                    label={form.fileName}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    sx={{ zoom: "75%", mr: 1 }}
                  />
                )}
                <small>(Total: {parsedData?.length || 0})</small>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography variant="overline" sx={{ fontWeight: "bold" }}>
                  Select prospect list type:
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <ToggleButtonGroup
                  color="info"
                  value={prospectListType}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  disabled={disabled}
                >
                  <ToggleButton value="csv upload">Upload CSV</ToggleButton>
                  <ToggleButton value="query builder">
                    SQL Query Builder
                  </ToggleButton>
                  <ToggleButton value="raw query">Raw SQL Query</ToggleButton>
                </ToggleButtonGroup>
                <Box sx={{ display: "flex", ml: 1 }}>
                  <NoMaxWidthTooltip
                    title={`For CSV please see the example CSV download for formatting
                    For query, it is necessary to alias columns to firstname,lastname, and phone for compatability`}
                  >
                    <Info color="secondary" />
                  </NoMaxWidthTooltip>
                </Box>
              </Box>
              <Divider sx={{ mt: 1 }} />
              <Box sx={{ mt: 2 }}>
                {prospectListType === "csv upload" && (
                  <CSVProspectUpload
                    disabled={disabled}
                    setParsedData={setParsedData}
                    form={form}
                    setForm={setForm}
                  />
                )}
                {prospectListType === "query builder" && (
                  <QueryBuilder
                    disabled={disabled}
                    form={form}
                    setForm={setForm}
                    testQuery={testQuery}
                  />
                )}
                {prospectListType === "raw query" && (
                  <Box>
                    <Typography variant="overline" sx={{ fontWeight: "bold" }}>
                      Raw SQL Query:{" "}
                      <IconButton onClick={() => testQuery(rawQuery)}>
                        <CheckCircle color="secondary" />
                      </IconButton>
                    </Typography>
                    <TextField
                      disabled={disabled}
                      fullWidth
                      size="small"
                      value={rawQuery}
                      onChange={(event) => {
                        const newQuery = event.target.value as string;
                        setRawQuery(newQuery);
                        setForm((prev) => ({ ...prev, rawQuery: newQuery }));
                      }}
                      multiline
                      minRows={4}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <IconButton
                              size="small"
                              onClick={handleCopy}
                              edge="end"
                            >
                              {showCopied ? (
                                <CheckIcon fontSize="small" />
                              ) : (
                                <ContentCopyIcon fontSize="small" />
                              )}
                            </IconButton>
                          ),
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Display parsed CSV data in a MUI List */}
          {parsedData && parsedData.length > 0 && (
            <Paper elevation={3} sx={{ padding: 1, mt: 1 }}>
              <List dense>
                {parsedData.map((person, index) => (
                  <>
                    <ListItemButton
                      disabled={!person?.VanID}
                      key={index}
                      onClick={() => {
                        if (Number(person.VanID) > 0) {
                          onProspectClick(Number(person.VanID));
                        }
                      }}
                    >
                      <ListItemIcon>
                        <HowToVote color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span>
                            {`${person.firstname} ${person.lastname}      `}
                            <small>{`(${person?.VanID})`}</small>
                          </span>
                        }
                        // secondary={person?.VanID}
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                ))}
              </List>
            </Paper>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
