import { forwardRef, useState } from 'react';
import { InputBase } from '@mui/material';
import { Field, FieldProps, getIn, useFormikContext } from 'formik';
import { useBreakpointQuery } from '../../utils';
import { JItem } from '../jformik/jformik.types';
import { useJFormikContext } from '../jformik/jformik.context';

export const FInputBase = forwardRef((jItem: JItem, ref) => {
  const smDown = useBreakpointQuery('sm-down');
  const { disabled: jDisabled = false, disableSubmitOnEnter = true } =
    useJFormikContext();
  const disabled =
    jItem.disabled === false ? false : jDisabled || jItem.disabled;

  return (
    <Field name={jItem.name}>
      {({
        field: { name, value, onChange, onBlur },
        form: { touched, values, errors, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => {
        return (
          <InputBase
            id={`${jItem.name}-field`}
            onKeyPress={e => {
              disableSubmitOnEnter && e.key === 'Enter' && e.preventDefault();
            }}
            disabled={disabled}
            label={
              jItem.jItemProps?.labelFunc
                ? jItem?.jItemProps?.labelFunc(name, values, {
                    setFieldValue,
                  })
                : jItem.label
            }
            name={name}
            variant="outlined"
            // size={smDown ? 'small' : 'medium'}
            fullWidth
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={
              !!(
                getIn(errors, name) &&
                (getIn(touched, name) ||
                  submitCount ||
                  jItem?.jItemProps?.touched)
              )
            }
            helperText={
              getIn(touched, name) || submitCount || jItem?.jItemProps?.touched
                ? getIn(errors, name)
                : null
            }
            {...jItem.jItemProps}
          />
        );
      }}
    </Field>
  );
});
