import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { Conversation } from "./models";
import { RootState } from "../reducer";

// Create an EntityAdapter for the Conversation entity
const conversationAdapter = createEntityAdapter<Conversation, EntityId>({
  selectId: (conversation: Conversation) => conversation.vanid,
  sortComparer: (a, b) => a.vanid - b.vanid,
});

// Define the initial state using the adapter's getInitialState method
interface ConversationsState extends EntityState<Conversation, EntityId> {
  // Add any additional state properties here if needed
}

export const initialConversationState: ConversationsState =
  conversationAdapter.getInitialState();

// Selectors for Conversations
export const {
  selectById: selectConversationById,
  selectAll: selectAllConversations,
  selectEntities: selectConversationEntities,
  selectIds: selectConversationIds,
  selectTotal: selectConversationTotal,
} = conversationAdapter.getSelectors((state: RootState) => state?.conversation);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState: initialConversationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(conversation.endpoints.getConversations.matchFulfilled),
      (state, action) => {
        conversationAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        conversation.endpoints.patchConversation.matchFulfilled,
        conversation.endpoints.createConversation.matchFulfilled,
        conversation.endpoints.getConversation.matchFulfilled
      ),
      (state, action) => {
        conversationAdapter.upsertOne(state, action.payload);
      }
    );
  },
});

// Define your API slice
export const conversation = baseApi
  .enhanceEndpoints({ addTagTypes: ["Conversations", "Conversation"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getConversations: builder.query<Conversation[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "conversation",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ vanid }) => ({
                  type: "Conversation" as const,
                  vanid,
                })),
                { type: "Conversations" as const },
              ]
            : [{ type: "Conversations" as const }],
      }),
      getConversation: builder.query<
        Conversation,
        { id: number; params?: object }
      >({
        query: ({ id, params }: { id: number; params?: object }) => ({
          url: `conversation/${id}`,
          method: "GET",
          params: params,
        }),
        providesTags: (result, error, arg) => [
          { type: "Conversation", id: arg.id },
        ],
        keepUnusedDataFor: 90,
      }),
      createConversation: builder.mutation<Conversation, Partial<Conversation>>(
        {
          query: (request) => ({
            url: "conversation",
            method: "POST",
            body: request,
          }),
          // transformResponse(data: Conversation, meta, arg) {
          //   return {
          //     ...data,
          //   };
          // },
          invalidatesTags: [{ type: "Conversations" }],
        }
      ),
      patchConversation: builder.mutation<Conversation, Conversation>({
        query: (request) => ({
          url: `conversation/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Conversation", id: arg.id },
        ],
      }),
      // updateConversation: builder.mutation<Conversation, Conversation>({
      //   query: (request) => ({
      //     url: `conversation/${request.id}`,
      //     method: "PUT",
      //     body: request,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "Conversation", id: arg.id },
      //   ],
      // }),
      // deleteConversation: builder.mutation<void, number>({
      //   query: (id) => ({
      //     url: `conversation/${id}`,
      //     method: "DELETE",
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: "Conversation", id: arg },
      //     { type: "Conversations" },
      //   ],
      // }),
    }),
  });

// Auto-generated hooks
export const {
  useGetConversationsQuery,
  useGetConversationQuery,
  useCreateConversationMutation,
  usePatchConversationMutation,
  // useUpdateConversationMutation,
  // useDeleteConversationMutation,
  useLazyGetConversationsQuery,
  usePrefetch: useConversationsPrefetch,
} = conversation;
