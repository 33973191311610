import * as Yup from "yup";
import { JFormikData } from "forms/jformik";
import { useAddress } from "./useAddress";
import { selectAllEventTypes } from "store";
import { RootState } from "store/reducer";
import { useSelector } from "react-redux";
import { VisMap } from "components/mobilize/VisGLMap";

export const useEventForm = (): JFormikData => {
  const types = useSelector((state: RootState) => selectAllEventTypes(state));
  const address = useAddress({ name: "" });
  return {
    validationSchema: Yup.object({
      title: Yup.string().required("Please specify a title"),
      type: Yup.object().required("Please select a type"),
    }),
    form: [
      {
        name: "title",
        label: "Title",
        itemType: "textfield",
        gridProps: { size: { xs: 9 } },
      },
      {
        name: "type",
        label: "Type",
        itemType: "autocomplete",
        options: types,
        gridProps: { size: { xs: 3 } },
      },
      {
        name: "featured_image_url",
        label: "Type",
        itemType: "image",
        options: [],
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "details",
        itemType: "textfield",
        jItemProps: { multiline: true, rows: 4, variant: "outlined" },
        label: `Add a description of the event`,
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "instructions",
        itemType: "textfield",
        jItemProps: { multiline: true, rows: 4, variant: "outlined" },
        label: `Add a instructions for the event`,
        gridProps: { size: { xs: 12 } },
      },
      ...address,
      {
        name: "map-embed",
        itemType: "embed",
        label: "",
        embed: ({ values, setFieldValue }) => {
          const address = `${values.address} ${values.city} ${values.state} ${values.zipcode}`;
          return (
            <VisMap
              active={true}
              markers={{
                centralLocation: {
                  address: address,
                  label: address,
                },
              }}
            />
          );
        },
      },
      {
        name: "basic-fields",
        itemType: "typography",
        jItemProps: {
          variant: "overline",
          sx: { mb: "0 !important", pb: "0 !important", fontWeight: 700 },
        },
        label: "Time slots",
      },
      {
        name: "timeslots",
        itemType: "fieldarray",
        label: "Time slots",
        arrayProps: { wrap: false },
        jItemProps: {
          sx: {
            paddingTop: 3,
            paddingBottom: 4,
            paddingX: 4,
            marginTop: 2,
          },
        },
        array: [
          {
            name: "checked",
            label: "",
            // defaultValue: true,
            // value: true,
            itemType: "checkbox",
            gridProps: { size: { xs: 2 } },
          },
          {
            name: "start_date",
            label: "Start Date/Time",
            itemType: "datetimepicker",
            jItemProps: { type: "time", InputProps: { type: "time" } },
            gridProps: { size: { xs: 5 } },
          },
          {
            name: "end_date",
            label: "End Date/Time",
            itemType: "datetimepicker",
            gridProps: { size: { xs: 5 } },
          },
        ],
      },
      {
        name: "submit",
        itemType: "button",
        jItemProps: { type: "submit" },
        label: "SAVE",
        gridProps: { size: { xs: 12 } },
      },
    ],
  } as JFormikData;
};
