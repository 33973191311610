import { forwardRef, useState, useMemo, useEffect } from 'react';
import {
  Grid2 as Grid,
  Paper,
} from '@mui/material';
import {
  useFormikContext,
} from 'formik';
import { useBreakpointQuery } from '../../utils';
import { JItem } from '../jformik/jformik.types';
import { JItemMapper } from '../jformik/JItemMapper';
import { useJFormikContext } from '../jformik/jformik.context';

const FField = ({ jItem, values }: { jItem: JItem; values: any }) => {
  const { gridProps } = jItem;
  const gridSizesResult =
    typeof gridProps === 'function'
      ? gridProps(values)
      : gridProps !== null
      ? gridProps
      : {};
  const gridSizes = typeof gridSizesResult === 'object' ? gridSizesResult : {};
  return (
    <Grid size={{xs:12}} {...gridSizes} key={`${jItem.name}-index`}>
      <JItemMapper jItem={jItem} />
    </Grid>
  );
};

export const FPaper = forwardRef((jItem: JItem, ref) => {
  const {
    array,
    disabled: jdisabled = false,
    arrayProps = { wrap: false },
  } = jItem;
  const { values } = useFormikContext();
  const smDown = useBreakpointQuery('sm-down');
  const { disabled: fdisabled = false } = useJFormikContext();
  const disabled = fdisabled || jdisabled;
  return (
    <Paper {...jItem?.jItemProps?.paper}>
      <Grid container spacing={1} {...jItem?.jItemProps?.grid}>
        {array &&
          array.map((item: JItem, index: number) =>
            !item.precondition ||
            item.precondition({
              ...(values as object),
              index: index,
              name: jItem.name,
            }) ? (
              <FField
                jItem={{
                  ...item,
                  name:
                    jItem.name !== ''
                      ? `${jItem.name}.${item.name}`
                      : `${item.name}`,
                  disabled:
                    item.disabled === false ? false : disabled || item.disabled,
                }}
                values={values}
                key={
                  jItem.name !== ''
                    ? `ffield-${jItem.name}-${item.name}}`
                    : `ffield-${item.name}}`
                }
              />
            ) : (
              <div />
            )
          )}
      </Grid>
    </Paper>
  );
});
