import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
  ListItemIcon,
  Chip,
  Autocomplete,
  alpha,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  AnimationOutlined,
  AccountCircleOutlined,
  Search as SearchIcon,
  Delete,
  Edit,
  Event as EventIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Event } from "store/api/mobilize/mobilize_models";
import { useDeleteEventMutation } from "store/api/event.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

export const MobilizeEventList = ({
  events,
  selectedEvent,
  handleEventClick,
  handleEventEditClick,
}: {
  events: Event[];
  selectedEvent?: Event;
  handleEventClick: (event: Event) => void;
  handleEventEditClick: (event: Event) => void;
}) => {
  const [openAlert, setOpenAlert] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [eventType, setEventType] = useState<string>("");
  const [account, setAccount] = useState<{ id: number; name: string }>();
  const [deleteEvent] = useDeleteEventMutation();
  const eventTypes = useMemo(() => {
    return [
      ...new Set<string>(
        events.map((e) => e.event_type).filter((x: any) => !!x)
      ),
    ].sort();
  }, [events]);

  const filteredEvents = [...(events || [])]
    ?.filter((e) => {
      return e.title?.toLowerCase()?.includes(search.toLowerCase());
    })
    ?.filter((e) => eventType === "" || e.event_type === eventType)
    ?.filter((e) => !account?.id || e.sponsor.id === account.id)
    ?.sort((a, b) => sortString(a.title, b.title));
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <Box
        id="topflex"
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          height: "100%",
        }}
      >
        <AlertDialog
          open={!!openAlert}
          title={`Delete event`}
          content={`Are you sure you want to delete this event?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteEvent(openAlert);
                toast.success("Event successfully deleted");
              } catch (error) {
                toast.success("Unable to delete contact");
              }
            }
            setOpenAlert(undefined);
          }}
        />
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid size={{ xs: 12, lg: 4 }}>
              <Typography variant="h6" component={"span"}>
                MOBILIZE EVENT LIST
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, lg: 8 }}>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid size={{ xs: 4 }} sx={{ flex: 0.33 }}>
                  <Autocomplete
                    options={[
                      { id: 1545, name: "MeckDems" },
                      { id: 37434, name: "Coordinated Campaign" },
                    ]}
                    fullWidth
                    getOptionLabel={(option) => (option as any)?.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Account"
                        variant="outlined"
                      />
                    )}
                    id="event-types"
                    size="small"
                    value={account}
                    onChange={(e, v) => {
                      setAccount(v || undefined);
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 4 }} sx={{ flex: 0.33 }}>
                  <Autocomplete
                    options={eventTypes}
                    fullWidth
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Event Type"
                        variant="outlined"
                      />
                    )}
                    id="event-types"
                    size="small"
                    value={eventType}
                    onChange={(e, v) => {
                      setEventType(v || "");
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 4 }} sx={{ flex: 0.33, textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Events"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    // sx={{ maxWidth: { xs: "none", sm: 240 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <List
          dense
          disablePadding
          sx={{
            height: "100%",
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          {filteredEvents?.map((event: Event) => (
            <>
              <ListItem
                key={event.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "4px",
                  bgcolor: (theme) =>
                    event?.sponsor?.id === 1545
                      ? alpha(
                          theme.palette.secondary.light,
                          selectedEvent && selectedEvent?.id === event?.id
                            ? 0.3
                            : 0.1
                        )
                      : alpha(
                          theme.palette.primary.light,
                          selectedEvent && selectedEvent?.id === event?.id
                            ? 0.3
                            : 0.1
                        ),
                  border: (theme) =>
                    `${
                      selectedEvent && selectedEvent?.id === event?.id ? 4 : 2
                    }px solid ${
                      selectedEvent && selectedEvent?.id === event?.id
                        ? "black"
                        : event?.sponsor?.id === 1545
                        ? theme.palette.secondary.light
                        : theme.palette.primary.light
                    }`,
                }}
                dense
              >
                <ListItemAvatar>
                  <Gauge
                    value={event?.timeslots?.filter((t) => t.is_full)?.length}
                    valueMax={event?.timeslots?.length}
                    startAngle={-110}
                    endAngle={110}
                    width={80}
                    height={50}
                    // startAngle={0}
                    // endAngle={360}
                    innerRadius="70%"
                    outerRadius="100%"
                    sx={{
                      [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 12,
                        transform: "translate(0px, 0px)",
                      },
                    }}
                    text={({ value, valueMax }) =>
                      `${Math.round((100 * (value || 0)) / valueMax)}%`
                    }
                  />
                </ListItemAvatar>
                <ListItemAvatar>
                  <Chip
                    label={event.event_type}
                    size="small"
                    variant="outlined"
                    color={event.sponsor.id === 1545 ? "primary" : "secondary"}
                    sx={{ zoom: "75%", mr: 1 }}
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <Typography variant="overline" sx={{ fontWeight: 700 }}>
                      {event.title}{" "}
                      <small style={{ marginLeft: 2, whiteSpace: "nowrap" }}>
                        (
                        {`${
                          event?.timeslots?.filter((t) => !t.is_full)?.length
                        }/${event?.timeslots?.length} slots available`}
                        )
                      </small>
                    </Typography>
                  }
                  onClick={() => handleEventClick(event)}
                  sx={{ cursor: "pointer", py: 0, my: 0 }}
                />
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};
