import { useSelector } from "react-redux";
import {
  useGetNGPContactQuery,
  selectNGPContactById,
} from "store/api/ngp/ngp_contact.api";
import { RootState } from "store/reducer";

export const useNGPContact = (vanid?: string | number) => {
  const skip =
    !vanid || `${vanid}` === "" || vanid === 0 || `${vanid}`.length !== 9;
  useGetNGPContactQuery({ vanid: Number(vanid) || 0 }, { skip: skip });
  useGetNGPContactQuery(
    { vanid: Number(vanid) || 0, params: { table: "contributions" } },
    { skip: skip }
  );
  useGetNGPContactQuery(
    { vanid: Number(vanid) || 0, params: { table: "events" } },
    { skip: skip }
  );
  useGetNGPContactQuery(
    { vanid: Number(vanid) || 0, params: { table: "forms" } },
    { skip: skip }
  );
  useGetNGPContactQuery(
    { vanid: Number(vanid) || 0, params: { table: "outreaches" } },
    { skip: skip }
  );
  return useSelector((state: RootState) =>
    selectNGPContactById(state, vanid || 0)
  );
};
