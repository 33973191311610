import { useState } from "react";
import { Box, Fab, Paper } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useGetLoginsQuery } from "store/api/login.api";
import { Login } from "store/api/models";
import { LoginList } from "./LoginList";
import { LoginDetails } from "./LoginDetails";
import { BackdropLoading } from "../BackdropLoading";

export const LoginsIndex = () => {
  const [selectedLogin, setSelectedLogin] = useState<Login>();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    data: logins = [] as Login[],
    isLoading: loginsLoading,
    refetch,
  } = useGetLoginsQuery({ params: { admin: true } });
  const handleLoginClick = (login: Login) => {
    setEditing(false);
    setSelectedLogin(login);
  };
  const handleLoginEditClick = (login: Login) => {
    setEditing(true);
    setSelectedLogin(login);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        position: "relative",
        flexDirection: "row",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {loginsLoading ? (
        <BackdropLoading />
      ) : (
        <Box
          sx={{
            flexGrow: selectedLogin ? 0.5 : 1,
            width: selectedLogin ? "50%" : undefined,
            maxWidth: selectedLogin ? "50%" : undefined,
            transition: "flex-grow 0.3s",
            pr: selectedLogin ? 1 : 0,
            maxHeight: "100%",
            height: "100%",
          }}
        >
          <Paper sx={{ p: 1, maxHeight: "100%", height: "100%" }} elevation={5}>
            <LoginList
              logins={logins}
              selectedLogin={selectedLogin}
              handleLoginClick={handleLoginClick}
              handleLoginEditClick={handleLoginEditClick}
              close={() => {
                setSelectedLogin(undefined);
                setEditing(false);
              }}
            />
          </Paper>
        </Box>
      )}
      {!editing && (
        <Fab
          color="primary"
          sx={{
            position: "absolute",
            left: 10,
            bottom: 50,
          }}
          onClick={() => {
            setEditing(true);
            setSelectedLogin({ admin: true } as Login);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* Login Details */}
      {selectedLogin && (
        <Box
          sx={{
            flexGrow: 0.5,
            width: selectedLogin ? "50%" : undefined,
            maxWidth: selectedLogin ? "50%" : undefined,
            position: "relative", // Allows positioning of the close button
            transition: "flex-grow 1s",
            maxHeight: "100%",
            height: "100%",
          }}
        >
          <LoginDetails
            disabled={!editing}
            login={selectedLogin}
            close={() => {
              setSelectedLogin(undefined);
              setEditing(false);
            }}
            callback={async () => {
              await refetch();
              setSelectedLogin(undefined);
              setEditing(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
