import { AccountBoxOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { MyAccountTab } from "./tabs/MyAccountTab";
import { TabDrawer, TabDrawerProps } from "drawers";

export interface AccountTabDrawerProps
  extends Omit<TabDrawerProps, "tabs" | "setTabs" | "title" | "status"> {}

export const AccountTabDrawer = (props: AccountTabDrawerProps) => {
  const theme = useTheme();

  return (
    <TabDrawer
      width={{ xs: "100%" }}
      maxWidth={theme.breakpoints.values.sm}
      {...props}
      title={"My Account"}
      initialTab="account"
      tabs={{
        account: {
          key: "account",
          sequence: 1,
          icon: <AccountBoxOutlined />,
          label: "",
          visible: true,
          backgroundColor: "white",
          contents: <MyAccountTab />,
        },
      }}
    />
  );
};
