import * as Yup from "yup";
import { MailOutlineOutlined } from "@mui/icons-material";
import { JFormikData } from "forms/jformik";

export const useAdminProfileForm = (admin?: boolean): JFormikData => {
  return {
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
    form: [
      {
        name: "email",
        label: "Email",
        itemType: "textfield",
        gridProps: { size: { xs: 12 } },
        jItemProps: {
          InputProps: {
            endAdornment: <MailOutlineOutlined color="secondary" />,
          },
        },
      },
      // {
      //   name: "form.phone_override",
      //   label: "Override Phone #",
      //   itemType: "textfield",
      //   tooltip: {
      //     title:
      //       "Enter a number to test the phone system and all calls will go to that number during your session.",
      //   },
      //   gridProps: { size: { xs: 12 } },
      //   jItemProps: {
      //     type: "phone",
      //     // InputProps: {
      //     //   endAdornment: <MailOutlineOutlined color="secondary" />,
      //     // },
      //   },
      // },
    ],
  } as JFormikData;
};
