import { useState } from "react";
import { Field, FieldProps } from "formik";
import { JItem } from "../jformik/jformik.types";

export const FImage = (jItem: JItem) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    form: any,
    field: any
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
        form.setFieldValue(jItem.name, reader.result); // Store the image data in Formik
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Field name={jItem.name}>
      {({ field: { value }, form }: FieldProps) => (
        <div>
          {/* Image preview */}
          {value || imagePreview ? (
            <img
              src={imagePreview || value}
              alt="Uploaded"
              loading="lazy"
              style={{ maxWidth: "100%", marginBottom: "10px" }}
            />
          ) : (
            <p>No image uploaded</p>
          )}

          {/* File input for image upload */}
          <input
            type="file"
            accept="image/*"
            onChange={(event) => handleImageUpload(event, form, value)}
          />
        </div>
      )}
    </Field>
  );
};
