import { forwardRef, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid2 as Grid, Typography } from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { CallReceived, ErrorOutline, DeleteOutline } from '@mui/icons-material';
import { JItem } from '../jformik/jformik.types';
import { Attachment } from './FAttachments';
import { JItemMapper } from '../jformik/JItemMapper';
import { useJFormikContext } from '../jformik/jformik.context';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column' as any,
  alignItems: 'center',
  padding: 16,
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%237A4' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: 4,
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  color: '#333333',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#4caf50',
};

const acceptStyle = {
  borderColor: '#1976d2',
};

const rejectStyle = {
  borderColor: '#f44336',
};

export interface FDropzoneProps {
  project_id: string;
  uploadQuote: ({ id, file }: { id: string; file: File }) => void;
}

export const FDropzone = forwardRef((jItem: JItem, ref) => {
  const { name, label, disabled: jdisabled } = jItem;
  const { disabled: fdisabled = false } = useJFormikContext();
  const disabled = jdisabled === false ? false : fdisabled || jdisabled;
  return (
    <Field name={name} innerRef>
      {({
        field: { name, value },
        form: { touched, errors, setFieldValue, values, submitCount },
      }: FieldProps) => {
        return (
          <DropComponent
            value={value || []}
            name={name}
            label={label}
            caption={jItem.jItemProps?.caption}
            onDownload={jItem?.jItemProps?.onDownload}
            onSelect={jItem?.jItemProps?.onSelect}
            onDelete={jItem?.jItemProps?.onDelete}
            setFieldValue={setFieldValue}
            multiple={jItem?.multiple}
            disabled={disabled}
          />
        );
      }}
    </Field>
  );
});

interface DropComponentProps {
  value: ExtendedFile[];
  name: string;
  label: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  onDownload?: Function;
  onSelect?: Function;
  onDelete?: Function;
  multiple?: boolean;
  disabled?: boolean;
  caption?: string;
}

interface ExtendedFile extends File {
  id?: number;
  bucket_key?: string;
  name: string;
  size: number;
  type: string;
  isLoading?: boolean;
}

const DropComponent = ({
  value = [],
  name,
  label,
  caption,
  setFieldValue,
  onDownload,
  onSelect,
  onDelete,
  multiple = true,
  disabled = false,
}: DropComponentProps) => {
  const [uploaderError, setUploaderError] = useState<string>('');
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { all: ['*'] },
    multiple: multiple,
    onDrop: async (acceptedFiles: File[], fileRejections) => {
      if (fileRejections.length > 0) {
        setUploaderError('You have to upload one file at a time!');
      } else {
        setUploaderError('');
        if (onSelect) {
          setFieldValue(name, [
            ...value,
            ...acceptedFiles.map((file: File) => ({
              file: file,
              name: file.name,
              type: file.type,
              size: file.size,
              isLoading: true,
            })),
          ]);
          for (let acceptedFile of acceptedFiles) {
            const uploadedFile = await onSelect(acceptedFile);
            setFieldValue(name, [
              ...value.filter(item => item.name !== uploadedFile.name),
              uploadedFile,
            ]);
          }
        } else {
          setFieldValue(name, [
            ...value,
            ...acceptedFiles.map((file: File) => ({
              file: file,
              name: file.name,
              type: file.type,
              size: file.size,
              isLoading: !!onSelect,
            })),
          ]);
        }
      }
    },
  });

  const removeFile = (file: any) => {
    setFieldValue(
      name,
      value.filter(item => item.name !== file.name)
    );
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <Grid container direction={'row'} spacing={0}>
      {value && value?.length > 0 && (
        <Grid size={{xs:12}}>
          <Grid container direction={'row'} spacing={0}>
            {value.map(file => {
              return (
                <Grid size={{xs:12}} sx={{ mb: '5px' }} key={file.name}>
                  <Attachment
                    onClose={(file: any) => removeFile(file)}
                    onDownload={onDownload}
                    onDelete={onDelete}
                    id={file.id}
                    name={file.name}
                    size={file.size}
                    type={file.type}
                    isLoading={file.isLoading}
                    disabled={disabled}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      {!disabled && (
        <Grid size={{xs:12}} {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CallReceived
            color={'secondary'}
            fontSize={'small'}
            sx={{
              transform: 'rotate(-45deg)',
              // marginBottom: 1,
            }}
          />
          <Typography
            variant="body1"
            color="textPrimary"
            align="center"
            sx={{
              opacity: 0.8,
            }}
          >
            {label}
          </Typography>
          <Typography
            variant="caption"
            color="textPrimary"
            align="center"
            sx={{
              opacity: 0.8,
            }}
          >
            {caption ? caption : 'Drag and drop pdfs, pngs, jpegs etc.'}
          </Typography>
        </Grid>
      )}

      {uploaderError && (
        <Typography
          variant="caption"
          color="error"
          className="flex items-center justify-center"
        >
          <ErrorOutline className="mr-2" fontSize="small" />
          {uploaderError}
        </Typography>
      )}
    </Grid>
  );
};
