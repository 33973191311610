import { Breakpoint, Theme, useMediaQuery, useTheme } from '@mui/material';

export type BreakPointQueries =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xs-down'
  | 'sm-down'
  | 'md-down'
  | 'lg-down'
  | 'xl-down'
  | 'xs-up'
  | 'sm-up'
  | 'md-up'
  | 'lg-up'
  | 'xl-up'
  | 'between-xs-sm'
  | 'between-xs-md'
  | 'between-xs-lg'
  | 'between-xs-xl'
  | 'between-sm-md'
  | 'between-sm-lg'
  | 'between-sm-xl'
  | 'between-md-lg'
  | 'between-md-xl'
  | 'between-lg-xl';

export const useBreakpointQuery = (query: BreakPointQueries) => {
  const callback = (theme: Theme) => {
    const mediaQuery = query.split('-');

    if (mediaQuery.length === 3 && mediaQuery[0] === 'between') {
      return theme.breakpoints.between(
        mediaQuery[1] as Breakpoint,
        mediaQuery[2] as Breakpoint
      );
    } else if (mediaQuery.length === 2) {
      switch (mediaQuery[1]) {
        case 'down':
          return theme.breakpoints.down(mediaQuery[0] as Breakpoint);
        case 'up':
          return theme.breakpoints.up(mediaQuery[0] as Breakpoint);
      }
    }
  };

  const media = callback(useTheme());

  return useMediaQuery(media || 'true');
};
