import React, { useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser, useGetVolunteerQuery } from "store";
import { usePreloadData } from "utils/preload.util";
import { useMQTT } from "utils/mqtt.util";
import { AccountTabDrawer } from "drawers/AccountTabDrawer";
import { AgentMenu } from "components/agent/AgentMenu";
import { AgentAppBar } from "components/agent/AgentAppbar";
import { VolunteerState } from "components/agent/agent.types";
import { useTwilioVoice } from "hooks/useTwilioVoice";
import { AgentTask } from "components/agent/AgentTask";

export const VolunteerIndex: React.FC = () => {
  const user = useSelector(selectUser);
  const [state, setState] = useState<VolunteerState>({
    selectedConversation: null,
    selectedSkill: null,
    selectedMenu: null,
    isLoading: false,
    completed: false,
    welcome: true,
    disposition: false,
    callOngoing: false,
  });
  usePreloadData(user.admin);
  //Init phone system
  useTwilioVoice();
  // useMQTT();
  const { data: volunteer } = useGetVolunteerQuery(
    { id: user?.volunteer || 0, params: { nested: "normalize" } },
    { skip: !user?.volunteer }
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
  const [openAccountDrawer, setOpenAccountDrawer] = useState<boolean>(false);

  return (
    <Box
      id="main-container"
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      {/* Top Toolbar */}
      <AgentAppBar
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        setOpenAccountDrawer={setOpenAccountDrawer}
      />
      {/* Adjust the margin top to account for the AppBar */}
      <Box sx={{ display: "flex", height: "100%", marginTop: "64px" }}>
        <AccountTabDrawer
          open={openAccountDrawer}
          onClose={() => {
            setOpenAccountDrawer(false);
          }}
        />
        {/* Left Side Menu */}
        <AgentMenu
          isMenuOpen={isMenuOpen}
          volunteer={volunteer}
          state={state}
          setState={setState}
        />

        {/* Right Side Content */}
        <AgentTask state={state} setState={setState} />
      </Box>
    </Box>
  );
};
