import * as Yup from "yup";
import { JFormikData } from "forms/jformik";
import { useAddress } from "./useAddress";
import { Checkbox, List, ListItem, ListItemText } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import moment from "moment";
import { sortByDate } from "utils";
import { useState } from "react";

export const useMobilizeEventForm = (submit: boolean): JFormikData => {
  return {
    validationSchema: Yup.object({
      title: Yup.string().required("Please specify a title"),
      type: Yup.object().required("Please select a type"),
    }),
    form: [
      {
        name: "title",
        label: "Title",
        itemType: "textfield",
        gridProps: { size: { xs: 9 } },
      },
      {
        name: "event_type",
        label: "Type",
        itemType: "autocomplete",
        options: [],
        gridProps: { size: { xs: 3 } },
      },
      {
        name: "featured_image_url",
        label: "Type",
        itemType: "image",
        options: [],
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "description",
        itemType: "textfield",
        jItemProps: { multiline: true, variant: "outlined" },
        label: `Description`,
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "instructions",
        itemType: "textfield",
        jItemProps: { multiline: true, variant: "outlined" },
        label: `Instructions`,
        gridProps: { size: { xs: 12 } },
      },
      {
        name: "basic-fields",
        itemType: "typography",
        disabled: true,
        jItemProps: {
          variant: "overline",
          sx: {
            mb: "0 !important",
            pb: "0 !important",
          },
        },
        label: "Time slots",
      },
      // {
      //   name: "basic-fields-bottom",
      //   label: "",
      //   itemType: "divider",
      //   gridProps: { size: { sx: { paddingTop: "0px !important" } } },
      // },
      {
        name: "timeslots",
        label: "",
        itemType: "embed",
        embed: ({ values, setFieldValue }) => (
          <List dense disablePadding>
            {[
              ...values.timeslots.filter(
                (t: any) => !t.is_full && moment(t.start_date).isAfter(moment())
              ),
            ]
              .sort((b, a) =>
                sortByDate(moment(a.start_date), moment(b.start_date))
              )
              .map((t: any, i: number) => (
                <EventTimeSlot
                  id={t.id}
                  checked={t.checked}
                  timeslot={t}
                  signup_count={t.signup_count}
                  start_date={t.start_date}
                  end_date={t.end_date}
                  index={i}
                  values={values}
                  setFieldValue={setFieldValue}
                  disabled={true}
                />
              ))}
          </List>
        ),
      },
      // {
      //   name: "",
      //   itemType: "paper",
      //   label: "Time slots",
      //   // arrayProps: { wrap: false, noModify: true },
      //   jItemProps: {
      //     paper: {
      //       elevation: 0,
      //     },
      //     sx: {
      //       paddingTop: 3,
      //       paddingBottom: 4,
      //       paddingX: 4,
      //       marginTop: 2,
      //     },
      //   },
      //   array: [
      //     {
      //       name: "basic-fields",
      //       itemType: "typography",
      //       jItemProps: {
      //         variant: "overline",
      //         sx: { mb: "0 !important", pb: "0 !important", fontWeight: 700 },
      //       },
      //       label: "Time slots",
      //     },
      //     {
      //       name: "timeslots",
      //       itemType: "fieldarray",
      //       arrayProps: { wrap: false, noModify: true },
      //       jItemProps: {
      //         sx: {
      //           paddingTop: 3,
      //           paddingBottom: 4,
      //           paddingX: 4,
      //           marginTop: 2,
      //         },
      //       },
      //       array: [
      //         {
      //           name: "start_date",
      //           label: "Start Date/Time",
      //           itemType: "datetimepicker",
      //           jItemProps: { type: "time", InputProps: { type: "time" } },
      //           gridProps: { size: { xs: 6 } },
      //         },
      //         {
      //           name: "end_date",
      //           label: "End Date/Time",
      //           itemType: "datetimepicker",
      //           gridProps: { size: { xs: 6 } },
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: `location.address_lines.0`,
        itemType: "textfield",
        label: "Address",
        gridProps: { size: { xs: 12 } },
      },
      {
        name: `location.locality`,
        itemType: "textfield",
        label: "City",
        gridProps: { size: { xs: 12, sm: 4 } },
      },
      {
        name: `location.region`,
        label: "State",
        itemType: "autocomplete",
        gridProps: { size: { xs: 12, sm: 4 } },
      },
      {
        name: `location.postal_code`,
        itemType: "textfield",
        label: "Postal Code",
        jItemProps: {
          type: "zipcode",
        },
        gridProps: { size: { xs: 12, sm: 4 } },
      },
    ],
  } as JFormikData;
};

export const EventTimeSlot = ({
  id,
  timeslot,
  start_date,
  end_date,
  index,
  values,
  setFieldValue,
  disabled,
  checked: isChecked,
  signup_count,
}: {
  id: number;
  timeslot: any;
  signup_count?: number;
  start_date: number;
  end_date: number;
  index: number;
  values: any;
  setFieldValue: Function;
  disabled: boolean;
  checked: boolean;
}) => {
  const startDate = moment(start_date);
  const endDate = moment(end_date);
  // Format start and end dates
  const formattedDate = `${startDate.format(
    "ddd, MMM D, h:mmA"
  )}–${endDate.format("h:mmA z")}`;

  const [checked, setChecked] = useState(isChecked);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (values.timeslot) {
      setFieldValue(`timeslot`, {
        ...values.timeslot,
        checked: event?.target.checked,
      });
      setChecked(event.target.checked);
    } else {
      const tindex = values.timeslots?.findIndex((t: any) => t.id === id);
      let updatedTimeslots = [...(values.timeslots || [])];
      updatedTimeslots[tindex] = {
        ...updatedTimeslots[tindex],
        checked: event?.target.checked,
      };
      setFieldValue(`timeslots`, updatedTimeslots);
      setChecked(event.target.checked);
    }
  };
  return (
    <ListItem key={index} dense disableGutters disablePadding sx={{ ml: 2 }}>
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      <ListItemText
        sx={{
          ml: 3,
          color: (theme) => (disabled ? theme.palette.grey[500] : undefined),
        }}
      >
        {formattedDate}{" "}
        {(signup_count || signup_count === 0) && (
          <small style={{ marginLeft: 2 }}>({signup_count} signups)</small>
        )}
      </ListItemText>
    </ListItem>
  );
};
