import * as React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { JItem } from '../jformik/jformik.types';
import { JItemMapper } from '../jformik/JItemMapper';
import { useBreakpointQuery } from '../../utils';

export const FJItem = (jItem: JItem) => {
  const smDown = useBreakpointQuery('sm-down');
  return (
    <Field name={jItem?.name}>
      {({ field: { value, name } }: FieldProps) => {
        if (value) {
          return (
            <Grid container spacing={smDown ? 1 : 2}>
              {value && Array.isArray(value) ? (
                value.map((item, index) => {
                  return (
                    <Grid size={{xs:12}} key={`${name}-${item.form?.name}`}>
                      <JItemMapper
                        jItem={{ ...item.form, name: `${name}.${index}.value` }}
                      />
                    </Grid>
                  );
                })
              ) : (
                <JItemMapper jItem={value} />
              )}
            </Grid>
          );
        } else {
          return <div />;
        }
      }}
    </Field>
  );
};
