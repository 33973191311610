import { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  ListItemButton,
  MenuList,
  Divider,
  CssBaseline,
  Badge,
  TextField,
} from "@mui/material";
import {
  Menu as MenuIcon,
  List as ListIcon,
  Notifications as NotificationsIcon,
  Logout,
  AccountBox,
  SettingsOutlined,
  EmojiPeople,
  EventOutlined,
  PeopleOutline,
  Description,
  AdminPanelSettings,
  EventAvailable,
  CalendarViewDay,
  Dvr,
  Storage,
  Flag,
  OutlinedFlag,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { selectUser, selectVolunteerEntities } from "store";
import { useLogout } from "utils";
import { SettingsTabDrawer } from "drawers/SettingsTabDrawer";
import { AccountTabDrawer } from "drawers/AccountTabDrawer";
import { VolunteersIndex } from "components/volunteers/VolunteersIndex";
import { EventsIndex } from "components/events/EventsIndex";
import { TasksIndex } from "components/tasks/TasksIndex";
import { usePreloadData } from "utils/preload.util";
import { MobilizeEventsIndex } from "components/mobilize/MobilizeEventsIndex";
import { MobilizeEventGroupsIndex } from "components/mobilize/MobilizeEventGroupsIndex";
import { ScriptsIndex } from "components/script/ScriptsIndex";
import { LoginsIndex } from "components/admin/LoginsIndex";
import { SchemaDrawer } from "drawers/SchemaDrawer";
import { TaskGroupsIndex } from "components/task_groups/TaskGroupsIndex";
import { ProspectListIndex } from "components/prospect/ProspectsListIndex";
import { useMQTT } from "utils/mqtt.util";
import { useTwilioVoice } from "hooks/useTwilioVoice";
import {
  useGetProspectNotesQuery,
  useUpdateProspectNoteMutation,
} from "store/api/prospect_note.api";
import moment from "moment";
import { selectProspectEntities } from "store/api/prospect.api";

const drawerWidthOpen = 250;
const drawerWidthCollapsed = 60;
const toolbarHeight = 64;

// Center the title in the toolbar
const ToolbarStyled = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const AdminIndex: React.FC = () => {
  const user = useSelector(selectUser);
  const [selectedVanID, setSelectedVanID] = useState<number>();
  // const { data: schema, error, isLoading } = useGetSchemaQuery({});
  usePreloadData(user.admin);
  useTwilioVoice();
  // useMQTT();
  const { data: flaggedNotes } = useGetProspectNotesQuery({
    params: { flagged: true, nested: "all" },
  });
  const prospectEntities = useSelector(selectProspectEntities);
  const [updateNote] = useUpdateProspectNoteMutation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openStorageDrawer, setOpenStorageDrawer] = useState<boolean>(false);
  const [openAccountDrawer, setOpenAccountDrawer] = useState<boolean>(false);
  const [list, setListActual] = useState<string>("volunteers");
  const setList = (newList: string) => {
    setSelectedVanID(undefined);
    setListActual(newList);
  };
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
  const [anchorElAvatar, setAnchorElAvatar] = useState<null | HTMLElement>(
    null
  );
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleExternalStorageClick = () => {
    setOpenStorageDrawer(true);
  };
  const handleSettingsClick = () => {
    setOpenDrawer(true);
  };

  // Handle avatar menu open/close
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvatar(event.currentTarget);
  };
  const handleAvatarClose = () => {
    setAnchorElAvatar(null);
  };
  const [anchorElNotifications, setAnchorElNotifications] =
    useState<null | HTMLElement>(null);
  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const onLogout = useLogout();
  return (
    <Box
      id="main-container"
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      {/* Top Toolbar */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#22335A",
          borderBottom: (theme) => `1px solid ${theme.palette.primary.light}`,
        }}
      >
        <ToolbarStyled>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            sx={{ mr: 2 }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          {/* Centered Title with Icon */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* <ConnectWithoutContactIcon
              sx={{ mr: 1, color: (theme) => theme.palette.primary.light }}
              fontSize="large"
            /> */}
            <img
              src="https://static.wixstatic.com/media/730684_370b1a730906433f9464d7a28f3bd821~mv2.png/v1/fill/w_384,h_112,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/730684_370b1a730906433f9464d7a28f3bd821~mv2.png"
              style={{ maxHeight: "60px" }}
            />
            <Typography
              variant="h6"
              sx={{
                color: "white",
                py: 0.2,
                px: 1,
                fontStretch: "3px",
                borderRadius: "4px",
                border: (theme) => `4px solid ${theme.palette.primary.light}`,
                fontWeight: "700",
              }}
            >
              VOLUNTIZE ADMIN
            </Typography>
          </Box>
          {/* Right-side Icons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // borderRadius: "4px",
              // border: (theme) => `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            <IconButton color="inherit" onClick={handleNotificationsClick}>
              <Badge badgeContent={flaggedNotes?.length || 0} color="info">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorElNotifications}
              open={Boolean(anchorElNotifications)}
              onClose={handleNotificationsClose}
            >
              <MenuList
                sx={{
                  width: "500px",
                  maxWidth: "75vw",
                  maxHeight: "90vh",
                  overflowY: "scroll",
                }}
              >
                {flaggedNotes?.map((note, i) => (
                  <MenuItem
                    onClick={async () => {
                      await setSelectedVanID(note?.prospect_r?.vanid);
                      // await setList("prospects");
                      handleNotificationsClose();
                    }}
                    key={i}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        variant="overline"
                        component={"div"}
                        sx={{ width: "100%", fontWeight: 600 }}
                      >
                        {`${
                          prospectEntities?.[note.prospect]?.contact_r
                            ?.firstname
                        } ${
                          prospectEntities?.[note.prospect]?.contact_r?.lastname
                        }`}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          value={note.form?.note}
                          fullWidth
                          disabled
                          multiline
                          sx={{
                            // Styles for the label when disabled
                            "& .Mui-disabled": {
                              color: "black", // Label color when disabled
                              opacity: 0.8,
                              WebkitTextFillColor: "unset",
                            },
                          }}
                          slotProps={{
                            input: {
                              sx: {},
                              endAdornment: (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    if (user.admin) {
                                      updateNote({
                                        ...note,
                                        flagged: !note.flagged,
                                      });
                                    }
                                  }}
                                  edge="end"
                                  disabled={!user.admin}
                                >
                                  <Tooltip
                                    title={"Flag the note for admin follow-up"}
                                  >
                                    {note.flagged ? (
                                      <Flag fontSize="small" color="error" />
                                    ) : (
                                      <OutlinedFlag fontSize="small" />
                                    )}
                                  </Tooltip>
                                </IconButton>
                              ),
                            },
                          }}
                          helperText={moment(note.created_at).format(
                            "ddd, MMM D, h:mmA"
                          )}
                        />
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            {/* Notifications Icon with Badge */}
            <IconButton color="inherit" onClick={handleExternalStorageClick}>
              <Tooltip title={"ngpsync schema view"}>
                <Storage />
              </Tooltip>
            </IconButton>
            {/* Notifications Icon with Badge */}
            <IconButton color="inherit" onClick={handleSettingsClick}>
              <Tooltip title={"Settings"}>
                <SettingsOutlined />
              </Tooltip>
            </IconButton>
            {/* Avatar Icon */}

            <IconButton onClick={handleAvatarClick} size="small">
              <Avatar
                alt="User Avatar"
                sx={{
                  bgcolor: stringToColour(user.email?.[0] || ""),
                  border: "2px solid white",
                }}
                variant="rounded"
              >
                {user.email?.[0]?.toUpperCase()}
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorElAvatar}
              open={Boolean(anchorElAvatar)}
              onClose={handleAvatarClose}
            >
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setOpenAccountDrawer(true);
                    handleAvatarClose();
                  }}
                >
                  <ListItemIcon>
                    <AccountBox fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Profile</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onLogout();
                    handleAvatarClose();
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </ToolbarStyled>
      </AppBar>
      <SettingsTabDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      />
      <SchemaDrawer
        open={openStorageDrawer}
        selectedVanID={selectedVanID}
        onClose={() => {
          setSelectedVanID(undefined);
          setOpenStorageDrawer(false);
        }}
      />
      <AccountTabDrawer
        open={openAccountDrawer}
        onClose={() => {
          setOpenAccountDrawer(false);
        }}
      />
      {/* Adjust the margin top to account for the AppBar */}
      <Box
        id="below-appbar"
        sx={{
          display: "flex",
          height: "100%",
          marginTop: `${toolbarHeight}px`,
        }}
      >
        {/* Left Side Menu */}
        <Drawer
          id="left-side-menu"
          elevation={5}
          variant="permanent"
          PaperProps={{ sx: { backgroundColor: "#4F5C7B" } }}
          sx={{
            width: isMenuOpen ? drawerWidthOpen : drawerWidthCollapsed,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isMenuOpen ? drawerWidthOpen : drawerWidthCollapsed,
              boxSizing: "border-box",
              top: `${toolbarHeight}px`, // Ensure the drawer starts below the AppBar
            },
          }}
        >
          <List>
            <Tooltip
              title="Admin list"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "admins" ? theme.palette.info.light : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("admins")}
              >
                <ListItemIcon>
                  <AdminPanelSettings sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="ADMINS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Volunteer list"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "volunteers"
                      ? theme.palette.info.light
                      : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("volunteers")}
              >
                <ListItemIcon>
                  <EmojiPeople sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="VOLUNTEERS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Prospect list"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "prospects" ? theme.palette.info.light : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("prospects")}
              >
                <ListItemIcon>
                  <PeopleOutline sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="PROSPECTS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Mobilize events"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "mobilize_events"
                      ? theme.palette.info.light
                      : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("mobilize_events")}
              >
                <ListItemIcon>
                  <EventOutlined sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="MOBILIZE EVENTS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Event groups"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "event_groups"
                      ? theme.palette.info.light
                      : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("event_groups")}
              >
                <ListItemIcon>
                  <CalendarViewDay sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="EVENT GROUPS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Script templates"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "scripts" ? theme.palette.info.light : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("scripts")}
              >
                <ListItemIcon>
                  <Description sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="SCRIPT TEMPLATES"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Task list"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "tasks" ? theme.palette.info.light : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("tasks")}
              >
                <ListItemIcon>
                  <ListIcon sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="TASKS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>

            {/* Add more menu items here */}
            <Divider
              sx={{
                backgroundColor: (theme) => theme.palette.primary.dark,
                height: 3,
              }}
            />
            <Tooltip
              title="Live task prioritization"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "task_groups"
                      ? theme.palette.info.light
                      : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("task_groups")}
              >
                <ListItemIcon>
                  <Dvr sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="LIVE TASKS"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
            <Divider
              sx={{
                backgroundColor: (theme) => theme.palette.primary.dark,
                height: 3,
              }}
            />
            <Tooltip
              title="Event list (coming soon)"
              placement="right"
              disableHoverListener={isMenuOpen}
            >
              <ListItemButton
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  bgcolor: (theme) =>
                    list === "events" ? theme.palette.info.light : undefined,
                  "&:hover": {
                    bgcolor: (theme) => theme.palette.info.dark,
                  },
                }}
                onClick={() => setList("events")}
              >
                <ListItemIcon>
                  <EventAvailable sx={{ color: "white" }} />
                </ListItemIcon>
                {isMenuOpen && (
                  <ListItemText
                    primary="EVENTS"
                    secondary="(coming soon)"
                    sx={{ color: "white", fontWeight: 900 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
          </List>
        </Drawer>

        {/* Right Side Content */}
        <Box
          id="right-side-content"
          sx={{
            height: `calc(100vh - ${toolbarHeight}px)`,
            width: `calc(100vw - ${
              isMenuOpen ? drawerWidthOpen : drawerWidthCollapsed
            }px)`,
            p: 1,
            bgcolor: (theme) => theme.palette.divider,
          }}
        >
          {list === "admins" && <LoginsIndex />}
          {list === "volunteers" && <VolunteersIndex />}
          {list === "prospects" && (
            <ProspectListIndex
              selectedVanID={selectedVanID}
              setSelectedVanID={setSelectedVanID}
            />
          )}
          {list === "events" && <EventsIndex />}
          {list === "mobilize_events" && <MobilizeEventsIndex />}
          {list === "tasks" && <TasksIndex />}
          {list === "event_groups" && <MobilizeEventGroupsIndex />}
          {list === "scripts" && <ScriptsIndex />}
          {list === "task_groups" && <TaskGroupsIndex />}
        </Box>
      </Box>
    </Box>
  );
};

export const stringToColour = (str: string): string => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = "#";

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;

    colour += ("00" + value.toString(16)).slice(-2);
  }

  return colour;
};
