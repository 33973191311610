import {
  createEntityAdapter,
  EntityState,
  EntityId,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { baseApi } from "./common.api";
import { SignupLink } from "./models";
import { RootState } from "store/reducer";

// Create an EntityAdapter for the SignupLink entity
const signupLinkAdapter = createEntityAdapter<SignupLink, EntityId>({
  selectId: (signup_link: SignupLink) => signup_link.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Define the initial state using the adapter's getInitialState method
interface SignupLinksState extends EntityState<SignupLink, EntityId> {
  // Add any additional state properties here if needed
}

const initialSignupLinkState: SignupLinksState =
  signupLinkAdapter.getInitialState();

// Selectors for SignupLinks
export const {
  selectById: selectSignupLinkById,
  selectAll: selectAllSignupLinks,
  selectEntities: selectSignupLinkEntities,
  selectIds: selectSignupLinkIds,
  selectTotal: selectSignupLinkTotal,
} = signupLinkAdapter.getSelectors((state: RootState) => state?.signup_link);

export const signupLinkSlice = createSlice({
  name: "signup_link",
  initialState: initialSignupLinkState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(signup_link.endpoints.getSignupLinks.matchFulfilled),
      (state, action) => {
        signupLinkAdapter.upsertMany(state, action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        signup_link.endpoints.createSignupLink.matchFulfilled,
        signup_link.endpoints.updateSignupLink.matchFulfilled,
        signup_link.endpoints.patchSignupLink.matchFulfilled
      ),
      (state, action) => {
        signupLinkAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addMatcher(
      signup_link.endpoints.deleteSignupLink.matchFulfilled,
      (state, action) => {
        signupLinkAdapter.removeOne(state, action.meta.arg.originalArgs);
      }
    );
  },
});

// Define your API slice
export const signup_link = baseApi
  .enhanceEndpoints({ addTagTypes: ["SignupLinks", "SignupLink"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSignupLinks: builder.query<SignupLink[], { params?: object }>({
        query: ({ params }: { params?: object }) => ({
          url: "signup_link",
          method: "GET",
          params: params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "SignupLink" as const,
                  id,
                })),
                { type: "SignupLinks" as const },
              ]
            : [{ type: "SignupLinks" as const }],
      }),
      getSignupLink: builder.query<SignupLink, { id: number; params?: object }>(
        {
          query: ({ id, params }: { id: number; params?: object }) => ({
            url: `signup_link/${id}`,
            method: "GET",
            params: params,
          }),
          providesTags: (result, error, arg) => [
            { type: "SignupLink", id: arg.id },
          ],
          keepUnusedDataFor: 90,
        }
      ),
      createSignupLink: builder.mutation<SignupLink, Partial<SignupLink>>({
        query: (request) => ({
          url: "signup_link",
          method: "POST",
          body: request,
        }),
        invalidatesTags: [{ type: "SignupLinks" }],
      }),
      patchSignupLink: builder.mutation<SignupLink, SignupLink>({
        query: (request) => ({
          url: `signup_link/${request.id}`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "SignupLink", id: arg.id },
        ],
      }),
      updateSignupLink: builder.mutation<SignupLink, SignupLink>({
        query: (request) => ({
          url: `signup_link/${request.id}`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "SignupLink", id: arg.id },
        ],
      }),
      deleteSignupLink: builder.mutation<void, number>({
        query: (id) => ({
          url: `signup_link/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "SignupLink", id: arg },
          { type: "SignupLinks" },
        ],
      }),
    }),
  });

// Auto-generated hooks
export const {
  useGetSignupLinksQuery,
  useGetSignupLinkQuery,
  useCreateSignupLinkMutation,
  usePatchSignupLinkMutation,
  useUpdateSignupLinkMutation,
  useDeleteSignupLinkMutation,
  usePrefetch: useSignupLinksPrefetch,
} = signup_link;
