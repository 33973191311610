import { useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Divider,
  Typography,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
  ListItemIcon,
  Chip,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  AnimationOutlined,
  AccountCircleOutlined,
  Search as SearchIcon,
  Delete,
  Edit,
  ContentCopy,
  ListAlt,
  TaskAlt,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Skill, Task } from "store/api/models";
import { useDeleteTaskMutation } from "store/api/task.api";
import { sortString } from "utils";
import { AlertDialog } from "dialogs";
import { EntityId } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { selectSkillEntities } from "store";
import { RootState } from "store/reducer";

export const TaskList = ({
  tasks,
  selectedTask,
  handleDuplicateTask = (task: Task) => {},
  close,
  handleTaskClick,
  handleTaskEditClick,
}: {
  tasks: Task[];
  selectedTask?: Task;
  handleDuplicateTask?: (task: Task) => void;
  close: Function;
  handleTaskClick: (task: Task) => void;
  handleTaskEditClick: (task: Task) => void;
}) => {
  const [openAlert, setOpenAlert] = useState<number>();
  const [view, setView] = useState<"skill" | "task">("task");
  const [search, setSearch] = useState<string>("");
  const [deleteTask] = useDeleteTaskMutation();
  const skills = useSelector((state: RootState) => selectSkillEntities(state));
  const filteredTasks = [...(tasks || [])]
    ?.filter((t) => {
      const sklls = t.skills?.map((s) => skills[s]);
      if (view === "task") {
        return t.title?.toLowerCase()?.includes(search.toLowerCase());
      } else {
        //if (view === "skill")
        return (
          t.title?.toLowerCase()?.includes(search.toLowerCase()) ||
          sklls?.find((s) =>
            s?.name?.toLowerCase()?.includes(search.toLowerCase())
          )
        );
      }
    })
    ?.sort((a, b) => sortString(a.title, b.title));
  const filteredSkills = groupTasksBySkill(filteredTasks, skills);

  const ListTask = ({ task }: { task: Task }) => {
    return (
      <>
        <ListItem
          key={task.id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: (theme) =>
              selectedTask && selectedTask?.id === task.id
                ? theme.palette.divider
                : undefined,
          }}
          dense
          secondaryAction={
            <Box
              sx={{
                borderRadius: "4px",
                border: (theme) => `1px solid ${theme.palette.secondary.main}`,
              }}
            >
              <Tooltip title={"duplicate"}>
                <IconButton
                  onClick={() =>
                    handleDuplicateTask({
                      ...task,
                      id: 0,
                    } as Task)
                  }
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Edit"}>
                <IconButton onClick={() => handleTaskEditClick(task)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"}>
                {openAlert && openAlert === task.id ? (
                  <IconButton>
                    <CircularProgress size={22} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setOpenAlert(task.id)}>
                    <Delete />
                  </IconButton>
                )}
              </Tooltip>
            </Box>
          }
        >
          <ListItemIcon>
            <TaskAlt
              sx={{ color: (theme) => theme.palette.secondary.main }}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="overline" sx={{ fontWeight: 700 }}>
                {task.title}
              </Typography>
            }
            secondary={
              <div>
                {task?.skills?.map((s) => (
                  <Chip
                    size="small"
                    label={skills[s]?.name}
                    variant="outlined"
                    color="secondary"
                    sx={{ zoom: "75%", mr: 0.5 }}
                  />
                ))}
              </div>
            }
            onClick={() => handleTaskClick(task)}
            sx={{ cursor: "pointer", py: 0, my: 0 }}
          />
        </ListItem>
        <Divider />
      </>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <Box
        id="topflex"
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          height: "100%",
        }}
      >
        <AlertDialog
          open={!!openAlert}
          title={`Delete task`}
          content={`Are you sure you want to delete this task?`}
          onClose={() => {
            setOpenAlert(undefined);
          }}
          onConfirm={async () => {
            if (openAlert && openAlert > 0) {
              try {
                await deleteTask(openAlert);
                close();
                toast.success("Task successfully deleted");
              } catch (error) {
                toast.success("Unable to delete task");
              }
            }
            setOpenAlert(undefined);
          }}
        />
        <Box sx={{ pt: 0, pb: { xs: 3, sm: 1 } }}>
          <Grid
            container
            alignItems={"bottom"}
            justifyContent={"space-between"}
            spacing={0}
          >
            <Grid>
              <Typography variant="h6" component={"span"}>
                {view === "skill" ? (
                  <span>
                    TASK LIST{" "}
                    <small>
                      <i>(Grouped by Skill)</i>
                    </small>
                  </span>
                ) : (
                  "TASK LIST"
                )}
              </Typography>
            </Grid>
            <Grid>
              <Grid
                container
                alignItems={"bottom"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid>
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(event: any, value: any) => setView(value)}
                  >
                    <ToggleButton value="task">
                      <ListAlt />
                    </ToggleButton>
                    <ToggleButton value="skill">
                      <AnimationOutlined />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid sx={{ textAlign: "right" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search Tasks"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(event) =>
                      setSearch((event?.target as any)?.value)
                    }
                    sx={{ maxWidth: { xs: "none", sm: 340 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <List
          dense
          disablePadding
          sx={{
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          {view === "task" &&
            filteredTasks?.map((task: Task) => <ListTask task={task} />)}
          {view === "skill" &&
            [...Object.keys(filteredSkills)].sort()?.map((skill, rindex) => (
              <>
                <ListItem
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[100],
                    fontWeight: "600",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "unset", pr: 2 }}>
                    <AccountCircleOutlined color="secondary" />
                  </ListItemIcon>
                  {skill}
                </ListItem>
                <Box sx={{ pl: 2 }}>
                  {filteredSkills[skill]?.tasks?.map((t: Task) => (
                    <ListTask task={t} />
                  ))}
                </Box>
              </>
            ))}
        </List>
      </Box>
    </Box>
  );
};

// Helper function to group tasks by skill
export const groupTasksBySkill = (
  tasks: Task[],
  skills: Record<EntityId, Skill>
) => {
  const skillMap: {
    [key: string]: { tasks: any[] };
  } = {};
  tasks?.forEach((task) => {
    task?.skills?.forEach((skill) => {
      if (!skillMap[skills[skill]?.name || " "]) {
        skillMap[skills[skill]?.name || " "] = {
          tasks: [],
        };
      }
      skillMap[skills[skill]?.name || " "].tasks.push(task);
    });
  });
  Object.keys(skillMap)?.forEach((skill: string) => {
    skillMap[skill].tasks = [...(skillMap[skill].tasks || [])].sort(
      (a: any, b: any) => sortString(a.title, b.title)
    );
  });

  return skillMap;
};
